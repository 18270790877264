import React from 'react';
import { withRouter, Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as tableActions from '../../../store/actions/table';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { FormControl, Checkbox } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Formik, useFormikContext } from 'formik';
import * as yup from 'yup';
import CategoryPicker from '../../Common/CategoryPicker';
import * as eventActions from '../../../store/actions/events';
import {Checkmark, Trash} from 'react-ionicons';
import TextareaAutosize from 'react-textarea-autosize';
import ReactTooltip from 'react-tooltip';
import humanizeDuration from 'humanize-duration';
import PageLoader from "../../Common/PageLoader";

const schema = yup.object({
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('This field is required'),
  password: yup.string().min(8, 'Password needs to be at least 8 characters long'),
  password2: yup.mixed().test('match', 'Password does not match', function (password2) {
    return password2 === this.parent.password;
  })
});

const PromptIfDirty = () => {
  const formik = useFormikContext();
  console.log("Round prmopting", formik.dirty);
  return (
    <Prompt
      when={formik.dirty && formik.submitCount === 0}
      message="Are you sure you want to leave? You have with unsaved changes."
    />
  );
};


class TabulatorJudgeTable extends React.Component {
  // Since the naming is horribly unclear, this is the one to use when the top tabs are rounds
  constructor(props) {
    super(props);
    this.state = {
      locked: [false, false, false, false],
      items: [],
      editing: [],
      timer: null
    };
  }

  componentDidMount(props) {
    ReactTooltip.rebuild();
    // if (this.props.table.view !== '') {
    // }

    // let self = this;
    // if (!this.state.timer) {
    //   let timer = setInterval(() => {
    //     self.props.updateTabScore(
    //       self.props.events.competition.template,
    //       self.props.events.competition.entries,
    //       self.props.auth.token.token._id,
    //       true
    //     );
    //   }, 15000);
    //   this.setState({ timer: timer });
    // }
  }

  componentWillUnmount() {
    if (this.state.timer) {
      clearInterval(this.state.timer);
      this.setState({ timer: null });
    }
  }

  componentDidUpdate(props, state) {
    if (
      (this.props.table.data.length !== this.state.items.length ||
        this.props.table.updateCount > props.table.updateCount ||
        this.props.table.data.length !== this.state.items.length) &&
      this.props.table.view === ''
    ) {
      this.setState({ items: this.props.table.data });
    }
  }

  emptyJudge = {
    name: 'Not',
    lname: 'Selected',
    email: ''
  };

  toggleLock = (val) => {
    let tab = this.props.events.competition.tabulation;
    tab['round' + this.props.match.params.round + 'Locked'] =
      !tab['round' + this.props.match.params.round + 'Locked'];
    this.props.updateTabulationObject(tab, this.props.auth.token.token._id);
  };

  renderSelector = () => {
    let judgeCountArray = this.props.events.competition.competition.grade.scoring.judge_count;
    let thisRound  = parseInt(this.props.match.params.round);
    let judgeMap = Array(judgeCountArray[thisRound - 1] + 1).fill(1, 0);

    return (
      <tr className={this.props.match.params.round === '3' && false ? 'semi-transparent' : ''}>
        <td width={400}>
          <Row>
            <Col xs={12}>
              <CategoryPicker
                value={{}}
                tabIndex={1 * this.props.events.competition.entries.length + 1}
                id={'dancerSelector'}
                itemValue={'_id'}
                onChange={(val) => {
                  if (val) {
                    if (
                      this.props.events.competition.entries.filter((en) => en._id === val._id)
                        .length === 0
                    ) {
                      this.props.addDancer(
                        val._id,
                        this.props.match.params.competition,
                        this.props.auth.token._id,
                        (res) => {}
                      );
                    }
                  }
                }}
                remote={true}
                remoteKey={'dancers'}
                urlRoute={`/searchDancerNumber?competition=${this.props.match.params.competition}&event=${this.props.match.params.id}`}
                displayValue={'dancer.name'}
                displayValue3={''}
                imageKey={'image'}
                localState={true}
                displayValue2={'dancer.lname'}
                showDelete={false}
                disableUpdateFetch={true}
              />
            </Col>
          </Row>
        </td>
        {judgeMap.map((n, ni) => {
          if (ni === judgeMap.length - 1) {
            let rel = [];
            if (thisRound === 4) {
            } else {
            }

            if (thisRound === 4) {
              return (
                <td
                  key={ni + 'n'}>
                  <Row>
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                  </Row>
                </td>
              );
            } else if (thisRound === 3) {
              return (
                <td
                  key={ni + 'n'}>
                  <Row></Row>
                </td>
              );
            } else if (thisRound === 2) {
              return null;
            } else {
              if (rel.length > 0) {
                return (
                  <td
                    key={ni + 'n'}>
                    <Row></Row>
                  </td>
                );
              } else {
                return (
                  <td
                    key={ni + 'n'}>
                    <Row></Row>
                  </td>
                );
              }
            }
          } else {
            if (thisRound === 4) {
              return (
                <td>
                  <Row>
                    <Col xs={4}></Col>
                    <Col xs={4}></Col>
                    <Col xs={4}></Col>
                  </Row>
                </td>
              );
            } else {
              return null;
            }
          }
        })}
      </tr>
    );
  };

  render() {
    let judgeCountArray = this.props.events.competition.competition.grade.scoring.judge_count;
    let roundsCount = this.props.events.competition.competition.grade.scoring.rounds;
    let thisRound  = parseInt(this.props.match.params.round);
    let judgeMap = Array(judgeCountArray[thisRound - 1] + 1).fill(1, 0);
    let rotating = this.props.events.competition.competition.grade.scoring.judge_rotation_type === 'ROTATING';
    let split = this.props.events.competition.competition.grade.scoring.judge_rotation_type === 'SPLIT';
    let setPanel = this.props.events.competition.competition.grade.scoring.judge_rotation_type === 'SET';
    let showSignoffs = this.props.events.competition.competition.grade.judgeSignoffs;
    let priorRoundJudgeCount = 0;

    // Double checked property was added to tabulation object. If this is a comp that existed before that, we need to initialize it
    // TODO move this to a common location for all tabulation screens
    for (let i = 1; i <= roundsCount; i++) {
      if (!this.props.events.competition.tabulation["round" + i + "DoubleChecked"]) {
          this.props.events.competition.tabulation["round" + i + "DoubleChecked"] = [];
      }
    }

    if (rotating) {
      for (let x = 1; x < thisRound; x++) {
        priorRoundJudgeCount += judgeCountArray[x - 1];
      }
    } else if (split && thisRound === 3) {
      priorRoundJudgeCount = judgeCountArray[0];
    }

    return (
      <PageLoader loaded={!this.props.events.loading}>
        <Formik
          validationSchema={schema}
          onSubmit={this.handleSubmit}
          enableReinitialize
          initialValues={{
            template: this.props.events.competition.template
          }}>
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            isSubmitting,
            setFieldValue
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <PromptIfDirty />
              <div>
                <div className={'tabulator-page-header'}>
                  <Row>
                    <Col xs={9}>
                      <h2>Round {this.props.round}</h2>
                      <h4>
                        {this.props.events.competition ? (
                          this.props.events.competition.competition ? (
                            this.props.events.competition.competition.grade ? (
                              this.props.events.competition.competition.grade.rounds === 1 ? (
                                <span></span>
                              ) : null
                            ) : null
                          ) : null
                        ) : null}
                      </h4>
                    </Col>
                    <Col className={'text-right'}>
                      <button
                        type="button"
                        className="btn btn-flat"
                        onClick={() => this.toggleLock(this.props.match.params.round)}>
                        {this.props.events.competition.tabulation ? (
                          this.props.events.competition.tabulation[
                            'round' + this.props.match.params.round + 'Locked'
                          ] ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="64"
                              height="48"
                              fill="currentColor"
                              className="bi bi-lock-fill"
                              viewBox="0 0 16 16">
                              <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="64"
                              height="48"
                              fill="currentColor"
                              className="bi bi-unlock-fill"
                              viewBox="0 0 16 16">
                              <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2z" />
                            </svg>
                          )
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="64"
                            height="48"
                            fill="currentColor"
                            className="bi bi-unlock-fill"
                            viewBox="0 0 16 16">
                            <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2z" />
                          </svg>
                        )}
                      </button>
                      <div className={'autosave'}>
                        {this.props.events.autosaved ? (
                          this.props.events.autosaved.at ? (
                            <span>
                              Last Saved:{' '}
                              {humanizeDuration(
                                new Date().getTime() -
                                  new Date(this.props.events.autosaved.at).getTime(),
                                { units: ['y', 'mo', 'w', 'd', 'h', 'm'], round: true }
                              )}{' '}
                              ago{' '}
                              {this.props.events.autosaved.type === 'autosave' ? `(autosave)` : ``}
                            </span>
                          ) : null
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </div>

                <Row className={'tabulator-table-pad'} style={{overflowX: "scroll", paddingBottom: "50px"}}>
                  <Table striped hover bordered className={'tab-table'} style={{tableLayout: "fixed"}}>
                    <thead>
                      <tr>
                        <th style={{width: "500px"}}>
                          <b>Dancer</b>
                        </th>
                        {judgeMap.map((n, ni) => {
                          if (ni === judgeMap.length - 1) {
                            return (
                              <th key={ni} width={this.props.match.params.round === "3" ? 250 : 44}>
                                  <span></span>
                              </th>
                            );
                          } else {
                            return (
                              <th key={ni} style={{width: "375px"}}>
                                {ni === judgeMap.length - 1 ? null : (
                                  <Row>
                                    <Col xs={4}>
                                      <span className={'float-left'}>
                                        {judgeCountArray[thisRound - 1] === 1
                                          ? 'Judge'
                                          : 'Judge ' + (priorRoundJudgeCount + ni + 1).toString()}
                                      </span>
                                    </Col>
                                    <Col xs={12}>
                                      <span className={"tab-table-double-checked-span"}><b>Double Checked</b></span>
                                      <FormControl
                                          disabled={this.props.locked}
                                          type="checkbox"
                                          name="doubleChecked"
                                          checked={this.props.events.competition.tabulation ? this.props.events.competition.tabulation[
                                              `round${this.props.match.params.round}DoubleChecked`][ni] : false}
                                          onChange={val => {
                                              let tab = this.props.events.competition.tabulation;
                                              tab[`round${this.props.match.params.round}DoubleChecked`][ni] = !tab[`round${this.props.match.params.round}DoubleChecked`][ni]

                                              this.props.updateTabulationObject(tab, this.props.auth.token.token._id);
                                          }}
                                          className={"no-width tab-table-double-checked"}
                                      />
                                    {showSignoffs ?
                                      <React.Fragment>
                                          <FormControl
                                            type="checkbox"
                                            checked={this.props.events.competition.tabulation[
                                              `round${this.props.match.params.round}SignedOff`][ni]
                                            }
                                            className={'no-width float-right'}
                                            name={`signedoff${this.props.match.params.round}${ni}`}
                                            onChange={(e) => {
                                              const target = e.target
                                              const checked = target.checked
                                              const name = target.name
                                              this.props.events.competition.tabulation[
                                                `round${this.props.match.params.round}SignedOff`][ni] = checked;
                                              this.setState({
                                                  [name]: checked,
                                              });
                                            }}
                                            disabled={this.props.locked}/>
                                          <span className={'float-right  pr-1'}>
                                            <b>
                                              Signed off?
                                              <br /> (
                                              <a
                                                href={`/app/pdf/${this.props.match.params.round}/${ni}/rank`}
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                Rank
                                              </a>
                                              ,{' '}
                                              <a
                                                href={`/app/pdf/${this.props.match.params.round}/${ni}/dancer`}
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                Dancer
                                              </a>
                                              )
                                            </b>
                                          </span>
                                      </React.Fragment>
                                      : null
                                    }
                                    </Col>
                                  </Row>
                                )}
                              </th>
                            );
                          }
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        {judgeMap.map((n, ni) => {
                          if (ni === judgeMap.length - 1) {
                            return null;
                          } else {
                            if (thisRound === 4) {
                              return null;
                            } else {
                              return (
                                <td key={ni}>
                                  <Row>
                                    <Col xs={12}>
                                      <CategoryPicker
                                        value={
                                          this.props.events.competition.template['rounds'][
                                            this.props.match.params.round
                                          ].judges[ni]
                                            ? this.props.events.competition.template['rounds'][
                                                this.props.match.params.round
                                              ].judges[ni]
                                            : {}
                                        }
                                        itemValue={'_id'}
                                        onChange={(val) => {
                                          this.props.updateJudge(
                                            this.props.match.params.round,
                                            ni,
                                            val
                                          );
                                        }}
                                        remote={true}
                                        remoteKey={'users'}
                                        urlRoute={'/search'}
                                        displayValue={'name'}
                                        displayValue3={'email'}
                                        imageKey={'image'}
                                        localState={true}
                                        displayValue2={'lname'}
                                        showDelete={false}
                                      />
                                    </Col>
                                    {/* <Col xs={3}>
                                      <FormControl
                                        type={'text'}
                                        className={'microInput'}
                                        placeholder={'Label'}
                                        disabled={
                                          this.props.events.competition.tabulation[
                                            `round${this.props.match.params.round}Locked`
                                          ] ||
                                          this.props.events.competition.tabulation[
                                            `round${this.props.match.params.round}SignedOff`
                                          ][ni]
                                        }
                                        value={
                                          this.props.events.competition.template.rounds[
                                            this.props.match.params.round.toString()
                                          ].judges[ni]
                                            ? this.props.events.competition.template.rounds[
                                                this.props.match.params.round.toString()
                                              ].judges[ni].label
                                            : ''
                                        }
                                        onChange={(e) => {
                                          this.props.updateJudgeLabel(
                                            this.props.match.params.round,
                                            ni,
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </Col> */}
                                  </Row>
                                </td>
                              );
                            }
                          }
                        })}
                      </tr>

                      <tr>
                        <td>
                          <Row>
                            <Col xs={3}>
                              <b>Name</b>
                            </Col>
                            <Col xs={3}>
                              <b>School</b>
                            </Col>
                            <Col xs={4}>
                              <div className={'text-center'}>
                                <b>
                                  <b>Number</b>
                                </b>
                              </div>
                            </Col>
                            <Col xs={2}></Col>
                          </Row>
                        </td>
                        {judgeMap.map((n, ni) => {
                          if (ni === judgeMap.length - 1) {
                            if (thisRound === 2) {
                              return null;
                            } else if (thisRound === 3) {
                              return (
                                <td key={ni}>
                                  <Row>
                                    <Col xs={6}>
                                      <div className={'text-center'}>
                                        <b>
                                          <b>IP All Rounds</b>
                                        </b>
                                      </div>
                                    </Col>
                                    <Col xs={6}>
                                      <div className={'text-center'}>
                                        <b>
                                          <b>Final Rank</b>
                                        </b>
                                      </div>
                                    </Col>

                                    {/* <Col xs={4}>
                                      <b>Placed</b>
                                    </Col> */}
                                  </Row>
                                </td>
                              );
                            } else if (this.props.match.params.round === 'overview') {
                              return null;
                            } else {
                              if (this.props.events.competition.competition.grade.rounds === 1) {
                                return null;
                              } else {
                                return null;
                              }
                            }
                          } else {
                            return (
                              <td key={ni}>
                                <Row>
                                  <Col xs={6}>
                                    <b>Score</b>
                                  </Col>
                                  <Col xs={6}>
                                    <b>Comments</b>
                                  </Col>
                                </Row>
                              </td>
                            );
                          }
                        })}
                      </tr>

                      {this.props.events.competition
                        ? this.props.events.competition.entries
                          ? this.props.events.competition.entries.map
                            ? this.props.events.competition.entries.map((comp, i) => {
                                let r1Rel = this.props.events.competition.template.rounds[
                                  '1'
                                ].scores[0].filter((item, index) => {
                                  return item.entryCompetition === comp._id;
                                });
                                let self = this;
                                let readOnly = this.props.match.params.round === '3' && this.props.events.competition.competition.recallCalculated && r1Rel[0] && !r1Rel[0].recall;

                                return (
                                  <tr
                                    key={r1Rel.length > 0 ? r1Rel[0]._id + i : i}
                                    style={{opacity: readOnly ? "0.5" : "1"}}>
                                    <td
                                      data-tip={'Click to assign to another dancer'}
                                      onClick={(e) => {
                                        this.setState(
                                          {editing: [comp._id]},
                                          () => {
                                            let inputElement = document.getElementById(comp._id);
                                            if (inputElement) {
                                                inputElement.focus();
                                            }
                                          });
                                      }}>
                                      {this.state.editing.indexOf(comp._id) > -1 ? (
                                        <Row>
                                          <Col>
                                            <CategoryPicker
                                              id={comp._id}
                                              items={[comp]}
                                              value={comp}
                                              tabIndex={
                                                1 * this.props.events.competition.entries.length + 1
                                              }
                                              itemValue={'_id'}
                                              onChange={(val) => {
                                                if (val) {
                                                  if (
                                                    this.props.events.competition.entries.filter(
                                                      (en) => en._id === val._id
                                                    ).length === 0
                                                  ) {
                                                    this.props.swapDancer(
                                                      val._id,
                                                      comp._id,
                                                      this.props.match.params.competition,
                                                      this.props.auth.token._id
                                                    );
                                                  } else {
                                                    // This dancer already has an entry, meaning the user did not select a new value
                                                    this.setState({editing: []});
                                                  }
                                                }
                                              }}
                                              remote={true}
                                              remoteKey={'dancers'}
                                              urlRoute={`/searchDancerNumber?competition=${this.props.match.params.competition}&event=${this.props.match.params.id}`}
                                              displayValue={'dancer.name'}
                                              displayValue3={''}
                                              imageKey={'image'}
                                              localState={true}
                                              displayValue2={'dancer.lname'}
                                              showCancel={true}
                                              onCancel={(e) => {
                                                self.setState(
                                                  {
                                                    editing: []
                                                  },
                                                  () => {}
                                                );
                                              }}
                                              disableUpdateFetch={true}
                                              selectOnBlur={true}
                                              readOnly={readOnly}
                                            />
                                          </Col>
                                        </Row>
                                      ) : (
                                        <Row>
                                          <Col xs={3}>
                                            {comp.dancer.name} {comp.dancer.lname}
                                          </Col>
                                          <Col xs={3}>
                                            {comp.dancer.school ? comp.dancer.school.name : ''}
                                          </Col>
                                          <Col xs={4}>
                                            <div className={'text-center'}>
                                              <b>{comp.dancerNumber || 0}</b>
                                            </div>
                                          </Col>
                                          <Col xs={2}>
                                            <Button variant={'flat'} className={"btn-small-padding"} onClick={(e) => {
                                              e.stopPropagation();
                                              e.preventDefault();

                                              let deleteConfirm = window.confirm("This will remove the dancer record from tabulation.\nAre you sure you want to continue?");
                                              if (deleteConfirm) {
                                                // Remove dancer from comp
                                                this.props.removeDancer(comp._id, comp.competition._id, this.props.auth.token._id, null)
                                              }
                                            }}><Trash fontSize={'18px'} {...{color: 'rgba(0,0,0,.5)'}} /></Button>
                                          </Col>
                                        </Row>
                                      )}
                                      <Col xs={6}>
                                        <div className={'text-center'}></div>
                                      </Col>
                                    </td>
                                    {judgeMap.map((n, ni) => {
                                      let r = this.props.match.params.round.toString();

                                      if (ni === judgeMap.length - 1) {
                                        let rel = [];
                                        if (thisRound === 4) {
                                          rel = this.props.events.competition.template.rounds[
                                            '1'
                                          ].scores[0].filter((item, index) => {
                                            return item.entryCompetition === comp._id;
                                          });
                                        } else {
                                          rel = this.props.events.competition.template.rounds[
                                            '1'
                                          ].scores[0].filter((item, index) => {
                                            return item.entryCompetition === comp._id;
                                          });
                                        }

                                        if (thisRound === 4) {
                                          return (
                                            <td
                                              key={i * ni + rel[0]._id}>
                                              <Row>
                                                <Col></Col>
                                                <Col></Col>
                                                <Col></Col>
                                                <Col></Col>
                                                <Col></Col>
                                                <Col></Col>
                                              </Row>
                                            </td>
                                          );
                                        } else if (thisRound === 3) {
                                          return (
                                            <td
                                              key={(1 + i) * (1 + ni)}>
                                              <Row>
                                                <Col xs={6}>
                                                  <span className={'slim'}>
                                                    <div className={'text-center'}>
                                                      {readOnly ? "Not Recalled" : rel.length > 0 && rel[0]['finalIp'] >= 0 ? rel[0]['finalIp'].toFixed(2) : ''}
                                                    </div>
                                                  </span>
                                                </Col>
                                                <Col xs={6}>
                                                  <div className={'text-center'}>
                                                  {readOnly ? <span>Not Recalled</span> :
                                                    <b> {rel.length > 0 && rel[0]['finalRank'] >= 0 ? rel[0]['finalRank'] : ''}</b>}
                                                  </div>
                                                </Col>

                                                {/* <Col xs={4}>
                                                  <FormControl
                                                    disabled={this.props.locked}
                                                    type="checkbox"
                                                    name="recall"
                                                    checked={r1Rel[0].wq || false}
                                                    onChange={(e) => {
                                                      this.props.updateEntryScore(
                                                        r1Rel[0],
                                                        3,
                                                        'wq',
                                                        !r1Rel[0].wq
                                                      );
                                                    }}
                                                    className={'no-width float-right'}
                                                  />
                                                </Col> */}
                                              </Row>
                                            </td>
                                          );
                                        } else if (thisRound === 2) {
                                          return null;
                                        } else {
                                          if (rel.length > 0) {
                                            if (
                                              this.props.events.competition.competition.grade
                                                .rounds === 1
                                            ) {
                                              return null;
                                            } else {
                                              return null;
                                            }
                                          } else {
                                            return null;
                                          }
                                        }
                                      } else {
                                        if (thisRound === 4) {
                                          return (
                                            <td key={(1 + i) * (1 + ni)}>
                                              <Row>
                                                <Col xs={4}></Col>
                                                <Col xs={4}>
                                                  <span className={'form-control slim'}></span>
                                                </Col>
                                                <Col xs={4}>
                                                  <span className={'form-control slim'}></span>
                                                </Col>
                                              </Row>
                                            </td>
                                          );
                                        } else {
                                          let rel = this.props.events.competition.template.rounds[r]
                                            .scores[ni]
                                            ? this.props.events.competition.template.rounds[r].scores[
                                                ni
                                              ].filter((item, index) => {
                                                return item.entryCompetition === comp._id;
                                              })
                                            : [{}];

                                          let className = '';
                                          let score = parseFloat(rel.length > 0 ? rel[0][`round${r}Score`] : 0);

                                          if (score > -1) {
                                            if (score >= 0 && score <= 100) {
                                              className = 'is-valid';
                                            } else {
                                              className = 'is-invalid';
                                            }
                                          } else {
                                            //console.log('SNE', score, 'not above', -1);
                                          }

                                          return (
                                            <td key={(1 + i) * (1 + ni)}>
                                              <Row>
                                                <Col xs={6}>
                                                  <FormControl
                                                    tabIndex={ni*2 + 1}
                                                    type={'number'}
                                                    className={`microInput ${className}`}
                                                    placeholder={'Score'}
                                                    id={rel[0] ? rel[0]._id : ''}
                                                    disabled={
                                                      this.props.events.competition.tabulation[
                                                        `round${this.props.match.params.round}Locked`
                                                      ] ||
                                                      this.props.events.competition.tabulation[
                                                        `round${this.props.match.params.round}SignedOff`
                                                      ][ni] || 
                                                      this.props.events.competition.tabulation[
                                                        `round${this.props.match.params.round}DoubleChecked`
                                                      ][ni] || readOnly
                                                    }
                                                    value={score > -1 ? score : ''}
                                                    onChange={(e) => {
                                                      this.props.updateScore(
                                                        rel[0],
                                                        parseFloat(e.target.value),
                                                        ni,
                                                        this.props.match.params.round
                                                      );
                                                      setFieldValue('template', {
                                                        ...this.props.events.competition.template,
                                                        rounds: {
                                                          ...this.props.events.competition.template
                                                            .rounds,
                                                          [r]: {
                                                            ...this.props.events.competition.template
                                                              .rounds[r],
                                                            scores:
                                                              this.props.events.competition.template.rounds[
                                                                r
                                                              ].scores.map((score, index) => {
                                                                return score.map((scoreItem) => {
                                                                  if (scoreItem._id === rel[0]._id) {
                                                                    return {
                                                                      ...scoreItem,
                                                                      ['round' + r + 'Score']:
                                                                        e.target.value
                                                                    };
                                                                  } else {
                                                                    return scoreItem;
                                                                  }
                                                                });
                                                              })
                                                          }
                                                        }
                                                      });
                                                    }}
                                                  />
                                                </Col>

                                                <Col xs={6}>
                                                  <TextareaAutosize
                                                    tabIndex={ni*2 + 2}
                                                    type={'text'}
                                                    className={'form-control microInput'}
                                                    placeholder={'Comments'}
                                                    id={rel[0] ? rel[0]._id : ''}
                                                    disabled={
                                                      this.props.events.competition.tabulation[
                                                        `round${this.props.match.params.round}Locked`
                                                      ] ||
                                                      this.props.events.competition.tabulation[
                                                        `round${this.props.match.params.round}SignedOff`
                                                      ][ni] || 
                                                      this.props.events.competition.tabulation[
                                                        `round${this.props.match.params.round}DoubleChecked`
                                                      ][ni] || readOnly
                                                    }
                                                    value={
                                                      rel[0]
                                                        ? rel[0]['round' + r + 'Comments'] || ''
                                                        : ''
                                                    }
                                                    onChange={(e) => {
                                                      this.props.updateComments(
                                                        rel[0],
                                                        e.target.value,
                                                        ni,
                                                        this.props.match.params.round
                                                      );
                                                      setFieldValue('template', {
                                                        ...this.props.events.competition.template,
                                                        rounds: {
                                                          ...this.props.events.competition.template
                                                            .rounds,
                                                          [r]: {
                                                            ...this.props.events.competition.template
                                                              .rounds[r],
                                                            scores:
                                                              this.props.events.competition.template.rounds[
                                                                r
                                                              ].scores.map((score, index) => {
                                                                return score.map((scoreItem) => {
                                                                  if (scoreItem._id === rel[0]._id) {
                                                                    return {
                                                                      ...scoreItem,
                                                                      ['round' + r + 'Comments']:
                                                                        e.target.value
                                                                    };
                                                                  } else {
                                                                    return scoreItem;
                                                                  }
                                                                });
                                                              })
                                                          }
                                                        }
                                                      });
                                                    }}
                                                  />
                                                </Col>
                                              </Row>
                                            </td>
                                          );
                                        }
                                      }
                                    })}
                                  </tr>
                                );
                              })
                            : null
                          : null
                        : null}

                      {this.renderSelector()}
                    </tbody>
                  </Table>
                </Row>
                <div className={'text-right ex-pad'}>
                  <Button
                    variant={'primary'}
                    onClick={(e) => {
                      e.preventDefault();

                      this.props.updateTabScore(
                        this.props.events.competition.template,
                        this.props.events.competition.tabulation,
                        this.props.auth.token._id,
                        false,
                        this.props.events.competition.competition._id,
                        this.props.events.competition.competition.grade.scoring.rounds
                      );
                    }}>
                    <Checkmark /> Save
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </PageLoader>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  table: state.table,
  user: state.user,
  events: state.events,
  single: state.single,
  tabulator: state.tabulator
});

const mapDispatchToProps = (dispatch) => ({
  getTableData: (view, skip, limit, sort, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
  },
  getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
  },
  updateTabScore: (template, tabulation, token, showAutosave, competitionId, rounds) => {
    dispatch(eventActions.updateTabulatorScore(template, tabulation, token, showAutosave, competitionId, rounds));
  },
  updateScore: (entry, value, judgeIndex, round) => {
    dispatch(eventActions.updateScore(entry, value, judgeIndex, round));
  },
  updateComments: (entry, value, judgeIndex, round) => {
    dispatch(eventActions.updateComments(entry, value, judgeIndex, round));
  },
  updateJudge: (round, judge, val) => {
    dispatch(eventActions.updateJudge(round, judge, val));
  },
  updateJudgeLabel: (round, judge, val) => {
    dispatch(eventActions.updateJudgeLabel(round, judge, val));
  },
  updateTabulationObject: (tab, token) => {
    dispatch(eventActions.updateTabulationObject(tab, token));
  },
  updateEntryScore: (entry, round, key, val) => {
    dispatch(eventActions.updateEntryScore(entry, round, key, val));
  },
  addDancer: (id, competition, token, cb) => {
    dispatch(eventActions.tabulatorAddDancer(id, competition, token, cb));
  },
  swapDancer: (newId, oldId, competition, token, cb) => {
    dispatch(eventActions.tabulatorSwapDancer(newId, oldId, competition, token, cb));
  },
  removeDancer: (entryCompetitionId, competition, token, cb) => {
    dispatch(eventActions.tabulatorRemoveDancer(entryCompetitionId, competition, token, cb));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(TabulatorJudgeTable));

class TabulatorJudgeTableContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    );
  }
}

export default TabulatorJudgeTableContainer;
