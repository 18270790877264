/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 3/29/2021
 */

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as tableActions from '../../../store/actions/table';
import urls from '../../../utils/urls';
import Button from 'react-bootstrap/Button';
import placeholder from '../../../assets/images/placeholder1.png';
import Nav from 'react-bootstrap/Nav';
import Bound from '../../Common/Bound';
import * as singleActions from '../../../store/actions/single';
import dayjs from 'dayjs';
import Competitions from './Competitions';

class SingleEvent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      preview: '',
      image: ''
    };
  }

  componentDidMount() {
    this.init();
    this.props.get(this.props.match.params.id, this.props.auth.token._id);
  }

  componentDidUpdate(props, state) {
    if (
      props.single.event !== this.props.single.event ||
      props.single.single !== this.props.single.single
    ) {
      this.init();
    }
  }

  init = () => {
    //console.log("init with image?");
    if (this.props.single.single.image) {
      if (this.props.single.single.image.path) {
        //console.log(this.props.single.single.image.path);
        this.setState({
          preview: urls.getS3Url(
            this.props.single.single.image.thumb || this.props.single.single.image.path
          )
        });
      }
    } else if (this.state.preview) {
      this.setState({ preview: '' });
    }
  };

  menu = [
    {
      title: 'Details',
      path: `/app/events/view/${this.props.match.params.id}/details`,

      dataSet: () => {
        this.props.getSingle('users', this.props.user.user._id, this.props.auth.token._id);
      }
    },
    {
      title: 'Competitions',
      path: `/app/events/view/${this.props.match.params.id}/competitions`,

      dataSet: () => {
        this.props.getSingle('users', this.props.user.user._id, this.props.auth.token._id);
      }
    }
  ];

  renderItem = (item, i) => {
    return (
      <Nav.Link
        key={i}
        to={item.path}
        as={Link}
        className={`tab tab-${i} ${this.props.location.pathname === item.path ? 'active' : ''}`}>
        {item.title}
      </Nav.Link>
    );
  };

  formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }

    return null;
  };

  renderContent = () => {
    if (this.props.location.pathname.indexOf('details') > -1) {
      return (
        <Bound>
          <div className={'page-container'}>
            <div className={'page-header'}>
              <Row>
                <Col>
                  <div className={'profile-right'}>
                    <ul className={'info-area'}>
                      <li>
                        <label>
                          <b>Contact email</b>
                          <br />{' '}
                          <a href={`mailto:${this.props.single.single.email}`}>
                            {this.props.single.single.email}
                          </a>
                        </label>
                      </li>
                      <li>
                        <label>
                          <b>Contact phone</b>
                          <br />{' '}
                          <a href={`tel:${this.props.single.single.phone}`}>
                            {this.formatPhoneNumber(this.props.single.single.phone)}
                          </a>
                        </label>
                      </li>
                      <li>
                        <label>
                          <b>Registration</b>
                          <br />{' '}
                          {dayjs(this.props.single.single.registrationStart)
                            .add(1, 'day')
                            .format('MMM DD YYYY')}{' '}
                          -{' '}
                          {dayjs(this.props.single.single.registrationEnd)
                            .add(1, 'day')
                            .format('MMM DD YYYY')}
                        </label>

                        {!this.props.single.single.inPerson ? (
                          <label>
                            <b>Video Uploads</b>
                            <br />{' '}
                            {dayjs(this.props.single.single.videoStart)
                              .add(1, 'day')
                              .format('MMM DD YYYY')}{' '}
                            -{' '}
                            {dayjs(this.props.single.single.videoEnd)
                              .add(1, 'day')
                              .format('MMM DD YYYY')}
                          </label>
                        ) : null}

                        <label>
                          <b>Judging</b>
                          <br />{' '}
                          {dayjs(this.props.single.single.judgingStart)
                            .add(1, 'day')
                            .format('MMM DD YYYY')}{' '}
                          -{' '}
                          {dayjs(this.props.single.single.judgingEnd)
                            .add(1, 'day')
                            .format('MMM DD YYYY')}
                        </label>
                      </li>
                      <li>
                        <label>
                          <b>Syllabus:</b>{' '}
                          {this.props.single.single.pdf ? (
                            <a
                              href={'#syllabus'}
                              onClick={(e) => {
                                e.preventDefault();
                                let link = urls.getS3Url(this.props.single.single.pdf.path);
                                var win = window.open(link, '_blank');
                                win.focus();
                              }}>
                              Download PDF
                            </a>
                          ) : (
                            'No PDF yet'
                          )}
                        </label>
                      </li>
                      <div className={'spacer'} />
                      {!this.props.isDash &&
                      this.props.single.single.published &&
                      new Date(this.props.single.single.registrationStart).getTime() <=
                        Date.now() &&
                      Date.now() <= new Date(this.props.single.single.registrationEnd).getTime() ? (
                        <div className={'text-center'}>
                          <Button
                            variant={'primary topZ'}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              this.props.setSingle({});
                              this.props.history.push(
                                `/app/events/${this.props.single.single._id}/register`
                              );
                            }}>
                            Register
                          </Button>
                        </div>
                      ) : null}
                    </ul>
                  </div>

                  <div className={'profile-left'}>
                    <div
                      dangerouslySetInnerHTML={{ __html: this.props.single.single.content }}></div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Bound>
      );
    } else if (this.props.location.pathname.indexOf('competitions') > -1) {
      return <Competitions />;
    }
  };

  render() {
    return (
      <div className={''}>
        <div className={'school-profile profile-page elevated padded rounded'}>
          {this.props.single.single._id === this.props.match.params.id ||
          this.props.match.params.id === 'new' ? (
            <div>
              <div className={'profile-header'}>
                <div
                  className={'artist-img avatar'}
                  style={{
                    background: this.state.preview
                      ? `url(${this.state.preview}), linear-gradient(0deg, rgba(0,0,0,.6), rgba(0,0,0,.4), rgba(0,0,0,.2), transparent)`
                      : `url(${placeholder}), linear-gradient(0deg, rgba(0,0,0,.6), rgba(0,0,0,.4), rgba(0,0,0,.2), transparent)`
                  }}></div>

                <h2 className={'profile-title'}>{this.props.single.single.name}</h2>
              </div>
              <div className={'profile-body'}>
                <div className={'tab-container'}>
                  <div className={'tab-row'}>
                    {this.menu.map((item, i) => {
                      return this.renderItem(item, i);
                    })}
                    <div className={'tab-highlight'} />
                    <div className={'tab-highlight-track'} />
                  </div>
                </div>
                <div className={'tab-content ex-pad'}>{this.renderContent()}</div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  table: state.table,
  single: state.single,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  getTableData: (view, skip, limit, sort, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
  },
  getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
  },
  get: (id, token) => {
    dispatch(singleActions.getSingle('events', id, token));
  },
  setSingle: (d) => {
    dispatch(singleActions.setSingleEntry(d));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(SingleEvent));

class SingleEventContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    );
  }
}

export default SingleEventContainer;
