

export const APP_CATEGORIES = {
    TEAMS: "teams",
    EVENTS: "events",
    COMPETITIONS: "competitions",
    TABULATION: "tabulation"
}

export const ROTATION_TYPE = {
    SET: "SET",
    SPLIT: "SPLIT",
    ROTATING: "ROTATING"
}

export const RECALL_TYPE = {
    NONE: "NONE",
    ALL: "ALL",
    PERCENT: "PERCENT"

}
