/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */

import React from 'react';
import { withRouter, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Header/Header';
import * as SingleActions from '../../store/actions/single';
import Bound from '../Common/Bound';
import OnboardingSteps from './Onboarding/OnboardingSteps';
import DancerForm from './Dancers/DancerForm';
import SchoolForm from './Schools/SchoolForm';
import SchoolJoiner from './Schools/SchoolJoiner';
import JoinOrganization from './Organizations/JoinOrganization';
import OnboardingComplete from './Onboarding/OnboardingComplete';
import Logout from './Auth/Logout';
import LogoutModal from '../Common/LogoutModal';

class Onboarding extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showTheme: false,
      showFormModal: false,
      sidebarOpen: false,
      creating: false,
      showLogoutModal: false
    };

    this.dancerForm = React.createRef();
  }

  handleThemeModal = (show) => {
    this.setState({ showTheme: show });
  };

  handleFormModal = (show) => {
    this.setState({ showFormModal: show });
  };

  handleFormModalWithData = (d) => {
    this.setState({ showFormModal: true });
    this.props.setSingle(d);
  };

  componentDidMount() {
    // Call required collections:
    this.props.loadOnboarding(this.props.auth.token._id);
  }

  handleNewItem = (e) => {
    e.preventDefault();
    let splitted = this.props.location.pathname.split('/');
    let view = splitted[2];
    if (['artists', 'albums', 'tracks', 'users', 'news', 'pages', 'help'].indexOf(view) > -1) {
      this.props.setSingle({ _id: 21 });
      this.props.history.push('/app/' + view + '/new');
    } else {
      this.handleFormModalWithData({});
    }
  };

  toggleSidebar = () => {
    this.setState({ sidebarOpen: !this.state.sidebarOpen });
  };

  setLogoutModal = (mode) => {
    this.setState({ showLogoutModal: mode });
  };

  render() {
    let step = this.props.user.user ? this.props.user.user.step : 0;
    let userStep = this.props.user.user
      ? this.props.user.user.onboardingSteps
        ? this.props.user.user.onboardingSteps[step]
        : {}
      : {};

    return (
      <div className={`feis-page-container`}>
        <Bound>
          <LogoutModal show={this.state.showLogoutModal} hideModal={this.setLogoutModal} />
        </Bound>
        <Bound>
          <Header
            handleThemeModal={this.handleThemeModal}
            onboarding={true}
            toggleSidebar={this.toggleSidebar}
            setLogoutModal={this.setLogoutModal}
          />
        </Bound>

        <Bound>{this.props.user.user.onboardingSteps ? <OnboardingSteps /> : null}</Bound>

        <Route
          exact={true}
          path={'/onboarding/logout'}
          component={(props) => {
            return (
              <Bound>
                <Logout />
              </Bound>
            );
          }}
        />

        <div
          className={`feis-page-onboarding ${this.props.auth.sidebarOpen ? 'sidebar-open' : ''}`}>
          {this.props.user.onboardingLoading ? (
            <div className={`lds-ring ${this.props.user.onboardingLoading ? 'active' : ''}`}>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          ) : null}
          <div
            className={`onboarding-container ${
              this.props.user.onboardingLoading ? 'loading' : ''
            }`}>
            <Route
              path={'/onboarding'}
              render={(props) => {
                if (step === 100) {
                  return (
                    <Bound>
                      <Redirect to={'/app'} />
                    </Bound>
                  );
                } else if (userStep) {
                  if (userStep.role === 'dancer') {
                    return (
                      <Bound>
                        <DancerForm onboarding={true} ref={this.dancerForm} />
                      </Bound>
                    );
                  } else if (userStep.role === 'teacher') {
                    return this.state.creating ? (
                      <Bound>
                        <SchoolForm
                          setCreating={(e, mode) => {
                            e.preventDefault();
                            this.setState({ creating: mode });
                          }}
                          onboarding={true}
                        />
                      </Bound>
                    ) : (
                      <Bound>
                        <SchoolJoiner
                          setCreating={(e, mode) => {
                            e.preventDefault();
                            this.setState({ creating: mode });
                          }}
                          onboarding={true}
                        />
                      </Bound>
                    );
                  } else if (userStep.role === 'organizer') {
                    return (
                      <Bound>
                        <JoinOrganization onboarding={true} />
                      </Bound>
                    );
                  } else if (userStep.role === 'complete') {
                    return <OnboardingComplete onboarding={true} />;
                  }
                } else {
                  return null;
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  single: state.single,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  setSingle: (d) => {
    dispatch(SingleActions.setSingleEntry(d));
  },
  loadOnboarding: (token) => {
    dispatch(SingleActions.loadOnboarding(token));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Onboarding));

class OnboardingContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected />
      </div>
    );
  }
}

export default OnboardingContainer;
