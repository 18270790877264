import React from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import * as errorActions from "../../store/actions/errors";

class Bound extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service

        if (window.rg4js) {
            try {
                window.rg4js('send', error)
            }catch(err) {

            }
        }
        this.props.post(window.location.href, error, errorInfo, this.props.auth.token._id)

    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h5>Something went wrong with this component.  An issue has been reported for this error.</h5>;
        }

        return this.props.children;
    }
}


const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    post: (url, error, errorInfo, token) => {
        dispatch(errorActions.logError(url, error, errorInfo, token));
    }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Bound));