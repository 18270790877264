/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Button from "react-bootstrap/Button";
import {CheckmarkDone, Undo} from "react-ionicons";
import { Add } from 'react-ionicons';
import { ReturnRight } from 'react-ionicons';
import { Eye } from 'react-ionicons';
import { ArrowBack } from 'react-ionicons';
import { ArrowForward } from 'react-ionicons';


class FormButtons extends React.Component {
    render() {
        return (
            <div className={`text-right form-buttons ${this.props.fixed ? 'form-buttons-fixed' : ''} ${this.props.className || ''}`}>
                <div className={`float-left ${this.props.label ? 'form-back-with-title' : ''}`}>
                    {!this.props.showPrevious ?
                        null
                    :
                        <Button variant="primary" onClick={e => this.props.back(e)}>
                            <ArrowBack />
                            &nbsp; <span>Previous</span>
                        </Button>
                    }

                    {this.props.label ?
                        <h4 className={"form-button-label"}>{this.props.label}</h4>
                        : null}

                    {this.props.showBack ?
                        <Button variant="flat" onClick={e => this.props.onBack(e)}>
                            <ArrowBack />
                        </Button>
                    : null}
                </div> &nbsp;




                {this.props.showDefault ?

                    <div className={'text-right'}>
                        {!this.props.showRevert ?
                            null
                        :
                            <Button variant={'flat'} className={"btn-small-padding"} onClick={this.props.onRevert}><Undo fontSize={'18px'} /></Button>
                        }

                        {!this.props.showPreview ?
                            null
                            :
                            <Button variant={'flat'} className={"btn-small-padding"} onClick={this.props.onShowPreview}><Eye fontSize={'18px'} /> &nbsp; Preview</Button>
                        }

                        {!this.props.showDefault ?
                            <div>
                                <div className={"spacer"} />
                                <div className={"spacer"} />
                            </div>
                        :
                            <Button variant={'flat'} className={"btn-small-padding"} onClick={e => this.props.confirm(e)}><CheckmarkDone fontSize={'18px'} /> &nbsp; Save</Button>
                        }



                    </div>
                    :
                    null
                }

                {this.props.specialLabel ?
                    this.props.specialLabel
                    : null
                }

                {this.props.canSkip ?
                    <Button variant="flat" className={`${this.props.label ? '': ''} secondary-button`} onClick={e => this.props.next(e)}>

                        <span>Skip</span> &nbsp; <ReturnRight />
                    </Button>
                : null}

                {this.props.showPlus ?
                    <Button variant="flat" className={`${this.props.label ? '': ''}`} onClick={e => this.props.add(e)}>
                        <Add /> &nbsp; Add
                    </Button>
                    : null}
                {this.props.showNext ?
                    <Button variant="primary" className={`${this.props.label ? '': ''} secondary-button ${this.props.nextButtonNegative ? 'neg5': ''}`} onClick={e => this.props.next(e)}>

                        <span>Next</span> &nbsp; <ArrowForward />
                    </Button>
                : null}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table
});

const mapDispatchToProps = dispatch => ({

});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(FormButtons));

class FormButtonsContainer extends React.Component {
    render() {
        return (

                <Connected {...this.props} />

        )
    }
}

export default FormButtonsContainer;
