/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 1/16/2021
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as tableActions from '../../../store/actions/table';
import FormButtons from '../../Common/FormButtons';
import CardForm from '../Settings/CardForm';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import * as yup from 'yup';
import CardSelect from '../../Common/CardSelect';
import Button from 'react-bootstrap/Button';
import { ArrowForward } from 'react-ionicons';
import * as singleActions from '../../../store/actions/single';
import { NotificationManager } from 'react-notifications';
import Table from 'react-bootstrap/Table';
import CovidModal from '../../Common/CovidModal';

class EntryPay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      addingCard: false,
      showModal: false
    };
  }

  schema = yup.object({
    card: yup.object().shape({
      _id: yup.string().required('Card required')
    }),
    tos: yup.boolean().required('You must accept the terms of service')
  });

  get isEdit() {
    return this.props.single.single?.state === 'edit';
  }

  get admissionFee() {
    return this.isEdit ? 0 : parseInt(this.props.single.event.admissionFee || 0);
  }

  get programFee() {
    return this.isEdit ? 0 : parseInt(this.props.single.event.programFee || 0);
  }

  handleSubmit = (values, actions) => {
    let data = new FormData();
    data.append('files', null);
    let params = {
      card: values.card,
      entryId: this.props.match.params.id,
      tos: values.tos,
      publication: values.publication,
      highlight: values.highlight,
      teacherAccess: values.teacherAccess
    };

    data.set('data', JSON.stringify(params));
    let self = this;

    this.props.post('entries/pay', data, this.props.auth.token._id, (id) => {
      this.props.history.push('/app/entries/' + self.props.match.params.id);
    }, 
    (err) => {
      actions.setSubmitting(false);
    });
  };

  componentDidUpdate(props) {
    if (props.single.cards && this.props.single.cards) {
      if (props.single.cards.length !== this.props.single.cards.length) {
        this.setState({ addingCard: false });
        this.init();
      }
    }
  }

  componentDidMount() {
    this.init();
  }

  init = () => {
    if (
      this.props.single.single._id &&
      this.props.single.single.event &&
      this.props.match.params.view
    ) {
      this.props.history.push(`/app/entries/${this.props.single.single._id}`);
    }
  };

  render() {
    return (
      <div className={'ex-pad'}>
        <Formik
          validationSchema={this.schema}
          onSubmit={this.handleSubmit}
          enableReinitialize={true}
          initialValues={{
            card: this.props.single.cards ? this.props.single.cards[0] : { last4: '', _id: '' },
            tos: false,
            highlight: false,
            publication: false,
            teacherAccess: true
          }}>
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            isSubmitting,
            setSubmitting,
            setFieldValue
          }) => (
            <Form noValidate onSubmit={handleSubmit} className={''}>
              <FormButtons
                confirm={(e) => {
                  this.handleSubmit();
                }}
                back={(e) => {
                  e.preventDefault();
                  this.props.entryFirstStep(this.props.match.params.id, this.props.auth.token._id);
                }}
                showPrevious={true}
                showRevert={false}
                showNext={false}
                next={(e) => {
                  e.preventDefault();
                  handleSubmit(values, 'add');
                }}
                showDefault={false}
                label={'Payment'}
                specialLabel={
                  <span className={'fee-container'}>
                    {this.admissionFee && this.props.user.user.roles.indexOf('teacher') === -1 ? (
                      <span className={'price-container'}>
                        <div className={'big-price fee'}>
                          ${this.admissionFee}
                          <div className={'price-title'}>Admission</div>
                        </div>
                      </span>
                    ) : null}

                    {this.programFee && this.props.user.user.roles.indexOf('teacher') === -1 ? (
                      <span className={'price-container'}>
                        <div className={'big-price fee'}>
                          ${this.programFee}
                          <div className={'price-title'}>FF Fee</div>
                        </div>
                      </span>
                    ) : null}

                    <span className={'price-container'}>
                      <div className={'big-price'}>
                        ${this.props.single.single.price - this.props.single.single.discount}
                        <div className={'price-title'}>Competitions</div>
                      </div>
                    </span>
                  </span>
                }
              />
              <CovidModal
                show={this.state.showModal}
                hideModal={(show) => this.setState({ showModal: show })}
              />

              {!this.state.addingCard && this.props.single.cards.length > 0 ? (
                <div>
                  <div className={'field-container'}>
                    <label>Payment Method</label>
                    <CardSelect
                      disableTyping={true}
                      cardLogos={true}
                      items={this.props.single.cards}
                      value={values.card}
                      itemValue={'_id'}
                      onChange={(val) => {
                        setFieldValue('card', val);
                      }}
                      displayValuePrefix={'****-****-****-'}
                      displayValue={'last4'}
                    />
                  </div>

                  {errors.card && touched.card && (
                    <p className={'text-danger hint-text'}>{errors.card._id}</p>
                  )}
                </div>
              ) : null}

              {!this.state.addingCard ? (
                <Button variant={'link'} onClick={(e) => this.setState({ addingCard: true })}>
                  Add a card
                </Button>
              ) : null}

              {this.state.addingCard ? (
                <CardForm
                  hideFormButtons={true}
                  setAdding={(e) => this.setState({ addingCard: false })}
                />
              ) : null}

              <div className={'spacer'}></div>
              {!this.state.addingCard ? (
                <div>
                  <div className={'spacer'} />
                  <div className={'divider'} />
                  <div className={'spacer'} />

                  <Table hover className={'table-full'}>
                    <thead>
                      <tr>
                        <th width={'25%'}>Name</th>
                        <th>Item</th>
                        <th width={'15%'}>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.single?.single?.sections?.map((section, i) => {
                        let dancer = this.props.single.dancers.filter(
                          (d) => d._id === section.dancer
                        )[0];
                        if (section.level === 'teams') {
                          dancer = this.props.single.teams.filter(
                            (d) => d._id === section.dancer
                          )[0];
                        }
                        let finalDances = [];
                        for (let o = 0; o < section.sections.length; o++) {
                          let sec = section.sections[o];
                          for (let d = 0; d < sec.dances.length; d++) {
                            let dance = sec.dances[d];

                            if (dance.selected.comp) {
                              if (dance.selected.comp._id !== '') {
                                finalDances.push(dance.selected);
                              }
                            }
                          }
                        }

                        if (dancer && finalDances.length > 0) {
                          return (
                            <tr key={i}>
                              <td>
                                {dancer.name + ' '}
                                {dancer.lname || ''}
                              </td>
                              <td>
                                {finalDances.map((dance, di) => {
                                  return (
                                    <div key={di} className={'mb-2'}>
                                      {dance.gradeName}
                                    </div>
                                  );
                                })}
                              </td>
                              <td>
                                {finalDances.map((dance, di) => {
                                  return (
                                    <div key={di} className={'mb-2'}>
                                      ${dance.price}
                                    </div>
                                  );
                                })}
                              </td>
                            </tr>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </tbody>
                  </Table>

                  <Table>
                    <tbody>
                      {this.admissionFee && this.props.user.user.roles.indexOf('teacher') === -1 ? (
                        <tr className={'bg-white'}>
                          <td width={'25%'}></td>
                          <td>Admission Fee</td>
                          <td width={'15%'}>${this.admissionFee}</td>
                        </tr>
                      ) : null}

                      {this.programFee && this.props.user.user.roles.indexOf('teacher') === -1 ? (
                        <tr className={'bg-white'}>
                          <td width={'25%'}></td>
                          <td>FF Fee</td>
                          <td width={'15%'}>${this.programFee}</td>
                        </tr>
                      ) : null}

                      {this.props.user.user.roles.indexOf('teacher') === -1 ? (
                        <tr className={'bg-white'}>
                          <td width={'25%'}></td>
                          <td>
                            <b>Subtotal</b>
                          </td>
                          <td width={'15%'}>
                            ${this.programFee + this.admissionFee + this.props.single.single.price}
                          </td>
                        </tr>
                      ) : (
                        <tr className={'bg-white'}>
                          <td width={'25%'}></td>
                          <td>
                            <b>Subtotal</b>
                          </td>
                          <td width={'15%'}>${this.props.single.single.price}</td>
                        </tr>
                      )}

                      {this.props.single.single.discount ? (
                        <tr className={'bg-white'}>
                          <td width={'25%'}></td>
                          <td>Discount</td>
                          <td width={'15%'} className={'text-success'}>
                            -${this.props.single.single.discount}
                          </td>
                        </tr>
                      ) : null}

                      {this.props.single.event.serviceFee ? (
                        this.props.user.user.roles.indexOf('teacher') === -1 ? (
                          <tr className={'bg-white'}>
                            <td width={'25%'}></td>
                            <td>Service fee (3%)</td>
                            <td width={'15%'}>
                              $
                              {parseFloat(
                                (this.props.programFee +
                                  this.props.admissionFee +
                                  this.props.single.single.price -
                                  this.props.single.single.discount) *
                                  0.03
                              ).toFixed(2)}
                            </td>
                          </tr>
                        ) : (
                          <tr className={'bg-white'}>
                            <td width={'25%'}></td>
                            <td>Service fee (3%)</td>
                            <td width={'15%'}>
                              $
                              {parseFloat(
                                (this.props.single.single.price -
                                  this.props.single.single.discount) *
                                  0.03
                              ).toFixed(2)}
                            </td>
                          </tr>
                        )
                      ) : null}

                      {this.props.single.event.serviceFee ? (
                        this.props.user.user.roles.indexOf('teacher') === -1 ? (
                          <tr className={'bg-white'}>
                            <td></td>
                            <td>
                              <b>Total</b>
                            </td>
                            <td>
                              $
                              {parseFloat(
                                (this.programFee +
                                  this.admissionFee +
                                  this.props.single.single.price -
                                  this.props.single.single.discount) *
                                  1.03
                              ).toFixed(2)}
                            </td>
                          </tr>
                        ) : (
                          <tr className={'bg-white'}>
                            <td></td>
                            <td>
                              <b>Total</b>
                            </td>
                            <td>
                              $
                              {parseFloat(
                                (this.props.single.single.price -
                                  this.props.single.single.discount) *
                                  1.03
                              ).toFixed(2)}
                            </td>
                          </tr>
                        )
                      ) : this.props.user.user.roles.indexOf('teacher') === -1 ? (
                        <tr className={'bg-white'}>
                          <td></td>
                          <td>
                            <b>Total</b>
                          </td>
                          <td>
                            $
                            {parseFloat(
                              this.programFee +
                                this.admissionFee +
                                this.props.single.single.price -
                                this.props.single.single.discount
                            ).toFixed(2)}
                          </td>
                        </tr>
                      ) : (
                        <tr className={'bg-white'}>
                          <td></td>
                          <td>
                            <b>Total</b>
                          </td>
                          <td>
                            $
                            {parseFloat(
                              this.props.single.single.price - this.props.single.single.discount
                            ).toFixed(2)}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>

                  <div className={'spacer'} />
                  <div className={'divider'} />
                  <div className={'spacer'} />

                  {!this.state.addingCard ? (
                    <div>
                      {this.props.single.event.inPerson ? null : (
                        <Form.Check
                          type="checkbox"
                          className="mb-2"
                          label={
                            <i>
                              <b>Highlight Reel:</b> I give permission for a short clip of my
                              dancer’s video(s) to be considered as part of a highlight reel after
                              the event. (up to 4 bars maximum, this video may appear on the
                              organizer’s social media and website). FeisFWD will randomly select
                              clips to be sent for editing and publication, post event.
                            </i>
                          }
                          name="highlight"
                          checked={values.highlight}
                          onChange={(v) => {
                            setFieldValue('highlight', !values.highlight);
                          }}
                          onBlur={handleBlur}
                        />
                      )}
                    </div>
                  ) : null}

                  {this.props.single.event.inPerson ? (
                    <Form.Check
                      type="checkbox"
                      className="mb-2"
                      label={
                        <i>
                          I agree to the{' '}
                          <a
                            href={'#showwaiver'}
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ showModal: true });
                            }}>
                            waiver
                          </a>
                        </i>
                      }
                      name="publication"
                      checked={values.publication}
                      onChange={(v) => {
                        setFieldValue('publication', !values.publication);
                      }}
                      onBlur={handleBlur}
                    />
                  ) : (
                    <Form.Check
                      type="checkbox"
                      className="mb-2"
                      label={
                        <i>
                          I agree that by participating in this event, that I and my dancer(s) will
                          not share by message or email, or otherwise disseminate or publish any of
                          my competitor’s videos online, prior to or during the competition entries
                          and adjudication period.
                        </i>
                      }
                      name="publication"
                      checked={values.publication}
                      onChange={(v) => {
                        setFieldValue('publication', !values.publication);
                      }}
                      onBlur={handleBlur}
                    />
                  )}

                  <Form.Check
                    type="checkbox"
                    className="mb-2"
                    label={
                      <i>
                        I have read and acknowledge the{' '}
                        <a
                          href={'https://feisfwd.com/terms'}
                          target={'_blank'}
                          rel="noopener noreferrer">
                          Terms of Service
                        </a>{' '}
                        and{' '}
                        <a
                          href={'https://feisfwd.com/privacy'}
                          target={'_blank'}
                          rel="noopener noreferrer">
                          Privacy Policy
                        </a>
                      </i>
                    }
                    name="tos"
                    checked={values.tos}
                    onChange={(v) => {
                      setFieldValue('tos', !values.tos);
                    }}
                    onBlur={handleBlur}
                  />
                </div>
              ) : null}
              <div className={'spacer'} />

              {(!this.state.addingCard &&
                this.props.single.cards.length > 0 &&
                this.props.single.single.price > 0) ||
              this.isEdit ? (
                <div className={'text-center'}>
                  <div className={'divider'} />
                  <div className={'spacer'} />
                  {this.props.single.event.serviceFee ? (
                    this.props.user.user.roles.indexOf('teacher') === -1 ? (
                      <h5>
                        Total: $
                        {parseFloat(
                          (this.programFee +
                            this.admissionFee +
                            this.props.single.single.price -
                            this.props.single.single.discount) *
                            1.03
                        ).toFixed(2)}
                      </h5>
                    ) : (
                      <h5>
                        Total: $
                        {parseFloat(
                          (this.props.single.single.price - this.props.single.single.discount) *
                            1.03
                        ).toFixed(2)}
                      </h5>
                    )
                  ) : this.props.user.user.roles.indexOf('teacher') === -1 ? (
                    <h5>
                      Total: $
                      {parseFloat(
                        this.programFee +
                          this.admissionFee +
                          this.props.single.single.price -
                          this.props.single.single.discount
                      ).toFixed(2)}
                    </h5>
                  ) : (
                    <h5>
                      Total: $
                      {parseFloat(
                        this.props.single.single.price - this.props.single.single.discount
                      ).toFixed(2)}
                    </h5>
                  )}

                  <Button
                    variant={'primary'}
                    className={`${isSubmitting ? 'disabled' : ''}`}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!isSubmitting) {
                        if (!values.tos || !values.publication) {
                          NotificationManager.error(
                            'You Must agree to the Terms of service and publication agreement'
                          );
                        } else {
                          handleSubmit(values, setSubmitting);
                        }
                      }
                    }}>
                    Pay Now &nbsp; <ArrowForward color={'#fff'} />
                  </Button>
                </div>
              ) : !this.state.addingCard && this.props.single.cards.length === 0 ? (
                <div className={'text-center'}>
                  <div className={'divider'} />
                  <div className={'spacer'} />
                  <Button variant={'primary'} onClick={(e) => this.setState({ addingCard: true })}>
                    Please add a payment method
                  </Button>
                </div>
              ) : null}
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  table: state.table,
  single: state.single,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  getTableData: (view, skip, limit, sort, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
  },
  getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
  },
  post: (view, data, token, redirect, errHandler) => {
    dispatch(singleActions.postFormData(view, data, token, true, redirect, true, true, errHandler));
  },
  entryFirstStep: (id, token) => {
    dispatch(singleActions.entryFirstStep(id, token));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(EntryPay));

class EntryPayContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    );
  }
}

export default EntryPayContainer;
