
    /* Copyright (C) Nick Germaine - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
*/

    import React from 'react';
    import {withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import Nav from 'react-bootstrap/Nav';
import {Home, HomeOutline, PeopleCircle} from 'react-ionicons';
import { Person, PersonOutline } from 'react-ionicons';
import { Camera, CameraOutline } from 'react-ionicons';
import { Globe, GlobeOutline } from 'react-ionicons';
import { People, PeopleOutline, PeopleCircleOutline } from 'react-ionicons';
import { HelpCircle, HelpCircleOutline } from 'react-ionicons';
import { Power, PowerOutline } from 'react-ionicons';
import { Settings, SettingsOutline } from 'react-ionicons';
import * as authActions from '../../store/actions/auth';
import { Briefcase, BriefcaseOutline } from 'react-ionicons';
import { Pricetags, PricetagsOutline } from 'react-ionicons';
import { Newspaper, NewspaperOutline } from 'react-ionicons';
import { School, SchoolOutline } from 'react-ionicons';
import { Book, BookOutline } from 'react-ionicons';
import { Trophy, TrophyOutline } from 'react-ionicons';
import Scrollbar from "react-scrollbars-custom";
import { PieChart, PieChartOutline } from 'react-ionicons';
import { Clipboard, ClipboardOutline } from 'react-ionicons';
import { Refresh, RefreshOutline } from 'react-ionicons';
import packageJson from '../../../package.json';
import { Notifications, NotificationsOutline } from 'react-ionicons';
import { BarChart, BarChartOutline } from 'react-ionicons';

const iconStyle = {
    fontSize: '24px',
    color: '#606060'
}



class Sidebar extends React.Component {

    menu = [
        {
            title: 'Dashboard ',
            icon: <Home {...iconStyle} />,
            outline: <HomeOutline {...iconStyle} />,
            path: '/app',
            type: 'link',

        },
        {
            title: 'Adjudicator Tasks',
            icon: <Clipboard {...iconStyle} />,
            outline: <ClipboardOutline {...iconStyle} />,
            path: '/app/judge',
            type: 'link',
            roles: ['judge']
        },
        {
            title: 'Tabulator Tasks',
            icon: <Clipboard {...iconStyle} />,
            outline: <ClipboardOutline {...iconStyle} />,
            path: '/app/tabulator',
            type: 'link',
            roles: ['tabulator']
        },
        {
            title: 'Event Dashboard ',
            icon: <PieChart {...iconStyle} />,
            outline: <PieChartOutline {...iconStyle} />,
            path: '/app/event-dashboard',
            type: 'link',
            roles: ['organizer', 'tabulator']
        },
        {
            title: 'Dancer Profiles',
            icon: <Person {...iconStyle} />,
            outline: <PersonOutline {...iconStyle} />,
            path: '/app/dancers',
            type: 'link',
            roles: ['dancer', 'parent', 'teacher']

        },
        {
            title: 'Teams',
            icon: <PeopleCircle {...iconStyle} />,
            outline: <PeopleCircleOutline {...iconStyle} />,
            path: '/app/teams/events/teams',
            type: 'link',
            roles: ['teacher']

        },
        {
            title: 'Entries',
            icon: <Camera {...iconStyle} />,
            outline: <CameraOutline {...iconStyle} />,
            path: '/app/entries',
            type: 'link',
            roles: ['dancer', 'parent', 'teacher']

        },
        {
            title: 'My School',
            icon:  <School {...iconStyle} />,
            outline: <SchoolOutline {...iconStyle} />,
            path: '/app/schools',
            type: 'link',
            roles: ['teacher']
        },
        {
            title: 'All Events',
            icon: <Newspaper {...iconStyle} />,
            outline: <NewspaperOutline {...iconStyle} />,
            path: '/app/events',
            type: 'link',
            role: 'admin'

        },
        {
            title: 'Results',
            icon:  <Trophy {...iconStyle} />,
            outline: <TrophyOutline {...iconStyle} />,
            path: '/app/results',
            type: 'link',
            roles: ['dancer', 'parent', 'teacher', "organizer", "judge"]

        },
        {
            title: 'My Payments',
            icon:  <Pricetags {...iconStyle} />,
            outline: <PricetagsOutline {...iconStyle} />,
            path: '/app/orders',
            type: 'link',
            role: 'admin'

        },
        {
            title: 'Notifications',
            icon: <Notifications {...iconStyle} />,
            outline: <NotificationsOutline {...iconStyle} />,
            path: '/app/notifications',
            type: 'link'
        },
        {
            title: 'Settings',
            icon: <Settings {...iconStyle} />,
            outline: <SettingsOutline {...iconStyle} />,
            path: '/app/settings/account',
            type: 'link',
        },
        {
            title: 'Music',
            type: 'title',
            role: 'admin'
        },
        {
            title: 'Regions',
            icon:  <Globe {...iconStyle} />,
            outline: <GlobeOutline {...iconStyle} />,
            path: '/app/admin/regions',
            type: 'link',
            role: 'admin'
        },
        {
            title: 'Organizations',
            icon:  <Briefcase {...iconStyle} />,
            outline: <BriefcaseOutline {...iconStyle} />,
            path: '/app/admin/organizations',
            type: 'link',
            role: 'admin'
        },
        {
            title: 'Schools',
            icon:  <School {...iconStyle} />,
            outline: <SchoolOutline {...iconStyle} />,
            path: '/app/admin/schools',
            type: 'link',
            role: 'admin'
        },
        {
            title: 'Scoring',
            icon:  <BarChart {...iconStyle} />,
            outline: <BarChartOutline {...iconStyle} />,
            path: '/app/admin/scoring',
            type: 'link',
            role: 'admin'
        },
        {
            title: 'Users',
            icon:  <People {...iconStyle} />,
            outline: <PeopleOutline {...iconStyle} />,
            path: '/app/admin/users',
            type: 'link',
            role: 'admin'
        },
        /*
        {
            title: 'Import',
            icon:  <People {...iconStyle} />,
            path: '/app/admin/import',
            type: 'link',
            role: 'admin'
        },
        {
            title: 'Parse Import',
            icon:  <IosBriefcase {...iconStyle} />,
            path: '/app/admin/parseimport',
            type: 'link',
            role: 'admin'
        },*/


        {
            title: 'Music',
            type: 'title',
        },

        /*{
            title: 'Categories',
            icon: <MdList {...iconStyle} />,
            path: '/app/categories',
            type: 'link'
        },*/
        {
            title: `What's New`,
            icon:  <Refresh {...iconStyle} />,
            outline: <RefreshOutline {...iconStyle} />,
            path: '/app/updates',
            type: 'link'

        },

        {
            title: 'Instructions',
            icon:  <Book {...iconStyle} />,
            outline: <BookOutline {...iconStyle} />,
            path: '/app/instructions',
            type: 'link',
            external: true,
            url: 'http://www.feisfwd.com/instructions'

        },
        {
            title: 'Help',
            icon: <HelpCircle {...iconStyle} />,
            outline: <HelpCircleOutline {...iconStyle} />,
            path: '/app/help',
            type: 'link',
            external: true,
            url: 'https://www.feisfwd.com/contact'
        },
        {
            title: 'Music',
            type: 'title',
        },

        {
            title: 'Logout',
            icon: <Power {...iconStyle} />,
            outline: <PowerOutline {...iconStyle} />,
            path: '/app/logout',
            action: () => {
                this.props.setLogoutModal(true)
            },
            type: 'link',
        },


    ]
    renderHeader = (item, i) => {
        let shouldRender = true;
        if (item.role) {
            if (this.props.user.user) {
                if (item.role === 'admin' && this.props.user.user.role !== 'admin') {
                    shouldRender = false;
                }
            } else {
                shouldRender = false;
            }
        }
        if (shouldRender) {
            return <div key={i} className={`separator`}/>

        } else {
            return null;
        }
    }
    renderItem = (item, i) => {
        let shouldRender = true;
        if (item.role) {
            if (this.props.user.user) {
                if (item.role === 'admin' && this.props.user.user.role !== 'admin') {
                    shouldRender = false;
                }
            } else {
                shouldRender = false;
            }
        }

        if (item.roles) {
            let matchRoles = 0;
            item.roles.map((r) => {
                if(this.props.user.user) {
                    if (this.props.user.user.roles) {
                        this.props.user.user.roles.map((ur) => {
                            if (r === ur) {
                                matchRoles += 1;
                            }

                            return ur;
                        })
                    }
                }

                return r;
            })

            if (matchRoles === 0) {
                shouldRender = false;
            }
        }

        if (shouldRender) {
            if (item.external) {
                return (
                    <Nav.Item key={i} className={`${this.props.location.pathname === item.path ? 'active' : ''}`}>
                        <a className={"nav-link"} href={item.url} target={"_blank"} rel="noopener noreferrer" >{this.props.location.pathname === item.path ? item.icon : item.outline} <span
                            className={`menu-text`}>{item.title}</span></a>
                    </Nav.Item>
                )
            }else {
                return (
                    <Nav.Item key={i} className={`${
                        this.props.location.pathname === "/app" && item.path === "/app" ? 'active' :
                            this.props.location.pathname.indexOf(item.path) > -1 && item.path !== "/app" ? 'active' : ''}`}>
                        <Nav.Link onClick={e => {
                            if (item.title === "Logout") {
                                e.preventDefault();
                                this.props.setLogoutModal(true);
                            }
                            this.props.setSidebar(false)
                        }} as={Link} to={item.path}>{(this.props.location.pathname.indexOf(item.path) > -1 && item.path !== "/app") || (this.props.location.pathname === "/app" && item.path === "/app") ? item.icon : item.outline} <span
                            className={`menu-text`}>{item.title}</span></Nav.Link>
                    </Nav.Item>

                )
            }
        } else {
            return null;
        }
    }
    render() {

        return (
            <div className={`sidebar ${this.props.auth.sidebarOpen ? 'open' : 'closed'} ${this.props.location.pathname.indexOf('/app/j/') > -1 ? 'judge-view' : ''} ${this.props.location.pathname.indexOf('/app/tabulation/') > -1 ? 'judge-view' : ''}`}>
                <Scrollbar className={"feis-sidebar-scroll"} noScrollX style={{height: `calc(100vh - 55px)`}}
                           removeTracksWhenNotUsed={true}
                >
                    {this.menu.map((m, i) => {
                        if (m.type === 'title') {
                            return this.renderHeader(m, i);
                        } else {
                            return this.renderItem(m, i);
                        }
                    })}
                    <div className={"sidebar-bottom"}>
                        <div className={"text-center"}>
                            <a href={"https://www.feisfwd.com/privacy"} target={"_blank"} rel="noopener noreferrer">Privacy</a> ·&nbsp;
                            <a href={"https://www.feisfwd.com/terms"} target={"_blank"} rel="noopener noreferrer">Terms</a> ·&nbsp;
                            <a href={"https://www.feisfwd.com/"} target={"_blank"} rel="noopener noreferrer">About</a> ·&nbsp;
                            <a href={"http://www.feisfwd.com/faq"} target={"_blank"} rel="noopener noreferrer">FAQ</a>

                        </div>

                        <div className={"text-center"}>
                            FeisFWD &copy; {new Date().getFullYear()}
                            <div>
                                <Link to={'/app/updates'} className={"version-text"}>Version {packageJson.version}</Link>
                            </div>

                        </div>

                    </div>


                    <div className={"sidebar-bottom-alt"}>


                        <div className={"text-center"}>
                            &copy; {new Date().getFullYear()}<br />
                            <div className={"version-text"}>V{packageJson.version}</div>
                        </div>

                    </div>

                </Scrollbar>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    setSidebar: (st) => {
        dispatch(authActions.setSidebar(st));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));

class SidebarContainer extends React.Component {

    render() {
        return (

            <Connected {...this.props} />


        )
    }
}

export default SidebarContainer;
