/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 1/30/2021
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {withFormik} from "formik";
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import * as tableActions from '../../../store/actions/table';
import Button from "react-bootstrap/Button";
import * as eventActions from '../../../store/actions/events';
import Form from "react-bootstrap/Form";
import Select from "../../Common/Select";
import Table from "react-bootstrap/Table";
import {Scrollbar} from "react-scrollbars-custom";


class JudgeConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    componentDidMount(props) {
        this.props.getJudges(this.props.match.params.id, this.props.auth.token._id);
    }

    componentDidUpdate(props, state) {

    }

    getClassName = (grade, c, judge) => {
        if (grade.type === "championship"){
            let cn = "championship-color ";

            this.props.values.judges.forEach((j, ji) => {
                if (judge._id === j._id) {
                    if (j.judgeEvent.competitions.indexOf(c._id) > -1) {
                        cn += "color-" + ji.toString();
                    }
                }
            })

            return c.code ? cn : '';
        } else {

            let cn = "color-";

            this.props.values.judges.forEach((j, ji) => {
                if (j.judgeEvent) {
                    if (j.judgeEvent.competitions.indexOf(c._id) > -1) {
                        cn += ji.toString();
                    }
                }
            })

            return c.code ? cn : '';
        }

    }

    isChecked = (grade, cond) => {

        let rel = this.props.values.judges.filter(j => j._id === this.props.values.currentJudge._id)[0];

        if (rel) {
            let av = [];
            grade.competitions.filter(com => {
                if (cond.type === "row") {
                    return com.row === cond.val && com.grade === grade._id;
                } else if (cond.type === "column") {
                    return com.column === cond.val && com.grade === grade._id;
                } else {
                    return false
                }
            }).forEach(comp => {
                let isValid = true;
                this.props.values.judges.filter(j => j._id === this.props.values.currentJudge._id).forEach(j => {
                    if (isValid) {
                        if (j.judgeEvent.competitions.indexOf(comp._id) > -1) {
                            //console.log
                            isValid = false;
                        }
                    }
                })

                if (isValid && av.indexOf(comp._id) === -1) {
                    av.push(comp._id);
                }

                return true;
            })


            let notFound = [];
            rel.judgeEvent.competitions.forEach(com => {
                if (av.indexOf(com) === -1) {
                    notFound.push(com);
                }
            })
            //console.log
            //console.log

            return notFound.length === av.length;

        } else {
            return false;
        }

    }

    render() {
        return (
            <div className={'judge-config-tables'}>
                <Row>
                    <Col md={6}>
                        <div className={"field-container"}>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Configure for </Form.Label>
                                <Select
                                    disableTyping={true}
                                    items={this.props.values.judges}
                                    value={this.props.values.currentJudge || {}}
                                    itemValue={"_id"}
                                    onChange={val => {
                                        this.props.setFieldValue('currentJudge', val);
                                    }}
                                    displayValue={"index"}

                                />

                            </Form.Group>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className={"text-right pt-3"}>
                            <Button variant={"secondary"} type={"submit"} onClick={e => this.props.handleSubmit(this.props.values)}>Save</Button>
                        </div>
                    </Col>
                </Row>



                <div className={"email-colors"}>
                    {this.props.values.judges ?
                        this.props.values.judges.map((j, i) => {
                            return (
                                <div className={`tag color-${i}`}>{`#${i + 1}`}</div>
                            )
                        })
                    : null}
                </div>
                <div className={"spacer"} />
                <div className={"divider"}/>
                <Scrollbar className={'feis-page-scroller'} noScrollX={true} onUpdate={this.handleUpdate}
                            style={{height: 'calc(100vh - 360px)', width: 'calc(100% + 40px)', marginLeft: '-20px', minHeight: '500px'}}>


                <div className={"spacer"} />
                {
                    this.props.values.grades ?
                        this.props.values.grades.map((grade, i) => {
                            return (
                                <div key={i}>

                                    <div className={"spacer"} />

                                    <div className={'table-container'}>
                                        <Table key={i} striped bordered hover>
                                            <thead>
                                            <tr>
                                                <th>
                                                    <b>{grade.title}</b>
                                                    <div className={"select-all-container float-right text-right"}>
                                                        <Button variant={"link npr"} onClick={e => {
                                                            let av = [];
                                                            grade.competitions.forEach(comp => {
                                                                let isValid = true;
                                                                this.props.values.judges.forEach(j => {
                                                                    if (isValid) {
                                                                        if (j.judgeEvent.competitions.indexOf(comp._id) > -1) {
                                                                            //console.log
                                                                            isValid = false;
                                                                        }
                                                                    }
                                                                })

                                                                if (isValid && av.indexOf(comp._id) === -1) {
                                                                    av.push(comp._id);
                                                                }
                                                            })
                                                            //console.log
                                                            let rel = this.props.values.judges.filter(j => j._id === this.props.values.currentJudge._id);

                                                            if (rel.length > 0) {
                                                                this.props.setFieldValue('judges', this.props.values.judges.map(j => {
                                                                    if (j._id === this.props.values.currentJudge._id) {
                                                                        return {
                                                                            ...j,
                                                                            judgeEvent: {
                                                                                ...j.judgeEvent,
                                                                                competitions: j.judgeEvent.competitions.concat(av)
                                                                            }
                                                                        }
                                                                    } else {
                                                                        return j
                                                                    }
                                                                }))
                                                            }
                                                        }}>All</Button> &nbsp; | &nbsp;
                                                        <Button variant={"link npr"} onClick={e => {
                                                            let av = [];
                                                            grade.competitions.forEach(comp => {
                                                                let isValid = false;
                                                                this.props.values.judges.filter(j => j._id === this.props.values.currentJudge._id).forEach(j => {
                                                                    if (!isValid) {
                                                                        if (j.judgeEvent.competitions.indexOf(comp._id) > -1) {
                                                                            isValid = true;
                                                                        }
                                                                    }
                                                                })

                                                                if (isValid) {
                                                                    av.push(comp._id);
                                                                }
                                                            })
                                                            let rel = this.props.values.judges.filter(j => j._id === this.props.values.currentJudge._id);

                                                            //console.log
                                                            if (rel.length > 0) {
                                                                this.props.setFieldValue('judges', this.props.values.judges.map(j => {
                                                                    if (j._id === this.props.values.currentJudge._id) {
                                                                        return {
                                                                            ...j,
                                                                            judgeEvent: {
                                                                                ...j.judgeEvent,
                                                                                competitions: j.judgeEvent.competitions.filter(com => av.indexOf(com) === -1)
                                                                            }
                                                                        }
                                                                    } else {
                                                                        return j
                                                                    }
                                                                }))
                                                            }
                                                        }}>None</Button>
                                                    </div>
                                                </th>
                                                {grade.columns.map((col, o) => {
                                                    return (
                                                        <th key={o}>

                                                            <b>{col.age}</b>
                                                            <div className={"select-all-container float-right text-right"}>
                                                                <Button variant={"link npr"} onClick={e => {
                                                                    let av = [];
                                                                    grade.competitions.filter(com => parseInt(com.column) === parseInt(col.id)).forEach(comp => {
                                                                        let isValid = true;
                                                                        this.props.values.judges.forEach(j => {
                                                                            if (isValid) {
                                                                                if (j.judgeEvent.competitions.indexOf(comp._id) > -1) {
                                                                                    //console.log
                                                                                    isValid = false;
                                                                                }
                                                                            }
                                                                        })

                                                                        if (isValid && av.indexOf(comp._id) === -1) {
                                                                            av.push(comp._id);
                                                                        }
                                                                    })
                                                                    //console.log
                                                                    let rel = this.props.values.judges.filter(j => j._id === this.props.values.currentJudge._id);

                                                                    if (rel.length > 0) {
                                                                        this.props.setFieldValue('judges', this.props.values.judges.map(j => {
                                                                            if (j._id === this.props.values.currentJudge._id) {
                                                                                return {
                                                                                    ...j,
                                                                                    judgeEvent: {
                                                                                        ...j.judgeEvent,
                                                                                        competitions: j.judgeEvent.competitions.concat(av)
                                                                                    }
                                                                                }
                                                                            } else {
                                                                                return j
                                                                            }
                                                                        }))
                                                                    }
                                                                }}>All</Button> &nbsp; | &nbsp;
                                                                <Button variant={"link npr"} onClick={e => {
                                                                    let av = [];
                                                                    grade.competitions.filter(com => parseInt(com.column) === parseInt(col.id)).forEach(comp => {
                                                                        let isValid = false;
                                                                        this.props.values.judges.filter(j => j._id === this.props.values.currentJudge._id).forEach(j => {
                                                                            if (!isValid) {
                                                                                if (j.judgeEvent.competitions.indexOf(comp._id) > -1) {
                                                                                    isValid = true;
                                                                                }
                                                                            }
                                                                        })

                                                                        if (isValid) {
                                                                            av.push(comp._id);
                                                                        }
                                                                    })
                                                                    let rel = this.props.values.judges.filter(j => j._id === this.props.values.currentJudge._id);

                                                                    //console.log
                                                                    if (rel.length > 0) {
                                                                        this.props.setFieldValue('judges', this.props.values.judges.map(j => {
                                                                            if (j._id === this.props.values.currentJudge._id) {
                                                                                return {
                                                                                    ...j,
                                                                                    judgeEvent: {
                                                                                        ...j.judgeEvent,
                                                                                        competitions: j.judgeEvent.competitions.filter(com => av.indexOf(com) === -1)
                                                                                    }
                                                                                }
                                                                            } else {
                                                                                return j
                                                                            }
                                                                        }))
                                                                    }
                                                                }}>None</Button>
                                                            </div>

                                                        </th>
                                                    )
                                                })}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {grade.rows.map((row, o) => {

                                                return (
                                                    <tr key={o}>
                                                        <td>
                                                            <b>{row.title}</b>

                                                            <div className={"select-all-container float-right text-right"}>
                                                                <Button variant={"link npr"} onClick={e => {
                                                                    let av = [];
                                                                    grade.competitions.filter(com => parseInt(com.row) === parseInt(row.id)).forEach(comp => {
                                                                        let isValid = true;
                                                                        this.props.values.judges.forEach(j => {
                                                                            if (isValid) {
                                                                                if (j.judgeEvent.competitions.indexOf(comp._id) > -1) {
                                                                                    //console.log
                                                                                    isValid = false;
                                                                                }
                                                                            }
                                                                        })

                                                                        if (isValid && av.indexOf(comp._id) === -1) {
                                                                            av.push(comp._id);
                                                                        }
                                                                    })
                                                                    //console.log
                                                                    let rel = this.props.values.judges.filter(j => j._id === this.props.values.currentJudge._id);

                                                                    if (rel.length > 0) {
                                                                        this.props.setFieldValue('judges', this.props.values.judges.map(j => {
                                                                            if (j._id === this.props.values.currentJudge._id) {
                                                                                return {
                                                                                    ...j,
                                                                                    judgeEvent: {
                                                                                        ...j.judgeEvent,
                                                                                        competitions: j.judgeEvent.competitions.concat(av)
                                                                                    }
                                                                                }
                                                                            } else {
                                                                                return j
                                                                            }
                                                                        }))
                                                                    }
                                                                }}>All</Button> &nbsp; | &nbsp;
                                                                <Button variant={"link npr"} onClick={e => {
                                                                    let av = [];
                                                                    grade.competitions.filter(com => parseInt(com.row) === parseInt(row.id)).forEach(comp => {
                                                                        let isValid = false;
                                                                        this.props.values.judges.filter(j => j._id === this.props.values.currentJudge._id).forEach(j => {
                                                                            if (!isValid) {
                                                                                if (j.judgeEvent.competitions.indexOf(comp._id) > -1) {
                                                                                    isValid = true;
                                                                                }
                                                                            }
                                                                        })

                                                                        if (isValid) {
                                                                            av.push(comp._id);
                                                                        }
                                                                    })
                                                                    let rel = this.props.values.judges.filter(j => j._id === this.props.values.currentJudge._id);

                                                                    //console.log
                                                                    if (rel.length > 0) {
                                                                        this.props.setFieldValue('judges', this.props.values.judges.map(j => {
                                                                            if (j._id === this.props.values.currentJudge._id) {
                                                                                return {
                                                                                    ...j,
                                                                                    judgeEvent: {
                                                                                        ...j.judgeEvent,
                                                                                        competitions: j.judgeEvent.competitions.filter(com => av.indexOf(com) === -1)
                                                                                    }
                                                                                }
                                                                            } else {
                                                                                return j
                                                                            }
                                                                        }))
                                                                    }
                                                                }}>None</Button>
                                                            </div>


                                                        </td>
                                                        {grade.columns ?
                                                            grade.columns.map((col, coli) => {
                                                                if (grade.competitions) {
                                                                    return grade.competitions.filter(c => parseInt(c.row) === parseInt(row.id) && parseInt(c.column) === parseInt(col.id)).map((c, co) => {
                                                                        let canSelect = false;
                                                                        if (grade.type === "championship") {
                                                                            let count = this.props.values.judges.filter(j => j._id !== this.props.values.currentJudge._id).filter(j => {
                                                                                return j.judgeEvent.competitions.indexOf(c._id) > -1;
                                                                            }).length;
                                                                            //console.log
                                                                            canSelect = count < 3;
                                                                        } else if (this.props.values.judges.filter(j => j._id !== this.props.values.currentJudge._id).length > 0){
                                                                            let count = this.props.values.judges.filter(j => j._id !== this.props.values.currentJudge._id).filter(j => {
                                                                                return j.judgeEvent.competitions.indexOf(c._id) > -1;
                                                                            }).length;
                                                                            canSelect = count === 0;
                                                                        } else if (this.props.values.judges.filter(j => j._id !== this.props.values.currentJudge._id).length === 0) {
                                                                            canSelect = true;
                                                                        }
                                                                        return (
                                                                            <td key={co} className={grade.type === "championship" ? "championship-cell" : this.getClassName(grade, c)}>
                                                                                {c.code && grade.type === "championship" ?
                                                                                    <div className={"championship-colors"}>
                                                                                        {this.props.values.judges.filter(judge => judge.judgeEvent.competitions.indexOf(c._id) > -1).map((judge, jui) => {

                                                                                            return <div className={this.getClassName(grade, c, judge)} key={jui} />

                                                                                        })}
                                                                                    </div>
                                                                                    : null}
                                                                                {c.code ?

                                                                                    <Form.Check
                                                                                        type="checkbox"
                                                                                        name="name"
                                                                                        placeholder="Enter event name"
                                                                                        label={c.code + " (" + c.count + ")"}
                                                                                        disabled={!canSelect}
                                                                                        checked={this.props.values.judges.filter(j => j._id === this.props.values.currentJudge._id).length > 0 ?
                                                                                            this.props.values.judges.filter(j => j._id === this.props.values.currentJudge._id)[0].judgeEvent.competitions.indexOf(c._id) > -1
                                                                                            : false}
                                                                                        onChange={(e) => {
                                                                                            let rel = this.props.values.judges.filter(j => j._id === this.props.values.currentJudge._id);

                                                                                            if (rel.length > 0) {
                                                                                                let index = rel[0].judgeEvent.competitions.indexOf(c._id);
                                                                                                //console.log
                                                                                                if (index > -1) {
                                                                                                    this.props.setFieldValue('judges', this.props.values.judges.map(j => {
                                                                                                        if (j._id === this.props.values.currentJudge._id) {
                                                                                                            return {
                                                                                                                ...j,
                                                                                                                judgeEvent: {
                                                                                                                    ...j.judgeEvent,
                                                                                                                    competitions: j.judgeEvent.competitions.filter(je => je !== c._id)
                                                                                                                }
                                                                                                            }
                                                                                                        } else {
                                                                                                            return j
                                                                                                        }
                                                                                                    }))
                                                                                                } else {
                                                                                                    this.props.setFieldValue('judges', this.props.values.judges.map(j => {
                                                                                                        if (j._id === this.props.values.currentJudge._id) {
                                                                                                            return {
                                                                                                                ...j,
                                                                                                                judgeEvent: {
                                                                                                                    ...j.judgeEvent,
                                                                                                                    competitions: j.judgeEvent.competitions.concat(c._id)
                                                                                                                }
                                                                                                            }
                                                                                                        } else {
                                                                                                            return j
                                                                                                        }
                                                                                                    }))
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                        onBlur={this.props.handleBlur}
                                                                                        className={`form-control form-control-mini`}
                                                                                    />

                                                                                    :
                                                                                    null}

                                                                            </td>
                                                                        )
                                                                    })


                                                                } else {
                                                                    return null;
                                                                }
                                                            })
                                                            : null}

                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            )
                        })
                        : null
                }
                </Scrollbar>



            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    events: state.events,
    single: state.single
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
    getJudges: (eventId, token) => {
        dispatch(eventActions.getPartial("events/getJudges", eventId, token));
    },
    save: (d, token) => {
        //console.log
        dispatch(eventActions.patchJudges("judgeEvents/multiPatch", d, token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(withFormik({
    mapPropsToValues: (props) => ({
        judges: props.events.event ? props.events.event.judges ? props.events.event.judges : [] : [],
        grades: props.events.event ? props.events.event.grades ? props.events.event.grades : [] : [],
        judgeComps: props.events.event ? props.events.event.judgeComps ? props.events.event.judgeComps : [] : [],
        currentJudge: props.events.event ? props.events.event.judges ? props.events.event.judges[0] : {} : {}
    }),
    validate: values => {

    },
    enableReinitialize: true,
    handleSubmit: (values, {props, setSubmitting}) => {
        //console.log
        props.save({judges: values.judges}, props.auth.token._id)
    }
})(JudgeConfig)));

class JudgeConfigContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default JudgeConfigContainer;
