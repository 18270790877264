/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 1/23/2021
 */

import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import * as tableActions from '../../../../store/actions/table';

class Rounds extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    componentDidMount(props) {

    }

    componentDidUpdate(props, state) {

    }

    render() {
        if (this.props.events.competition.single) {
            if (this.props.events.competition.single.score) {
                return (
                    <div>
                        <div className={"spacer-mini"} />
                        <Row>
                            <Col sm={2}>
                                <div className={"round-tile"}>
                                    <h4>{this.props.events.competition.single.score.round1Score || '-'}</h4>
                                    <div className={"round-title"}>Round 1</div>
                                </div>
                            </Col>
                            <Col sm={2}>
                                <div className={"round-tile"}>
                                    <h4>{this.props.events.competition.single.score.round2Score || '-'}</h4>
                                    <div className={"round-title"}>Round 2</div>
                                </div>
                            </Col>

                            {this.props.events.competition.single.rounds >= 3 ?
                            <Col sm={2}>
                                <div className={"round-tile"}>
                                    <h4>{this.props.events.competition.single.score.round1Score && this.props.events.competition.single.score.round2Score ?
                                        this.props.events.competition.single.score.round1Score + this.props.events.competition.single.score.round2Score
                                    : '-'}</h4>
                                    <div className={"round-title"}>Subtotal</div>
                                </div>
                            </Col>
                                : null}
                            {this.props.events.competition.single.rounds >= 3 ?
                            <Col sm={2}>
                                <div className={"round-tile"}>
                                    <h4>{this.props.events.competition.single.score.round3Score || '-'}</h4>
                                    <div className={"round-title"}>Round 3</div>
                                </div>
                            </Col>
                                : null}
                            <Col sm={2}>
                                {this.props.events.competition.single.rounds >= 3 ?
                                    <div className={"round-tile"}>
                                        <h4>{this.props.events.competition.single.score.round1Score && this.props.events.competition.single.score.round2Score &&
                                        this.props.events.competition.single.score.round3Score ?
                                            this.props.events.competition.single.score.round1Score + this.props.events.competition.single.score.round2Score + this.props.events.competition.single.score.round3Score
                                            : '-'}</h4>
                                        <div className={"round-title"}>Total Score</div>
                                    </div>
                                    :
                                    <div className={"round-tile"}>
                                        <h4>{this.props.events.competition.single.score.round1Score && this.props.events.competition.single.score.round2Score ?
                                            this.props.events.competition.single.score.round1Score + this.props.events.competition.single.score.round2Score
                                            : '-'}</h4>
                                        <div className={"round-title"}>Total Score</div>
                                    </div>
                                }
                            </Col>
                            <Col sm={2}>
                                <div className={"round-tile"}>
                                    <h4>{
                                        this.props.match.params.round === "1" ?
                                            this.props.events.competition.single.score.round1Rank || '-'
                                            : this.props.match.params.round === "2" ?
                                            this.props.events.competition.single.score.round2Rank || '-'
                                            : this.props.match.params.round === "3" ?
                                                this.props.events.competition.single.score.finalRank || this.props.events.competition.single.score.round3Rank
                                                : null
                                    }</h4>
                                    <div className={"round-title"}>Ranking</div>
                                </div>
                            </Col>
                        </Row>
                        <div className={"text-right"}>
                            <Link to={"#leaderboard"} onClick={e => {
                                e.preventDefault();
                                this.props.showModal(e);
                            }}>View Leaderboard</Link>
                        </div>
                    </div>
                )
            } else {
                return null;
            }
        }else {
            return null;
        }

    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Rounds));

class RoundsContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default RoundsContainer;
