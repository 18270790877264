/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Modal from "react-bootstrap/Modal";
import * as singleActions from '../../store/actions/single';
import Table from "react-bootstrap/Table";
import * as eventActions from '../../store/actions/events';
import {FormControl} from "react-bootstrap";

class CompetitionModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            preview: ''
        }
    }
    hideModal = () => {
        this.props.handleModal(false)
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(props) {
        if (this.props.data._id !== props.data._id) {
            this.init();
        }
    }

    init = () => {
        this.props.get({id: this.props.data.event, competition: this.props.data._id}, this.props.auth.token._id);
    }


    render() {
        if (this.props.events.organizerCompetition) {
            if (this.props.events.organizerCompetition._id === this.props.data._id) {
                return (
                    <div className={this.props.user.user.theme}>
                        <Modal centered size={'lg'} className={`theme-${this.props.user.user.theme}`} show={this.props.show} onHide={e => this.props.hideModal(false)}>
                            <Modal.Body>
                                <div className={"spacer"} />
                                <h5>
                                    {this.props.events.organizerCompetition.grade ? this.props.events.organizerCompetition.grade.title : ''} -&nbsp;
                                    {this.props.events.organizerCompetition.rowTitle} -&nbsp;
                                    {this.props.events.organizerCompetition.columnTitle}&nbsp;
                                    ({this.props.events.organizerCompetition.code})
                                </h5>
                                <div className={"spacer"} />
                                <Table responsive>
                                    <thead>
                                    <tr>
                                        <th>Dancer Number</th>
                                        <th>Name</th>
                                        <th>School</th>
                                        <th>Age</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {this.props.events.organizerCompetition ? this.props.events.organizerCompetition.entries ? this.props.events.organizerCompetition.entries.map((d, i) => {
                                        return (
                                            <tr>
                                                {this.props.user.user.role === "admin" ? 
                                                    <td>
                                                    <input
                                                        type={"number"}
                                                        id={d._id}
                                                        name={"dancerNumber"}
                                                        defaultValue ={d.dancerNumber}
                                                        onChange={(e) => {
                                                            this.props.updateDancerNum(e.target.id, e.target.value, this.props.auth.token._id);
                                                            this.props.events.organizerCompetition.entries.map((entry, index) => {
                                                                if (e.target.id === entry._id) {
                                                                    entry.dancerNumber = e.target.value;
                                                                }
                                                                return entry;
                                                            });
                                                        }}
                                                        className={"microInput"}
                                                    />
                                                    </td>
                                                    : <td>{d.dancerNumber}</td>}

                                                <td>{d.profile.name} {d.profile.lname}</td>
                                                <td>{d.profile.school?.name}</td>
                                                <td>{d.profile.age || 'N/A'}</td>
                                                </tr>

                                        )
                                    }) : null : null}

                                    </tbody>
                                </Table>
                            </Modal.Body>
                        </Modal>
                    </div>
                )
            } else{
                return null
            }
        } else {
            return null
        }

    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user,
    single: state.single,
    events: state.events
});

const mapDispatchToProps = dispatch => ({
    post: (uri, item, token) => {
        dispatch(singleActions.post(uri, item, token));
    },
    postFile: (uri, fd, token) => {
        dispatch(singleActions.postFile(uri, fd, token));
    },
    patch: (uri, id, item, token) => {
        dispatch(singleActions.patch(uri, id, item, token));
    },
    get: (query, token) => {
        dispatch(eventActions.getOrganizerCompetition(query, token));
    },
    updateDancerNum: (entryCompetition, dancerNum, token) => {
        dispatch(eventActions.updateDancerNum(entryCompetition, dancerNum, token));
    },
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(CompetitionModal));

class CompetitionModalContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default CompetitionModalContainer;
