/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as tableActions from '../../../store/actions/table';



class Users extends React.Component {

    fetchMoreData = (skip) => {
        this.props.getTableData('users', skip, 20, this.props.auth.token._id);
    }

    render() {
        return (
            <div>
                <h1>Users</h1>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user,
    table: state.table
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, token) => {
        dispatch(tableActions.getTableData(view, skip, limit, token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Users));

class UsersContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default UsersContainer;