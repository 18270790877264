/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 1/30/2021
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import * as tableActions from '../../../store/actions/table';
import Button from "react-bootstrap/Button";
import { ArrowBack } from "react-ionicons";

class Alerts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    componentDidMount(props) {

    }

    componentDidUpdate(props, state) {

    }

    render() {
        return (
            <div className={'page-container'}>
                <div className={'page-header'}>
                    <Row>
                        <Col>
                            <h1>
                                {this.props.location.pathname.split("/").length > 3 ?
                                    <Button variant={"flat btn-round"} onClick={e => {

                                        this.props.history.push(`/app/event-dashboard/${this.props.match.params.id}`);
                                    }}>
                                        <ArrowBack />
                                    </Button>
                                    : null}

                                Alerts</h1>
                        </Col>
                    </Row>
                </div>


            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    events: state.events
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Alerts));

class AlertsContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default AlertsContainer;