import Api from '../../utils/Api';

export function getInitialNotifications(skip, token){
    return dispatch => {
        const api = new Api(token);
        api.get('notifications', {skip}, res => {
            if (res.status === 200) {
                if (res.data.status === 200) {
                    try {
                        dispatch({
                            type: 'INIT_NOTIFICATION',
                            payload: res.data.data
                        })
                    } catch(err) {

                    }
                }
            }

        })
    }
}

// MARK ALL READ TOMORROW
export function markAllRead(token) {
    return dispatch => {
        const api = new Api(token);
        api.post('notifications/markAllRead', {}, res => {
            if (res.status === 200) {
                if (res.data.status === 200) {
                    try {
                        dispatch({
                            type: 'MARK_ALL_SEEN',
                            payload: res.data.data
                        })
                    } catch(err) {

                    }
                }
            }

        })
    }
}