/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 6/11/2020
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Formik} from "formik";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as yup from 'yup';
import Button from 'react-bootstrap/Button';

const schema = yup.object({
    name: yup.string().required('This field is required.')
});

class RegionForm extends React.Component {
    handleSubmit = (values) => {
        this.props.post(values);
        this.props.hideModal();
    }


    componentDidUpdate(props, state) {
        if (props.single.single !== this.props.single.single) {
            this.props.setValues({
                ...this.props.single
            })
        }
    }

    render() {
        return (
            <div>
                <Formik
                    validationSchema={schema}
                    onSubmit={this.handleSubmit}
                    initialValues={{
                        name: this.props.single.single.name || '',

                    }}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                          touched,
                          isValid,
                          errors,
                          isSubmitting
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row>
                                <Col>
                                    <Form.Group controlId="name">
                                        <Form.Label>Title</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            placeholder="Enter title"
                                            value={values.name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isValid={touched.name && !errors.name}

                                        />
                                        <Form.Control.Feedback></Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                            </Row>




                            <div className={'text-right'}>
                                <Button variant={'primary'} className={'btn-flat btn-transparent'} onClick={e => this.props.hideModal()}>Cancel</Button>
                                <Button variant={'primary'} type={'submit'}>Save</Button>
                            </div>
                        </Form>
                    )}
                </Formik>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    single: state.single
});

const mapDispatchToProps = dispatch => ({

});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(RegionForm));

class RegionFormContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default RegionFormContainer;