import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {Formik} from 'formik';
import * as yup from 'yup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import urls from "../../../utils/urls";
import placeholder from "../../../assets/images/placeholder-person.png";
import * as singleActions from "../../../store/actions/single";
import {NotificationManager} from "react-notifications";
import Select from '../../Common/Select';
import CategoryPicker from "../../Common/CategoryPicker";
import FormButtons from "../../Common/FormButtons";
import * as SingleActions from "../../../store/actions/single";





class TeamForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fileSelectField: '',
            filePickerOpen: false,
            selectingFileType: 'image',
            preview: '',
            image: '',
            disableNext: false,
            createSchool: '',
            createTeacher: ''
        }

        this.form = React.createRef();
    }

    handleFormModal = (e) => {
        this.setState({filePickerOpen: false})
    }

    selectFile = (file) => {
        //this.props.updateField('image', file);
        this.props.setValues({image: file});
    }



    schema = yup.object({
        name: yup.string().required(),
        label: yup.string().required(),
        region: yup.object().shape({
            _id: yup.string().required("Region is required"),
        }),
        school: yup.object().shape({
            _id: yup.string().required("School is required"),
        })
    });

    handleSubmit = (values) => {

        let data = new FormData();
        data.append("files", this.state.image);

        let params = {team: values};
        params.team.admins = [this.props.user.user._id];
        params.team.region = values.region ? values.region._id : '';
        params.team.school = values.school ? values.school._id : '';

        if (params.team.teacher === ''){
            delete params.team.teacher;
        }

        if (params.team.region === ''){
            delete params.team.region;
        }

        if (this.props.single.single._id) {
            params.team._id = this.props.single.single._id;
        }

        if (this.state.createTeacher) {
            params.createTeacher = this.state.createTeacher;
        }

        if (this.state.createSchool) {
            params.createSchool = this.state.createSchool;
        }

        data.set("data", JSON.stringify(params));

        this.setState({disableNext: true});

        if (this.props.onboarding) {
            this.props.postOnboarding("users/nextStep", data, this.props.auth.token._id, (id) => {
                this.setState({disableNext: false});
            });
        } else {
            if (this.props.single.single._id) {
                this.props.patch("teams", this.props.single.single._id, data, this.props.auth.token._id, (id) => {
                    this.props.history.push('/app/teams/' + id);
                });
            } else {
                this.props.post("teams", data, this.props.auth.token._id, (id) => {
                    this.props.history.push('/app/teams/' + id);
                });
            }
        }

    }

    componentDidMount(){
        this.props.loadOnboarding(this.props.auth.token._id);
        this.init();
    }

    componentDidUpdate(props, state) {
        if(props.single.single !== this.props.single.single) {
            this.init();
            if (this.form.current) {
                this.form.current.setValues({
                    ...this.props.single.single
                })
            }
            if (!this.props.onboarding) {
                if (props.match.params.id === 'new' && this.props.single.single._id) {
                    this.props.history.push('/app/teams/' + this.props.single.single._id)
                    NotificationManager.success('Your changes have been saved');
                }
            }

        }


    }

    init = () => {

        if (this.props.single.single.image) {
            if (this.props.single.single.image.path) {
                this.setState({preview: urls.getS3Url(this.props.single.single.image.thumb || this.props.single.single.image.path)})
            }
        } else if (this.state.preview) {
            this.setState({preview: ''})

        }
    }

    //Grades (Beginner - Prizewinner), Championship, Adult
    grades = [
        {name: 'Grades (Non-Champion)', id: 'grades'},
        {name: 'Championship', id: 'championship'},
        {name: 'Adult', id: 'adult'},

    ];


    render() {

        let disableFields = false;

        if (this.props.location.pathname.indexOf('/teams/') > -1 && this.props.match.params.id === 'new') {
            disableFields = false;
        } else if (this.props.user.user.role === 'admin') {
            disableFields = false;
        } else {
            if (this.props.location.pathname.indexOf('/teams/') > -1) {
                if (this.props.single.single) {
                    if (this.props.single.single.owners) {
                        if (this.props.single.single.owners.indexOf(this.props.user.user._id) === -1) {
                            disableFields = true;
                        }
                    }
                }
            }
        }
        return (
            <div>
                <Formik
                    validationSchema={this.schema}
                    onSubmit={this.handleSubmit}
                    enableReinitialize
                    ref={this.form}
                    initialValues={{
                        name: this.props.location.pathname.indexOf('/teams/') > -1 ? this.props.match.params.id === 'new' ? '' : this.props.single.single.name : this.props.single.single.name || '',
                        label: this.props.location.pathname.indexOf('/teams/') > -1 ? this.props.match.params.id === 'new' ? '' : this.props.single.single.label : this.props.single.single.label || '',
                        image: this.props.location.pathname.indexOf('/teams/') > -1 ? this.props.match.params.id === 'new' ? '' : this.props.single.single.image : this.props.single.single.image || '',
                        region: this.props.location.pathname.indexOf('/teams/') > -1 ? this.props.match.params.id === 'new' ? {} : this.props.single.single.region : this.props.single.single.region || {},
                        organization: this.props.location.pathname.indexOf('/teams/') > -1 ? this.props.match.params.id === 'new' ? '' : this.props.single.single.organization : this.props.single.single.organization || '',
                        school: this.props.location.pathname.indexOf('/teams/') > -1 ? this.props.match.params.id === 'new' ? {} : this.props.single.single.school : this.props.single.single.school || {},
                        members: this.props.location.pathname.indexOf('/teams/') > -1 ? this.props.match.params.id === 'new' ? [] : this.props.single.single.members || [] : this.props.single.single.members || [],
                    }}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                          touched,
                          isValid,
                          errors,
                          isSubmitting,
                          setFieldValue
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            {this.props.onboarding ?
                                <FormButtons
                                    confirm={(e) => {
                                        handleSubmit(values);
                                    }}
                                    back={(e) => {
                                        e.preventDefault();
                                        this.step(values, "previous");
                                    }}
                                    showPrevious={false}
                                    showRevert={false}
                                    showNext={true}
                                    next={(e) => {
                                        e.preventDefault();
                                        handleSubmit(values);
                                    }}
                                    showDefault={false}
                                    label={'Team Profile'}

                                />
                                : null}

                            <div>
                                <div className={"profile-header"}>
                                    <Form.Group controlId="image">
                                        {this.state.preview ?
                                            <div onClick={e => this.setState({filePickerOpen: true})} className={"artist-img avatar"} style={{background: this.state.preview ? `url(${this.state.preview}), linear-gradient(0deg, rgba(0,0,0,.6), rgba(0,0,0,.4), rgba(0,0,0,.2), transparent)` : `url(${placeholder}), linear-gradient(0deg, rgba(0,0,0,.6), rgba(0,0,0,.4), rgba(0,0,0,.2), transparent)`}}></div>
                                            //<img src={this.state.preview} className={"form-preview-image"} />
                                            :
                                            <div onClick={e => this.setState({filePickerOpen: true})} className={"artist-img avatar"} style={{background: `url(${placeholder}), linear-gradient(0deg, rgba(0,0,0,.6), rgba(0,0,0,.4), rgba(0,0,0,.2), transparent)`}}></div>

                                        }
                                        <Form.Control
                                            type="file"
                                            name="image"
                                            placeholder="Upload image"
                                            onChange={(e) => {
                                                this.setState({
                                                    preview: URL.createObjectURL(e.target.files[0]),
                                                    image: e.target.files[0]
                                                })
                                            }}
                                            disabled={disableFields}
                                            onBlur={handleBlur}
                                            isValid={touched.image && !errors.image}
                                            className={`form-control hidden-file-input centered-input ${touched.image ? errors.image ? 'is-invalid' : 'is-valid' : ''}`}
                                        />
                                    </Form.Group>
                                    <h2 className={"profile-title"}>{this.props.single.single.name}</h2>
                                    {this.props.single.single.admins ? this.props.single.single.admins.filter(a => a._id === this.props.user.user._id).length > 0 ?
                                        <Button variant={"flat"} onClick={e => this.props.history.push(`/app/schools/${this.props.match.params.id}/edit`)}>Edit</Button>
                                        : null : null}
                                </div>
                                <div className={"profile-body"}>

                                    <div className={"tab-content ex-pad"}>

                                        <Row>
                                            <Col md={3}></Col>
                                            <Col md={6}>
                                                <div className={"field-container"}>
                                                    <Form.Group controlId="name">
                                                        <Form.Label>Team Name</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="name"
                                                            placeholder="Enter name"
                                                            value={values.name}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            disabled={disableFields}
                                                            isValid={touched.name && !errors.name}
                                                            className={`form-control ${touched.name ? errors.name ? 'is-invalid' : 'is-valid' : ''}`}
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col md={3}></Col>
                                            <Col md={6}>
                                                <div className={"field-container"}>
                                                    <Form.Group controlId="label">
                                                        <Form.Label>Team Label</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="label"
                                                            placeholder="Team Label"
                                                            value={values.label}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            disabled={disableFields}
                                                            isValid={touched.label && !errors.label}
                                                            className={`form-control ${touched.label ? errors.label ? 'is-invalid' : 'is-valid' : ''}`}
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col md={3}></Col>
                                            <Col md={6}>
                                                <div className={"field-container"}>
                                                    <label>School</label>

                                                    <CategoryPicker
                                                        items={this.props.single.schools}
                                                        value={values.school || {}}
                                                        itemValue={"_id"}
                                                        onChange={val => setFieldValue('school', val)}
                                                        remote={true}
                                                        remoteKey={'schools'}
                                                        urlRoute={"/search"}
                                                        displayValue={"name"}
                                                        imageKey={"image"}
                                                        showDelete={false}
                                                        onDelete={val => {
                                                            setFieldValue('school', {})
                                                        }}
                                                        onType={e => {
                                                            this.setState({createSchool: e.target.value})
                                                        }}

                                                    />
                                                    {errors.school && touched.school && (<p className={"text-danger hint-text"}>{errors.school._id}</p>)}

                                                </div>
                                            </Col>
                                        </Row>


                                        <div className={"spacer"} />
                                        <div className={"divider"} />
                                        <div className={"spacer"} />

                                        <Row>
                                            <Col sm={3}>

                                            </Col>
                                            <Col sm={6}>
                                                <div className={"field-container"}>
                                                    <label>Members</label>
                                                    {values.members ?
                                                        values.members.map((m, i) => {
                                                            return <CategoryPicker
                                                                items={this.props.single.dancers || []}
                                                                value={m || {}}
                                                                itemValue={"_id"}
                                                                onChange={val => setFieldValue('members', values.members.map((mem, memi) => {
                                                                    if (memi === i) {
                                                                        return val;
                                                                    } else {
                                                                        return mem;
                                                                    }
                                                                }))}
                                                                remote={true}
                                                                remoteKey={'dancers'}
                                                                urlRoute={"/search"}
                                                                displayValue={"name"}

                                                                displayValue2={"lname"}
                                                                imageKey={"image"}
                                                                showDelete={true}
                                                                onDelete={val => {
                                                                    setFieldValue('members', values.members.filter(mem => mem._id !== val._id));
                                                                }}
                                                                onType={e => {
                                                                    this.setState({createSchool: e.target.value})
                                                                }}

                                                            />
                                                        }) : null
                                                    }

                                                    <CategoryPicker
                                                        items={this.props.single.dancers || []}
                                                        value={{}}
                                                        itemValue={"_id"}
                                                        onChange={val => setFieldValue('members', values.members.concat(val))}
                                                        remote={true}
                                                        remoteKey={'dancers'}
                                                        urlRoute={"/search"}
                                                        displayValue={"name"}

                                                        displayValue2={"lname"}
                                                        imageKey={"image"}
                                                        showDelete={false}
                                                        onDelete={val => {
                                                            setFieldValue('school', {})
                                                        }}
                                                        onType={e => {
                                                            this.setState({createSchool: e.target.value})
                                                        }}

                                                    />
                                                    {errors.school && touched.school && (<p className={"text-danger hint-text"}>{errors.school._id}</p>)}

                                                </div>
                                            </Col>
                                        </Row>





                                        <div className={"spacer"} />
                                        <div className={"divider"} />
                                        <div className={"spacer"} />

                                        <Row>

                                            <Col sm={3}></Col>
                                            <Col sm={6}>
                                                <div className={"field-container"}>
                                                    <label>Region</label>

                                                    <Select

                                                        disableTyping={true}
                                                        items={this.props.single.regions}
                                                        value={values.region || {}}
                                                        itemValue={"_id"}
                                                        onChange={val => {
                                                            setFieldValue('region', val);
                                                        }}
                                                        displayValue={"name"}

                                                    />
                                                    {errors.region && touched.region && (<p className={"text-danger hint-text"}>{errors.region._id}</p>)}
                                                </div>
                                            </Col>



                                        </Row>
                                        {!this.props.onboarding ?
                                            <div>
                                                <div className={"divider"}/>
                                                <div className={"spacer"} />

                                                <Row>
                                                    <Col md={12} className={"text-right"}>
                                                        <Button variant="primary" onClick={e => handleSubmit(values)} disabled={disableFields || this.state.submitting}>
                                                            Submit
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <div className={"spacer"} />
                                            </div>
                                            : null}

                                    </div>
                                </div>
                            </div>



                        </Form>
                    )}
                </Formik>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    single: state.single,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    postOnboarding: (view, data, token, redirect) => {
        dispatch(singleActions.postNextStep(view, data, token, true, redirect));
    },
    post: (view, data, token, redirect) => {
        dispatch(singleActions.postFormData(view, data, token, true, redirect, true));
    },
    patch: (view, id, data, token) => {
        dispatch(singleActions.patchFormData(view, id, data, token));
    },
    loadOnboarding: (token) => {
        dispatch(SingleActions.loadOnboardingNoSingle(token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(TeamForm));

class TeamFormContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default TeamFormContainer;
