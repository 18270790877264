/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import { Add } from "react-ionicons";
import Button from "react-bootstrap/Button";
import { Menu } from 'react-ionicons';
import { MusicalNote } from 'react-ionicons';

const iconStyle = {fontSize: "22px", color: "#ffffff"};

class FAB extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false
        }
    }

    renderMenus = (view) => {
        let menu = {
            music: [
                {
                    name: 'Track',
                    icon: <MusicalNote {...iconStyle} />,
                    onClick: (e) => {
                        let splitView = this.props.location.pathname.split('/');
                        if (splitView[3]) {
                            this.props.history.push('/admin/tracks/new/' + splitView[3]);
                        }
                    }
                },
                {
                    name: 'Category',
                    icon: <Menu {...iconStyle} />,
                    onClick: (e) => {
                        this.props.handleNewItem(e)
                    }
                }
            ]
        }

        let res = [];
        if (menu[view]) {
            res = menu[view];
        }
        return res;

    }


    render() {
        let splitView = this.props.location.pathname.split('/');
        let view = '';
        if (splitView.length > 2) {
            view = splitView[2];
        }

        let menu = this.renderMenus(view);

        return (
            <div className={``}>

                    <ul className={`fab-menu ${this.state.menuOpen ? 'open' : ''}`}>
                        {menu.map((item, i) => {
                            return (
                                <li className={`step${menu.length - i - 1}`} onClick={e => {
                                    this.setState({menuOpen: false});
                                    item.onClick(e);
                                }}>

                                    <div className={'fab-menu-label'}>{item.name}</div>
                                    <div className={'fab-menu-icon'}>{item.icon}</div>

                                </li>
                            )
                        })}
                    </ul>

                <Button variant="primary" onClick={e => {
                    if(menu.length > 0) {
                        this.setState({menuOpen: !this.state.menuOpen});
                    } else {
                        this.props.handleNewItem(e)
                    }
                }} className={`btn-action fab-menu-toggle ${this.state.menuOpen ? 'toggled' : ''}`}><Add fontSize={"32px"} color={"#ffffff"} />
                </Button>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table
});

const mapDispatchToProps = dispatch => ({

});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(FAB));

class FABContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default FABContainer;