/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 1/13/2021
 */

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as singleActions from '../../../store/actions/single';
import EventBasic from './EventBasic';
import { Scrollbar } from 'react-scrollbars-custom';
import Grades from './Grades';
import EventReview from './EventReview';
import * as SingleActions from '../../../store/actions/single';
import Nav from 'react-bootstrap/Nav';
import EventAdvanced from './EventAdvanced';

class Event extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fileSelectField: '',
      filePickerOpen: false,
      selectingFileType: 'image',
      preview: '',
      image: '',
      createModerator: '',
      createAdmin: ''
    };
  }

  handleFormModal = (e) => {
    this.setState({ filePickerOpen: false });
  };

  selectFile = (file) => {
    //this.props.updateField('image', file);
    this.props.setValues({ image: file });
  };

  componentDidMount() {
    this.init({ single: { single: {} } });
    this.props.loadOnboarding(this.props.auth.token._id);
  }

  componentDidUpdate(props) {
    if (
      props.match.params.id !== this.props.match.params.id ||
      props.single.single._id !== this.props.single.single._id
    ) {
      this.init(props);
    }
  }

  init = (props) => {
    if (this.props.match.params.id !== 'new') {
      this.props.get(this.props.match.params.id, this.props.auth.token._id);
    } else if (props.single.single._id !== this.props.single.single._id) {
      // Fix save and nav on first creation

      let tab = 2;

      if (this.props.match.params.tab) {
        let currentTab = parseInt(this.props.match.params.tab);
        if (currentTab >= 3) {
          tab = 3;
        } else {
          tab = currentTab++;
        }
      }
      this.props.history.push(`/app/events/${this.props.single.single._id}/edit/${tab}`);
    } else {
      this.props.setBlankSingle();
    }
  };

  handleSubmit = (values) => {
    let view = 'schools';

    let data = new FormData();
    data.append('files', this.state.image);

    let params = { school: values };
    params.school.admins = values.admins.map((item) => item._id);
    params.school.moderators = values.moderators.map((item) => item._id);

    if (this.state.createAdmin) {
      params.createAdmin = this.state.createAdmin;
    }

    if (this.state.createModerator) {
      params.createModerator = this.state.createModerator;
    }

    if (this.props.single.single._id) {
      params.school._id = this.props.single.single._id;
    }

    data.set('data', JSON.stringify(params));

    if (params._id) {
      this.props.patch(view, params._id, data, this.props.auth.token._id);
    } else {
      if (this.props.onboarding) {
        this.props.postOnboarding('users/nextStep', data, this.props.auth.token._id, (id) => {
          this.setState({ disableNext: false });
        });
      } else {
        this.props.post(view, data, this.props.auth.token._id, (id) => {
          this.props.history.push('/admin/schools/' + id);
        });
      }
    }
  };

  menu = () => {
    return [
      {
        title: 'Basic Details',
        path: `/app/event-dashboard/${this.props.match.params.id}/edit/1`,
        component: <EventBasic />
      },
      {
        title: 'Advanced Details',
        path: `/app/event-dashboard/${this.props.match.params.id}/edit/2`,
        component: <EventAdvanced />
      },
      {
        title: 'Competitions Data',
        path: `/app/event-dashboard/${this.props.match.params.id}/edit/3`,
        component: <Grades />
      },
      {
        title: 'Review',
        path: `/app/event-dashboard/${this.props.match.params.id}/edit/4`,
        component: <EventReview />
      }
    ];
  };

  renderItem = (item, i) => {
    return (
      <Nav.Link
        key={i}
        to={item.path}
        disabled={this.props.single.single.step === 0}
        as={Link}
        className={`tab tab-${i} ${this.props.location.pathname === item.path ? 'active' : ''}`}>
        {item.title}
      </Nav.Link>
    );
  };

  render() {
    return this.props.single.single.admins || this.props.match.params.id === 'new' ? (
      <div>
        {/*<EventSteps />*/}

        {!isNaN(this.props.single.single.step) ? (
          this.props.match.params.id === 'new' ||
          this.props.single.single.admins.filter((a) => {
            //console.log("Testing admin", a, this.props.user.user);
            return a._id === this.props.user.user._id;
          }).length > 0 ? (
            <div className={'negative-20-pad'}>
              <div className={'tab-container'}>
                <div className={'tab-row items-4'}>
                  {this.menu().map((item, i) => {
                    return this.renderItem(item, i);
                  })}
                  <div className={'tab-highlight'} />
                  <div className={'tab-highlight-track'} />
                </div>
              </div>
              <Scrollbar
                className={'feis-page-scroller'}
                style={{ height: 'calc(100vh - 160px)' }}
                noScrollX={true}>
                <div
                  className={`event-container ${
                    this.props.user.onboardingLoading ? 'loading' : ''
                  }`}>
                  {this.props.single.single.step === 0 || this.props.match.params.tab === '1' ? (
                    <EventBasic />
                  ) : null}

                  {this.props.match.params.tab === '2' ? <EventAdvanced /> : null}

                  {this.props.match.params.tab === '3' ? <Grades /> : null}

                  {this.props.match.params.tab === '4' ? <EventReview /> : null}
                </div>
              </Scrollbar>
            </div>
          ) : null
        ) : null}
      </div>
    ) : (
      'no admins'
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  single: state.single,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  post: (view, data, token, redirect) => {
    dispatch(singleActions.postFormData(view, data, token, true, redirect));
  },
  postOnboarding: (view, data, token, redirect) => {
    dispatch(singleActions.postNextStep(view, data, token, true, redirect));
  },
  patch: (view, id, data, token) => {
    dispatch(singleActions.patch(view, id, data, token));
  },
  get: (id, token) => {
    dispatch(singleActions.getSingle('events', id, token));
  },
  setBlankSingle: () => {
    dispatch(singleActions.setSingleEntry({ step: 0 }));
  },
  loadOnboarding: (token) => {
    dispatch(SingleActions.loadOnboarding(token));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Event));

class EventContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    );
  }
}

export default EventContainer;
