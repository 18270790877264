/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {Formik} from 'formik';
import * as yup from 'yup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as singleActions from "../../../store/actions/single";
import FormButtons from "../../Common/FormButtons";
import * as SingleActions from "../../../store/actions/single";



class ParseImport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fileSelectField: '',
            filePickerOpen: false,
            selectingFileType: 'image',
            preview: '',
            image: '',
            disableNext: false,
            createSchool: '',
            createTeacher: ''
        }

        this.form = React.createRef();
    }

    handleFormModal = (e) => {
        this.setState({filePickerOpen: false})
    }

    selectFile = (file) => {
        //this.props.updateField('image', file);
        this.props.setValues({image: file});
    }



    schema = yup.object({

    });

    handleSubmit = (values) => {

        let data = new FormData();
        data.append("files", this.state.image);

        let params = {eventId: values.eventId};


        data.set("data", JSON.stringify(params));

        this.setState({disableNext: true});

        this.props.post("users/parseimport", data, this.props.auth.token._id, (id) => {
            // this.props.history.push('/admin/schools/' + id);
        });

    }

    componentDidMount(){

        this.init();
    }

    componentDidUpdate(props, state) {



    }

    init = () => {

    }

    //Grades (Beginner - Prizewinner), Championship, Adult
    grades = [
        {name: 'Grades (Beginner - Prizewinner)', id: 'grades'},
        {name: 'Championship', id: 'championship'},
        {name: 'Adult', id: 'adult'},

    ];


    render() {

        let disableFields = false;

        if (this.props.location.pathname.indexOf('/dancers/') > -1 && this.props.match.params.id === 'new') {
            disableFields = false;
        } else {
            if (this.props.location.pathname.indexOf('/dancers/') > -1) {
                if (this.props.single.single) {
                    if (this.props.single.single.owners) {
                        if (this.props.single.single.owners.indexOf(this.props.user.user._id) === -1) {
                            disableFields = true;
                        }
                    }
                }
            }
        }
        return (
            <div>
                <h1>Parse Import XML</h1>
                <Formik
                    validationSchema={this.schema}
                    onSubmit={this.handleSubmit}
                    enableReinitialize
                    ref={this.form}
                    initialValues={{
                        eventId: '',
                    }}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                          touched,
                          isValid,
                          errors,
                          isSubmitting,
                          setFieldValue
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            {this.props.onboarding ?
                                <FormButtons
                                    confirm={(e) => {
                                        handleSubmit(values);
                                    }}
                                    back={(e) => {
                                        e.preventDefault();
                                        this.step(values, "previous");
                                    }}
                                    showPrevious={false}
                                    showRevert={false}
                                    showNext={true}
                                    next={(e) => {
                                        e.preventDefault();
                                        handleSubmit(values);
                                    }}
                                    showDefault={false}
                                    label={'Dancer Profile'}

                                />
                                : null}

                            <Row>
                                <Col md={6} xs={12}>
                                    <div className={"field-container"}>
                                        <Form.Group controlId="image">
                                            <Form.Label>XML File</Form.Label>
                                            <Form.Control
                                                type="file"
                                                name="image"
                                                placeholder="Upload image"
                                                onChange={(e) => {
                                                    this.setState({
                                                        preview: URL.createObjectURL(e.target.files[0]),
                                                        image: e.target.files[0]
                                                    })
                                                }}
                                                disabled={disableFields}
                                                onBlur={handleBlur}
                                                isValid={touched.image && !errors.image}
                                                className={`form-control ${touched.image ? errors.image ? 'is-invalid' : 'is-valid' : ''}`}
                                            />
                                        </Form.Group>
                                    </div>
                                </Col>
                                <Col md={6}>

                                    <div className={"field-container"}>
                                        <Form.Group controlId="lname">
                                            <Form.Label>Event ID</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="eventId"
                                                placeholder="Event ID"
                                                value={values.eventId}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                disabled={disableFields}
                                                isValid={touched.eventId && !errors.eventId}
                                                className={`form-control ${touched.eventId ? errors.eventId ? 'is-invalid' : 'is-valid' : ''}`}
                                            />
                                        </Form.Group>
                                    </div>

                                </Col>

                            </Row>

                            <div className={"divider"} />


                            {!this.props.onboarding ?
                                <div>
                                    <div className={"divider"}/>
                                    <div className={"spacer"} />

                                    <Row>
                                        <Col md={12} className={"text-right"}>
                                            <Button variant="primary" onClick={e => handleSubmit(values)} disabled={this.state.submitting}>
                                                Submit
                                            </Button>
                                        </Col>
                                    </Row>
                                    <div className={"spacer"} />
                                </div>
                                : null}

                        </Form>
                    )}
                </Formik>

                <div className={"divider"} />
                <div>
                    {this.props.table.data.length > 0 ?
                        <div>
                            {this.props.table.data.map((d, i) => {
                                return (
                                    <div className={"parsedImport"} key={i}>
                                        <div className={"spacer"} />
                                        <h3><b>{d.school}</b></h3>
                                        <div>
                                            {d.teachers.map((t, ti) => {
                                                return (
                                                    <div key={ti}>
                                                        <a href={`mailto:${t}`}>{t}</a>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <br />
                                        <div className={"divider"} />
                                        <div className={"spacer"} />
                                        {d.dancers.map((dancer, di) => {
                                            return (
                                                <div key={di}>
                                                    <b>DANCER: {dancer.dancer}</b><br />
                                                    <b>ENTRIES:</b>
                                                    <div>
                                                        {dancer.competitions.map((comp, ci) => {
                                                            return (
                                                                <div key={ci}>{comp}</div>
                                                            )
                                                        })}
                                                    </div>
                                                    <br />
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                    : null}
                </div>


            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    single: state.single,
    user: state.user,
    table: state.table
});

const mapDispatchToProps = dispatch => ({
    postOnboarding: (view, data, token, redirect) => {
        dispatch(singleActions.postNextStep(view, data, token, true, redirect));
    },
    post: (view, data, token, redirect) => {
        dispatch(singleActions.postImport(view, data, token, true, redirect, true));
    },
    patch: (view, id, data, token) => {
        dispatch(singleActions.patchFormData(view, id, data, token));
    },
    loadOnboarding: (token) => {
        dispatch(SingleActions.loadOnboarding(token));
    },
    setBlank: () => {
        dispatch(singleActions.setBlankTable());
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(ParseImport));

class ParseImportContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default ParseImportContainer;