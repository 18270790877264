import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CheckmarkDone } from 'react-ionicons';

import './StripeButton.css';

const StripeButton = ({ eventId, stripeAccountId }) => {
  const state = uuidv4();
  const redirectUri = `${window.location.origin}/app/event-dashboard`;
  const url = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&scope=read_write&state=${state}&redirect_uri=${redirectUri}`;

  const handleClick = () => {
    localStorage.setItem(`stripeEventId_${state}`, eventId);
  };

  return eventId ? (
    <div className="stripe-connect-wrapper">
      <a
        href={url}
        onClick={handleClick}
        className={`stripe-connect ${stripeAccountId ? 'disabled' : ''}`}>
        <span>Connect with</span>
      </a>
      {stripeAccountId && <CheckmarkDone className="stripe-connected" />}
    </div>
  ) : null;
};

export default StripeButton;
