import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import queryString from 'query-string';

import * as tableActions from '../../../store/actions/table';
import * as singleActions from '../../../store/actions/single';
import Bound from '../../Common/Bound';
import EventSelector from './EventSelector';
import SingleEvent from './SingleEvent';
import Charts from './Charts';
import Competitions from './Competitions';
import Judges from './Judges';
import Results from './Results';
import Alerts from './Alerts';
import AddJudges from './AddJudges';
import Activity from './Activity';
import Transactions from './Transactions';
import Dancers from './Dancers';
import Event from '../Events/Event';
import Schools from './Schools';
import Merge from './Merge';
import Split from './Split';
import PrintMaterials from './PrintMaterial/PrintMaterials';
import Entries from './Entries';
import AdminSingleEntry from '../Entries/AdminSingleEntry';

class EventDash extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: []
    };
  }

  componentDidMount(props) {
    this.redirectToEvent();
  }

  componentDidUpdate(props, state) {}

  redirectToEvent = () => {
    const queryParams = queryString.parse(this.props.location.search);
    const key = `stripeEventId_${queryParams?.state}`;
    const stripeEventId = localStorage.getItem(key);

    if (!isEmpty(stripeEventId)) {
      this.props.addStripeAccountId(
        stripeEventId,
        queryParams?.code,
        this.props.auth.token._id,
        () => {
          localStorage.removeItem(key);
          this.props.history.push(`/app/event-dashboard/${stripeEventId}/edit/2`);
        },
        () => {
          localStorage.removeItem(key);
        }
      );
    }
  };

  render() {
    return (
      <div className={'page-container elevated rounded ex-pad'}>
        <Route
          path={`/app/event-dashboard/:id/edit/:tab`}
          exact={true}
          render={(props) => {
            return (
              <Bound>
                <Event {...props} />
              </Bound>
            );
          }}
        />

        <Route
          path={`/app/event-dashboard`}
          exact={true}
          render={(props) => {
            // Event Selector
            return (
              <Bound>
                <EventSelector />
              </Bound>
            );
          }}
        />

        <Route
          path={`/app/event-dashboard/:id`}
          exact={true}
          render={(props) => {
            // Event Home / charts
            return (
              <Bound>
                <SingleEvent />
              </Bound>
            );
          }}
        />

        <Route
          path={`/app/event-dashboard/:id/merge`}
          exact={true}
          render={(props) => {
            // Event Home / charts
            return (
              <Bound>
                <Merge />
              </Bound>
            );
          }}
        />

        <Route
          path={`/app/event-dashboard/:id/split`}
          exact={true}
          render={(props) => {
            // Event Home / charts
            return (
              <Bound>
                <Split />
              </Bound>
            );
          }}
        />

        <Route
          path={`/app/event-dashboard/:id/entries`}
          exact={true}
          render={(props) => {
            // Event Home / charts
            return (
              <Bound>
                <Entries />
              </Bound>
            );
          }}
        />

        <Route
          path={`/app/event-dashboard/:eventId/entry/:id`}
          exact={true}
          render={(props) => {
            return (
              <Bound>
                <AdminSingleEntry {...props} />
              </Bound>
            );
          }}
        />

        <Route
          path={`/app/event-dashboard/:id/print`}
          exact={true}
          render={(props) => {
            // Event Home / charts
            return (
              <Bound>
                <PrintMaterials />
              </Bound>
            );
          }}
        />

        <div>
          <Route
            path={`/app/event-dashboard/:id/logs`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <Activity {...props} />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/event-dashboard/:id/transactions`}
            exact={true}
            render={(props) => {
              return (
                <Bound>
                  <Transactions {...props} />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/event-dashboard/:id/stats`}
            exact={true}
            render={(props) => {
              // Event Home / charts
              return (
                <Bound>
                  <Charts />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/event-dashboard/:id/dancers`}
            exact={true}
            render={(props) => {
              // Event Home / charts
              return (
                <Bound>
                  <Dancers />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/event-dashboard/:id/schools`}
            exact={true}
            render={(props) => {
              // Event Home / charts
              return (
                <Bound>
                  <Schools />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/event-dashboard/:id/competitions`}
            exact={true}
            render={(props) => {
              // Event Home / charts
              return (
                <Bound>
                  <Competitions />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/event-dashboard/:id/judges`}
            exact={true}
            render={(props) => {
              // Event Home / charts
              return (
                <Bound>
                  <Judges />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/event-dashboard/:id/judges/add`}
            exact={true}
            render={(props) => {
              // Event Home / charts
              return (
                <Bound>
                  <AddJudges />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/event-dashboard/:id/results`}
            exact={true}
            render={(props) => {
              // Event Home / charts
              return (
                <Bound>
                  <Results />
                </Bound>
              );
            }}
          />

          <Route
            path={`/app/event-dashboard/:id/alerts`}
            exact={true}
            render={(props) => {
              // Event Home / charts
              return (
                <Bound>
                  <Alerts />
                </Bound>
              );
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  table: state.table,
  single: state.single,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  getTableData: (view, skip, limit, sort, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
  },
  getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
  },
  addStripeAccountId: (eventId, code, token, success, error) => {
    dispatch(singleActions.addStripeAccountId(eventId, code, token, success, error));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(EventDash));

class EventDashContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    );
  }
}

export default EventDashContainer;
