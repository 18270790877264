/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */

import axios from 'axios';
import urls from './urls';

class Api {
  //host = 'http://192.168.0.185:8081/';
  //host = "https://api.feisfwd.com/";
  token = '';
  host = urls.urls.development.host;

  constructor(token = '') {
    this.token = token;
  }

  getHeaders = () => {
    return {
      Authorization: this.token
    };
  };

  setToken = (token, success) => {
    this.token = token;
    success({ token: token });
  };

  post = (uri, params, success) => {
    params.deviceType = 'web';
    axios
      .post(
        `${this.host}${uri}`,
        { params, headers: this.getHeaders() },
        { headers: this.getHeaders() }
      )
      .then((res) => {
        success(res);
      })
      .catch((e) => {});
  };

  postFormData = (uri, params, success, failure) => {
    params.deviceType = 'web';
    axios
      .post(`${this.host}${uri}`, params, {
        headers: {
          ...this.getHeaders(),
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res) => {
        success(res);
      })
      .catch((err) => {
        failure(err);
      });
  };

  postFormDataWithProgress = (uri, id, obj, params, success, progress) => {
    params.deviceType = 'web';
    axios
      .post(
        `${this.host}${uri}/upload?round=${params.round}&entry=${params.entry}&event=${params.event}&_id=${params._id}&round3Dance=${params.round3Dance}&round3Speed=${params.round3Speed}`,
        obj,
        {
          onUploadProgress: (progressData) => {
            progress(progressData);
          },
          headers: {
            ...this.getHeaders(),
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      .then((res) => {
        success(res);
      })
      .catch((err) => {
        console.log('Got Axios Err', err);
      });
  };

  patchFormDataWithProgress = (uri, id, params, success, progress) => {
    params.deviceType = 'web';
    axios
      .patch(`${this.host}${uri}`, params, {
        onUploadProgress: (progressData) => {
          progress(progressData);
        },
        headers: {
          ...this.getHeaders(),
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res) => {
        success(res);
      })
      .catch((err) => {
        console.log('Got Axios Err', err);
      });
  };

  patchFormDataWithProgress2 = (uri, id, params, success, progress) => {
    params.deviceType = 'web';
    axios
      .patch(`${this.host}${uri}`, params, {
        onUploadProgress: (progressData) => {
          progress(progressData);
        },
        headers: {
          ...this.getHeaders(),
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res) => {
        success(res);
      });
  };

  patchFormData = (uri, id, params, success) => {
    params.deviceType = 'web';
    axios
      .patch(`${this.host}${uri}`, params, {
        headers: {
          ...this.getHeaders(),
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res) => {
        success(res);
      });
  };

  addStripeAccountId = (uri, params, success) => {
    params.deviceType = 'web';
    axios
      .post(`${this.host}${uri}`, params, {
        headers: {
          ...this.getHeaders(),
          'Content-Type': 'application/json'
        }
      })
      .then((res) => {
        success(res);
      });
  };

  postFile = (uri, data, success) => {
    const params = {
      deviceType: 'web'
    };
    axios
      .post(`${this.host}${uri}`, data, {
        params,
        headers: {
          ...this.getHeaders(),
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res) => {
        success(res);
      });
  };

  patch = (uri, id, params, success) => {
    params.deviceType = 'web';
    axios
      .patch(
        `${this.host}${uri}`,
        { params: { ...params, _id: id } },
        { headers: this.getHeaders() }
      )
      .then((res) => {
        success(res);
      });
  };

  put = (uri, params, success) => {
    params.deviceType = 'web';
    axios
      .put(
        `${this.host}${uri}`,
        { params: params },
        { headers: this.getHeaders() }
      )
      .then((res) => {
        success(res);
      });
  };

  delete = (uri, params, success) => {
    params.deviceType = 'web';
    axios.delete(`${this.host}${uri}`, { params, headers: this.getHeaders() }).then((res) => {
      success(res);
    });
  };

  get = (uri, params, success) => {
    params.deviceType = 'web';
    axios.get(`${this.host}${uri}`, { params: params, headers: this.getHeaders() }).then((res) => {
      success(res);
    });
  };
}

export default Api;
