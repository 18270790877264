/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 1/13/2021
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {Formik} from 'formik';
import * as yup from 'yup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import urls from "../../../utils/urls";
import * as singleActions from "../../../store/actions/single";
import {NotificationManager} from "react-notifications";
import InputGroup from "react-bootstrap/InputGroup";
import { Globe } from 'react-ionicons';
import { LogoFacebook } from 'react-ionicons';
import { LogoInstagram } from 'react-ionicons';
import CategoryPicker from "../../Common/CategoryPicker";
import FormButtons from "../../Common/FormButtons";
import Select from "../../Common/Select";
import * as SingleActions from "../../../store/actions/single";


const schema = yup.object({
    name: yup.string().required("This field is required"),
});


class SchoolForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fileSelectField: '',
            filePickerOpen: false,
            selectingFileType: 'image',
            preview: '',
            image: '',
            createModerator: '',
            createAdmin: ''
        }

        this.form = React.createRef();
    }

    handleFormModal = (e) => {
        this.setState({filePickerOpen: false})
    }

    selectFile = (file) => {
        //this.props.updateField('image', file);
        this.props.setValues({image: file});
    }

    handleSubmit = (values) => {
        let view = "schools";

        let data = new FormData();
        data.append("files", this.state.image);

        let params = {school: JSON.parse(JSON.stringify(values))};
        params.school.admins = values.admins;
        params.school.moderators = values.moderators;

        params.school.region = values.region ? values.region._id ? values.region._id : '' : '';
        if(params.school.region === '') {
            delete params.school.region;
        }

        if (this.state.createAdmin) {
            params.createAdmin = this.state.createAdmin;
        }

        if (this.state.createModerator) {
            params.createModerator = this.state.createModerator;
        }

        if (this.props.single.single._id) {
            params.school._id = this.props.single.single._id;
        }

        if (params.school._id === '') {
            delete params.school._id;
        }

        data.set("data", JSON.stringify(params));



        if (params.school._id) {
            this.props.patch(view, params._id, data, this.props.auth.token._id);
        } else {

            if (this.props.onboarding) {
                this.props.postOnboarding("users/nextStep", data, this.props.auth.token._id, (id) => {
                    this.setState({disableNext: false});
                });
            } else {
                if (this.props.single.single._id) {
                    this.props.patch("schools", this.props.single.single._id, data, this.props.auth.token._id, (id) => {
                        this.props.history.push('/app/schools/' + id);
                    });
                } else {
                    this.props.post("schools", data, this.props.auth.token._id, (id) => {
                        this.props.history.push('/app/schools/' + id);
                    });
                }
            }
        }
    }

    componentDidMount(){
        this.props.loadOnboarding(this.props.auth.token._id);
        this.init();
    }

    componentDidUpdate(props, state) {
        if(props.single.single !== this.props.single.single) {
            this.init();
            if (this.form.current) {
                this.form.current.setValues({
                    ...this.props.single.single
                })
            }

            if (!this.props.onboarding) {
                if (props.match.params.id === 'new' && this.props.single.single._id) {
                    //this.props.history.push('/app/schools/' + this.props.single.single._id)
                    //NotificationManager.success('Your changes have been saved');
                }
            }

        } else if(this.form.current) {
            if(this.props.single.single._id !== this.form.current.values._id) {
                this.form.current.setValues({
                    ...this.props.single.single
                })
            }
        }


    }

    init = () => {
        if (this.props.single.single.image) {
            if (this.props.single.single.image.path) {
                this.setState({preview: urls.getS3Url(this.props.single.single.image.thumb || this.props.single.single.image.path)})
            }
        } else if (this.state.preview) {
            this.setState({preview: ''})

        }
    }


    render() {


        //console.log
        let shouldDisable = false;
        if (!this.props.onboarding) {
            if (this.props.single.single) {
                if (this.props.single.single.admins) {
                    if (this.props.single.single.admins.filter(item => item._id === this.props.user.user._id).length === 0) {
                        shouldDisable = true;
                    }
                }
            }
        }

        if(shouldDisable) {
            return null;
        } else {

            return (
                <div className={"ex-pad"}>
                    <Formik
                        validationSchema={schema}
                        onSubmit={this.handleSubmit}
                        enableReinitialize
                        ref={this.form}
                        initialValues={{
                            _id: this.props.single.single._id || '',
                            name: this.props.single.single.name || '',
                            about: this.props.single.single.about || '',
                            background: this.props.single.single.background || '',
                            image: this.props.single.single.image || '',
                            url: this.props.single.single.url || '',
                            fb: this.props.single.single.fb || '',
                            ig: this.props.single.single.ig || '',
                            admins: this.props.single.single.admins ? this.props.single.single.admins.length > 0 ? this.props.single.single.admins : [this.props.user.user] : [this.props.user.user],
                            moderators: this.props.single.single.moderators || [],
                            city: this.props.single.single.city || '',
                            region: this.props.single.single.region || {}
                        }}
                    >
                        {({
                              handleSubmit,
                              handleChange,
                              handleBlur,
                              values,
                              touched,
                              isValid,
                              errors,
                              isSubmitting,
                              setFieldValue
                          }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                {this.props.onboarding ?
                                    <FormButtons
                                        confirm={(e) => {
                                            handleSubmit(values);
                                        }}
                                        back={(e) => {
                                            e.preventDefault();
                                            this.step(values, "previous");
                                        }}
                                        showPrevious={false}
                                        showRevert={false}
                                        showNext={true}
                                        next={(e) => {
                                            e.preventDefault();
                                            handleSubmit(values);
                                        }}
                                        showDefault={false}
                                        label={'School Settings'}

                                    />
                                    : null}
                                <Row>
                                    <Col md={12}>

                                        <div className={""}>
                                            <Row>
                                                <Col>
                                                    <div className={"field-container"}>
                                                        <Form.Group controlId="name">
                                                            <Form.Label>Logo</Form.Label>
                                                            {this.state.preview ?
                                                                <img src={this.state.preview}
                                                                     className={"form-preview-image"}
                                                                     alt={values.name}/>
                                                                : null}
                                                            <Form.Control
                                                                type="file"
                                                                name="image"
                                                                placeholder="Upload image"
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        preview: URL.createObjectURL(e.target.files[0]),
                                                                        image: e.target.files[0]
                                                                    })
                                                                }}
                                                                onBlur={handleBlur}
                                                                disabled={shouldDisable}
                                                                isValid={touched.image && !errors.image}
                                                                className={`form-control ${touched.image ? errors.image ? 'is-invalid' : 'is-valid' : ''}`}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </Col>


                                                <Col>
                                                    <Row>
                                                        <Col md={12}>
                                                            <div className={"field-container"}>
                                                                <Form.Group controlId="name">
                                                                    <Form.Label>School Name</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="name"
                                                                        placeholder="Enter school name"
                                                                        value={values.name}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        isValid={touched.name && !errors.name}
                                                                        disabled={shouldDisable}
                                                                        className={`form-control ${touched.name ? errors.name ? 'is-invalid' : 'is-valid' : ''}`}
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                        </Col>
                                                        <Col md={12}>
                                                            <Form.Group controlId="name">
                                                                <InputGroup>
                                                                    <InputGroup.Prepend>
                                                                        <InputGroup.Text id="inputGroupPrepend"><Globe/></InputGroup.Text>
                                                                    </InputGroup.Prepend>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Website URL"
                                                                        aria-describedby="inputGroupPrepend"
                                                                        name="url"
                                                                        value={values.url}
                                                                        onChange={handleChange}
                                                                        isInvalid={!!errors.url}
                                                                        disabled={shouldDisable}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {errors.url}
                                                                    </Form.Control.Feedback>
                                                                </InputGroup>
                                                            </Form.Group>


                                                        </Col>

                                                        <Col md={12}>
                                                            <Form.Group controlId="name">
                                                                <InputGroup>
                                                                    <InputGroup.Prepend>
                                                                        <InputGroup.Text
                                                                            id="inputGroupPrepend"><LogoFacebook/></InputGroup.Text>
                                                                    </InputGroup.Prepend>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Facebook URL"
                                                                        aria-describedby="inputGroupPrepend"
                                                                        name="fb"
                                                                        value={values.fb}
                                                                        onChange={handleChange}
                                                                        isInvalid={!!errors.fb}
                                                                        disabled={shouldDisable}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {errors.fb}
                                                                    </Form.Control.Feedback>
                                                                </InputGroup>
                                                            </Form.Group>


                                                        </Col>

                                                        <Col md={12}>
                                                            <Form.Group controlId="name">
                                                                <InputGroup>
                                                                    <InputGroup.Prepend>
                                                                        <InputGroup.Text
                                                                            id="inputGroupPrepend"><LogoInstagram/></InputGroup.Text>
                                                                    </InputGroup.Prepend>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Instagram URL"
                                                                        aria-describedby="inputGroupPrepend"
                                                                        name="ig"
                                                                        value={values.ig}
                                                                        disabled={shouldDisable}
                                                                        onChange={handleChange}
                                                                        isInvalid={!!errors.ig}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {errors.ig}
                                                                    </Form.Control.Feedback>
                                                                </InputGroup>
                                                            </Form.Group>


                                                        </Col>
                                                    </Row>
                                                </Col>


                                            </Row>

                                        </div>
                                    </Col>
                                </Row>
                                <div className={"divider"}/>
                                <div className={"spacer"}/>
                                <Row>
                                    <Col md={12}>
                                        <div className={"field-container"}>
                                            <Form.Group controlId="name">
                                                <Form.Label>About</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    as={"textarea"}
                                                    rows={10}
                                                    name="about"
                                                    placeholder="Write about the school"
                                                    value={values.about}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isValid={touched.about && !errors.about}
                                                    disabled={shouldDisable}
                                                    className={`form-control ${touched.about ? errors.about ? 'is-invalid' : 'is-valid' : ''}`}
                                                />
                                            </Form.Group>
                                        </div>
                                    </Col>
                                </Row>
                                <div className={"divider"}/>
                                <div className={"spacer"}/>
                                <Row>
                                    <Col md={6}>
                                        <div className={"field-container"}>
                                            <Form.Group controlId="name">
                                                <Form.Label>City</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="city"
                                                    placeholder="Enter city"
                                                    value={values.city}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isValid={touched.city && !errors.city}
                                                    disabled={shouldDisable}
                                                    className={`form-control ${touched.city ? errors.city ? 'is-invalid' : 'is-valid' : ''}`}
                                                />
                                            </Form.Group>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className={"field-container"}>
                                            <label>Region</label>

                                            <Select

                                                disableTyping={true}
                                                items={this.props.single.regions}
                                                value={values.region || {}}
                                                itemValue={"_id"}
                                                onChange={val => {
                                                    setFieldValue('region', val);
                                                }}
                                                displayValue={"name"}

                                            />
                                            {errors.region && touched.region && (
                                                <p className={"text-danger hint-text"}>{errors.region._id}</p>)}
                                        </div>
                                    </Col>
                                </Row>

                                <div className={"divider"}/>
                                <div className={"spacer"}/>
                                {!shouldDisable ?
                                    <Row>
                                        <Col md={12}>
                                            <div className={"field-container"}>
                                                <label>School Administrators</label>
                                                {values.admins.map((c, i) => {
                                                    if (c) {
                                                        return (
                                                            <CategoryPicker
                                                                key={c._id}
                                                                items={this.props.single.users}
                                                                value={c}
                                                                itemValue={"_id"}
                                                                selectedItems={values.admins.map(item => item._id)}
                                                                onChange={val => setFieldValue('admins', values.admins.map((cats, cati) => {
                                                                    if (cati === i) {
                                                                        let relevant = this.props.single.users.filter(c => c._id === val._id);
                                                                        return relevant[0] || {};
                                                                    } else {
                                                                        return cats
                                                                    }
                                                                }))}
                                                                remote={true}
                                                                urlRoute={"/search"}
                                                                remoteKey={'users'}
                                                                displayValue={"email"}
                                                                imageKey={"image"}
                                                                showDelete={true}
                                                                onDelete={val => {
                                                                    setFieldValue('admins', values.admins.filter(c => c._id !== val._id))
                                                                }}

                                                            />
                                                        )
                                                    } else {
                                                        return null;
                                                    }
                                                })}
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <CategoryPicker
                                                items={this.props.single.users}
                                                value={''}
                                                itemValue={"_id"}
                                                selectedItems={values.admins.map(item => item._id)}
                                                onChange={val => {
                                                    this.setState({createAdmin: ''})
                                                    setFieldValue('admins', values.admins.concat(val));
                                                }}
                                                remote={true}
                                                urlRoute={"/search"}
                                                remoteKey={'users'}
                                                displayValue={"email"}
                                                displayJoinValue={"path"}
                                                displayJoin={true}
                                                imageKey={"image"}
                                                onType={e => {
                                                    this.setState({createAdmin: e.target.value})
                                                }}

                                            />
                                        </Col>

                                        <div className={"spacer"}/>
                                        <div className={"divider"}/>
                                        <div className={"spacer"}/>
                                    </Row>
                                    : null}
                                {!shouldDisable ?
                                    <Row>
                                        <Col md={12}>
                                            <div className={"field-container"}>
                                                <label>Additional teachers/staff</label>
                                                {values.moderators.map((c, i) => {
                                                    if (c) {
                                                        return (
                                                            <CategoryPicker
                                                                key={c._id}
                                                                items={this.props.single.users}
                                                                value={c}
                                                                itemValue={"_id"}
                                                                selectedItems={values.moderators ? values.moderators.map(item => item._id) : []}
                                                                onChange={val => setFieldValue('moderators', values.moderators.map((cats, cati) => {
                                                                    if (cati === i) {
                                                                        let relevant = this.props.single.users.filter(c => c._id === val._id);
                                                                        return relevant[0] || {};
                                                                    } else {
                                                                        return cats
                                                                    }
                                                                }))}
                                                                remote={true}
                                                                urlRoute={"/search"}
                                                                remoteKey={'users'}
                                                                displayValue={"email"}
                                                                imageKey={"image"}
                                                                showDelete={true}
                                                                onDelete={val => {
                                                                    setFieldValue('moderators', values.moderators.filter(c => c._id !== val._id))
                                                                }}

                                                            />
                                                        )
                                                    } else {
                                                        return null;
                                                    }
                                                })}
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <CategoryPicker
                                                items={this.props.single.users}
                                                value={''}
                                                itemValue={"_id"}
                                                selectedItems={values.moderators ? values.moderators.map(item => item ? item._id : null) : []}
                                                onChange={val => {
                                                    this.setState({createModerator: ''})
                                                    setFieldValue('moderators', values.moderators.concat(val));
                                                }}
                                                remote={true}
                                                urlRoute={"/search"}
                                                remoteKey={'users'}
                                                displayValue={"email"}
                                                displayJoinValue={"path"}
                                                displayJoin={true}
                                                imageKey={"image"}
                                                onType={e => {
                                                    this.setState({createModerator: e.target.value})
                                                }}


                                            />
                                        </Col>
                                        <div className={"spacer"}/>

                                    </Row>
                                    : null}


                                {this.props.onboarding ?
                                    <div>
                                        <div className={"divider"}/>
                                        <div className={"spacer"}/>
                                        <div className={"or text-center"}>or</div>
                                        <div className={"spacer"}/>

                                        <Col md={12} className={"text-center"}>
                                            <Button variant="primary" disabled={this.state.submitting}
                                                    onClick={e => this.props.setCreating(e, false)}>
                                                Search for your school
                                            </Button>
                                        </Col>
                                    </div>
                                    : null}
                                {!this.props.onboarding && !shouldDisable ?
                                    <div>
                                        <div className={"divider"}/>
                                        <div className={"spacer"}/>

                                        <Row>
                                            <Col md={12} className={"text-right"}>
                                                <Button variant="primary" disabled={this.state.submitting}
                                                        type="submit">
                                                    Submit
                                                </Button>
                                            </Col>
                                        </Row>
                                        <div className={"spacer"}/>
                                    </div>
                                    : null}

                            </Form>
                        )}
                    </Formik>

                </div>
            )
        }
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    single: state.single,
    user: state.user
});

const mapDispatchToProps = dispatch => ({

    postOnboarding: (view, data, token, redirect) => {
        dispatch(singleActions.postNextStep(view, data, token, true, redirect));
    },
    post: (view, data, token, redirect) => {
        dispatch(singleActions.postFormData(view, data, token, true, redirect, true));
    },
    patch: (view, id, data, token) => {
        dispatch(singleActions.patchFormData(view, id, data, token, false, null, true));
    },
    loadOnboarding: (token) => {
        dispatch(SingleActions.loadOnboardingNoSingle(token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(SchoolForm));

class SchoolFormContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default SchoolFormContainer;
