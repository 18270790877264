/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 12/4/2020
 */

const defaultState = {
  categories: [],
  categoryTemplates: [],
  subscriptionTemplates: [],
  artists: [],
  albums: [],
  tracks: [],
  single: {},
  singleTrack: {},
  categoryUpdated: 0,
  tracksUpdated: 0,
  player: {
    open: false,
    audio: null,
    playing: false,
    volume: 1
  }
};

const trackPermissions = ['view', 'access', 'purchase'];

const music = (state = defaultState, action) => {
  switch (action.type) {
    case 'LOGOUT':
      return defaultState;
    case 'SET_TRACK_VALUE':
      return {
        ...state,
        singleTrack: {
          ...state.singleTrack,
          [action.payload.key]: action.payload.val
        }
      };

    case 'GOT_TRACK_TEMPLATE':
      /*
            title: {type: String},
    description: {type: String},
    image: {type: Schema.Types.ObjectId, ref: 'Files'},
    thumbnail: {type: Schema.Types.ObjectId, ref: 'Files'},
    artist: {type: Schema.Types.ObjectId, ref: 'Artists'},
    album: {type: Schema.Types.ObjectId, ref: 'Albums'},
    categories: [{type: Schema.Types.ObjectId, ref: 'Category'}],
    orders: [{
        category: {type: Schema.Types.ObjectId, ref: 'Category'},
        number: {type: Number}
    }],
    subscriptions: [{
       subscription: {type: Schema.Types.ObjectId, ref: 'Subscription'},
       permissions: [{
            view, access, purchase
       }]
    }],
    url: {type: String},
    tags: [{type: Schema.Types.ObjectId, ref: 'Tags'}],
    duration: {type: Number},
    */

      return {
        ...state,
        categoryTemplates: action.payload.categories,
        subscriptionTemplates: action.payload.subscriptions,
        artists: action.payload.artists,
        albums: action.payload.albums,
        singleTrack: {
          _id: '',
          title: '',
          description: '',
          content: '',
          image: {},
          artist: '',
          album: {},
          categories: action.payload.categories.filter((c) => {
            return c._id === action.payload.defaultCategory;
          }),
          subscriptions: action.payload.subscriptions.map((s) => {
            return {
              subscription: s._id,
              title: s.title,
              permissions: trackPermissions.map((p) => {
                return {
                  [p]: false
                };
              })
            };
          })
        }
      };
    case 'GOT_SINGLE_WITH_MUSICDATA':
      return {
        ...state,
        artists: action.payload.data.artists || state.artists,
        albums: action.payload.data.albums || state.albums
      };
    case 'GOT_MUSIC_PAGE':
      return {
        ...state,
        categories: action.payload.categories,
        tracks: action.payload.tracks
      };
    case 'UPDATED_CATEGORY':
      return {
        ...state,
        categoryUpdated: state.categoryUpdated + 1,
        categories: state.categories.map((c) => {
          if (c === action.payload.data._id) {
            return {
              ...c,
              ...action.payload.data
            };
          } else {
            return c;
          }
        })
      };
    case 'SET_CATEGORIES':
      return {
        ...state,
        categories: action.payload
      };
    case 'DELETED_CATEGORY':
      return {
        ...state,
        categories: state.categories.filter((c) => c._id !== action.payload)
      };
    case 'SET_SINGLE_ENTRY':
      return {
        ...state,
        artists: action.payload.artists,
        albums: action.payload.albums
      };
    default:
      return state;
  }
};

export default music;
