import React from 'react';

import PDF from "./PDFComponent";
import { parseInt } from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as tableActions from '../../../store/actions/table';


const PDFPage = (props) => {

  const recall = props.match.params.recall === "true" ? true : false;   // true means recall report, false means award callback

  const events = props.events;
  if (!events) return null;
  const rounds = events.competition.competition.grade.rounds;
  const round = events.competition.template.rounds[1];    // recall, placed, and final scores/rank show on every record, just grab the first round
  var scores = round.scores[0];               // This is not judge specific so just grab the first one
  const teams = events.competition.competition.grade.type.toLowerCase().includes('team');

  let label = recall ? 'RECALLS' : 'AWARD CALLBACK';
  label = label + ' - COMPETITION ' + events.competition.competition.code;


  const competitionName = events.competition.competition.grade?.title + " | " +
    events.competition.competition.rowTitle + " | " +
    events.competition.competition.columnTitle

  const titles = [
    props.events.competition.competition.grade.event.name,
    competitionName
  ]

  let subtitles = [];

  if (!recall) {
    subtitles.push(' ');
    subtitles.push('The following competitors placed in this competition and are requested to be present');
    subtitles.push('for this evening\'s awards ceremony');
    subtitles.push(' ');
  } else {
    subtitles.push(`Total Competitors: ${scores.length}`)
  }

  const headers = teams ?
  [
    "Team",
    "Name",
    "School"
  ]
  :
  [
    "Dancer",
    "Name",
    "School"
  ];

  const filterField = recall ? 'recall' : 'placed';


  var content = scores.filter(item => item[filterField]).map((score, i) => {
    let rel = events.competition.entries.filter(item => item._id === score.entryCompetition);
    let s = {};

    if (rel.length > 0) {
      s = rel[0];
    }

    const nameString = teams ? s.dancer?.name : s.dancer?.name + " " + s.dancer?.lname;


    return [
      s.dancerNumber ? s.dancerNumber : 0,
      nameString,
      s.dancer ? s.dancer.school?.name : ""
    ]
  });

  // Add recall or placed totals to headers
  let totalHeading = recall ? 'Total Recalls' : 'Total Placed'
  subtitles.push(`${totalHeading}: ${content.length}`)

  content = content.sort((a, b) => {

    return a[0] - b[0]
  })

  // break content into chunks of records (different amounts fit depending on of this is the recall report or the award callback)
  const chunkSize = recall ? 17 : 16;
  let chunks = [];
  var i,j, temporary, chunk2 = chunkSize;
  for (i = 0,j = content.length; i < j; i += chunk2) {
      temporary = content.slice(i, i + chunk2);
      chunks.push(temporary);
  }

  let tables = [];

  for(var chunk of chunks) { 
      tables.push({
          headers,
          content: chunk
      });
  }

  const pdfData = {
    tables: tables,
    titles: titles,
    image: props.events.competition.competition.grade.event.image.path,
    labels: [label],
    subtitles: subtitles,
    labelSize: recall ? 30 : 24
  }

  return (
    <div style={{ height: "100vh" }}>

      <PDF pdfData={pdfData} />
    </div>
  );
}

const mapStateToProps = state => ({
  auth: state.auth,
  table: state.table,
  user: state.user,
  events: state.events
});


const mapDispatchToProps = dispatch => ({
  getTableData: (view, skip, limit, sort, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
  },
  getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
  },
});


const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(PDFPage));

class PDFPageContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    )
  }
}

export default PDFPageContainer;
