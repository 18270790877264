/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 2/10/2021
 */

import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import * as publicActions from '../../../store/actions/publicActions';
import Table from "react-bootstrap/Table";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";


class CompetitionSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            grade: ''
        }
    }

    componentDidMount(props) {
        this.props.get(this.props.match.params.id);
    }

    componentDidUpdate(props, state) {

    }

    render() {
        return (
            <div className={'page-container'}>
                <Row>
                    <Col><h3>{this.props.publicState.event.name}</h3></Col>
                    <Col>
                        {
                            this.props.publicState.event.grades ?
                                <select value={this.state.grade} className="form-control" onChange={e => {
                                    this.setState({grade: e.target.value});
                                }}>
                                    <option value="">All</option>
                                    {this.props.publicState.event.grades.map((grade, i) => {
                                        return <option key={i} value={grade._id}>{grade.title}</option>
                                    })}
                                </select>

                                : null}
                    </Col>
                </Row>

                <p>Thank you to all participants, teachers, adjudicators and organizers.</p>
                <p>To view who placed in each competition, please click on the links below.</p>
                <p>Each dancer appearing in these public results has placed.</p>
                <p>If you participated in the event, please <a href={"https://app.feisfwd.com/"}>LOG IN</a> and navigate to “my results” to see your detailed results, including scores and comments.</p>
                <p>Teachers can see school summaries and detailed results when logged in under their school account.</p>

                <p>Thank you for your support and congratulations to everyone who was a part of this event!</p>
                {
                    this.props.publicState.event.grades ?
                        this.props.publicState.event.grades.filter(g => this.state.grade !== "" ? g._id === this.state.grade : true ).map((grade, i) => {
                            return (
                                <div key={i}>

                                    <div className={"spacer"} />

                                    <Table key={i} striped bordered hover responsive>
                                        <thead>
                                        <tr>
                                            <th>
                                                <b>{grade.title}</b>

                                            </th>
                                            {grade.columns.map((col, o) => {
                                                console.log("Col", col)
                                                let str = col.age ? col.age : col;
                                                if (typeof str === "object") {
                                                    str = '';
                                                }
                                                return (
                                                    <th key={o}>

                                                        <b>{str}</b>


                                                    </th>
                                                )
                                            })}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {grade.rows.map((row, o) => {

                                            //console.log("rw", row);
                                            return (
                                                <tr key={o}>
                                                    <td>
                                                        <b>{row.title ? row.title : row}</b>

                                                    </td>
                                                    {grade.columns ?
                                                        grade.columns.map((col, coli) => {
                                                            if (grade.competitions) {
                                                                //console.log("R", grade.competitions.map(c => c.column));
                                                                return grade.competitions.filter(c => c.row.toString() === row.id.toString() && parseInt(c.column) === parseInt(col.id)).map((c, co) => {

                                                                    return (
                                                                        <td key={co}>

                                                                            {c.code ?

                                                                                <Link to={`/public/results/event/${this.props.match.params.id}/${c._id}`}>{c.code}</Link>

                                                                                :
                                                                                null}

                                                                        </td>
                                                                    )
                                                                })


                                                            } else {
                                                                return null;
                                                            }
                                                        })
                                                        : null}

                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </Table>
                                </div>
                            )
                        })
                        : null
                }


            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    publicState: state.publicState
});

const mapDispatchToProps = dispatch => ({
    get: (id) => {
        dispatch(publicActions.getSingle(id));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(CompetitionSelector));

class CompetitionSelectorContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default CompetitionSelectorContainer;