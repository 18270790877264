/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 2/9/2021
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import ResultTable from '../Results/ResultTable';
import ChampResultTable from "../Results/ChampResultTable";
import * as publicActions from '../../../store/actions/publicActions';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


class Competition extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    componentDidMount(props) {
        this.props.get(this.props.match.params.event, this.props.match.params.id);

    }

    render() {
        if (this.props.publicState.result) {
            if(this.props.publicState.result.event) {
                if (this.props.publicState.result.event._id === this.props.match.params.event) {
                    return (
                        <div className={'page-container'}>
                            <div className={"rounded elevated ex-pad"}>
                                <div>
                                    <Row>
                                        <Col sm={6}>
                                            <h2>{this.props.publicState.result.event.name}</h2>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col>
                                            <p>Dancers appearing on this page have placed in this competition.</p>
                                                <p>For participants and teachers, please <a href={"https://app.feisfwd.com/"}>LOG IN</a> to view the detailed results.</p>
                                                    <p><a href={"/public/results"}>{'<<'}Back to results page</a></p>
                                        </Col>
                                    </Row>

                                    <div>
                                        {
                                            this.props.publicState.result.event.grades.map((grade, g) => {
                                                return grade.competitions.map((comp, c) => {
                                                    if(comp.results) {
                                                        if (comp.results.length > 0) {
                                                            if (grade.rounds === 3) {
                                                                return <ChampResultTable isMulti={true} isPublic={true} key={c} competition={comp}
                                                                                         title={grade.title} scoring={grade.scoring} />
                                                            } else {
                                                                return <ResultTable isMulti={true} isPublic={true} key={c} competition={comp}
                                                                                    title={grade.title}/>

                                                            }
                                                        } else {
                                                            return null;
                                                        }
                                                    }else {
                                                        return null;
                                                    }

                                                })

                                            })
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    )
                } else {
                    return null;
                }
            }else {
                return null;
            }
        } else {
            return null;
        }

    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    publicState: state.publicState
});

const mapDispatchToProps = dispatch => ({
    get: (event, id) => {
        dispatch(publicActions.getSingleResult(event, id));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Competition));

class CompetitionContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default CompetitionContainer;