/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 1/22/2021
 */

const defaultState = {
    socket: null
};

const socket = (state = defaultState, action) => {
    switch (action.type) {
        case 'CONNECTED_SOCKET':
            return {
                ...state,
                socket: action.payload
            }
        case 'LOGOUT':
            return {
                ...defaultState
            }
        default:
            return {
                ...state
            };
    }
}

export default socket;