/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Autocomplete from 'react-autocomplete';
import urls from "../../utils/urls";
import placeholder from "../../assets/images/placeholder1.png";
import * as singleActions from '../../store/actions/single';
import Bound from './Bound';
import {Scrollbar} from "react-scrollbars-custom";
import Button from 'react-bootstrap/Button';
import { Trash } from 'react-ionicons';

const menuStyle = {
    boxShadow: '0 5px 8px rgba(0,0,0,.3)',
    background: 'rgba(255, 255, 255, 0.9)',
    padding: '2px 0',
    fontSize: '90%',
    position: 'fixed',
    overflow: 'auto',
    maxHeight: '50%', // TODO: don't cheat, let it flow to the bottom
    minWidth: '220px',
    marginLeft: '-36px',
    marginTop: '14px',
    borderRadius: '10px'
};

class ObjectPicker extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            menuOpen: false,
            value: '',
        }
    }

    requestTimer = null

    render() {
        
        if (this.props.items) {
            return (
                <Bound>
                    <div className={"object-picker-container-top"}>
                        {this.props.label ?
                            <label>{this.props.label}</label>
                            : null
                        }


                        <Autocomplete
                            getItemValue={(item) => item[this.props.itemValue]}
                            wrapperStyle={{display: 'block'}}
                            className={"object-picker-parent"}
                            items={this.props.selectedItems ? this.props.items.filter(item => this.props.selectedItems.indexOf(item._id) === -1) : this.props.items}
                            onMenuVisibilityChange={open => this.setState({menuOpen: open})}
                            selectOnBlur={true}
                            renderItem={(item, isHighlighted) =>

                                <div key={item._id} className={`object-picker-menu-item ${isHighlighted ? 'hovered' : ''}`}
                                     onClick={e => this.setState({menuOpen: false})}>
                                    <div className={"object-menu-item-image"}
                                         style={{background: item ? item.image ? `url(${urls.getS3Url(item.image.thumbXs || item.image.thumbSm || item.image.thumb || item.image.path)})` : `url(${placeholder})` : `url(${placeholder})`}}/>
                                    <div className={"object-menu-item-label"}>{
                                        this.props.displayJoin ?
                                            item[this.props.displayJoinValue].join(" > ")
                                            : item[this.props.displayValue]}</div>
                                </div>

                            }
                            renderInput={(props) => {
                                if (this.props.items) {
                                    let relevant = this.props.items.filter(i => i[this.props.itemValue] === this.props.value);
                                    return (
                                        <div className={"object-picker-container"}>
                                            <div className={"object-picker-image"}>
                                                <div className={"object-image"}
                                                     style={{background: relevant.length > 0 ? relevant[0].image ? `url(${urls.development.s3 + (relevant[0].image.thumbXs || relevant[0].image.thumbSm || relevant[0].image.thumb || relevant[0].image.path)})` : `url(${placeholder})` : `url(${placeholder})`}}/>
                                            </div>
                                            <div className={"object-picker-input"}>
                                                <input
                                                    onClick={e => this.setState({menuOpen: true})} {...props}
                                                    onChange={e => this.setState({value: e.target.value})}
                                                    value={this.state.value ? this.state.value : this.state.menuOpen ? this.state.value : relevant.length > 0 ? relevant[0][this.props.displayValue] : ''}/>
                                            </div>
                                            {this.props.showDelete ?
                                                <Button variant={'flat'} className={"btn-small-padding"} onClick={() => this.props.onDelete(this.props.value)}><Trash fontSize={'18px'} {...{color: 'rgba(0,0,0,.5)'}} /></Button>
                                                : null}
                                        </div>

                                    )
                                }
                            }}
                            shouldItemRender={(item, value) => {

                                if (item[this.props.displayValue]) {
                                    return item[this.props.displayValue].toLowerCase().indexOf(this.state.value.toLowerCase()) > -1
                                } else {
                                    return ''.toLowerCase().indexOf(this.state.value.toLowerCase()) > -1
                                }

                            }}
                            renderMenu={(items, value, style) => {
                                return (
                                    <div className={`object-picker-menu ${this.state.menuOpen ? 'open' : ''}`}
                                         style={{...style, ...menuStyle}}>
                                        <Scrollbar className={'re'} style={{height: '300px'}} noScrollX={true}>
                                            {items}
                                        </Scrollbar>
                                    </div>
                                )
                            }}
                            autoHighlight={true}
                            value={this.state.value}
                            onChange={(e) => {
                                //this.props.onChange(e.target.value);
                                //this.setState({value: e.target.value});
                                clearTimeout(this.requestTimer)
                                if (this.props.remote) {
                                    this.requestTimer = this.props.callRemoteObjects({
                                        val: e.target.value,
                                        collection: this.props.remoteKey
                                    }, this.props.auth.token.token._id);
                                }

                            }}
                            onSelect={(val) => {
                                this.props.onChange(val)
                                this.setState({value: ''})
                            }}
                        />


                    </div>
                </Bound>


            )
        } else {
            return null
        }
    }
}

const mapStateToProps = state => ({
    user: state.user,
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    callRemoteObjects: (data, token) => {
        dispatch(singleActions.callRemoteObjects(data, token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(ObjectPicker));

class ObjectPickerContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}
export default ObjectPickerContainer;
