import React from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { NewspaperOutline, Add } from 'react-ionicons';
import Button from 'react-bootstrap/Button';

import * as tableActions from '../../../store/actions/table';
import EventTile from '../../Common/EventTile';
import placeholder2 from '../../../assets/images/placeholder1.png';
import * as singleActions from '../../../store/actions/single';
import EventModal from '../../Common/EventModal';
import * as ENUMS from '../../../utils/enums';

class Events extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      showModal: false,
      ev: {}
    };
  }

  // TODO: MT 11/3/2024 - Lots of cleanup to do here. This was the beginning of an attempt to reuse data between the events and teams screen.
  // This either needs to be completed or deleted. See PR from 11/2 for all code added related to this.
  shouldComponentUpdate(nextProps, nextState) {

    let reload = true;

    // Don't need to reload if we're showing the same screen and haven't unloaded the data
    if (this.props?.redirectSource && this.props.redirectSource === nextProps.redirectSource) {
      reload = false;
    }

    return reload;
  }

  componentDidMount(props) {

    // TODO apparently this method of fetching url params goes away in react-router-dom v6 so we will need to update how we do this
    let destination = this.props.match?.params?.destination;
    if (destination && destination.toLowerCase() === ENUMS.APP_CATEGORIES.TEAMS) {
      // Load events for teams view
      this.props.getEventsAndTeams(0, 999, this.props.auth.token._id);
    } else {
      // Load all events
      this.props.getTableData('events', 0, 999, { number: 1 }, this.props.auth.token._id, 'add');
    }
  }

  componentDidUpdate(props, state) {
    if (
      (this.props.table.data.length !== this.state.items.length ||
        this.props.table.updateCount > props.table.updateCount ||
        this.props.table.data.length !== this.state.items.length) &&
      this.props.table.view === 'events'
    ) {
      this.setState({ items: this.props.table.data });
    }
  }

  showModal = (event) => {
    this.setState({ showModal: true, ev: event });
  };

  hideModal = () => {
    this.setState({ showModal: false, ev: {} });
  };

  render() {
    let destination = this.props.match?.params?.destination;
    let teams = destination && destination.toLowerCase() === ENUMS.APP_CATEGORIES.TEAMS;
    let header = teams ? 'Select an Event to View Teams' : 'Events';
    return (
      <div className={'page-container'}>
        <EventModal event={this.state.ev} show={this.state.showModal} hideModal={this.hideModal} />
        <div className={'page-header'}>
          <Row>
            <Col>
              <h1>
                <NewspaperOutline /> {header}
              </h1>
            </Col>
            <Col>
              {!teams && this.props.user.user ? (
                this.props.user.user.roles ? (
                  this.props.user.user.roles.indexOf('organizer') > -1 ||
                  this.props.user.user.role === 'admin' ? (
                    <Col>
                      <div className={'text-right'}>
                        <Button
                          variant={'primary'}
                          onClick={(e) => {
                            this.props.setBlankSingle();
                            this.props.history.push('/app/event-dashboard/new/edit/1');
                          }}>
                          <Add fontSize={'18px'} color={'#fff'} /> &nbsp; Create Event
                        </Button>
                      </div>
                    </Col>
                  ) : null
                ) : null
              ) : null}
            </Col>
          </Row>
        </div>

        <div className={'section'}>
          <div className={'tile-grid'}>
            <Row>
              {this.props.table.data.length > 0 ? (
                this.props.table.data.map((d, i) => {
                  return (
                    <EventTile
                      showModal={(event) => this.showModal(event)}
                      {...d}
                      data={d}
                      key={i}
                      placeholder={placeholder2}
                      teams={teams}
                    />
                  );
                })
              ) : (
                <h4 className={'no-data'}>No Events Found</h4>
              )}
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  table: state.table,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  getTableData: (view, skip, limit, sort, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
  },
  getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
  },
  getEventsAndTeams: (skip, limit, token) => {
    dispatch(tableActions.getEventsAndTeams(skip, limit, token));
  },
  setBlankSingle: () => {
    dispatch(singleActions.setSingleEntry({ step: 0 }));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Events));

class EventsContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    );
  }
}

export default EventsContainer;
