/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */

import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import UserForm from '../../Users/UserForm';
import * as singleActions from "../../../../store/actions/single";
import Bound from "../../../Common/Bound";
import Nav from "react-bootstrap/Nav";
import Dancers from './Profile/Dancers';
import AdminEntries from "../AdminEntries";

class SingleUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    componentDidMount(props) {


        if (this.props.single.single) {
            if ((this.props.match.params.id && this.props.single.single._id !== this.props.match.params.id) && this.props.match.params.id !== "new") {
                this.props.getSingle("users", this.props.match.params.id, this.props.auth.token._id);
            } else if (this.props.match.params.id === "new" && (this.props.single.single._id !== "" || !this.props.single.single._id)) {
                let data = {
                    token: this.props.auth.token._id
                }

                if (this.props.match.params.category) {
                    data.category = this.props.match.params.category;
                }
                //this.props.setBlankSingleTrack(data);
            }
        } else {
            this.props.getSingle("users", this.props.match.params.id, this.props.auth.token._id);
        }
    }

    componentDidUpdate(props, state) {

    }

    menu = [
        {
            title: 'Account',
            path: `/app/admin/users/${this.props.match.params.id}/account`,
            component: <UserForm remoteProfile={true} />,
            dataSet: () => {
                this.props.getSingle("users", this.props.match.params.id, this.props.auth.token._id);
            }
        },
        {
            title: 'Entries',
            path: `/app/admin/users/${this.props.match.params.id}/entries`,
            component: <AdminEntries />
        },
        {
            title: 'Dancers',
            path: `/app/admin/users/${this.props.match.params.id}/dancers`,
            component: <Dancers />
        }
    ]

    renderContent = () => {
        let menuItem = this.menu.filter(item => item.path === this.props.location.pathname);
        if (menuItem.length > 0) {
            return (
                <Bound>
                    {menuItem[0].component}
                </Bound>

            );
        } else {
            return null;
        }
    }

    renderItem = (item, i) => {
        return (
            <Nav.Link key={i} to={item.path} as={Link} className={`tab tab-${i} ${this.props.location.pathname === item.path ? 'active' : ''}`}>{item.title}</Nav.Link>
        )
    }

    render() {

        return (
            <div className={'page-container elevated rounded'}>
                <div className={"tab-container"}>
                    <div className={"tab-row"}>
                        {this.menu.map((item, i) => {
                            return this.renderItem(item, i)
                        })}
                        <div className={"tab-highlight"} />
                        <div className={"tab-highlight-track"} />
                    </div>

                </div>
                <div className={"tab-content ex-pad"}>

                    {this.renderContent()}

                </div>


            </div>
        )

    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    single: state.single
});

const mapDispatchToProps = dispatch => ({
    getSingle: (view, id, token) => {
        //console.log("Getting single", view)
        dispatch(singleActions.getSingle(view, id, token));
    },
    updateField: (field, val) => {
        dispatch(singleActions.updateField(field, val));
    },
    post: (view, data, token, redirect) => {
        dispatch(singleActions.post(view, data, token, true, redirect));
    },
    patch: (view, id, data, token) => {
        dispatch(singleActions.patch(view, id, data, token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(SingleUser));

class SingleUserContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default SingleUserContainer;
