/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 6/11/2020
 */

import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Sortly, { useDrag, useDrop } from 'react-sortly';
import { CameraOutline, CreateOutline } from 'react-ionicons';
import * as tableActions from '../../../../store/actions/table';
import * as singleActions from '../../../../store/actions/single';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import urls from '../../../../utils/urls';
import placeholder from '../../../../assets/images/placeholder1.png';
import { Scrollbar } from 'react-scrollbars-custom';
import Bound from '../../../Common/Bound';
import { ArrowBack } from 'react-ionicons';
import Form from 'react-bootstrap/Form';
import { ArrowForward } from 'react-ionicons';
import Select from '../../../Common/Select';
import ReactTooltip from 'react-tooltip';
import { PersonCircleOutline } from 'react-ionicons';

const ItemRenderer = (props) => {
  const [, drag] = useDrag();
  const [, drop] = useDrop();
  const {
    data: { _id, name, lname, email, image },
    history,
    view
  } = props;
  const ref = React.useRef(null);
  drag(drop(ref));

  let smallestImage;

  if (image) {
    smallestImage = image.path;
    if (image.thumbXs) {
      smallestImage = image.thumbXs;
    } else if (image.thumbSm) {
      smallestImage = image.thumbSm;
    } else if (image.thumb) {
      smallestImage = image.thumb;
    }
  }

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <Bound>
      <ReactTooltip id={_id} effect={'solid'} className={'f21-tooltip'} place={'bottom'} />
      <div ref={drop} className={'plan'}>
        <div ref={drag}>
          <Row>
            <Col md={9} className={''}>
              <span className={'row-name article-row'}>
                <div
                  onClick={(e) => history.push('/app/admin/' + view + '/' + _id)}
                  className={'news-item-img circle-img'}
                  style={{
                    background: image
                      ? `url(${urls.getS3Url(
                          smallestImage
                        )}), linear-gradient(0deg, rgba(0,0,0,.6), rgba(0,0,0,.4), rgba(0,0,0,.2), transparent)`
                      : `url(${placeholder}), linear-gradient(0deg, rgba(0,0,0,.6), rgba(0,0,0,.4), rgba(0,0,0,.2), transparent)`
                  }}></div>
                <div
                  className={'row-title'}
                  onClick={(e) => history.push('/app/admin/' + view + '/' + _id)}>
                  {name ? name : null}
                  {name && lname ? <span>&nbsp;</span> : null}
                  {lname ? lname : null}
                  {!name && !lname ? email : null}
                </div>
                <div className={'row-content overflow-hidden'}>{name || lname ? email : null}</div>
              </span>
            </Col>

            <Col md={3} className={'text-right action-buttons-right'}>
              <Button
                data-tip={'Edit'}
                variant={'primary'}
                onClick={(e) => history.push('/app/admin/' + view + '/' + _id)}
                className={'btn-flat btn-transparent btn-slim'}>
                <CreateOutline fontSize={'22px'} />
              </Button>{' '}
              &nbsp;
              <Button
                variant={'primary'}
                onClick={(e) => history.push('/app/admin/' + view + '/' + _id + '/entries')}
                className={'btn-flat btn-transparent btn-slim'}
                data-tip={'View Entries'}>
                <CameraOutline fontSize={'22px'} />
              </Button>{' '}
              &nbsp;
              <Button
                variant={'primary'}
                onClick={(e) => history.push('/app/admin/' + view + '/' + _id + '/dancers')}
                className={'btn-flat btn-transparent btn-slim'}
                data-tip={'View Dancers'}>
                <PersonCircleOutline fontSize={'22px'} />
              </Button>{' '}
              &nbsp;
              {/*<Button variant={'primary'} onClick={e => remove(_id)} className={'btn-flat btn-transparent btn-slim'}><Trash fontSize={'22px'}/></Button>*/}
            </Col>
          </Row>
        </div>
      </div>
    </Bound>
  );
};

const SortableItems = ({
  data,
  save,
  remove,
  edit,
  parentUpdateCount,
  view,
  history,
  markTop,
  maxDepth
}) => {
  const [items, setItems] = React.useState([...data]);
  const [started, setStarted] = React.useState(false);
  const [updateCount, setUpdateCount] = React.useState(0);
  const handleChange = (newItems) => {
    setItems(
      newItems.map((d) => {
        return {
          ...d,
          depth: d.depth > maxDepth ? maxDepth : d.depth
        };
      })
    );
  };

  if (parentUpdateCount > updateCount) {
    setItems(data);
    setUpdateCount(parentUpdateCount);
  }

  if ((!started && data.length > 0) || data.length !== items.length) {
    setItems(
      data.map((d) => {
        return d;
      })
    );
    setStarted(true);
  }

  return (
    <div className={'page-container'}>
      <Sortly items={items} onChange={handleChange}>
        {(props) => (
          <ItemRenderer
            markTop={markTop}
            key={props.index}
            {...props}
            view={view}
            history={history}
            remove={(id) => remove(id)}
            edit={(id) => edit(id)}
          />
        )}
      </Sortly>
    </div>
  );
};

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      title: '',
      sort: null,
      filter: null,
      showModal: false,
      data: {},
      search: ''
    };
  }

  componentDidMount(props) {
    let page = 1;
    if (this.props.match.params.page) {
      page = this.props.match.params.page;
    }
    let itemsPerPage = 10;
    let skip = itemsPerPage * (page - 1);

    //if (this.props.table.view !== view) {
    this.props.getTableData(
      'users',
      skip,
      itemsPerPage,
      { number: 1 },
      this.props.auth.token._id,
      'add'
    );
    //}
    //}
  }

  /*

    componentDidUpdate(props, state) {
        let splitView = this.props.location.pathname.split('/');
        let view = splitView[2];
        let itemsPerPage = 10;
        let skip = this.props.table.data.length;
        if (this.props.location.pathname !== props.location.pathname) {
            this.props.getTableData(view, skip, itemsPerPage, {number: 1}, this.props.auth.token._id, 'add');
        }
        if ((this.props.table.data.length !== this.state.items.length || this.props.table.updateCount > props.table.updateCount || this.props.table.data.length !== this.state.items.length) && this.props.table.view === view) {
            this.setState({items: this.props.table.data, title: view});
        }
    }

     */

  componentDidUpdate(props, state) {
    if (state.sort !== this.state.sort || state.filter !== this.state.filter) {
      let query = { event: this.props.match.params.id };
      let sort = { code: 1 };
      if (this.state.sort) {
        sort = this.state.sort.sort;
      }

      if (this.state.filter) {
        query.filter = this.state.filter.id;
      }

      if (this.state.search) {
        query.search = this.state.search;
      }

      this.props.getTableDataWithQuery(
        'users',
        0,
        10,
        sort,
        query,
        this.props.auth.token._id,
        'add'
      );
    }
  }

  remove = (id) => {
    this.props.deleteItem(this.props.table.view, id, this.props.auth.token._id);
  };

  edit = (id) => {
    if (this.props.table.data.filter((d) => d._id === id).length > 0) {
      this.props.handleFormModalWithData(this.props.table.data.filter((d) => d._id === id)[0]);
    }
  };

  save = (e, items) => {
    let page = this.props.match.params.page ? this.props.match.params.page : 1;
    this.props.reorder(this.props.table.view, page, items, this.props.auth.token._id);
  };

  markTop = (e, item) => {
    let filteredItems = this.props.table.data.filter((d) => d._id === item);
    if (filteredItems.length > 0) {
      let item = filteredItems[0];
      item.topStory = !item.topStory;
      this.props.updateTableItem('news', item, this.props.auth.token._id);
    }
  };

  loadMore = () => {
    let query = { event: this.props.match.params.id };
    let sort = { createdAt: -1 };
    if (this.state.sort) {
      sort = this.state.sort.sort;
    }

    if (this.state.filter) {
      query.filter = this.state.filter.id;
    }

    if (this.state.search) {
      query.search = this.state.search;
    }

    this.props.getTableDataWithQuery(
      'users',
      this.props.table.data.length,
      10,
      sort,
      query,
      this.props.auth.token._id,
      'update'
    );
  };

  handleAboutToReachBottom = () => {
    if (this.props.table.hasMore) {
      this.loadMore();
    }
  };
  handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const pad = 100; // 100px of the bottom
    // t will be greater than 1 if we are about to reach the bottom
    const t = (scrollTop + pad) / (scrollHeight - clientHeight);
    //console.log(t, this.props.table.hasMore);
    if (t > 1) this.handleAboutToReachBottom();
  };

  search = () => {
    let query = { event: this.props.match.params.id };
    let sort = { createdAt: -1 };
    if (this.state.sort) {
      sort = this.state.sort.sort;
    }

    if (this.state.filter) {
      query.filter = this.state.filter.id;
    }

    if (this.state.search) {
      query.search = this.state.search;
    }

    this.props.getTableDataWithQuery('users', 0, 10, sort, query, this.props.auth.token._id, 'add');
  };

  filters = [
    {
      id: 'all',
      name: 'All'
    },
    {
      id: 'parent',
      name: 'Parents'
    },
    {
      id: 'dancer',
      name: 'Dancers'
    },
    {
      id: 'teacher',
      name: 'Teachers'
    },
    {
      id: 'judge',
      name: 'Judges'
    },
    {
      id: 'organizer',
      name: 'Organizers'
    },
    {
      id: 'admin',
      name: 'Admins'
    }
  ];

  sort = [
    {
      id: 'compdesc',
      name: 'Newest First',
      sort: { createdAt: -1 }
    },
    {
      id: 'compasc',
      name: 'Oldest First',
      sort: { createdAt: 1 }
    }
  ];

  render() {
    return (
      <div className={'admin-users-page circle-plan-button-page'}>
        <div className={'page-header'}>
          <Row>
            <Col sm={2} className={'pt-3'}>
              <h1>
                {this.props.location.pathname.split('/').length > 3 ? (
                  <Button
                    variant={'flat btn-round'}
                    onClick={(e) => {
                      this.props.history.push(`/app/`);
                    }}>
                    <ArrowBack />
                  </Button>
                ) : null}
              </h1>
            </Col>
            <Col sm={4}>
              <div className={'field-container'}>
                <label>Search</label>
                <Form.Control
                  value={this.state.search}
                  onChange={(e) => this.setState({ search: e.target.value })}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      this.search();
                    }
                  }}
                />
                <Button variant={'primary'} className={'btn-search'} onClick={this.search}>
                  <ArrowForward />
                </Button>
              </div>
            </Col>
            <Col sm={3}>
              <Select
                disableTyping={true}
                items={this.filters}
                value={this.state.filter || this.filters[0]}
                itemValue={'id'}
                onChange={(val) => {
                  this.setState({ filter: val });
                }}
                displayValue={'name'}
                label={'Filter'}
              />
            </Col>
            <Col sm={3}>
              <Select
                disableTyping={true}
                items={this.sort}
                value={this.state.sort || this.sort[0]}
                itemValue={'id'}
                onChange={(val) => {
                  this.setState({ sort: val });
                }}
                displayValue={'name'}
                label={'Sort'}
              />
            </Col>
          </Row>
        </div>

        <Scrollbar
          className={'feis-page-scroller'}
          noScrollX={true}
          onUpdate={this.handleUpdate}
          style={{ height: this.props.embedded ? 'calc(100vh - 98px)' : 'calc(100vh - 220px)' }}>
          <SortableItems
            maxDepth={0}
            view={this.props.table.view}
            markTop={this.markTop}
            history={this.props.history}
            parentUpdateCount={this.props.table.updateCount}
            save={(e, items) => this.save(e, items)}
            remove={(id) => this.remove(id)}
            edit={(id) => this.edit(id)}
            data={this.props.table.data}
          />

          {this.props.table.hasMore ? (
            <div className={'text-center'}>
              <Button onClick={this.loadMore}>Load More</Button>
            </div>
          ) : null}
        </Scrollbar>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  table: state.table
});

const mapDispatchToProps = (dispatch) => ({
  getTableData: (view, skip, limit, sort, token, mode) => {
    dispatch(tableActions.getTableData(view, skip, limit, sort, token, mode));
  },
  getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
  },
  reorder: (view, page, data, token) => {
    dispatch(tableActions.reorderWithPage(view, page, data, token));
  },
  deleteItem: (view, id, token) => {
    dispatch(tableActions.deleteItem(view, id, token));
  },
  setSingleData: (item) => {
    dispatch(singleActions.setSingleEntry(item));
  },
  updateTableItem: (view, item, token) => {
    dispatch(tableActions.updateItemField(view, item, token));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Users));

class UsersContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    );
  }
}

export default UsersContainer;
