/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Col from 'react-bootstrap/Col';
import { Add } from 'react-ionicons';
import Button from 'react-bootstrap/Button';
import { Build } from 'react-ionicons';
import * as singleActions from '../../store/actions/single';
import Bound from './Bound';
import urls from '../../utils/urls';
import * as dayjs from 'dayjs';

class EntryTile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      preview: ''
    };
  }
  componentDidMount() {
    if (this.props.data.event && !this.state.preview) {
      if (this.props.data.event.image) {
        if (this.props.data.event.image._id) {
          this.setState({
            preview: urls.getS3Url(
              this.props.data.event.image.thumb || this.props.data.event.image.path
            )
          });
        }
      }
    }
  }

  componentDidUpdate(props) {
    if (this.props.data.event && !this.state.preview) {
      if (this.props.data.event.image) {
        if (this.props.data.event.image._id) {
          this.setState({
            preview: urls.getS3Url(
              this.props.data.event.image.thumb || this.props.data.event.image.path
            )
          });
        }
      }
    }
  }

  getCanUpload = () => {
    if (this.props.data.event) {
      let videoEnd = dayjs.utc(this.props.data.event.videoEnd).add(31, 'hours');
      let now = dayjs();

      let diff = now.diff(videoEnd, 'minutes');

      return diff < 0;
    }
  };

  status = ['Configure competitions', 'Ready for payment', 'Paid'];

  render() {
    return (
      <Col md={4} lg={6} xl={4}>
        <Bound>
          {this.props.more ? (
            <div
              className={'tile-container'}
              onClick={(e) => {
                this.props.admin
                  ? this.props.history.push(`/app/admin/users/${this.props.match.params.id}/entry`)
                  : this.props.history.push(`/app/events`);
              }}
              style={{ background: `rgba(0,0,0,.4)` }}>
              <div className={'tile-gradient'}></div>
              <div className={' text-center tile-symbol'}>
                <Add fontSize={'64px'} />
                <h3 className={'more'}>{this.props.more} More</h3>
              </div>
            </div>
          ) : (
            <div
              className={'tile-container'}
              onClick={(e) => {
                console.log('Clicking', this.props.admin);
                this.props.admin
                  ? this.props.history.push(
                      `/app/admin/users/${this.props.match.params.id}/entry/${this.props.data._id}`
                    )
                  : this.props.history.push(`/app/entries/${this.props.data._id}`);
              }}
              style={{
                background: `url(${this.state.preview || this.props.placeholder}), rgba(0,0,0,.2)`
              }}>
              {this.props.data ? (
                this.props.data.admins ? (
                  this.props.data.admins.indexOf(this.props.user.user._id) > -1 ? (
                    <Button
                      variant={'flat'}
                      className={'floating-edit-button'}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        this.props.history.push(`/app/events/${this.props.data._id}`);
                      }}>
                      <Build />
                    </Button>
                  ) : null
                ) : null
              ) : null}
              <div className={'tile-gradient'}></div>

              <div className={'tile-content'}>
                <h3>Event: {this.props.name}</h3>
                <h5>{this.props.date}</h5>
                {this.props.data.sections ? (
                  this.props.data.sections.filter((dan) => dan.entered || dan.dancerNumber).length >
                  0 ? (
                    <h4>
                      Dancers:{' '}
                      {this.props.data.sections
                        ? this.props.data.sections
                            .filter((d) => d.dancerNumber || d.entered)
                            .map((d) => {
                              let rel = this.props.data.dancers
                                ? this.props.data.dancers.filter((dan) => dan._id === d.dancer)[0]
                                : null;

                              if (rel) {
                                return rel.name + ' ' + rel.lname;
                              } else {
                                rel = this.props.table.dancers
                                  ? this.props.table.dancers.filter(
                                      (dan) => dan._id === d.dancer
                                    )[0]
                                  : null;
                                if (rel) {
                                  return rel.name + ' ' + rel.lname;
                                } else {
                                  return null;
                                }
                              }
                            })
                            .join(', ')
                        : this.props.table.dancers
                        ? this.props.table.dancers.map((d) => d.name + ' ' + d.lname).join(', ')
                        : null}
                    </h4>
                  ) : null
                ) : null}
                {this.props.data.event ? (
                  this.props.data.step >= 2 ? (
                    <h4>
                      Status:{' '}
                      <span className={'text-success'}>
                        {this.status[this.props.data.step]}{' '}
                        {this.props.data.complete
                          ? ' - Videos uploaded'
                          : this.props.data.event.inPerson
                          ? ''
                          : this.getCanUpload()
                          ? ' - Accepting Video Uploads'
                          : ' - Not Accepting New Videos'}
                      </span>
                    </h4>
                  ) : (
                    <h4>
                      Status:{' '}
                      <span className={'text-success'}>
                        {this.status[this.props.data.step]}{' '}
                        {this.props.data.event.inPerson
                          ? ''
                          : this.getCanUpload()
                          ? ' - Accepting Video Uploads'
                          : ' - Not Accepting New Videos'}
                      </span>
                    </h4>
                  )
                ) : null}
              </div>
            </div>
          )}
        </Bound>
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user,
  single: state.single,
  table: state.table
});

const mapDispatchToProps = (dispatch) => ({
  setSingle: (d) => {
    dispatch(singleActions.setSingleEntry(d));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(EntryTile));

class EntryTileContainer extends React.Component {
  render() {
    return <Connected {...this.props} />;
  }
}

export default EntryTileContainer;
