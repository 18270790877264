import Api from "../../utils/Api";
import {NotificationManager} from 'react-notifications';

export function logError(url, error, errorInfo, token) {
    return dispatch => {
        const api = new Api(token);
        api.post("errors", {url, error, errorInfo}, res => {

        });
    }
}

export function logVideoError(url, error, errorInfo, token) {
    return dispatch => {
        const api = new Api(token);
        api.post("errors", {url, error, errorInfo}, res => {
            NotificationManager.success("Your report has been saved");
        });
    }
}