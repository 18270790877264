/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 1/13/2021
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {Formik} from 'formik';
import * as yup from 'yup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as singleActions from "../../../store/actions/single";
import FormButtons from "../../Common/FormButtons";


const schema = yup.object({
    name: yup.string().required("This field is required"),
});


class SchoolJoiner extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fileSelectField: '',
            filePickerOpen: false,
            selectingFileType: 'image',
            preview: '',
            image: ''
        }
    }

    handleFormModal = (e) => {
        this.setState({filePickerOpen: false})
    }

    selectFile = (file) => {
        //this.props.updateField('image', file);
        this.props.setValues({image: file});
    }

    handleSubmit = (values) => {
        let view = "schools";

        let data = new FormData();


        let params = {schoolJoin: values.school};
        data.set("data", JSON.stringify(params));


        if (params._id) {
            this.props.patch(view, params._id, data, this.props.auth.token._id);
        } else {
            if (this.props.onboarding) {
                this.props.postOnboarding("users/nextStep", data, this.props.auth.token._id, (id) => {
                    this.setState({disableNext: false});
                });
            } else {
                this.props.post(view, data, this.props.auth.token._id, (id) => {
                    this.props.history.push('/admin/schools/' + id);
                });
            }
        }
    }


    render() {
        return (
            <div>
                <Formik
                    validationSchema={schema}
                    onSubmit={this.handleSubmit}
                    enableReinitialize
                    initialValues={{
                        school: this.props.single.single.school || {}
                    }}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                          touched,
                          isValid,
                          errors,
                          isSubmitting,
                          setFieldValue
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            {this.props.onboarding ?
                                <FormButtons
                                    confirm={(e) => {
                                        this.handleSubmit();
                                    }}
                                    back={(e) => {
                                        e.preventDefault();
                                        this.step(values, "previous");
                                    }}
                                    showRevert={false}
                                    showNext={true}
                                    next={(e) => {
                                        e.preventDefault();
                                        this.handleSubmit(values);
                                    }}
                                    showDefault={false}
                                    label={'School Settings'}
                                    canSkip={false}

                                />
                                : null}

                            <div className={"onboarding-complete"}>
                                <p>If your school already exists on our app, you'll need to request that an administrator of the school adds you as staff.</p>
                                <p>If you're the administrator of a school, and it hasn't already been added to the system, you will be able to create your school by navigating to "My School" in the sidebar.</p>
                                <p>If you need assistance, please email us at <a href={"mailto:dance@feisfwd.com"}>dance@feisfwd.com</a></p>

                            </div>


                            {/*
                            <Col md={12}>
                                <div className={"field-container"}>
                                    <label>School</label>

                                    <CategoryPicker
                                        items={this.props.single.schools}
                                        value={values.school}
                                        itemValue={"_id"}
                                        onChange={val => setFieldValue('school', val)}
                                        remote={true}
                                        remoteKey={'schools'}
                                        urlRoute={"/search"}
                                        displayValue={"name"}
                                        imageKey={"image"}
                                        showDelete={true}
                                        onDelete={val => {
                                            setFieldValue('school', {})
                                        }}

                                    />

                                </div>
                            </Col>

                            <div className={"spacer"} />
                            <div className={"divider"} />
                            <div className={"spacer"} />

                            <div className={"or text-center"}>or</div>
                            <div className={"spacer"} />

                            <Col md={12} className={"text-center"}>
                                <Button variant="primary" disabled={this.state.submitting} onClick={e => this.props.setCreating(e, true)}>
                                    Create a school
                                </Button>
                            </Col>

*/}

                            {!this.props.onboarding ?
                                <div>
                                    <div className={"divider"}/>
                                    <div className={"spacer"} />

                                    <Row>
                                        <Col md={12} className={"text-right"}>
                                            <Button variant="primary" disabled={this.state.submitting} type="submit">
                                                Submit
                                            </Button>
                                        </Col>
                                    </Row>
                                    <div className={"spacer"} />
                                </div>
                                : null}

                        </Form>
                    )}
                </Formik>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    single: state.single,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    post: (view, data, token, redirect) => {
        dispatch(singleActions.postFormData(view, data, token, true, redirect));
    },
    postOnboarding: (view, data, token, redirect) => {
        dispatch(singleActions.postNextStep(view, data, token, true, redirect));
    },
    patch: (view, id, data, token) => {
        dispatch(singleActions.patch(view, id, data, token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(SchoolJoiner));

class SchoolJoinerContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default SchoolJoinerContainer;
