/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Modal from "react-bootstrap/Modal";
import * as singleActions from '../../store/actions/single';
import { Power } from 'react-ionicons';
import Button from 'react-bootstrap/Button';

class LogoutModal extends React.Component {

    postSingle = (item) => {

    }

    renderContent = () => {

    }
    render() {
        return (
            <div className={this.props.user.user.theme}>
                <Modal centered size={'md'} className={`theme-${this.props.user.user.theme} ${this.props.user.user ? this.props.user.user.darkTheme ? 'dark-theme' : '' : ''}`} show={this.props.show} onHide={e => this.props.hideModal(false)}>
                    <Modal.Body className={"text-center"}>
                        <h4>Are you sure you want to log out?</h4>

                        <Button variant={"primary"} onClick={e => {
                            if (this.props.location.pathname.indexOf('onboarding') > -1) {
                                //console.log("Onboard logout")
                                this.props.history.push('/onboarding/logout')
                            } else {
                                this.props.history.push('/app/logout')
                            }
                        }}><Power /> Logout</Button>

                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user,
    single: state.single
});

const mapDispatchToProps = dispatch => ({
    post: (uri, item, token) => {
        dispatch(singleActions.post(uri, item, token));
    },
    postFile: (uri, fd, token) => {
        dispatch(singleActions.postFile(uri, fd, token));
    },
    patch: (uri, id, item, token) => {
        dispatch(singleActions.patch(uri, id, item, token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(LogoutModal));

class LogoutModalContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default LogoutModalContainer;
