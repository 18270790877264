import Api from "../../utils/Api";
import {NotificationManager} from "react-notifications";

export function getSingleResult(event, id) {
    return dispatch => {
        const api = new Api();
        api.post(`events/getPublicResult`, {eventId: event, id} , (res) => {
            if(res.status === 200) {
                try {
                    dispatch({
                        type: 'GOT_PUBLIC_RESULT',
                        payload: {
                            ...res.data
                        }
                    })
                }catch (err) {
                    NotificationManager.error("Please reload this view and try again", "An Error Occured")
                }
            }
        })
    }
}

export function getSingle(id) {
    return dispatch => {
        const api = new Api();
        api.get(`events/public`, {id} , (res) => {
            if(res.status === 200) {
                try {
                    dispatch({
                        type: 'GOT_PUBLIC_EVENT',
                        payload: {
                            ...res.data
                        }
                    })
                }catch (err) {
                    NotificationManager.error("Please reload this view and try again", "An Error Occured")
                }
            }
        })
    }
}

export function getTableDataWithQuery(view, skip, limit, sort, query) {
    return dispatch => {
        const api = new Api();
        api.get(`${view}/public`, {skip, limit, sort, query} , (res) => {
            if(res.status === 200) {
                try {
                    dispatch({
                        type: 'GOT_PUBLIC_DATA',
                        payload: {
                            ...res.data,
                            view: view
                        }
                    })
                }catch (err) {
                    NotificationManager.error("Please reload this view and try again", "An Error Occured")
                }
            }
        })
    }
}