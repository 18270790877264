import Api from '../../utils/Api';
import { NotificationManager } from 'react-notifications';
import urls from '../../utils/urls';
import noteSound from '../../assets/audio/note.mp3';

export function postMerge(view, data, token) {
  return (dispatch) => {
    const api = new Api(token);

    api.post(view, data, (res) => {
      if (res.data.status === 200) {
        dispatch({
          type: 'GOT_MERGE_RESPONSE',
          payload: res.data.data
        });
        try {
          NotificationManager.success(
            'The merge you requested has completed successfully.',
            'Merge has succeeded'
          );
        } catch (err) {
          NotificationManager.error('Please reload this view and try again', 'An Error Occured');
        }
      }
    });
  };
}

export function uploadSchedule(id, data, token) {
  return (dispatch) => {
    const api = new Api(token);
    api.postFormData('events/uploadSchedule?id=' + id, data, (res) => {
      console.log('RES', res);
      if (res.data) {
        if (res.data.status === 200) {
          dispatch({
            type: 'UPLOADED_SCHEDULE',
            payload: res.data.data
          });
        } else {
          NotificationManager.error('Please reload this view and try again', 'An Error Occured');
        }
      } else {
        NotificationManager.error('Please reload this view and try again', 'An Error Occured');
      }
    });
  };
}

export function postSplit(view, data, token) {
  return (dispatch) => {
    const api = new Api(token);

    api.post(view, data, (res) => {
      if (res.data.status === 200) {
        dispatch({
          type: 'GOT_SPLIT_RESPONSE',
          payload: res.data.data
        });
        try {
          NotificationManager.success(
            'The merge you requested has completed successfully.',
            'Merge has succeeded'
          );
        } catch (err) {
          NotificationManager.error('Please reload this view and try again', 'An Error Occured');
        }
      }
    });
  };
}

export function tabulatorSwapDancer(newId, oldId, competition, token, cb) {
  return (dispatch) => {
    const api = new Api(token);

    api.post('events/tabulatorSwapDancer', { newId, oldId, competition }, (res) => {
      if (res.data) {
        if (res.data.status === 200) {
          dispatch({
            type: 'TABULATOR_CHANGED_DANCER',
            payload: {
              oldId: oldId,
              data: res.data.data
            }
          });
          cb(res.data.data);
        }
      }
    });
  };
}

export function tabulatorAddDancer(id, competition, token, cb) {
  return (dispatch) => {
    const api = new Api(token);

    api.post('events/tabulatorAddDancer', { id, competition }, (res) => {
      if (res.data) {
        if (res.data.status === 200) {
          dispatch({
            type: 'TABULATOR_ADDED_DANCER',
            payload: res.data.data
          });
          cb(res.data.data);
        }
      }
    });
  };
}

export function tabulatorRemoveDancer(entryCompetitionId, competition, token, cb) {
  return dispatch => {
    const api = new Api(token);

    api.post('events/tabulatorRemoveDancer', { entryCompetitionId, competition }, (res) => {
      if (res.data) {
        if (res.data.status === 200) {
          dispatch({
            type: 'TABULATOR_REMOVED_DANCER',
            payload: {
              entryCompetitionId: entryCompetitionId,
              data: res.data.data
            }
          });
          cb(res.data.data);
        }
      }
    });
  };

}

export function getTableData(view, skip, limit, sort, token, mode = 'add') {
  return (dispatch) => {
    dispatch({
      type: 'SET_LOAD_STATE',
      payload: true
    });
    const api = new Api(token);
    api.get(`${view}`, { skip, limit, token, sort }, (res) => {
      if (res.data.status === 200) {
        if (res.data.status === 200) {
          if (mode === 'add') {
            if (view === 'files') {
              try {
                dispatch({
                  type: 'GOT_FILE_DATA',
                  payload: {
                    ...res.data,
                    view: view
                  }
                });
              } catch (err) {
                NotificationManager.error(
                  'Please reload this view and try again',
                  'An Error Occured'
                );
              }
            } else {
              try {
                dispatch({
                  type: 'GOT_TABLE_DATA',
                  payload: {
                    ...res.data,
                    view: view
                  }
                });
              } catch (err) {
                NotificationManager.error(
                  'Please reload this view and try again',
                  'An Error Occured'
                );
              }
            }
          } else {
            if (view === 'files') {
              try {
                dispatch({
                  type: 'GOT_MORE_FILE_DATA',
                  payload: {
                    ...res.data,
                    view: view
                  }
                });
              } catch (err) {
                NotificationManager.error(
                  'Please reload this view and try again',
                  'An Error Occured'
                );
              }
            } else {
              try {
                dispatch({
                  type: 'GOT_MORE_TABLE_DATA',
                  payload: {
                    ...res.data,
                    view: view
                  }
                });
              } catch (err) {
                NotificationManager.error(
                  'Please reload this view and try again',
                  'An Error Occured'
                );
              }
            }
          }
        }
      }
    });
  };
}

export function setTableView(view) {
  return (dispatch) => {
    try {
      dispatch({
        type: 'SET_TABLE_VIEW',
        payload: view
      });
    } catch (err) {
      NotificationManager.error('Please reload this view and try again', 'An Error Occured');
    }
  };
}

function roughSizeOfObject(object) {
  var objectList = [];
  var stack = [object];
  var bytes = 0;

  while (stack.length) {
    var value = stack.pop();

    if (typeof value === 'boolean') {
      bytes += 4;
    } else if (typeof value === 'string') {
      bytes += value.length * 2;
    } else if (typeof value === 'number') {
      bytes += 8;
    } else if (typeof value === 'object' && objectList.indexOf(value) === -1) {
      objectList.push(value);

      for (var i in value) {
        stack.push(value[i]);
      }
    }
  }
  return bytes;
}

export function generateDancerCard(eventId, entryId, dancerNumber, token) {
  return (dispatch) => {
    const api = new Api(token);
    api.post('events/generateDancerCard', { eventId, entryId, dancerNumber }, (res) => {
      console.log('RES', res);
      if (res.data) {
        if (res.data.status === 200) {
          if (res.data.data._id) {
            let link = urls.getS3Url(res.data.data.path);
            var win = window.open(link, '_blank');
            win.focus();
          }
        }
      }
    });
  };
}

export function generateCompetitionResults(eventId, competitionId, token, recalculateRecall) {
  return (dispatch) => {
    const api = new Api(token);

    dispatch({
      type: 'SET_COMP_GENERATING',
      payload: true
    });

    api.post(
      'events/generateInPersonCompetitionResults',
      { id: eventId, competitionId: competitionId, recalculateRecall: recalculateRecall},
      (res) => {
        if (res.data) {
          if (res.data.status === 200) {
            dispatch({
              type: 'UPDATE_COMP_RESULT_SUCCESS',
              payload: res.data.data
            });
            NotificationManager.success(
              'Your request to generate results for this competition has succeeded',
              'Results Generated'
            );
          } else {
            dispatch({
              type: 'UPDATE_COMP_RESULT_STATUS',
              payload: {}
            });
            NotificationManager.error(
              'Your request to generate results for this competition has failed.  Please reload this page and try again',
              'Results Generating Failed'
            );
          }
        } else {
          NotificationManager.error(
            'Your request to generate results for this competition has failed.  Please reload this page and try again',
            'Results Generating Failed'
          );
        }
      }
    );
  };
}

export function deleteCompetitionResults(competitionId, token) {
  return (dispatch) => {
    const api = new Api(token);

    dispatch({
      type: 'UPDATE_COMP_RESULT_STATUS',
      payload: true
    });

    api.post(
      'events/deleteCompetitionResults',
      { competitionId: competitionId },
      (res) => {
        if (res.data) {
          if (res.data.status === 200) {
            dispatch({
              type: 'UPDATE_COMP_RESULT_SUCCESS',
              payload: res.data.data
            });
            NotificationManager.success(
              'Your request to delete results for this competition has succeeded',
              'Results Deleted'
            );
          } else {
            dispatch({
              type: 'UPDATE_COMP_RESULT_STATUS',
              payload: {}
            });
            NotificationManager.error(
              'Your request to delete results for this competition has failed.  Please reload this page and try again',
              'Results Deleting Failed'
            );
          }
        } else {
          NotificationManager.error(
            'Your request to delete results for this competition has failed.  Please reload this page and try again',
            'Results Deleting Failed'
          );
        }
      }
    );
  };
}

export function updateTabulatorScore(template, tabulation, token, showAutosave, competitionId, rounds, cb) {
  return (dispatch) => {
    const api = new Api(token);

    dispatch({
      type: 'SET_EVENT_LOAD_STATE',
      payload: false
    });

    let newJudgeArray;
    let newScoresArray;
    let newScoreArray;
    let iterator;
    // clone the judge objects so we're not sending extra info
    for (let [key, roundObj] of Object.entries(template.rounds)) {
      console.log("key: " + key);
      console.log(roundObj);
      newJudgeArray = [];
      for (let judge of roundObj.judges) {
        // create a new object, copying only the properties we need from the judge object
        newJudgeArray.push((({ _id, label }) => ({ _id, label }))(judge));
      }
      newScoresArray = [];
      iterator = 0;
      for (let scoreArray of roundObj.scores) {
        newScoreArray = [];
        for (let score of scoreArray) {
        // create a new object, copying only the properties we need from the score object
        newScoreArray.push((({ _id, round1Score, round2Score, round3Score, round1Comments, round2Comments, round3Comments, recall, wq, nq, placed, wmh, judgeLabel }) => 
          ({ _id, round1Score, round2Score, round3Score, round1Comments, round2Comments, round3Comments, recall, wq, nq, placed, wmh, judgeLabel }))(score));
        }
        newScoresArray[iterator] = [...newScoreArray];
        iterator++;
      }
      roundObj.judges = [...newJudgeArray];
      roundObj.scores = [...newScoresArray];
    }
    
    let bf = 0;
    let af = 0;

    let scores = [];
    template = JSON.parse(JSON.stringify(template));
    template.rounds['1'].scores.map((item) => {
      return item.map((ite) => {
        let it = JSON.parse(JSON.stringify(item));
        delete it.entry;
        delete it.event;
        delete it.dancer;
        return it;
      });
    });

    template.rounds['2'].scores.map((item) => {
      return item.map((ite) => {
        let it = JSON.parse(JSON.stringify(item));
        delete it.entry;
        delete it.event;
        delete it.dancer;
        return it;
      });
    });

    template.rounds['3'].scores.map((item) => {
      return item.map((ite) => {
        let it = JSON.parse(JSON.stringify(item));
        delete it.entry;
        delete it.event;
        delete it.dancer;
        return it;
      });
    });

    template = JSON.stringify(template);

    let params = { 
      template: template, 
      autosave: showAutosave ? true : false, 
      competitionId: competitionId,
      tabulation: tabulation,
      rounds: rounds
    };

    api.post(`events/submitMultiScore`, params, (res) => {
      
      let aud = new Audio(noteSound);

      if (!showAutosave) {
        if (res.data.status === 200) {
          if (cb) {
            cb(res);
          }
          NotificationManager.success('Your changes have been saved', 'Saved');
          aud.play();
          dispatch({
            type: 'MANUAL_SAVE',
            payload: {
              compData: res.data.data,
            }
          });
        } else {
          NotificationManager.error('Please reload this view and try again', 'An Error Occured');
        }
      } else {
        dispatch({
          type: 'AUTOSAVED',
          payload: {
            type: 'autosave'
          }
        });
      }
    });
  };
}

export function updateTabulationObject(tabulation, token) {
  return (dispatch) => {
    const api = new Api(token);
    api.post(`events/updateTabulationObject`, { tabulation }, (res) => {
      dispatch({
        type: 'UPDATE_TABULATION',
        payload: res.data.data
      });
    });
  };
}

export function getTableDataWithQuery(view, skip, limit, sort, query, token, mode = 'add') {
  return (dispatch) => {
    const api = new Api(token);
    api.get(`${view}`, { skip, limit, token, sort, query }, (res) => {
      if (res.status === 200) {
        if (mode === 'add') {
          try {
            dispatch({
              type: 'GOT_EVENTS_DATA',
              payload: {
                ...res.data,
                view: view
              }
            });
          } catch (err) {
            NotificationManager.error('Please reload this view and try again', 'An Error Occured');
          }
        } else {
          try {
            dispatch({
              type: 'GOT_MORE_EVENTS_DATA',
              payload: {
                ...res.data,
                view: view
              }
            });
          } catch (err) {
            NotificationManager.error('Please reload this view and try again', 'An Error Occured');
          }
        }
      }
    });
  };
}

export function updateJudgeLabel(round, judge, val) {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_JUDGE_LABEL',
      payload: {
        round,
        judge,
        val
      }
    });
  };
}

export function updateStandardJudge(round, judge, val) {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_STANDARD_JUDGE',
      payload: {
        round,
        judge,
        val
      }
    });
  };
}

export function updateJudge(round, judge, val) {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_JUDGE',
      payload: {
        round,
        judge,
        val
      }
    });
  };
}

export function updateComments(entry, value, judgeIndex, round) {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_COMMENTS',
      payload: {
        entry,
        value,
        judgeIndex,
        round
      }
    });
  };
}

export function updateScore(entry, value, judgeIndex, round) {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_SCORE',
      payload: {
        entry,
        value,
        judgeIndex,
        round
      }
    });
  };
}

export function patch(uri, id, obj, token) {
  return (dispatch) => {
    const api = new Api(token);
    obj._id = id;
    api.patch(uri, id, obj, (res) => {
      if (res.data) {
        if (res.data.status === 200) {
          dispatch({
            type: 'PATCHED_EVENT',
            payload: res.data.data
          });
        }
      }
    });
  };
}

export function submitCompetition(id, token) {
  return (dispatch) => {
    const api = new Api(token);
    api.post('events/submitCompetition', { id: id }, (res) => {
      if (res.status === 200) {
        if (res.data.status === 200) {
          dispatch({
            type: 'COMPLETE_JUDGE_COMPETITION',
            payload: res.data.data
          });
          NotificationManager.success('Your scores have been submitted');
        }
      }
    });
  };
}

export function updateEntryScore(entry, round, key, val) {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_ENTRYSCORE',
      payload: {
        entry,
        round,
        key,
        val
      }
    });
  };
}

export function submitScore(params, token) {
  return (dispatch) => {
    const api = new Api(token);
    api.post('events/submitScore', params, (res) => {
      if (res.status === 200) {
        if (res.data.status === 200) {
          dispatch({
            type: 'UPDATE_JUDGE_COMPETITION',
            payload: {
              data: res.data.data,
              nextUrl: params.data.nextUrl
            }
          });
          NotificationManager.success('Your score has been saved');
        }
      }
    });
  };
}

export function updateDancerNum(entryCompetition, dancerNum, token) {
  let params = {
    id: entryCompetition,
    number: dancerNum
  }
  return (dispatch) => {
    const api = new Api(token);
    api.put('events/updateDancerNum', params, (res) => {
      if (res.status === 200) {
        NotificationManager.success('Dancer Update Saved');
      }
    });
  };
}

export function getSingle(id, token) {
  return (dispatch) => {
    const api = new Api(token);
    api.get('events/getJudgeSingle', { id: id }, (res) => {
      if (res.status === 200) {
        if (res.data.status === 200) {
          dispatch({
            type: 'GOT_JUDGE_COMPETITION',
            payload: res.data.data
          });
        }
      }
    });
  };
}

export function getOrganizerSchool(query, token) {
  return (dispatch) => {
    const api = new Api(token);
    api.get('events/getOrganizerSchool', { query }, (res) => {
      if (res.status === 200) {
        if (res.data.status === 200) {
          dispatch({
            type: 'GOT_ORGANIZER_SCHOOL',
            payload: res.data.data
          });
        }
      }
    });
  };
}

export function getOrganizerCompetition(query, token) {
  return (dispatch) => {
    const api = new Api(token);
    api.get('events/getOrganizerCompetition', { query }, (res) => {
      if (res.status === 200) {
        if (res.data.status === 200) {
          dispatch({
            type: 'GOT_ORGANIZER_COMPETITION',
            payload: res.data.data
          });
        }
      }
    });
  };
}

export function getTabulatorCompetition(query, token) {
  return (dispatch) => {
    const api = new Api(token);
    dispatch({
      type: 'SET_EVENT_LOAD_STATE',
      payload: false
    });
    api.get('events/getTabulatorCompetition', { query }, (res) => {
      if (res.status === 200) {
        if (res.data.status === 200) {
          dispatch({
            type: 'GOT_TABULATOR_COMPETITION',
            payload: res.data.data
          });
        }
      }
    });
  };
}

export function getJudgeCompetition(query, token) {
  return (dispatch) => {
    const api = new Api(token);
    api.get('events/getJudgeCompetition', { query }, (res) => {
      if (res.status === 200) {
        if (res.data.status === 200) {
          dispatch({
            type: 'GOT_JUDGE_COMPETITION',
            payload: res.data.data
          });
        }
      }
    });
  };
}

export function togglePublishedResults(id, publish, token) {
  return (dispatch) => {
    const api = new Api(token);
    api.post('events/togglePublishResults', { id, publish }, (res) => {
      if (res.data) {
        if (res.data.status === 200) {
          dispatch({
            type: 'PATCHED_SINGLE_EVENT',
            payload: {
              ...res.data.data,
              generatingResults: false
            }
          });
        }
      }
    });
  };
}

export function generateResults(id, token) {
  return (dispatch) => {
    const api = new Api(token);
    dispatch({
      type: 'PATCHED_SINGLE_EVENT',
      payload: { generatingResults: true }
    });

    api.post('events/generateResults', { id }, (res) => {
      if (res.data) {
        if (res.data.status === 200) {
          dispatch({
            type: 'PATCHED_SINGLE_EVENT',
            payload: {
              ...res.data.data,
              generatingResults: false
            }
          });
        }
      }
    });
  };
}

export function patchJudges(uri, obj, token) {
  return (dispatch) => {
    const api = new Api(token);
    api.post(uri, obj, (res) => {
      if (res.data) {
        if (res.data.status === 200) {
          dispatch({
            type: 'PATCHED_EVENT_JUDGES',
            payload: res.data.data
          });
        }
      }
    });
  };
}

export function getPartial(uri, id, token) {
  return (dispatch) => {
    const api = new Api(token);
    api.get(uri, { id }, (res) => {
      dispatch({
        type: 'PATCHED_SINGLE_EVENT',
        payload: res.data.data
      });
    });
  };
}

export function getSingleEvent(id, token) {
  return (dispatch) => {
    const api = new Api(token);
    api.get(`events/getForOrganizer`, { id }, (res) => {
      if (res.data) {
        if (res.data.status === 200) {
          dispatch({
            type: 'GOT_SINGLE_EVENT',
            payload: res.data.data
          });
        }
      }
    });
  };
}

