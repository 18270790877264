/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */

import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {Formik} from 'formik';
import * as yup from 'yup';
import * as authActions from '../../../store/actions/auth';
import {NotificationManager} from 'react-notifications';
import { ArrowForward } from "react-ionicons";


const schema = yup.object({
    password: yup.string().min(8, 'Password needs to be at least 8 characters long'),
    password2: yup.mixed().test('match', 'Password does not match', function (password2) {
        return password2 === this.parent.password
    })
});

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            submitting: false
        }
    }

    handleSubmit = (values) => {
        this.setState({submitting: true});
        this.props.resetPassword(this.props.match.params.token, values.password);
        //this.props.forgotPassword(values.email);
    }

    componentDidMount(){
        if (this.props.match.params.email && this.props.match.params.token) {
            this.props.validateToken(this.props.match.params.email, this.props.match.params.token);
        } else {
            NotificationManager.error("Invalid code");
        }
    }

    componentDidUpdate(props) {
        if (props.auth.attempts < this.props.auth.attempts) {
            this.setState({submitting: false});
        }
    }

    render() {
        return (
            <div className={`login-form ex-pad`}>

                {this.props.auth.validCode ?
                    <div>
                        <h4 className="auth-title">Set Password</h4>
                        <Formik
                            validationSchema={schema}
                            onSubmit={this.handleSubmit}
                            enableReinitialize
                            initialValues={{
                                password: '',
                                password2: ''
                            }}
                        >
                            {({
                                  handleSubmit,
                                  handleChange,
                                  handleBlur,
                                  values,
                                  touched,
                                  isValid,
                                  errors,
                                  isSubmitting
                              }) => (

                                <Form onSubmit={handleSubmit}>
                                    <Form.Group controlId="password">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="password"
                                            placeholder="Enter password"
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isValid={touched.password && !errors.password}
                                            className={`form-control ${touched.password ? errors.password ? 'is-invalid' : 'is-valid' : ''}`}
                                        />
                                    </Form.Group>
                                    {errors.password ?
                                        <div className={"text-hint text-error"}>{errors.password}</div>
                                        :
                                        ''}



                                <Form.Group controlId="password2">
                                <Form.Label>Password (Again)</Form.Label>
                                    <Form.Control
                                    type="password"
                                    name="password2"
                                    placeholder="Enter password"
                                    value={values.password2}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.password2 && !errors.password2}
                                    className={`form-control ${touched.password2 ? errors.password2 ? 'is-invalid' : 'is-valid' : ''}`}
                                    />
                                    </Form.Group>

                                    {errors.password2 ?
                                        <div className={"text-hint text-error"}>{errors.password2}</div>
                                        :
                                        ''}
                                    <div className={"hint-text text-center auth-hint"}>
                                        Don't have an account yet?  <Link to={"/a/register"}>Register here</Link>.<br />
                                        Remember your password?  <Link to={"/a/login"}>Log in here</Link>.
                                    </div>
                                    <div className={`text-center mb-20`}>
                                        <Button variant="primary" disabled={this.state.submitting || !isValid} type="submit">
                                            Set Password &nbsp; <ArrowForward />
                                        </Button>
                                        <div className={"spacer"} />
                                    </div>
                                </Form>
                            )}

                        </Formik>

                    </div>

                    : this.props.auth.message ?
                        <div className={"text-center"}><div className={"text-danger text-center"}>{this.props.auth.message}</div>

                            <Link to={"/a/login"}>Back to login</Link>

                        </div>
                        : null

                }



            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    login: (credentials) => {
        dispatch(authActions.login(credentials));
    },
    setBlankMessage: () => {
        dispatch(authActions.setBlankMessage());
    },
    validateToken: (email, token) => {
        dispatch(authActions.validateToken(email, token));
    },
    resetPassword: (token, password) => {
        dispatch(authActions.resetPassword(token, password));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));

class ResetPasswordContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default ResetPasswordContainer;