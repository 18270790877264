/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 1/30/2021
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import * as tableActions from '../../../store/actions/table';
import Button from "react-bootstrap/Button";
import { ArrowBack } from "react-ionicons";
import Select from "../../Common/Select";
import CompetitionModal from '../../Common/CompetitionModal';
import Form from 'react-bootstrap/Form';
import { ArrowForward } from 'react-ionicons';
import MergeConfirmationModal from "../../Common/MergeConfirmationModal";
import CompetitionDeleteConfirmationModal from "../../Common/CompetitionDeleteConfirmationModal";


class Competitions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            sort: null,
            filter: null,
            showModal: false,
            data: {},
            search: '',
            showDeleteModal: false
        }
    }

    componentDidMount(props) {
        this.props.getTableData({id: this.props.match.params.id}, {code: 1}, this.props.auth.token._id)
    }

    componentDidUpdate(props, state) {
        if (state.sort !== this.state.sort || state.filter !== this.state.filter) {
        let query = {id: this.props.match.params.id};
        let sort = {code: 1};
        if (this.state.sort) {
            sort = this.state.sort.sort;
        }

        if (this.state.filter) {
            query.filter = this.state.filter.id;
        }

        if (this.state.search) {
            query.search = this.state.search;
        }

        this.props.getTableData(query, sort, this.props.auth.token._id)



        }
    }

    filters = [
        {
            id: 'all',
            name: 'All',
        },
        {
            id: 'started',
            name: 'Started'
        },
        {
            id: 'notStarted',
            name: 'Not Started'
        },
        {
            id: 'complete',
            name: 'Completed'
        }
    ]

    sort = [
        {
            id: 'compasc',
            name: 'Competition Asc',
            sort: {code: 1}
        },
        {
            id: 'compdesc',
            name: 'Competition Desc',
            sort: {code: -1}
        },
        {
            id: 'entriesasc',
            name: 'Entries Asc',
            sort: {code: 1, entries: 1}
        },
        {
            id: 'entriesdesc',
            name: 'Entries Desc',
            sort: {code: 1, entries: -1}
        }
    ]

    search = () => {
        let query = {id: this.props.match.params.id};
        let sort = {code: 1};
        if (this.state.sort) {
            sort = this.state.sort.sort;
        }

        if (this.state.filter) {
            query.filter = this.state.filter.id;
        }

        if (this.state.search) {
            query.search = this.state.search;
        }

        this.props.getTableData(query, sort, this.props.auth.token._id)
    }

    submitDelete = () => {

        this.props.disableEmptyCompetitions(this.props.match.params.id, this.props.auth.token._id);
        this.setState({showDeleteModal: false})

    }

    render() {
        return (
            <div className={'page-container'}>
                <CompetitionDeleteConfirmationModal submitCompetitionDelete={this.submitDelete}
                                        show={this.state.showDeleteModal} hideModal={() => this.setState({showDeleteModal: false})} />

                <div className={'page-header'}>
                    <Row>
                        <Col sm={2} className={"pt-3"}>
                            <h1>
                                {this.props.location.pathname.split("/").length > 3 ?
                                    <Button variant={"flat btn-round"} onClick={e => {

                                        this.props.history.push(`/app/event-dashboard/${this.props.match.params.id}`);
                                    }}>
                                        <ArrowBack />
                                    </Button>
                                    : null}
                            </h1>
                        </Col>
                        <Col sm={4}>
                            <div className={"field-container"}>
                                <label>Search</label>
                                <Form.Control
                                    value={this.state.search}
                                    onChange={e => this.setState({search: e.target.value})}
                                    onKeyDown={e => {
                                        if (e.key === 'Enter') {
                                            this.search();
                                        }
                                    }}
                                />
                                <Button variant={"primary"} className={"btn-search"} onClick={this.search}><ArrowForward /></Button>
                            </div>

                        </Col>
                        <Col sm={3}>
                            <Select
                                disableTyping={true}
                                items={this.filters}
                                value={this.state.filter || this.filters[0]}
                                itemValue={"id"}
                                onChange={val => {
                                    this.setState({filter: val});
                                }}
                                displayValue={"name"}
                                label={"Filter"}

                            />
                        </Col>
                        <Col sm={3}>
                            <Select
                                disableTyping={true}
                                items={this.sort}
                                value={this.state.sort || this.sort[0]}
                                itemValue={"id"}
                                onChange={val => {
                                    this.setState({sort: val});
                                }}
                                displayValue={"name"}
                                label={"Sort"}

                            />
                        </Col>
                    </Row>
                </div>
                <CompetitionModal show={this.state.showModal} data={this.state.data} hideModal={() => this.setState({showModal: false})} />
                {this.props.table.view === "events/getOrganizerCompetitions" ?
                    <div className={"text-right"}>

                        <span>{this.props.table.data.length}&nbsp; &nbsp;
                            {this.props.table.data.length === 1 ? 'Result' : 'Results'}</span>&nbsp;
                        <span><Button variant={"primary"} className={``} onClick={e =>
                        {
                            e.preventDefault();
                            this.setState({showDeleteModal: true});

                        }}>

                                                Disable Empty Competitions  &nbsp; <ArrowForward color={"#fff"} />
                                            </Button></span></div>
                    : null}
                <div className={"spacer"} />
                {this.props.table.view === "events/getOrganizerCompetitions" ?
                    this.props.table.data ?
                        this.props.table.data.length > 0 ?
                            this.props.table.data.map((d, i) => {
                                let completed = false;
                                if(this.props.table.judgeEvent){
                                    if(this.props.table.judgeEvent.event === this.props.match.params.id ) {
                                        if(this.props.table.judgeEvent.completed.indexOf(d._id) > -1) {
                                            completed = true;
                                        }
                                    }
                                }

                                return (
                                    <div className={`competition-tile`} onClick={e => {
                                        this.setState({
                                            showModal: true,
                                            data: d
                                        })
                                        //this.props.history.push(`/app/judge/${this.props.match.params.id}/${d._id}`)
                                    }}>

                                        <h1>{d.code}</h1>
                                        <h4>{d.rowTitle} {d.columnTitle}</h4>

                                        {
                                            completed ?
                                                <h5 className={``}>Completed</h5>
                                                :
                                                <h5 className={`${d.count > 0 ? '' : 'text-danger'}`}>{d.count} entries</h5>

                                        }


                                    </div>
                                )
                            })
                            : null
                        : null
                    : null}


            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    events: state.events
});

const mapDispatchToProps = dispatch => ({
    getTableData: (query, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQueryWithData("events/getOrganizerCompetitions", 0, 999, sort, query, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
    disableEmptyCompetitions: (id, token) => {
        dispatch(tableActions.disableEmptyCompetitions(id, token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Competitions));

class CompetitionsContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default CompetitionsContainer;
