import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { Build } from 'react-ionicons';

const SingleEntryEditButton = ({ show, className, click }) => {
  const onClickHandler = () => {
    if (click) click();
  };

  return (
    show && (
      <Button
        variant={'flat'}
        className={`btn-small-padding ${className}`}
        onClick={onClickHandler}>
        <Build />
      </Button>
    )
  );
};

SingleEntryEditButton.propTypes = {
  show: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default SingleEntryEditButton;
