/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 6/25/2020
 */

const defaultState = {
    files: []
};

const files = (state = defaultState, action) => {
    switch (action.type) {
        case 'LOGOUT':
            return defaultState;
        case 'ADD_FILES':
            return {
                ...state,
                files: state.files.concat(action.payload)
            }
        default:
            return state
    }
}

export default files;