/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Form from 'react-bootstrap/Form';
import {Formik} from 'formik';
import * as yup from 'yup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import urls from "../../../utils/urls";
import placeholder from "../../../assets/images/placeholder-person.png";
import * as singleActions from "../../../store/actions/single";
import FormButtons from "../../Common/FormButtons";


const schema = yup.object({
    email: yup.string().email("Please enter a valid email address").required("This field is required"),
    password: yup.string().min(8, 'Password needs to be at least 8 characters long'),
    password2: yup.mixed().test('match', 'Password does not match', function (password2) {
        return password2 === this.parent.password
    })
});


class UserForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fileSelectField: '',
            filePickerOpen: false,
            selectingFileType: 'image',
            preview: '',
            image: '',
        }
    }

    handleFormModal = (e) => {
        this.setState({filePickerOpen: false})
    }

    componentDidMount(){
        this.init();
    }

    componentDidUpdate(props, state) {
        if(props.single.single !== this.props.single.single) {
            this.init();
        } else {
            if ((!this.state.preview && this.props.single.single.image) || this.props.single.single.image !== props.single.single.image) {
                this.init();
            }
        }
    }

    init = () => {
        if (this.props.single.single.image) {
            if (this.props.single.single.image.path) {
                this.setState({preview: urls.getS3Url(this.props.single.single.image.thumb || this.props.single.single.image.path)})
            }
        } else {
            this.setState({preview: ''})
        }
    }

    selectFile = (file) => {
        //this.props.updateField('image', file);
        this.props.setValues({image: file});
    }

    handleSubmit = (values) => {
        let data = new FormData();
        if(this.state.image) {
            data.append("files", this.state.image);
        }
        let params = {user: values};


        if (this.props.single.single._id) {
            params.user._id = this.props.single.single._id;
        }

        if (this.props.location.pathname.indexOf('settings') > -1) {
            params.user._id = this.props.user.user._id;
        }

        data.set("data", JSON.stringify(params));

        if (params.user._id) {
            //console.log("Patching", params);
            this.props.patch("users", params._id, data, this.props.auth.token._id);
        } else {
            this.props.post("users", data, this.props.auth.token._id, (id) => {
            });
        }
    }

    roles = ["dancer", "parent", "teacher", "judge", "tabulator"];

    render() {
        return (
            <div>
                <Formik
                    validationSchema={schema}
                    onSubmit={this.handleSubmit}
                    enableReinitialize
                    initialValues={{
                        email: this.props.location.pathname.indexOf('admin') > -1 ? this.props.single.single.email || '' : this.props.single.user ? this.props.single.user.email || '' : '',
                        password: '',
                        password2: '',
                        name: this.props.location.pathname.indexOf('admin') > -1 ? this.props.single.single.name || '' : this.props.single.user ? this.props.single.user.name || '' : '',
                        lname: this.props.location.pathname.indexOf('admin') > -1 ? this.props.single.single.lname || '' : this.props.single.user ? this.props.single.user.lname || '' : '',
                        image: this.props.location.pathname.indexOf('admin') > -1 ? this.props.single.single.image || '' : this.props.single.user ? this.props.single.user.image ? this.props.single.user.image.path : '' || '' : '',
                        role: this.props.location.pathname.indexOf('admin') > -1 ? this.props.single.single.role || '' : this.props.single.user ? this.props.single.user.role || 'user' : '',
                        roles: this.props.location.pathname.indexOf('admin') > -1 ? this.props.single.single.roles || [] : this.props.single.user ? this.props.single.user.roles || [] : [],
                        notify: this.props.location.pathname.indexOf('admin') > -1 ? this.props.single.single.notify || '' : this.props.single.user ? this.props.single.user.notify || false : false,
                        darkTheme: this.props.location.pathname.indexOf('admin') > -1 ? this.props.single.single.darkTheme || false : this.props.single.user ? this.props.single.user.darkTheme || false : false,
                        teacherId: this.props.location.pathname.indexOf('admin') > -1 ? this.props.single.single.teacherId || '' : this.props.single.user ? this.props.single.user.teacherId || '' : '',
                        judgeId: this.props.location.pathname.indexOf('admin') > -1 ? this.props.single.single.judgeId || '' : this.props.single.user ? this.props.single.user.judgeId || '' : '',
                        customerId: this.props.location.pathname.indexOf('admin') > -1 ? this.props.single.single.customerId || '' : this.props.single.user ? this.props.single.user.customerId || '' : '',
                    }}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                          touched,
                          isValid,
                          errors,
                          isSubmitting,
                          setFieldValue
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>


                            <FormButtons
                                confirm={(e) => {
                                    this.handleSubmit(values);
                                }}
                                back={(e) => {
                                    e.preventDefault();
                                    this.step(values, "previous");
                                }}
                                showPrevious={false}
                                showRevert={false}
                                showNext={false}
                                next={(e) => {
                                    e.preventDefault();
                                    this.handleSubmit(values);
                                }}
                                showDefault={true}
                                showBack={this.props.remoteProfile}
                                onBack={e => {
                                    this.props.history.push('/app/admin/users');
                                }}
                                label={''}

                            />

                            <Row>
                                <Col md={12}>


                                    <div className={""}>
                                        <Row>
                                            <Col md={4}>
                                                <div className={"field-container"}>
                                                    <Form.Group controlId="image">
                                                        {this.state.preview ?
                                                            <div onClick={e => this.setState({filePickerOpen: true})} className={"artist-img avatar"} style={{background: this.state.preview ? `url(${this.state.preview}), linear-gradient(0deg, rgba(0,0,0,.6), rgba(0,0,0,.4), rgba(0,0,0,.2), transparent)` : `url(${placeholder}), linear-gradient(0deg, rgba(0,0,0,.6), rgba(0,0,0,.4), rgba(0,0,0,.2), transparent)`}}></div>
                                                            //<img src={this.state.preview} className={"form-preview-image"} />
                                                            :
                                                            <div onClick={e => this.setState({filePickerOpen: true})} className={"artist-img avatar"} style={{background: `url(${placeholder}), linear-gradient(0deg, rgba(0,0,0,.6), rgba(0,0,0,.4), rgba(0,0,0,.2), transparent)`}}></div>

                                                        }
                                                        <Form.Control
                                                            type="file"
                                                            name="image"
                                                            placeholder="Upload image"
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    preview: URL.createObjectURL(e.target.files[0]),
                                                                    image: e.target.files[0]
                                                                })
                                                            }}
                                                            onBlur={handleBlur}
                                                            isValid={touched.image && !errors.image}
                                                            className={`form-control hidden-file-input ${touched.image ? errors.image ? 'is-invalid' : 'is-valid' : ''}`}
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </Col>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        <Form.Group controlId="name">
                                                            <Form.Label>First Name</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="name"
                                                                placeholder="Enter name"
                                                                value={values.name}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                isValid={touched.name && !errors.name}
                                                                className={`form-control ${touched.name ? errors.name ? 'is-invalid' : 'is-valid' : ''}`}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group controlId="lname">
                                                            <Form.Label>Last Name</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="lname"
                                                                placeholder="Enter name"
                                                                value={values.lname}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                isValid={touched.lname && !errors.lname}
                                                                className={`form-control ${touched.lname ? errors.lname ? 'is-invalid' : 'is-valid' : ''}`}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                    <h5>I am a</h5>
                                                        {values.roles ? this.roles.map((r, i) => {
                                                            return (
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    className="mb-2"
                                                                    label={r}
                                                                    key={i}
                                                                    name={r}
                                                                    value={values[r]}
                                                                    checked={values.roles.indexOf(r) > -1}
                                                                    onChange={e => {
                                                                        if (values.roles.indexOf(r) > -1) {
                                                                            // remove
                                                                            setFieldValue('roles', values.roles.filter(role => role !== r));
                                                                        } else {
                                                                            //add
                                                                            setFieldValue('roles', values.roles.concat(r));
                                                                        }
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    isValid={touched[r] && !errors[r]}
                                                                />
                                                            )
                                                        }) : null}

                                                        {this.props.user.user.role === 'admin' && values.roles ?
                                                            <Form.Check
                                                                type="checkbox"
                                                                className="mb-2"
                                                                label={"Organizer"}
                                                                name={"organizer"}
                                                                value={values["organizer"]}
                                                                checked={values.roles.indexOf("organizer") > -1}
                                                                onChange={e => {
                                                                    if (values.roles.indexOf("organizer") > -1) {
                                                                        // remove
                                                                        setFieldValue('roles', values.roles.filter(role => role !== "organizer"));
                                                                    } else {
                                                                        //add
                                                                        setFieldValue('roles', values.roles.concat("organizer"));
                                                                    }
                                                                }}
                                                                onBlur={handleBlur}
                                                                isValid={touched["organizer"] && !errors["organizer"]}
                                                            />
                                                        : null}

                                                    </Col>
                                                    <Col>
                                                        <Form.Check
                                                            type="checkbox"
                                                            className="mb-2"
                                                            label="Play notification sound"
                                                            name="notifications"
                                                            checked={values.notify}
                                                            onChange={e => {
                                                                setFieldValue('notify', !values.notify);
                                                            }}
                                                            onBlur={handleBlur}
                                                            isValid={touched.judge && !errors.judge}
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            className="mb-2"
                                                            label="Dark Theme"
                                                            name="darkTheme"
                                                            checked={values.darkTheme}
                                                            onChange={e => {
                                                                setFieldValue('darkTheme', !values.darkTheme);
                                                            }}
                                                            onBlur={handleBlur}
                                                            isValid={touched.darkTheme && !errors.darkTheme}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        {values.roles.indexOf('teacher') > -1 || values.roles.indexOf('judge') > -1 ?
                                         <Row>
                                             {values.roles.indexOf('teacher') > -1 ?
                                                 <Col md={6}>
                                                     <Form.Group controlId="teacherId">
                                                         <Form.Label>Teacher ID</Form.Label>
                                                         <Form.Control
                                                             type="text"
                                                             name="teacherId"
                                                             placeholder="Teacher ID"
                                                             value={values.teacherId}
                                                             onChange={handleChange}
                                                             onBlur={handleBlur}
                                                             isValid={touched.teacherId && !errors.teacherId}
                                                             className={`form-control ${touched.teacherId ? errors.teacherId ? 'is-invalid' : 'is-valid' : ''}`}
                                                         />
                                                     </Form.Group>
                                                 </Col>
                                             : null}

                                             {values.roles.indexOf('judge') > -1 ?
                                                 <Col md={6}>
                                                     <Form.Group controlId="JudgeId">
                                                         <Form.Label>ADCRG ID</Form.Label>
                                                         <Form.Control
                                                             type="text"
                                                             name="judgeId"
                                                             placeholder="ADCRG ID"
                                                             value={values.judgeId}
                                                             onChange={handleChange}
                                                             onBlur={handleBlur}
                                                             isValid={touched.judgeId && !errors.judgeId}
                                                             className={`form-control ${touched.judgeId ? errors.judgeId ? 'is-invalid' : 'is-valid' : ''}`}
                                                         />
                                                     </Form.Group>
                                                 </Col>
                                                 : null}
                                         </Row>
                                        : null}

                                        <Row>
                                            <Col md={12}>
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Label>Email address</Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        name="email"
                                                        placeholder="Enter email"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isValid={touched.email && !errors.email}
                                                        className={`form-control ${touched.email ? errors.email ? 'is-invalid' : 'is-valid' : ''}`}

                                                    />
                                                </Form.Group>
                                            </Col>
                                            {this.props.user.user.role === 'admin' ?
                                            <Col md={6}>
                                                <Form.Group controlId="formBasicRole">
                                                    <Form.Label>Role</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="role"
                                                        as={"select"}
                                                        placeholder="Enter role"
                                                        value={values.role}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isValid={touched.role && !errors.role}
                                                        className={`form-control ${touched.role ? errors.role ? 'is-invalid' : 'is-valid' : ''}`}
                                                    >
                                                        <option value={"user"}>User</option>
                                                        <option value={"admin"}>Admin</option>

                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                                : null}


                                            {this.props.user.user.role === 'admin' ?
                                                <Col md={6}>
                                                    <Form.Group controlId="JudgeId">
                                                        <Form.Label>Customer ID (Stripe)</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="customerId"
                                                            placeholder="Customer ID"
                                                            value={values.customerId}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            isValid={touched.customerId && !errors.customerId}
                                                            className={`form-control ${touched.customerId ? errors.customerId ? 'is-invalid' : 'is-valid' : ''}`}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                : null}

                                        </Row>

                                    </div>
                                </Col>
                            </Row>

                            <div className={"divider"} />
                            {this.props.location.pathname.indexOf('/settings') > -1 ?
                                <Row>
                                    <Col md={6}>

                                        <Form.Group controlId="formBasicPassword">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control
                                                type="password"
                                                name="password"
                                                placeholder="Enter password"
                                                value={values.password}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isValid={touched.password && !errors.password}
                                                className={`form-control ${touched.password ? errors.password ? 'is-invalid' : 'is-valid' : ''}`}
                                            />
                                        </Form.Group>
                                        {errors.password && touched.password ?
                                            <div className={"text-hint text-error"}>{errors.password}</div>
                                            :
                                            ''}
                                    </Col>
                                    <Col md={6}>

                                        <Form.Group controlId="formBasicPassword2">
                                            <Form.Label>Password (Again)</Form.Label>
                                            <Form.Control
                                                type="password"
                                                name="password2"
                                                placeholder="Enter password"
                                                value={values.password2}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isValid={touched.password2 && !errors.password2}
                                                className={`form-control ${touched.password2 ? errors.password2 ? 'is-invalid' : 'is-valid' : ''}`}
                                            />
                                        </Form.Group>

                                        {errors.password2 && touched.password2 ?
                                            <div className={"text-hint text-error"}>{errors.password2}</div>
                                            :
                                            ''}
                                    </Col>
                                </Row>
                                : null}



                        </Form>
                    )}
                </Formik>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    single: state.single,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    post: (view, data, token, redirect) => {
        dispatch(singleActions.postFormData(view, data, token, true, redirect));
    },
    patch: (view, id, data, token) => {
        dispatch(singleActions.patchFormData(view, id, data, token));
    },
    get: (id, token) => {
        dispatch(singleActions.getSingle('users', id, token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(UserForm));

class UserFormContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default UserFormContainer;
