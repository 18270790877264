/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import GeneralTile from '../../Common/GeneralTile';
import Row from "react-bootstrap/Row";
import placeholder from '../../../assets/images/placeholder-person.png';
import * as tableActions from '../../../store/actions/table';


class StudentResults extends React.Component {

    dancers = [
        {
            title: 'Some Person',
            group: 'U15',
            entries: 4
        },
        {
            title: 'Some Person',
            group: 'U15',
            entries: 4
        },
        {
            title: 'Some Person',
            group: 'U15',
            entries: 4
        }
    ]

    events = [
        {
            title: 'Event 1',
            group: 'U15',
            date: 'Jan 21, 20201',
            registering: true,
            entries: 4
        },
        {
            title: 'Event 2',
            group: 'U15',
            date: 'Jan 7, 20201',
            registering: true,
            entries: 4
        },
        {
            title: 'Event 3',
            group: 'U15',
            date: 'March 13, 20201',
            registering: false,
            entries: 4
        }
    ]

    componentDidMount(){

        if (this.props.user.user.roles.indexOf('teacher') > -1) {
            this.props.getStudents(this.props.auth.token._id);
        }
    }

    render() {
        return (
            <div>

                {this.props.user.user.roles.indexOf('teacher') > -1 ?
                    <div>



                            <div className={"tile-grid"}>
                                <Row>
                                    {
                                        this.props.table.students ?
                                            this.props.table.students.map((d, i) => {
                                                return <GeneralTile isResults={true} collection={"dancers"} {...d} key={i} data={d} placeholder={placeholder} />
                                            }) : <h4 className={"no-data"}>No Students</h4>}
                                </Row>
                            </div>

                    </div>
                    : null}

            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    get: (token) => {
        dispatch(tableActions.getTableData("dancers", 0, 99, {}, token, "add"));
    },
    getStudents: (token) => {
        dispatch(tableActions.getStudents(0, 99, {}, token, "add"));
    },
    loadData: (view) => {

    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(StudentResults));

class StudentResultsContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected/>
            </div>
        )
    }
}

export default StudentResultsContainer;