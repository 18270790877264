/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */

import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Dropdown from "react-bootstrap/Dropdown";
import {ChatbubbleEllipsesOutline, Power} from 'react-ionicons';
import { Settings } from 'react-ionicons';
import { Home } from 'react-ionicons';
import LogoSimple from '../../assets/images/white-logo.png';
import { Menu } from 'react-ionicons';
import * as appActions from '../../store/actions/app';
import Button from 'react-bootstrap/Button';
import { Notifications } from 'react-ionicons';
import Notification from '../Common/Notification';
import { Checkmark } from 'react-ionicons';
import { Clipboard } from 'react-ionicons';
import * as socketActions from '../../store/actions/socket';
import * as notificationActions from '../../store/actions/notifications';
import Scrollbar from "react-scrollbars-custom";
import noteSound from '../../assets/audio/note.mp3';
import AccountCircle from "../Common/AccountCircle";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ArrowForward } from "react-ionicons";
import { Refresh } from "react-ionicons";
import { HelpCircle } from "react-ionicons";



const iconStyle = {
    fontSize: '24px',
    color: '#606060'
}

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            chime: null,
            showDropdown: false
        }
    }

    toggleDropdown = () => {
        if (this.state.showDropdown) {
            this.setState({ showDropdown: false });
        } else {
            this.setState({ showDropdown: true });
        }
    }


    notifications = [
        {
            icon: <Clipboard />,
            title: 'Results available',
            description: 'The event that you entered is now complete and a detailed score card...',
            ts: '7 minutes ago'
        },
        {
            icon: <ChatbubbleEllipsesOutline />,
            title: 'Request for more information',
            description: 'One of your dancer profiles requires further validation.  Click here to get started.',
            ts: '22 hours ago'
        },
        {
            icon: <Checkmark />,
            title: 'Your entry has been submitted',
            description: 'Your entry has been submitted and detailed information will be available...',
            ts: '8 days ago'
        },

    ]

    notifications = []

    componentDidMount(){
        this.props.getNotifications(this.props.auth.token._id);
        if (!this.state.chime) {
            let aud = new Audio(noteSound);
            aud.volume = 0.6;
            this.setState({chime: aud})
        }
    }

    componentDidUpdate(props) {
        if (this.props.notifications.count > props.notifications.count) {
            if (this.state.chime) {
                if (this.props.user.user.notify) {
                    try{
                        this.state.chime.play();
                    } catch(r) {

                    }

                }
            }
        }
    }

    menu = [
        {
            title: 'Dashboard ',
            icon: <Home {...iconStyle} />,
            path: '/app',
            type: 'link',

        },
        {
            title: 'Settings',
            icon: <Settings {...iconStyle} />,
            path: '/app/settings/account',
            type: 'link',
        },

        /*
        {
            title: 'Import',
            icon:  <MdPeople {...iconStyle} />,
            path: '/app/admin/import',
            type: 'link',
            role: 'admin'
        },
        {
            title: 'Parse Import',
            icon:  <IosBriefcase {...iconStyle} />,
            path: '/app/admin/parseimport',
            type: 'link',
            role: 'admin'
        },*/



        {
            title: `What's New`,
            icon:  <Refresh {...iconStyle} />,
            path: '/app/updates',
            type: 'link'

        },

        {
            title: 'Help',
            icon: <HelpCircle {...iconStyle} />,
            path: '/app/help',
            type: 'link',
            external: true,
            url: 'https://www.feisfwd.com/contact'
        },
        {
            title: 'Music',
            type: 'title',
        },

        {
            title: 'Logout',
            icon: <Power {...iconStyle} />,
            path: '/app/logout',
            action: () => {
                this.props.setLogoutModal(true)
            },
            type: 'link',
        },


    ]
    renderHeader = (item, i) => {
        let shouldRender = true;
        if (item.role) {
            if (this.props.user.user) {
                if (item.role === 'admin' && this.props.user.user.role !== 'admin') {
                    shouldRender = false;
                }
            } else {
                shouldRender = false;
            }
        }
        if (shouldRender) {
            return <div key={i} className={`separator`}/>

        } else {
            return null;
        }
    }
    renderItem = (item, i) => {
        let shouldRender = true;
        if (item.role) {
            if (this.props.user.user) {
                if (item.role === 'admin' && this.props.user.user.role !== 'admin') {
                    shouldRender = false;
                }
            } else {
                shouldRender = false;
            }
        }

        if (item.roles) {
            let matchRoles = 0;
            item.roles.map((r) => {
                if(this.props.user.user) {
                    if (this.props.user.user.roles) {
                        this.props.user.user.roles.map((ur) => {
                            if (r === ur) {
                                matchRoles += 1;
                            }

                            return ur;
                        })
                    }
                }

                return r;
            })

            if (matchRoles === 0) {
                shouldRender = false;
            }
        }

        if (shouldRender) {
            if (item.external) {
                return (
                    <Nav.Item key={i} className={`${this.props.location.pathname === item.path ? 'active' : ''}`}>
                        <a className={"nav-link"} href={item.url} target={"_blank"} rel="noopener noreferrer" >{item.icon} <span
                            className={`menu-text`}>{item.title}</span></a>
                    </Nav.Item>
                )
            }else {
                return (
                    <Nav.Item key={i} className={`${
                        this.props.location.pathname === "/app" && item.path === "/app" ? '' :
                            this.props.location.pathname.indexOf(item.path) > -1 && item.path !== "/app" ? '' : ''}`}>
                        <Nav.Link onClick={e => {

                            e.preventDefault();
                            e.stopPropagation();




                            if (item.title === "Logout") {
                                e.preventDefault();
                                this.props.setLogoutModal(true);
                            } else {
                                this.props.history.push(item.path);
                            }
                            document.dispatchEvent(new MouseEvent('click'));
                            //this.props.setSidebar(false)
                        }} as={Link} to={item.path}>{item.icon} <span
                            className={`menu-text`}>{item.title}</span></Nav.Link>
                    </Nav.Item>
                )
            }
        } else {
            return null;
        }
    }

    render() {
        return (
            <Navbar bg="light" expand="lg">
                {/*
                <div className={"show-sm"}>
                    <Nav.Link onClick={e => {
                        this.toggleSidebar();
                    }}><Menu {...iconStyle} /></Nav.Link>
                </div>
                */}
                <Navbar.Brand as={Link} to={this.props.auth.token._id ? "/app" : "/public/results"}>
                    <img src={LogoSimple} className={`main-logo`} alt={"FeisFWD"} />
                </Navbar.Brand>

                {/*
                <Navbar.Toggle aria-controls="none" onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                }} />
                */}






                {this.props.auth.token._id ?
                    <Nav className="ml-auto">
                        <Dropdown title="" id="basic-nav-dropdown">
                            <Dropdown.Toggle variant="transparent" id="dropdown-basic" data-tip={"Notifications"} className={`btn-flat note-btn`} >
                                {this.props.notifications.count > 0 ?
                                    <div className={"badge-count animated heartBeat"}>{this.props.notifications.count}</div>
                                    : null}
                                <Notifications className={`${this.props.notifications.count > 0 ? 'active' : ''}`} onClick={e => {
                                    if (this.props.notifications.count > -1) {
                                        this.props.markAllRead(this.props.auth.token._id);
                                    }
                                }}/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className={"notification-menu"}>
                                <Scrollbar noScrollX className={"note-scroller"}>
                                    <div className={"notification-header ex-pad"}>
                                        <Row>
                                            <Col xs={9}>
                                                <h5>Notifications</h5>
                                            </Col>
                                            <Col xs={3}>
                                                <div className={'text-right'}>
                                                    <Button variant={'link'} onClick={e => {


                                                        e.preventDefault();
                                                        e.stopPropagation();

                                                        this.props.history.push('/app/notifications');
                                                        document.dispatchEvent(new MouseEvent('click'));
                                                    }}>See All <ArrowForward /></Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>


                                {this.props.notifications.notifications.length > 0 ?
                                    this.props.notifications.notifications.map((n, i) => {
                                    return <Notification key={i} step={i} notification={n} />
                                })

                                : <div className={"no-notes"}>
                                        You have no notifications
                                    </div>}

                                <div className={"dropdown-footer"}>
                                    {/*<a href={"#"}>See All</a>*/}
                                </div>
                                </Scrollbar>
                            </Dropdown.Menu>
                        </Dropdown>

                        {this.props.onboarding ?
                            <Dropdown title="" id="basic-nav-dropdown">
                                <Dropdown.Toggle variant="transparent" id="dropdown-basic" data-tip={"Account"} className={`btn-flat`}>

                                    <Menu />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>

                                    <Dropdown.Item as={Link} to="/app/logout"
                                                   onClick={e => {
                                                       e.preventDefault();
                                                       this.props.setLogoutModal(true);
                                                   }}
                                    ><Power fontSize={"18px"}
                                                                                       className={"menu-icon"}/> Logout</Dropdown.Item>
                                </Dropdown.Menu>

                            </Dropdown>
                            :
                            <Dropdown title="" id="basic-nav-dropdown">
                                <Dropdown.Toggle variant="transparent" id="dropdown-basic" data-tip={"Account"} className={`btn-flat user-btn`}>
                                    {this.props.user.user.image ?
                                        <AccountCircle />
                                        : <Menu />
                                    }
                                </Dropdown.Toggle>
                                <Dropdown.Menu>

                                    {this.menu.map((m, i) => {
                                        if (m.type === 'title') {
                                            return this.renderHeader(m, i);
                                        } else {
                                            return this.renderItem(m, i);
                                        }
                                    })}

                                </Dropdown.Menu>

                            </Dropdown>
                        }
                        <Button variant={"flat"} className={"show-sm"} onClick={e => {
                            this.props.toggleSidebar();
                        }}><Menu color={"#ffffff"} /></Button>
                    </Nav>

                    : null }
            </Navbar>

        )
    }
}


const mapStateToProps = state => ({
    app: state.app,
    auth: state.auth,
    user: state.user,
    socket: state.socket,
    notifications: state.notifications
});


const mapDispatchToProps = dispatch => ({
    toggleSidebar: () => {
        dispatch(appActions.toggleSidebar());
    },
    send: (d) => {
        dispatch(socketActions.send(d));
    },
    getNotifications: token => {
        dispatch(notificationActions.getInitialNotifications(0, token));
    },
    markAllRead: (token) => {
        dispatch(notificationActions.markAllRead(token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));

class HeaderContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default HeaderContainer;
