/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import GeneralTile from '../../Common/GeneralTile';
import Row from "react-bootstrap/Row";
import EventTile from '../../Common/EventTile';
import placeholder from '../../../assets/images/placeholder-person.png';
import placeholder2 from '../../../assets/images/placeholder1.png';
import * as tableActions from '../../../store/actions/table';
import {NewspaperOutline} from 'react-ionicons';
import { PersonCircleOutline } from 'react-ionicons';
import EventModal from "../../Common/EventModal";
import { Camera } from "react-ionicons";
import EntryTile from '../../Common/EntryTile';
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { ArrowForward } from 'react-ionicons';
import PageLoader from "../../Common/PageLoader";

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            showModal: false,
            ev: {}
        }
    }
    dancers = [
        {
            title: 'Some Person',
            group: 'U15',
            entries: 4
        },
        {
            title: 'Some Person',
            group: 'U15',
            entries: 4
        },
        {
            title: 'Some Person',
            group: 'U15',
            entries: 4
        }
    ]

    events = [
        {
            title: 'Event 1',
            group: 'U15',
            date: 'Jan 21, 20201',
            registering: true,
            entries: 4
        },
        {
            title: 'Event 2',
            group: 'U15',
            date: 'Jan 7, 20201',
            registering: true,
            entries: 4
        },
        {
            title: 'Event 3',
            group: 'U15',
            date: 'March 13, 20201',
            registering: false,
            entries: 4
        }
    ]

    componentDidMount(){
        this.props.getHomePage(this.props.auth.token._id);
    }

    showModal = (event) => {
        this.setState({showModal: true, ev: event});
    }

    hideModal = () => {
        this.setState({showModal: false, ev: {}});
    }

    render() {
        console.log("TL", this.props.table.tableLoading);
        return (
            <div className={"page-container-slim"}>

                <PageLoader loaded={!this.props.table.tableLoading}>
                    <div className={"section"}>
                        <div className={"page-header"} >
                            <Row>
                                <Col>
                                    <h1><NewspaperOutline /> Upcoming Events</h1>
                                </Col>
                            </Row>
                        </div>

                        <div className={"tile-grid"}>
                            <Row>

                                {this.props.table.events.length === 0 ?
                                    <h4 className={"no-data"}>No Events Found</h4>
                                    : null}
                                {this.props.table.events.map((d, i) => {
                                    return <EventTile showModal={(event) => this.showModal(event)} {...d} data={d} key={i} placeholder={placeholder2} />
                                })}

                                {this.props.table.eventCount ?
                                    <EventTile more={this.props.table.eventCount} />
                                    : null}

                            </Row>
                        </div>
                    </div>

                    {this.props.user.user.roles ?
                        this.props.user.user.roles.indexOf('dancer') > -1 ||
                        this.props.user.user.roles.indexOf('parent') > -1 ||
                        this.props.user.user.roles.indexOf('teacher') > -1 ?
                            <div className={"section"}>
                                <div className={"page-header"} >
                                    <Row>
                                        <Col>

                                            <h1><PersonCircleOutline /> Dancer Profiles</h1>
                                        </Col>
                                        <Col>
                                            {this.props.user.user ?
                                                this.props.user.user.roles ?
                                                    this.props.user.user.roles.indexOf('teacher') > -1 || this.props.user.user.role === 'admin' ?
                                                        <Col>
                                                            <div className={'text-right'}>
                                                                <Button variant={'link'} onClick={e => {
                                                                    this.props.history.push('/app/dancers');
                                                                }}>See All <ArrowForward /></Button>
                                                            </div>
                                                        </Col>
                                                        : null
                                                    : null
                                                : null}
                                        </Col>
                                    </Row>

                                </div>

                                <div className={"tile-grid"}>
                                    <div>
                                        {this.props.table.dancers.map((d, i) => {
                                            return <GeneralTile index={i + this.props.table.events.length} collection={"dancers"} {...d} key={i} data={d} placeholder={placeholder} />
                                        })}
                                        <GeneralTile new={true} index={this.props.table.events.length + this.props.table.dancers.length} onAddNew={e => this.props.history.push('/app/dancers/new')} />
                                    </div>
                                </div>
                            </div>
                            : null
                        : null}

                    {this.props.table.entries ?

                        <div className={"section"}>
                            <div className={"page-header"} >
                                <Row>
                                    <Col>

                                        <h1><Camera />  My Entries</h1>
                                    </Col>
                                    <Col>
                                        {this.props.user.user ?
                                            this.props.user.user.roles ?
                                                this.props.user.user.roles.indexOf('teacher') > -1 || this.props.user.user.role === 'admin' ?
                                                    <Col>
                                                        <div className={'text-right'}>
                                                            <Button variant={'link'} onClick={e => {
                                                                this.props.history.push('/app/entries');
                                                            }}>See All <ArrowForward /></Button>
                                                        </div>
                                                    </Col>
                                                    : null
                                                : null
                                            : null}
                                    </Col>
                                </Row>

                            </div>


                            <div className={"tile-grid"}>
                                <Row>

                                    {this.props.table.entries.length === 0 ?
                                        <h4 className={"no-data"}>No Entries Found</h4>
                                        : null}
                                    {this.props.table.entries.map((d, i) => {
                                        return <EntryTile {...d} data={d} key={i} placeholder={placeholder2} />
                                    })}



                                </Row>
                            </div>
                        </div>
                        : null}

                </PageLoader>
                <EventModal event={this.state.ev} show={this.state.showModal} hideModal={this.hideModal} />




            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    getHomePage: (token) => {
        dispatch(tableActions.getHomePage(token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));

class HomeContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected/>
            </div>
        )
    }
}

export default HomeContainer;
