import React from 'react';

import PDF from "./PDFComponent";
import { parseInt } from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as tableActions from '../../../store/actions/table';


const PDFPage = (props) => {

  const events = props.events;
  if (!events) return null;
  const rounds = events.competition.competition.grade.rounds;
  const round = events.competition.template.rounds[1];    // recall, placed, and final scores/rank show on every record, just grab the first round
  var scores = round.scores[0];               // This is not judge specific so just grab the first one
  const teams = events.competition.competition.grade.type.toLowerCase().includes('team');

  let labels = ['RECALL JUDGE SHEET'];


  const competitionName = + events.competition.competition.code + " | " +
    events.competition.competition.grade?.title + " | " +
    events.competition.competition.rowTitle + " | " +
    events.competition.competition.columnTitle

  const titles = [
    props.events.competition.competition.grade.event.name,
    competitionName
  ]

  let subtitles = [];
  subtitles.push(`Total Competitors: ${scores.length}`)

  const headers = teams ?
  [
    "Team",
    "R3 Score"
  ]
  :
  [
    "Dancer",
    "R3 Score"
  ];

  var content = scores.filter(item => item['recall']).map((score, i) => {
    let rel = events.competition.entries.filter(item => item._id === score.entryCompetition);
    let s = {};

    if (rel.length > 0) {
      s = rel[0];
    }

    const nameString = teams ? s.dancer?.name : s.dancer?.name + " " + s.dancer?.lname;


  return [
    s.dancerNumber ? s.dancerNumber : 0,
    ''
  ]
});

  subtitles.push(`Total Recalls: ${content.length}`)

  content = content.sort((a, b) => {

    return a[0] - b[0]
  })

  // break content into chunks of 17 records (17 records fit per page)
  let chunks = [];
  var i,j, temporary, chunk2 = 15;
  for (i = 0,j = content.length; i < j; i += chunk2) {
      temporary = content.slice(i, i + chunk2);
      chunks.push(temporary);
  }

  let tables = [];

  for(var chunk of chunks) { 
      tables.push({
          headers,
          content: chunk
      });
  }

  // Add section for Judge to print name
  subtitles.push(" ");
  subtitles.push("JUDGE NAME __________________________________________________________________");

  const pdfData = {
    tables: tables,
    titles: titles,
    image: props.events.competition.competition.grade.event.image.path,
    labels: labels,
    subtitles: subtitles
  }

  return (
    <div style={{ height: "100vh" }}>

      <PDF pdfData={pdfData} />
    </div>
  );
}

const mapStateToProps = state => ({
  auth: state.auth,
  table: state.table,
  user: state.user,
  events: state.events
});


const mapDispatchToProps = dispatch => ({
  getTableData: (view, skip, limit, sort, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
  },
  getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
  },
});


const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(PDFPage));

class PDFPageContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    )
  }
}

export default PDFPageContainer;
