/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 3/28/2021
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import * as tableActions from '../../../store/actions/table';
import Button from "react-bootstrap/Button";
import { ArrowBack } from "react-ionicons";
import Form from "react-bootstrap/Form";
import { ArrowForward } from "react-ionicons";
import Select from "../../Common/Select";
import {Scrollbar} from "react-scrollbars-custom";
import Payment from "./Transactions/Payment";

class Transactions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            title: '',
            sort: null,
            filter: null,
            showModal: false,
            data: {},
            search: ''
        }
    }

    maxDepth = 0;

    componentDidMount(props){
        let page = 1;
        let itemsPerPage = 10;
        let skip = (itemsPerPage * (page - 1));

        //console.log("Getting items for", view, this.props.location.pathname);
        //if (this.props.table.view !== view) {


        this.props.getTableDataWithQuery("transactions", skip, itemsPerPage, {createdAt: -1}, {event: this.props.match.params.id}, this.props.auth.token._id, 'add');
        //}
        //}
    }

    componentDidUpdate(props, state) {

        if (state.sort !== this.state.sort || state.filter !== this.state.filter) {
            let query = {event: this.props.match.params.id};
            let sort = {code: 1};
            if (this.state.sort) {
                sort = this.state.sort.sort;
            }

            if (this.state.filter) {
                query.filter = this.state.filter.id;
            }

            if (this.state.search) {
                query.search = this.state.search;
            }

            this.props.getTableDataWithQuery("transactions", 0, 10, sort, query, this.props.auth.token._id, 'add');


        }

    }

    dragEnd = (res) => {
        //console.log("DROPPED ", res);
    }

    remove = (id) => {
        this.props.deleteItem(this.props.table.view, id, this.props.auth.token._id);
    }

    edit = (id) => {
        if (this.props.table.data.filter(d => d._id === id).length > 0) {
            this.props.handleFormModalWithData(this.props.table.data.filter(d => d._id === id)[0]);
        }
    }

    save = (e, items) => {
        let page = this.props.match.params.page ? this.props.match.params.page : 1;
        this.props.reorder(this.props.table.view, page, items, this.props.auth.token._id);
    }

    markTop = (e, item) => {
        //console.log("Marking", item, e.target);
        let filteredItems = this.props.table.data.filter(d => d._id === item);
        if (filteredItems.length > 0) {
            let item = filteredItems[0];
            item.topStory = !item.topStory;
            this.props.updateTableItem("news", item, this.props.auth.token._id);
        }


    }

    loadMore = () => {
        let query = {event: this.props.match.params.id};
        let sort = {createdAt: -1};
        if (this.state.sort) {
            sort = this.state.sort.sort;
        }

        if (this.state.filter) {
            query.filter = this.state.filter.id;
        }

        if (this.state.search) {
            query.search = this.state.search;
        }

        this.props.getTableDataWithQuery("transactions", this.props.table.data.length, 10, sort, query, this.props.auth.token._id, 'update');

    }

    handleAboutToReachBottom = () => {
        if (this.props.table.hasMore) {
            this.loadMore();
        }
    }
    handleUpdate = (values) => {
        const { scrollTop, scrollHeight, clientHeight } = values;
        const pad = 100; // 100px of the bottom
        // t will be greater than 1 if we are about to reach the bottom
        const t = ((scrollTop + pad) / (scrollHeight - clientHeight));
        //console.log(t, this.props.table.hasMore);
        if (t > 1) this.handleAboutToReachBottom();
    }

    search = () => {
        let query = {event: this.props.match.params.id};
        let sort = {createdAt: -1};
        if (this.state.sort) {
            sort = this.state.sort.sort;
        }

        if (this.state.filter) {
            query.filter = this.state.filter.id;
        }

        if (this.state.search) {
            query.search = this.state.search;
        }

        this.props.getTableDataWithQuery("transactions", 0, 10, sort, query, this.props.auth.token._id, 'add');

    }

    filters = [
        {
            id: 'all',
            name: 'All',
        },
        {
            id: 'completed',
            name: 'Completed',
        },
        {
            id: 'incompleted',
            name: 'Incomplete',
        }
    ]

    sort = [

        {
            id: 'compdesc',
            name: 'Newest First',
            sort: {createdAt: -1}
        },
        {
            id: 'compasc',
            name: 'Oldest First',
            sort: {createdAt: 1}
        }
    ]

    render() {
        return (
            <div>
                <div className={'page-header'}>
                    <Row>
                        <Col sm={2} className={"pt-3"}>
                            <h1>
                                {this.props.location.pathname.split("/").length > 3 ?
                                    <Button variant={"flat btn-round"} onClick={e => {

                                        this.props.history.push(`/app/event-dashboard/${this.props.match.params.id}/stats`);
                                    }}>
                                        <ArrowBack />
                                    </Button>
                                    : null}
                            </h1>
                        </Col>
                        <Col sm={4}>
                            <div className={"field-container"}>
                                <label>Search</label>
                                <Form.Control
                                    value={this.state.search}
                                    onChange={e => this.setState({search: e.target.value})}
                                    onKeyDown={e => {
                                        if (e.key === 'Enter') {
                                            this.search();
                                        }
                                    }}
                                />
                                <Button variant={"primary"} className={"btn-search"} onClick={this.search}><ArrowForward /></Button>
                            </div>

                        </Col>
                        <Col sm={3}>
                            <Select
                                disableTyping={true}
                                items={this.filters}
                                value={this.state.filter || this.filters[0]}
                                itemValue={"id"}
                                onChange={val => {
                                    this.setState({filter: val});
                                }}
                                displayValue={"name"}
                                label={"Filter"}

                            />
                        </Col>
                        <Col sm={3}>
                            <Select
                                disableTyping={true}
                                items={this.sort}
                                value={this.state.sort || this.sort[0]}
                                itemValue={"id"}
                                onChange={val => {
                                    this.setState({sort: val});
                                }}
                                displayValue={"name"}
                                label={"Sort"}

                            />
                        </Col>
                    </Row>
                </div>

                {this.props.table.data ?

                    <Scrollbar className={'feis-page-scroller'} noScrollX={true} onUpdate={this.handleUpdate}
                               style={{height: 'calc(100vh - 240px)'}}>
                        {this.props.table.view === "transactions" && this.props.table.data ?
                            this.props.table.data.map ?
                                this.props.table.data.map((d, i) => {
                                    return <Payment data={d} key={i} />
                                })
                                : null
                            : null
                        }
                        {this.props.table.hasMore ?
                            <div className={"text-center"}>
                                <Button onClick={this.loadMore}>Load More</Button>
                            </div>

                            : null}

                    </Scrollbar>
                    : null}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Transactions));

class TransactionsContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default TransactionsContainer;
