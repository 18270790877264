/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as tableActions from '../../../../store/actions/table';
import LeaderItem from './LeaderItem';
import Scrollbar from "react-scrollbars-custom";


class Leaderboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    componentDidMount(props) {

    }

    componentDidUpdate(props, state) {

    }

    render() {
        return (
            <div className={"leaderboard-container"}>
                <Scrollbar className={"feis-sidebar-scroll feisfwd-leader-scroll"} noScrollX style={{height: `calc(100vh - 140px)`}}>
                {this.props.events.competition ?
                    this.props.events.competition.entries ?
                        this.props.events.competition.entries.map ?
                            this.props.events.competition.entries.map((comp, i) => {
                                return (
                                    <LeaderItem r={this.props.match.params.round} data={comp} key={i} items={this.props.events.competition.entries.length} />
                                )
                            })
                            : null
                        : null
                    : null}


                </Scrollbar>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    events: state.events
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Leaderboard));

class LeaderboardContainer extends React.Component {
    render() {
        return (

                <Connected {...this.props} />

        )
    }
}

export default LeaderboardContainer;