/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */

import React from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as tableActions from '../../../store/actions/table';
import Regions from './Regions/Regions';
import FormModal from '../../Common/FormModal';
import Fab from '../../Common/FAB';
import * as singleActions from '../../../store/actions/single';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { ContextProvider } from 'react-sortly';
import Users from './Users/Users';
import Schools from '../Schools/Schools';
import Bound from '../../Common/Bound';
import Import from './Import';
import ParseImport from './ParseImport';
import SingleUser from './Users/SingleUser';
import ScoringList from './Scoring/ScoringList';
import EditScoring from './Scoring/EditScoring';
import AdminSingleEntry from '../Entries/AdminSingleEntry';
import AdminEntries from './AdminEntries';
import AdminEditEntry from './Entries/AdminEditEntry';

class AdminWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showTheme: false,
      showFormModal: false,
      sidebarOpen: false
    };
  }

  componentDidMount(props) {}

  componentDidUpdate(props, state) {}

  handleFormModal = (show) => {
    this.setState({ showFormModal: show });
  };

  handleFormModalWithData = (d) => {
    this.setState({ showFormModal: true });
    this.props.setSingle(d);
  };

  handleNewItem = (e) => {
    e.preventDefault();
    this.handleFormModalWithData({});
  };

  render() {
    return (
      <div>
        <DndProvider backend={HTML5Backend}>
          <ContextProvider>
            <FormModal show={this.state.showFormModal} handleFormModal={this.handleFormModal} />
            <Bound>
              <Route
                path={`/app/admin/regions`}
                render={(props) => {
                  return <Regions />;
                }}
              />
            </Bound>

            <Route
              path={`/app/admin/users/:id/:subview`}
              exact={true}
              render={(props) => {
                return (
                  <Bound>
                    <SingleUser {...props} />
                  </Bound>
                );
              }}
            />

            <Route
              path={`/app/admin/users/:id`}
              exact={true}
              render={(props) => {
                return <Redirect to={`/app/admin/users/${props.match.params.id}/account`} />;
              }}
            />

            <Route
              path={`/app/admin/users/:id/entry`}
              exact={true}
              render={(props) => {
                return (
                  <Bound>
                    <AdminEntries {...props} />
                  </Bound>
                );
              }}
            />

            <Route
              path={`/app/admin/users/:userId/entry/:id/edit`}
              exact={true}
              render={(props) => {
                return (
                  <Bound>
                    <AdminEditEntry {...props} />
                  </Bound>
                );
              }}
            />

            <Route
              path={`/app/admin/users/:userId/entry/:id`}
              exact={true}
              render={(props) => {
                return (
                  <Bound>
                    <AdminSingleEntry {...props} />
                  </Bound>
                );
              }}
            />

            <Route
              path={`/app/admin/users`}
              exact={true}
              render={(props) => {
                return (
                  <Bound>
                    <Users />
                  </Bound>
                );
              }}
            />

            <Route
              path={`/app/admin/import`}
              render={(props) => {
                return (
                  <Bound>
                    <Import />
                  </Bound>
                );
              }}
            />

            <Route
              path={`/app/admin/parseimport`}
              render={(props) => {
                return (
                  <Bound>
                    <ParseImport />
                  </Bound>
                );
              }}
            />

            <Route
              path={`/app/admin/schools`}
              exact={true}
              render={(props) => {
                return (
                  <Bound>
                    <Schools {...props} />
                  </Bound>
                );
              }}
            />

            <Route
              path={`/app/admin/scoring`}
              exact={true}
              render={(props) => {
                return (
                  <Bound>
                    <ScoringList {...props} />
                  </Bound>
                );
              }}
            />

            <Route
              path={`/app/admin/scoring/edit/:id`}
              exact={true}
              render={(props) => {
                return (
                  <Bound>
                    <EditScoring {...props} />
                  </Bound>
                );
              }}
            />

            <Bound>
              <Fab handleNewItem={this.handleNewItem} />
            </Bound>
          </ContextProvider>
        </DndProvider>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  table: state.table
});

const mapDispatchToProps = (dispatch) => ({
  getTableData: (view, skip, limit, sort, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
  },
  getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
  },
  setSingle: (d) => {
    dispatch(singleActions.setSingleEntry(d));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminWrapper));

class AdminWrapperContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    );
  }
}

export default AdminWrapperContainer;
