/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */

import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as tableActions from '../../../store/actions/table';
import Nav from 'react-bootstrap/Nav';
import * as singleActions from "../../../store/actions/single";
import Bound from '../../Common/Bound';

class ResultTypes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    menu = [
        {
            title: 'Results',
            path: '/app/results',

        },
        {
            title: 'My Results',
            path: '/app/results/dancers',
            roles: ['parent', 'dancer']
        },
        {
            title: 'School Results',
            path: '/app/results/schools',
            roles: ['teacher']

        }
    ]

    loadData = () => {

    }

    componentDidMount(props) {
        this.loadData();
    }

    componentDidUpdate(props, state) {
        if (props.location.pathname !== this.props.location.pathname) {
            this.loadData();
        }
    }

    renderItem = (item, i) => {
        let shouldRender = true;
        if (item.role) {
            if (this.props.user.user) {
                if (item.role === 'admin' && this.props.user.user.role !== 'admin') {
                    shouldRender = false;
                }
            } else {
                shouldRender = false;
            }
        }

        if (item.roles) {
            let matchRoles = 0;
            item.roles.map((r) => {
                if(this.props.user.user) {
                    if (this.props.user.user.roles) {
                        this.props.user.user.roles.map((ur) => {
                            if (r === ur) {
                                matchRoles += 1;
                            }

                            return ur;
                        })
                    }
                }

                return r;
            })

            if (matchRoles === 0) {
                shouldRender = false;
            }
        }

        if (shouldRender) {
            return (
                <Nav.Link key={i} to={item.path} as={Link}
                          className={`tab tab-${i} ${i === 0 ? 'active' : ''}`}>{item.title}</Nav.Link>
            )
        }else{
            return null;
        }
    }

    renderContent = () => {
        return (
            <Bound>
                <div className={`type-tile`} onClick={e => {
                    this.props.history.push(`/app/results/event/${this.props.match.params.id}/grades`)
                }}>

                    <h2>Grades</h2>


                </div>

                <div className={`type-tile`} onClick={e => {
                    this.props.history.push(`/app/results/event/${this.props.match.params.id}/teams`)
                }}>

                    <h2>Teams</h2>


                </div>


                <div className={"type-tile"} onClick={e => {
                    this.props.history.push(`/app/results/event/${this.props.match.params.id}/championship`)
                }}>
                    <h2>Championship</h2>
                </div>
                <div className={"type-tile"} onClick={e => {
                    this.props.history.push(`/app/results/event/${this.props.match.params.id}/championshipspecial`)
                }}>
                    <h2>Championship Specials</h2>
                </div>
            </Bound>

        );
    }


    render() {
        return (
            <div className={'page-container elevated rounded'}>
                <div className={"tab-container"}>
                    <div className={"tab-row"}>
                        {this.menu.map((item, i) => {
                            return this.renderItem(item, i)
                        })}
                        <div className={"tab-highlight"} />
                        <div className={"tab-highlight-track"} />
                    </div>

                </div>
                <div className={"tab-content ex-pad"}>

                    {this.renderContent()}

                </div>


            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    user: state.user,
    single: state.single
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
    getSingle: (view, id, token) => {
        dispatch(singleActions.getSingle(view, id, token));
    },
    loadOnboarding: (token) => {
        dispatch(singleActions.loadOnboarding(token));
    }
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(withRouter(ResultTypes));

class ResultTypesContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default ResultTypesContainer;