import React from 'react';

import PDF from "./PDFComponent";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as tableActions from '../../../store/actions/table';
import PublicPDF from "./PublicPDFComponent";


const PDFPage = (props) => {

    const roundNumber = props.match.params.round ? parseInt(props.match.params.round) : 4;
    const judgeNumber = props.match.params.judge ? parseInt(props.match.params.judge) - 1 : 0;

    const events = props.events;
    if (!events) return null;
    const event = events.event;
    const round = events.competition.template.rounds["1"];
    var scores = round.scores[judgeNumber];


    let grade = events.competition.competition.grade;
    let comp = events.competition.competition;

    let ageTitle = '';

    for(var col of grade.columns){
        if (col.id.toString() === comp.column) {
            ageTitle = col.title;
        }
    }

    const competitionName = events.competition.competition.grade.title + " " +
        events.competition.competition.rowTitle + " | " +
        ageTitle;
    const code = events.competition.competition.code;

    const titles = [
        event.name,
        code,
        competitionName
    ]

    const headers = [
        "Rank",
        "Dancer #",
        "Name",
        "School",

    ]

    //console.log("Scores", scores);
    let rankField;
    if (roundNumber < 4) {
        rankField = "round" + roundNumber + "Rank";
    } else {
        rankField = "finalRank";
    }

    scores = scores.sort((a, b) => {
        return a[rankField] - b[rankField];
    });

    console.log("Scores", scores);

    if (roundNumber === 4) {
        scores = scores.filter(item => item.placed);
    }
    console.log("Scores", scores);

    var content = [];

    let chunks = [];
    var i,j, temporary, chunk2 = 20;
    for (i = 0,j = scores.length; i < j; i += chunk2) {
        temporary = scores.slice(i, i + chunk2);
        chunks.push(temporary);
        // do whatever
    }

    //console.log("tem", chunks, scores);


    let tables = [];

    for(var chunk of chunks) {
        var content2 = chunk.map((score, i) => {
            let rel = events.competition.entries.filter(item => item._id === score.entryCompetition);
            //console.log("Rel", rel, events.competition.entries[i]);
            let s = {};

            if (rel.length > 0) {
                s = rel[0];
            }
            return [
                score[rankField],
                s.dancerNumber ? s.dancerNumber : 0,
                s.dancer ? s.dancer.name + " " + (s.dancer.lname ? s.dancer.lname : "") : "",
                s.dancer ? s.dancer.school.name : "",

            ]
        });

        tables.push({
            headers,
            content: content2
        });
    }

    const table = {
        headers,
        content
    }

    let judgeNames;
    if (roundNumber === 4) {
        judgeNames = props.events.competition.template.rounds["1"].judges.filter(item => item.name).map(item => item.name + " " + item.lname);
    } else {
        judgeNames = [props.events.competition.template.rounds["1"].judges[judgeNumber].name + " " + 
        props.events.competition.template.rounds["1"].judges[judgeNumber].lname];
    }
    const pdfData = { 
        table,
        tables,
        titles,
        judges: judgeNames,
        image: props.events.competition.competition.grade.event.image.path,
    }

    return (
        <div style={{ height: "100vh" }}>

            <PublicPDF pdfData={pdfData} />
        </div>
    );
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    user: state.user,
    events: state.events
});


const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
});


const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(PDFPage));

class PDFPageContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default PDFPageContainer;
