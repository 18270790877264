/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 01/03/2020
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Autocomplete from 'react-autocomplete';
import urls from '../../utils/urls';
import placeholder from '../../assets/images/placeholder1.png';
import Bound from './Bound';
import Dropdown from 'react-bootstrap/Dropdown';
import { Scrollbar } from 'react-scrollbars-custom';

const menuStyle = {
  boxShadow: '0 5px 8px rgba(0,0,0,.3)',
  background: 'rgba(255, 255, 255, 0.9)',
  padding: '2px 0',
  fontSize: '90%',
  position: 'absolute',
  overflow: 'auto',
  maxHeight: '50%', // TODO: don't cheat, let it flow to the bottom
  minWidth: '220px',
  marginLeft: '-36px',
  marginTop: '14px',
  borderRadius: '10px'
};

const getPropValue = (sourceObject, dotNotationPath) => {
  let returnData = sourceObject;

  dotNotationPath.split('.').forEach((subPath) => {
    returnData = returnData[subPath] || `Property ${subPath} not found`;
  });

  return returnData;
};

class Select extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuOpen: false,
      value: ''
    };
  }

  render() {
    if (this.props.items) {
      return (
        <Bound>
          <div className={'object-picker-container-top'}>
            {this.props.label ? <label>{this.props.label}</label> : null}

            <Autocomplete
              getItemValue={(item) => getPropValue(item, this.props.itemValue)}
              wrapperStyle={{ display: 'block' }}
              className={'object-picker-parent'}
              items={
                this.props.selectedItems
                  ? this.props.items.filter(
                      (item) => this.props.selectedItems.indexOf(item._id) === -1
                    )
                  : this.props.items
              }
              onMenuVisibilityChange={(open) => this.setState({ menuOpen: open })}
              selectOnBlur={true}
              renderItem={(item, isHighlighted, ind) => (
                <div
                  key={item._id || getPropValue(item, this.props.itemValue)}
                  className={`object-picker-menu-item ${isHighlighted ? 'hovered' : ''}`}
                  onClick={(e) => this.setState({ menuOpen: false })}>
                  <div
                    className={'object-menu-item-image'}
                    style={{
                      background: item
                        ? item.image
                          ? `url(${urls.getS3Url(
                              item.image.thumbXs ||
                                item.image.thumbSm ||
                                item.image.thumb ||
                                item.image.path
                            )})`
                          : `url(${placeholder})`
                        : `url(${placeholder})`
                    }}
                  />
                  <div className={'object-menu-item-label'}>
                    {this.props.displayJoin
                      ? item[this.props.displayJoinValue].join(' > ')
                      : this.props.displayValue === 'index'
                      ? ind
                      : item[this.props.displayValue]}
                  </div>
                </div>
              )}
              renderInput={(props) => {
                if (this.props.items) {
                  let relevant = [];
                  let ind = 0;
                  if (this.props.itemValue && this.props.value) {
                    relevant = this.props.items.filter((i, index) => {
                      if (
                        getPropValue(i, this.props.itemValue) ===
                        getPropValue(this.props.value, this.props.itemValue)
                      ) {
                        ind = index;
                        return true;
                      } else {
                        return false;
                      }
                    });
                  }
                  return (
                    <div className={'object-picker-container'}>
                      <div className={'object-picker-image'}>
                        <div
                          className={'object-image'}
                          style={{
                            background:
                              relevant.length > 0
                                ? relevant[0].image
                                  ? `url(${urls.getS3Url(
                                      relevant[0].image.thumbXs ||
                                        relevant[0].image.thumbSm ||
                                        relevant[0].image.thumb ||
                                        relevant[0].image.path
                                    )})`
                                  : `url(${placeholder})`
                                : `url(${placeholder})`
                          }}
                        />
                      </div>
                      <div
                        className={'object-picker-input'}
                        onClick={(e) => this.setState({ menuOpen: true })}>
                        {this.props.disableTyping ? (
                          <Dropdown className={'object-picker-menu-alt'}>
                            <Dropdown.Toggle
                              variant=""
                              className={'non-typing-input'}
                              id="dropdown-basic">
                              {relevant.length > 0
                                ? this.props.displayValue === 'index'
                                  ? ind + 1
                                  : relevant[0][this.props.displayValue]
                                : 'No selection'}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Scrollbar
                                className={'re'}
                                noScrollX={true}
                                onUpdate={this.handleUpdate}
                                style={{ height: '500px', padding: '0px' }}>
                                {this.props.items
                                  ? this.props.items.map((item, i) => {
                                      return (
                                        <Dropdown.Item
                                          key={i}
                                          href="#t"
                                          className={'object-picker-menu-item'}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({ menuOpen: false });
                                            this.props.onChange(item);
                                          }}>
                                          {this.props.displayJoin
                                            ? item[this.props.displayJoinValue].join(' > ')
                                            : this.props.displayValue === 'index'
                                            ? i + 1
                                            : item[this.props.displayValue]}
                                        </Dropdown.Item>
                                      );
                                    })
                                  : null}
                              </Scrollbar>
                            </Dropdown.Menu>
                          </Dropdown>
                        ) : (
                          <input
                            onClick={(e) => this.setState({ menuOpen: true })}
                            {...props}
                            onChange={(e) => {
                              if (!this.props.disableTyping) {
                                this.setState({ value: e.target.value });
                              }
                            }}
                            value={
                              !this.props.disableTyping
                                ? this.state.value
                                  ? this.state.value
                                  : this.state.menuOpen
                                  ? this.state.value
                                  : relevant.length > 0
                                  ? relevant[0][this.props.displayValue]
                                  : ''
                                : relevant[0]
                                ? relevant[0][this.props.displayValue]
                                : ''
                            }
                          />
                        )}
                      </div>
                      {/*this.props.showDelete ?
                                                <Button variant={'flat'} className={"btn-small-padding"} onClick={() => this.props.onDelete(this.props.value)}><MdTrash fontSize={'18px'} {...{color: 'rgba(0,0,0,.5)'}} /></Button>
                                                : null*/}
                    </div>
                  );
                }
              }}
              shouldItemRender={(item, value) => {
                if (item[this.props.displayValue]) {
                  return (
                    item[this.props.displayValue]
                      .toLowerCase()
                      .indexOf(this.state.value.toLowerCase()) > -1
                  );
                } else {
                  return ''.toLowerCase().indexOf(this.state.value.toLowerCase()) > -1;
                }
              }}
              renderMenu={(items, value, style) => {
                return (
                  <div
                    className={`object-picker-menu ${this.state.menuOpen ? 'open' : ''}`}
                    style={{ ...style, ...menuStyle }}>
                    <Scrollbar className={'re'} style={{ height: '300px' }} noScrollX={true}>
                      {items}
                    </Scrollbar>
                  </div>
                );
              }}
              autoHighlight={true}
              value={this.state.value}
              onChange={(e) => {
                this.props.onChange(e.target.value);
                this.setState({ value: e.target.value });
              }}
              onSelect={(val) => {
                let relevant = [];
                if (this.props.itemValue) {
                  relevant = this.props.items.filter(
                    (i) => getPropValue(i, this.props.itemValue) === val
                  );
                }
                if (relevant.length > 0) {
                  this.props.onChange(relevant[0]);
                }
                this.setState({ value: '' });
              }}
            />
          </div>
        </Bound>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Select));

class SelectContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    );
  }
}

export default SelectContainer;
