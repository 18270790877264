/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 6/7/2020
 */

const defaultState = {
  single: {},
  dancer: {},
  school: {},
  event: {},
  competition: {},
  entry: {},
  scoring: [],
  users: [],
  schools: [],
  regions: [],
  organizations: [],
  subscriptions: [],
  cards: [],
  loading: false,
  result: {}
};

const single = (state = defaultState, action) => {
  switch (action.type) {
    case 'GOT_FULL_RESULT':
      return {
        ...state,
        result: action.payload
      };
    case 'CANCEL_UPLOAD':
      return {
        ...state,
        single: {
          ...state.single,
          competitions: state.single.competitions.map((c) => {
            if (c._id === action.payload.id) {
              return {
                ...c,
                [action.payload.round + 'Loading']: false,
                [action.payload.round + 'Progress']: false
              };
            } else {
              return c;
            }
          })
        }
      };
    case 'SET_SINGLE_ENTRY_LOADING':
      return {
        ...state,
        loading: action.payload
      };
    case 'ADDED_CARD':
      return {
        ...state,
        cards: state.cards.concat(action.payload.data)
      };
    case 'GOT_ONBOARDING_NO_SINGLE':
      return {
        ...state,
        ...action.payload
      };
    case 'GOT_ONBOARDING':
      return {
        ...state,
        ...action.payload,
        single: { step: 0 }
      };
    case 'LOGOUT':
      return defaultState;
    case 'GOT_REMOTE_DATA':
      return {
        ...state,
        [action.payload.info.collection]: action.payload.data.data
      };
    case 'GOT_SINGLE_WITH_MUSICDATA':
      return {
        ...state,
        single: action.payload.data.data
      };
    case 'UPDATE_FIELD':
      return {
        ...state,
        single: {
          ...state.single,
          [action.payload.field]: action.payload.val
        }
      };
    case 'UPDATED_ITEM':
      return {
        ...state,
        single: {
          ...state.single,
          ...action.payload.data
        }
      };
    case 'GOT_REC_SINGLE':
      return {
        ...state,
        ...action.payload,
        single: action.payload[action.payload.k],
        loading: false
      };
    case 'SET_UPLOADING':
      return {
        ...state,
        single: {
          ...state.single,
          competitions: state.single.competitions.map((c) => {
            if (c._id === action.payload.id) {
              return {
                ...c,
                uploading: true
              };
            } else {
              return c;
            }
          })
        }
      };
    case 'DELETED_FIELD_NESTED_SINGLE':
      return {
        ...state,
        single: {
          ...state.single,
          competitions: state.single.competitions.map((c) => {
            if (c._id === action.payload.fieldDeleted.id) {
              let cop = c;
              delete cop[action.payload.fieldDeleted.field];
              return {
                ...cop,
                uploading: false
              };
            } else {
              return c;
            }
          })
        }
      };
    case 'UPDATED_NESTED_PROGRESS':
      return {
        ...state,
        single: {
          ...state.single,
          competitions: state.single.competitions.map((c) => {
            if (c._id === action.payload.id) {
              return {
                ...c,
                ['round' + action.payload.round.toString() + 'Loading']: true,
                ['round' + action.payload.round.toString() + 'Progress']: action.payload.data
              };
            } else {
              return c;
            }
          })
        }
      };
    case 'UPDATED_NESTED_SINGLE':
      if (state.single._id === action.payload._id) {
        return {
          ...state,
          single: {
            ...state.single,
            competitions: state.single.competitions
              ? state.single.competitions.map((c) => {
                  if (c._id === action.payload.compId) {
                    return {
                      ...c,
                      [action.payload.key]: action.payload.data,
                      ['round' + action.payload.round.toString() + 'Loading']: false,
                      ['round' + action.payload.round.toString() + 'Progress']: 0
                    };
                  } else {
                    return c;
                  }
                })
              : []
          }
        };
      } else {
        return state;
      }
    case 'SET_SINGLE_ENTRY':
      return {
        ...state,
        single: {
          ...state.single,
          ...action.payload
        },
        loading: false
      };
    case 'SET_SINGLE_ENTRY_HARD':
      return {
        ...state,
        single: {
          ...action.payload
        },
        loading: false
      };
    default:
      return state;
  }
};

export default single;
