/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 11/26/2020
 */


export function toggleSidebar() {
    return dispatch => {
        dispatch({
            type: 'TOGGLE_SIDEBAR'
        })
    }
}