import { combineReducers } from 'redux'
import auth from './auth'
import user from './user';
import table from './table';
import single from './single';
import files from './files';
import app from './app';
import music from './music';
import player from './player';
import socket from './socket';
import notifications from './notifications';
import events from './events';
import publicState from './public';
import reports from './reports';
import tabulator from './tabulator';


export default combineReducers({
    app,
    auth,
    user,
    table,
    single,
    music,
    files,
    player,
    socket,
    events,
    notifications,
    publicState,
    reports,
    tabulator
})
