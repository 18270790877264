/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 3/28/2021
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import * as tableActions from '../../../../store/actions/table';
import urls from "../../../../utils/urls";
import placeholder from "../../../../assets/images/placeholder-person.png";
import dayjs from "dayjs";

class Dancer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            preview: ''
        }
    }

    componentDidMount(props) {
        if (this.props.data.dancer.image) {
            this.setState({preview: urls.getS3Url(this.props.data.dancer.image.thumb || this.props.data.dancer.image.path)})
        }
    }

    componentDidUpdate(props, state) {

    }

    render() {
        let {data} = this.props;
        return data.dancer ? (
            <div className={'plan'}>
                <div>
                    <Row>
                        <Col md={9} className={""}>
                        <span className={'row-name article-row'}>
                            <div className={"news-item-img img-circle"} style={{background: this.state.preview ? `url(${this.state.preview}), linear-gradient(0deg, rgba(0,0,0,.6), rgba(0,0,0,.4), rgba(0,0,0,.2), transparent)` : `url(${placeholder}), linear-gradient(0deg, rgba(0,0,0,.6), rgba(0,0,0,.4), rgba(0,0,0,.2), transparent)`}}></div>
                            <div className={"row-title"}>
                                {data.dancerNumber ? `#${data.dancerNumber} - ` : ``}
                                {data.dancer.name ? data.dancer.name : ''}&nbsp;
                                {data.dancer.lname ? data.dancer.lname : ''}&nbsp;
                            </div>
                            <div className={"row-content overflow-hidden"}>
                                {data.content ?
                                    data.content
                                    :
                                    data.dancer.school ? data.dancer.school.name : 'N/A'
                                }
                            </div>

                        </span>
                        </Col>
                        <Col md={3} className={"text-right text-hint"}>
                            {dayjs(data.createdAt).format("MMM DD YYYY @ hh:mm A")}
                        </Col>


                    </Row>
                </div>
            </div>
        ) : null;
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Dancer));

class DancerContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default DancerContainer;
