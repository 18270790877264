/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 11/1/2021
 */

const defaultState = {
    competition: {},
    entries: [],
    template: {}
};

const tabulator = (state = defaultState, action) => {
    switch (action.type) {
        default:
            return {
                ...state
            };
    }
}

export default tabulator;
