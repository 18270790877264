/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 3/28/2021
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import * as tableActions from '../../../store/actions/table';
import urls from "../../../utils/urls";
import placeholder from "../../../assets/images/placeholder-person.png";
import dayjs from "dayjs";

class MiniUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            preview: ''
        }
    }

    componentDidMount(props) {
        if (this.props.data.user) {
            if (this.props.data.user.image) {
                this.setState({preview: urls.getS3Url(this.props.data.user.image.thumb || this.props.data.user.image.path)})
            }
        }
    }

    componentDidUpdate(props, state) {

    }


    getDances = () => {

    }

    render() {
        let {data} = this.props;
        return (
            <div className={'plan'} onClick={e => {
                this.props.history.push(`/app/updates/${this.props.data._id}`);
            }}>
                <div>
                    <Row>
                        <Col md={9} className={""}>
                        <span className={'row-name article-row mini-update-row'}>
                            <div className={"news-item-img"} style={{background: `url(${placeholder}), linear-gradient(0deg, rgba(0,0,0,.6), rgba(0,0,0,.4), rgba(0,0,0,.2), transparent)`}}></div>
                            <div className={"row-title text-capitalize"}>
                                {this.props.data.type} version {this.props.data.version} Released
                            </div>
                            <div className={"row-content overflow-hidden text-capitalize"}>
                                {this.props.data.description}
                            </div>

                        </span>
                        </Col>
                        <Col md={3} className={"text-right text-hint update-right"}>

                            {dayjs(data.createdAt).format("MMM DD YYYY @ hh:mm A")}
                        </Col>


                    </Row>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(MiniUpdate));

class MiniUpdateContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default MiniUpdateContainer;
