/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 2/10/2021
 */

const defaultState = {
    events: [],
    competition: {},
    event: {},
    result: {}
};

const publicState = (state = defaultState, action) => {
    switch (action.type) {
        case 'GOT_PUBLIC_RESULT':
            return {
                ...state,
                result: action.payload.data
            }
        case 'GOT_PUBLIC_EVENT':
            return {
                ...state,
                event: action.payload.data
            }
        case 'GOT_PUBLIC_DATA':
            return {
                ...state,
                events: action.payload.data
            }
        default:
            return {
                ...state
            };
    }
}

export default publicState;