/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 6/28/2020
 */

import AWS from 'aws-sdk';

let config = {
  region: 'us-east-2',
  bucket: process.env.REACT_APP_MODE === 'production' ? 'onlinefeis' : 'feisfwddev',
  accessKeyId: 'AKIA3XT52V5XDLPIWYFK',
  secretAccessKey: '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'
};

AWS.config.update({
  secretAccessKey: config.secretAccessKey,
  accessKeyId: config.accessKeyId,
  region: config.region,
  signatureVersion: 'v3'
});

function getS3Url(key) {
  if (key) {
    const s3 = new AWS.S3({
      signatureVersion: 'v4'
    });
    const params = { Bucket: config.bucket, Key: key };
    const url = s3.getSignedUrl('getObject', params);

    return url;
  } else {
    return null;
  }
}

function getLongS3Url(key) {
  if (key) {
    const s3 = new AWS.S3({
      signatureVersion: 'v4'
    });
    const params = { Bucket: config.bucket, Key: key, Expires: 86400 };
    const url = s3.getSignedUrl('getObject', params);

    return url;
  } else {
    return null;
  }
}

let urls = {
  development: {
    api: '',
    s3: 'https://feisfwddev.s3.us-east-2.amazonaws.com/',
    host: 'http://localhost:9081/'
  }
};

switch (process.env.REACT_APP_MODE) {
  case 'beta':
    urls.development = {
      api: '',
      s3: 'https://feisfwddev.s3.us-east-2.amazonaws.com/',
      host: 'https://betaapi.feisfwd.com/'
    };
    break;
  case 'production':
    urls.development = {
      api: '',
      s3: 'https://onlinefeis.s3.us-east-2.amazonaws.com/',
      host: 'https://api.feisfwd.com/'
    };
    break;
  default:
    break;
}

export default {
  urls,
  getS3Url,
  getLongS3Url
};
