import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as yup from 'yup';
import urls from '../../../utils/urls';
import placeholder from '../../../assets/images/placeholder-person.png';
import * as singleActions from '../../../store/actions/single';
import Select from '../../Common/Select';
import FormButtons from '../../Common/FormButtons';
import Col from 'react-bootstrap/Col';
import NotificationManager from 'react-notifications/lib/NotificationManager';

const schema = yup.object({
  name: yup.string().required('This field is required')
});

class EntryCompetitions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fileSelectField: '',
      filePickerOpen: false,
      selectingFileType: 'image',
      preview: '',
      image: '',
      createModerator: '',
      createAdmin: '',
      previews: [],
      setPreviews: false,
      mode: 'dancers'
    };
  }

  get isEdit() {
    return this.props.single?.single?.state === 'edit';
  }

  handleFormModal = (e) => {
    this.setState({ filePickerOpen: false });
  };

  selectFile = (file) => {
    this.props.setValues({ image: file });
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(props, state) {
    if (props.single.single !== this.props.single.single) {
      this.init();
    }
  }

  init = () => {
    if (!this.state.setPreviews) {
      if (this.props.single.dancers) {
        if (this.props.single.dancers.length > 0) {
          this.setState({
            previews: this.props.single.dancers.map((d) => {
              return d.image
                ? d.image.thumb || d.image.path
                  ? urls.getS3Url(d.image.thumb || d.image.path)
                  : placeholder
                : placeholder;
            }),
            setPreviews: true
          });
        }
      }
    }
  };

  handleSubmit = (values) => {
    let view = 'entries';

    let data = new FormData();
    data.append('files', this.state.image);
    let params = { entry: values };
    if (this.props.match.params.id) {
      delete params.entry.dancers;
    }

    if (this.props.single.single._id) {
      params.entryId = this.props.single.single._id;
      params.direction = 'add';
      params.entry._id = this.props.single.single._id;

      if (this.isEdit) {
        params.state = 'edit';
      }
    }

    if (this.props.match.params.id && this.props.match.params.view) {
      if (this.props.match.params.view === 'register') {
        params.entry.event = this.props.match.params.id;
      }
    }

    data.set('data', JSON.stringify(params));

    if (params._id) {
      this.props.patch(view, params._id, data, this.props.auth.token._id);
    } else {
      if (this.props.single.single._id) {
        this.props.patch(
          view,
          this.props.single.single._id,
          data,
          this.props.auth.token._id,
          (id) => {
            this.props.history.push(`/app/entries/${id}/${this.isEdit ? 'edit' : ''}`);
          }
        );
      } else {
        this.props.post(view, data, this.props.auth.token._id, (id) => {
          this.props.history.push(`/app/entries/${id}/${this.isEdit ? 'edit' : ''}`);
        });
      }
    }
  };

  grades = {
    grades: 'Grades (Beginner - Prizewinner)',
    championship: 'Championship',
    adult: 'Adult'
  };

  configs = [
    {
      id: 'dancers',
      name: 'Dancers'
    },
    {
      id: 'teams',
      name: 'Teams'
    }
  ];

  render() {
    this.props.single.event.admissionFee = this.isEdit ? 0 : this.props.single.event.admissionFee;

    return (
      <div>
        <Formik
          validationSchema={schema}
          onSubmit={this.handleSubmit}
          enableReinitialize
          initialValues={{
            dancers:
              this.props.match.params.view === 'register'
                ? this.props.single.dancers
                    .map((d) => d._id)
                    .concat(this.props.single.teams.map((d) => d._id))
                : this.props.single.entry
                ? this.props.single.entry.sections
                  ? this.props.single.entry.sections.map((d) => d.dancer)
                  : this.props.single.single
                  ? this.props.single.single.sections
                    ? this.props.single.single.sections.map((s) => s.dancer)
                    : []
                  : []
                : [],
            sections:
              this.props.match.params.view === 'register'
                ? this.props.single.sections
                : this.props.single.single.sections || [],
            price:
              this.props.match.params.view === 'register' || this.isEdit
                ? 0
                : this.props.single.single.price || this.props.single.entry.price,
            discount:
              this.props.match.params.view === 'register' || this.isEdit
                ? 0
                : this.props.single.single.discount || this.props.single.entry.discount
          }}>
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            isSubmitting,
            setFieldValue
          }) => (
            <Form noValidate onSubmit={handleSubmit} className={''}>
              <div className={'elevated rounded show-overflow'}>
                <FormButtons
                  fixed={true}
                  confirm={(e) => {
                    this.handleSubmit();
                  }}
                  back={(e) => {
                    e.preventDefault();
                    this.step(values, 'previous');
                  }}
                  showPrevious={false}
                  showRevert={false}
                  showNext={true}
                  next={(e) => {
                    e.preventDefault();
                    if (values.price > 0 || this.isEdit) {
                      this.handleSubmit(values);
                    } else {
                      NotificationManager.error(
                        'You need to select at least one competition to enter.',
                        'We detected an issue with your entry',
                        7000
                      );
                    }
                  }}
                  nextButtonNegative={true}
                  className={'pb-2 pt-2'}
                  showDefault={false}
                  label={'Competition Data'}
                  specialLabel={
                    <span className={'fee-container'}>
                      {this.props.single.event.admissionFee &&
                      this.props.user.user.roles.indexOf('teacher') === -1 ? (
                        <span className={'price-container'}>
                          <div className={'big-price fee'}>
                            ${parseInt(this.isEdit ? 0 : this.props.single.event.admissionFee || 0)}
                            <div className={'price-title'}>Admission</div>
                          </div>
                        </span>
                      ) : null}

                      {this.props.single.event.programFee &&
                      this.props.user.user.roles.indexOf('teacher') === -1 ? (
                        <span className={'price-container'}>
                          <div className={'big-price fee'}>
                            ${parseInt(this.isEdit ? 0 : this.props.single.event.programFee || 0)}
                            <div className={'price-title'}>FF Fee</div>
                          </div>
                        </span>
                      ) : null}

                      <span className={'price-container'}>
                        <div className={'big-price'}>
                          ${values.price - values.discount}
                          <div className={'price-title'}>Competitions</div>
                        </div>
                      </span>
                    </span>
                  }
                />

                <div className={'spacer'} />
                <div className={'dancer-select-container pb-3'}>
                  {values?.sections.filter((s) => s.level === 'teams').length > 0 ? (
                    <Col sm={4}>
                      <div className={'field-container'}>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Configure for</Form.Label>
                          <Select
                            disableTyping={true}
                            items={[
                              {
                                id: 'dancers',
                                name: 'Dancers'
                              },
                              {
                                id: 'teams',
                                name: 'Teams'
                              }
                            ]}
                            value={this.configs.filter((c) => c.id === this.state.mode)[0] || {}}
                            itemValue={'id'}
                            onChange={(val) => {
                              this.setState({ mode: val.id });
                            }}
                            displayValue={'name'}
                          />
                        </Form.Group>
                      </div>
                    </Col>
                  ) : null}
                </div>
              </div>

              <div className={'mt-5'} />

              {values.sections
                ? values.sections.map((d, i) => {
                    let relevant = values.dancers
                      ? values.dancers.filter((da) => da === d.dancer)[0]
                      : [];
                    let dancer = null;
                    if (this.state.mode === 'dancers') {
                      dancer = this.props.single.dancers.filter((da) => da._id === d.dancer)[0];
                    }

                    if (d.level === 'teams') {
                      if (this.state.mode === 'teams') {
                        dancer = this.props.single.teams.filter((da) => da._id === d.dancer)[0];
                      }
                    }

                    let section = d;
                    if (relevant && section && dancer) {
                      return (
                        <div key={i} className={'ex-pad mb-5 pb-5 elevated rounded show-overflow'}>
                          <h5>
                            <div className={'small-avatar'}>
                              <div
                                onClick={(e) => this.setState({ filePickerOpen: true })}
                                className={'artist-img avatar'}
                                style={{
                                  background: this.state.previews[i]
                                    ? `url(${this.state.previews[i]}), linear-gradient(0deg, rgba(0,0,0,.6), rgba(0,0,0,.4), rgba(0,0,0,.2), transparent)`
                                    : `url(${placeholder}), linear-gradient(0deg, rgba(0,0,0,.6), rgba(0,0,0,.4), rgba(0,0,0,.2), transparent)`
                                }}></div>
                            </div>
                            <div className={'dancer-name'}>
                              {dancer.name} {dancer.lname}
                            </div>
                          </h5>
                          {d.level !== 'teams' ? (
                            <div className={'text-hint'}>
                              <div>
                                <label className={'dancer-label'}>Age:</label> {section.age} (as of
                                January 1)
                              </div>
                              <div>
                                <label className={'dancer-label'}>Level:</label>{' '}
                                {this.grades[section.level]}
                              </div>
                            </div>
                          ) : null}
                          <div className={'divider'} />
                          <div className={'spacer'} />

                          {section.sections.map((s, si) => {
                            return s.dances ? (
                              s.dances.length > 0 ? (
                                <div key={si}>
                                  <h6 className={'text-center'}>{s.name}</h6>
                                  <div className={'divider'} />
                                  <div className={'spacer'} />
                                  <div className={'dances'}>
                                    {s.dances
                                      ? s.dances.map((dance, dancei) => {
                                          return (
                                            <div key={dancei}>
                                              <div className={'field-container'}>
                                                <Form.Group controlId="formBasicEmail">
                                                  <Form.Label>{dance.name.title}</Form.Label>
                                                  <Select
                                                    disableTyping={true}
                                                    items={dance.options}
                                                    value={
                                                      dance.selected ? dance.selected : {} || {}
                                                    }
                                                    itemValue="comp._id"
                                                    onChange={(val) => {
                                                      if (val.price) {
                                                        let priceToMod = val.price || 0;
                                                        if (dance.selected.price) {
                                                          values.price -= dance.selected.price;
                                                        }

                                                        setFieldValue(
                                                          'price',
                                                          values.price + priceToMod
                                                        );
                                                      } else {
                                                        let priceToMod = dance.selected.price || 0;
                                                        const total = values.price - priceToMod;

                                                        setFieldValue(
                                                          'price',
                                                          total > 0 ? total : 0
                                                        );
                                                      }

                                                      if (val.price) {
                                                        if (val.applyFamilyMax) {
                                                          let familyMax = val.price;
                                                          values.sections.forEach((vsec) => {
                                                            vsec.sections.forEach((sec2) => {
                                                              sec2.dances.forEach((vdance) => {
                                                                if (
                                                                  vdance.selected.applyFamilyMax
                                                                ) {
                                                                  familyMax +=
                                                                    vdance.selected.price;
                                                                }
                                                              });
                                                            });
                                                          });

                                                          if (
                                                            familyMax >
                                                            this.props.single.event.familyMax
                                                          ) {
                                                            setFieldValue(
                                                              'discount',
                                                              familyMax -
                                                                this.props.single.event.familyMax
                                                            );
                                                          }
                                                        }
                                                      } else {
                                                        if (dance.selected.applyFamilyMax) {
                                                          let familyMax = 0;
                                                          values.sections.forEach((vsec) => {
                                                            vsec.sections.forEach((sec2) => {
                                                              sec2.dances.forEach((vdance) => {
                                                                if (
                                                                  vdance.selected.applyFamilyMax
                                                                ) {
                                                                  familyMax +=
                                                                    vdance.selected.price;
                                                                }
                                                              });
                                                            });
                                                          });
                                                          familyMax -= dance.selected.price * 2;

                                                          if (
                                                            familyMax <
                                                            this.props.single.event.familyMax
                                                          ) {
                                                            setFieldValue('discount', 0);
                                                          }
                                                        }
                                                      }

                                                      setFieldValue(
                                                        'sections',
                                                        values.sections.map((sec, seci) => {
                                                          if (seci === i) {
                                                            return {
                                                              ...sec,
                                                              sections: sec.sections.map(
                                                                (secondsec, secondseci) => {
                                                                  if (secondseci === si) {
                                                                    return {
                                                                      ...secondsec,
                                                                      dances: secondsec.dances.map(
                                                                        (dance2, dance2i) => {
                                                                          if (dance2i === dancei) {
                                                                            return {
                                                                              ...dance2,
                                                                              selected: val
                                                                            };
                                                                          } else {
                                                                            return dance2;
                                                                          }
                                                                        }
                                                                      )
                                                                    };
                                                                  } else {
                                                                    return secondsec;
                                                                  }
                                                                }
                                                              )
                                                            };
                                                          } else {
                                                            return sec;
                                                          }
                                                        })
                                                      );
                                                    }}
                                                    displayValue={'gradeName'}
                                                  />

                                                  {errors.grade && touched.grade && (
                                                    <p className={'text-danger hint-text'}>
                                                      {errors.grade.id}
                                                    </p>
                                                  )}
                                                </Form.Group>
                                              </div>
                                            </div>
                                          );
                                        })
                                      : null}
                                  </div>
                                </div>
                              ) : null
                            ) : null;
                          })}
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })
                : null}
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  single: state.single,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  post: (view, data, token, redirect) => {
    dispatch(singleActions.postFormData(view, data, token, true, redirect, true));
  },
  postOnboarding: (view, data, token, redirect) => {
    dispatch(singleActions.postNextStep(view, data, token, true, redirect));
  },
  patch: (view, id, data, token) => {
    dispatch(singleActions.patchFormData(view, id, data, token, false, null, true));
  },
  get: (id, token) => {
    dispatch(singleActions.getSingle('events', id, token));
  },
  setBlankSingle: () => {
    //dispatch(singleActions.setSingleEntry({step: 0}));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(EntryCompetitions));

class EntryCompetitionsContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    );
  }
}

export default EntryCompetitionsContainer;
