import React from 'react';

import PDF from "./PDFComponent";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as tableActions from '../../../store/actions/table';


const PDFPage = (props) => {

    const judgeNumber = 0;      // This is not judge specific

    const events = props.events;
    if (!events) return null;
    const event = events.event;
    const rounds = events.competition.competition.grade.rounds;
    const round = events.competition.template.rounds[rounds];   // need to grab the final round for a podium results report
    var scores = round.scores[judgeNumber];
    // Assume this is the podium report unless the value is explicitly "false"
    const placed = props.match.params.placed === "false" ? false : true;
    const teams = events.competition.competition.grade.type.toLowerCase().includes('team');


    let grade = events.competition.competition.grade;
    let comp = events.competition.competition;

    let ageTitle = '';

    for(var col of grade.columns){
        if (col.id.toString() === comp.column) {
            ageTitle = col.title;
        }
    }

    let labels = placed ? ["Podium Results"] : ["Championship Awards"]
    labels.push(props.events.competition.competition.grade.event.name);
    labels.push("COMPETITION # " + events.competition.competition.code);

    let subtitles = [];

    if (!placed) {
        subtitles.push("Sorted by Dancer Number");
    }

    const competitionName = events.competition.competition.grade.title + " " +
        events.competition.competition.rowTitle + " | " +
        ageTitle;

    const titles = [
        competitionName
    ]

    const headers = placed ? [
        "Rank",
        "Dancer #",
        "Name",
        "School",
        "Region"
    ] : [
        "Champ Award",
        "Dancer #",
        "Name",
        "School",
        "Region"
    ];

    if (placed && events.competition.competition.grade.nationalQualifier) { 
        headers.push("NQ");
    }
    if (placed && events.competition.competition.grade.worldQualifier) { 
        headers.push("WQ");
    }

    scores = scores.map(s => {
        let rel = events.competition.entries.filter(item => item._id === s.entryCompetition);
        let d = {};
    
        if (rel.length > 0) {
          d = rel[0];
        }
        return {
          ...s,
          dancerNumber: d.dancerNumber
        }
      });


    // Sort by rank
    scores = scores.sort((a, b) => {
        return b["finalIp"] - a["finalIp"];
    });

    var content = scores.filter(scoreItem => scoreItem.placed === placed && (placed || scoreItem.recall === false)).map((score, i) => {
        let rel = events.competition.entries.filter(item => item._id === score.entryCompetition);
        let s = {};

        if (rel.length > 0) {
            s = rel[0];
        }

        let rankString = score["finalRank"] && score["finalRank"] > -1 ?
            score["finalRank"].toString() + (score["tied"] ? "T" : "") 
            : "";

        const nameString = teams ? s.dancer?.name : s.dancer?.name + " " + s.dancer?.lname;

        // TODO Ok obviously this is a horrible hack for Aus Nationals
        let regionString = s.dancer?.region ? s.dancer.region.name?.replace("Australia", "")
            .replace(" - ", "").replace(" – ", "") || '' : '';
        let returnArray = placed ? [
            rankString,
            s.dancerNumber ? s.dancerNumber : 0,
            nameString,
            s.dancer ? s.dancer.school?.name : "",
            regionString
        ] : [
            "CA",
            s.dancerNumber ? s.dancerNumber : 0,
            nameString,
            s.dancer ? s.dancer.school?.name : "",
            regionString
        ]

        if (placed && events.competition.competition.grade.nationalQualifier) { 
            returnArray.push(score["nq"] ? "Yes" : "No");
        }
        if (placed && events.competition.competition.grade.worldQualifier) { 
            returnArray.push(score["wq"] ? "Yes" : "No");
        }

        return returnArray;
    });

    // Sort by dancerNumer and not score if this is the 'honorable mention' report
    if (!placed) {
        content = content.sort((a, b) => {

            return a[1] - b[1]
          })
    }

    // break content into chunks of 16 records (we can fit 16 on a page)
    let chunks = [];
    var i,j, temporary, chunk2 = 14;
    for (i = 0,j = content.length; i < j; i += chunk2) {
        temporary = content.slice(i, i + chunk2);
        chunks.push(temporary);
    }

    let tables = [];

    for(var chunk of chunks) { 
        tables.push({
            headers,
            content: chunk
        });
    }

    const pdfData = {
        tables: tables,
        titles: titles,
        judges: props.events.competition.template.rounds["1"].judges.filter(item => item.name).map(item => item.name + " " + item.lname),
        image: props.events.competition.competition.grade.event.image.path,
        labels: labels,
        subtitles: subtitles,
        podiumResults: true
    }

    return (
        <div style={{ height: "100vh" }}>

            <   PDF pdfData={pdfData} />
        </div>
    );
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    user: state.user,
    events: state.events
});


const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
});


const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(PDFPage));

class PDFPageContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default PDFPageContainer;
