/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 3/28/2021
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import * as tableActions from '../../../../store/actions/table';
import Button from "react-bootstrap/Button";
import {Scrollbar} from "react-scrollbars-custom";
import { StatsChartOutline } from 'react-ionicons';
import MiniScore from "./MiniScore";

class ScoringList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            title: '',
            sort: null,
            filter: null,
            showModal: false,
            data: {},
            search: ''
        }
    }

    maxDepth = 0;

    componentDidMount(props){

        let page = 1;
        let itemsPerPage = 100;
        let skip = (itemsPerPage * (page - 1));

        //console.log("Getting items for", view, this.props.location.pathname);
        //if (this.props.table.view !== view) {


        this.props.getTableDataWithQuery("scoring", skip, itemsPerPage, {createdAt: -1}, {event: this.props.match.params.id}, this.props.auth.token._id, 'add');
        //}
        //}
    }

    componentDidUpdate(props, state) {

        if (state.sort !== this.state.sort || state.filter !== this.state.filter) {
            let query = {event: this.props.match.params.id};
            let sort = {code: 1};
            if (this.state.sort) {
                sort = this.state.sort.sort;
            }

            if (this.state.filter) {
                query.filter = this.state.filter.id;
            }

            if (this.state.search) {
                query.search = this.state.search;
            }

            this.props.getTableDataWithQuery("scoring", 0, 100, sort, query, this.props.auth.token._id, 'add');


        }

    }

    dragEnd = (res) => {
        //console.log("DROPPED ", res);
    }

    remove = (id) => {
        this.props.deleteItem(this.props.table.view, id, this.props.auth.token._id);
    }

    edit = (id) => {
        if (this.props.table.data.filter(d => d._id === id).length > 0) {
            this.props.handleFormModalWithData(this.props.table.data.filter(d => d._id === id)[0]);
        }
    }

    save = (e, items) => {
        let page = this.props.match.params.page ? this.props.match.params.page : 1;
        this.props.reorder(this.props.table.view, page, items, this.props.auth.token._id);
    }

    markTop = (e, item) => {
        //console.log("Marking", item, e.target);
        let filteredItems = this.props.table.data.filter(d => d._id === item);
        if (filteredItems.length > 0) {
            let item = filteredItems[0];
            item.topStory = !item.topStory;
            this.props.updateTableItem("scoring", item, this.props.auth.token._id);
        }


    }

    loadMore = () => {
        let query = {event: this.props.match.params.id};
        let sort = {createdAt: -1};
        if (this.state.sort) {
            sort = this.state.sort.sort;
        }

        if (this.state.filter) {
            query.filter = this.state.filter.id;
        }

        if (this.state.search) {
            query.search = this.state.search;
        }

        this.props.getTableDataWithQuery("scoring", this.props.table.data.length, 10, sort, query, this.props.auth.token._id, 'update');

    }

    handleAboutToReachBottom = () => {
        if (this.props.table.hasMore) {
            this.loadMore();
        }
    }
    handleUpdate = (values) => {
        const { scrollTop, scrollHeight, clientHeight } = values;
        const pad = 100; // 100px of the bottom
        // t will be greater than 1 if we are about to reach the bottom
        const t = ((scrollTop + pad) / (scrollHeight - clientHeight));
        //console.log(t, this.props.table.hasMore);
        if (t > 1) this.handleAboutToReachBottom();
    }

    search = () => {
        let query = {event: this.props.match.params.id};
        let sort = {createdAt: -1};
        if (this.state.sort) {
            sort = this.state.sort.sort;
        }

        if (this.state.filter) {
            query.filter = this.state.filter.id;
        }

        if (this.state.search) {
            query.search = this.state.search;
        }

        this.props.getTableDataWithQuery("scoring", 0, 10, sort, query, this.props.auth.token._id, 'add');

    }

    filters = [
        {
            id: 'all',
            name: 'All',
        },
        {
            id: 'completed',
            name: 'Completed',
        },
        {
            id: 'incompleted',
            name: 'Incomplete',
        }
    ]

    sort = [

        {
            id: 'compdesc',
            name: 'Newest First',
            sort: {createdAt: -1}
        },
        {
            id: 'compasc',
            name: 'Oldest First',
            sort: {createdAt: 1}
        }
    ]

    render() {
        return (
            <Scrollbar className={'feis-page-scroller margin-neg-20'} noScrollX={true} onUpdate={this.handleUpdate}
                       style={{height: 'calc(100vh - 60px)'}}>
                <div className={"standard-page"}>
                    <div className={'page-header ex-pad pb-0 pt-0 pr-4 pl-4'}>
                        <Row>
                            <Col sm={6}>
                                <h1><StatsChartOutline /> Scoring Systems</h1>
                            </Col>
                            <Col sm={6} className={"text-right"}>
                                {this.props.user.user.role === 'admin' ?
                                    <Button variant={"primary"} onClick={e => {
                                        this.props.history.push('/app/admin/scoring/edit/new');
                                    }}>Create New</Button>
                                    : null}
                            </Col>
                        </Row>

                    </div>

                    {this.props.table.data ?


                        this.props.table.view === "scoring" && this.props.table.data ?
                            this.props.table.data.map ?
                                this.props.table.data.map((d, i) => {
                                    return <MiniScore data={d} key={i} />
                                })
                                : null
                            : null : null
                    }
                    {this.props.table.hasMore ?
                        <div className={"text-center"}>
                            <Button onClick={this.loadMore}>Load More</Button>
                        </div>

                        : null}

                </div>
            </Scrollbar>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(ScoringList));

class ScoringListContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default ScoringListContainer;
