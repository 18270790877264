/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 2/10/2021
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import * as publicActions from '../../../store/actions/publicActions';
import EventResultTile from "../../Common/EventResultTile";
import placeholder2 from "../../../assets/images/placeholder1.png";


class Events extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    componentDidMount() {
        this.props.getTableDataWithQuery("events", 0, 999, {number: 1}, {hasResults: true});
    }

    componentDidUpdate(props, state) {

    }

    render() {
        return (
            <div>
                <div className={'page-header'}>
                    <Row>
                        <Col>
                            <h1>Results</h1>
                        </Col>
                    </Row>
                </div>
                <div className={"tile-grid"}>
                    <Row>
                        {this.props.publicState.events.length > 0 ?
                            this.props.publicState.events.map((d, i) => {
                                return <EventResultTile isPublic={true} {...d} data={d} key={i} placeholder={placeholder2} />
                            })
                            :
                            <h4 className={"no-data"}>No Events Found</h4>
                        }
                    </Row>
                </div>


            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    publicState: state.publicState,
});

const mapDispatchToProps = dispatch => ({
    getTableDataWithQuery: (view, skip, limit, sort, query) => {
        dispatch(publicActions.getTableDataWithQuery(view, skip, limit, sort, query));
    },

});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Events));

class EventsContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default EventsContainer;