/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 11/1/2021
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as tableActions from '../../../store/actions/table';
import TabulatorJudgeTable from "./TabulatorJudgeTable";
import TabulatorOverviewTable from "./TabulatorOverviewTable";
import JudgeStandardTable from "./JudgeStandardTable";
import TabulatorTwoRoundJudges from "./TabulatorTwoRoundJudges";

class TabulatorTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    componentDidMount(props) {
        if (this.props.table.view !== "") {
            //this.props.getTableData("", 0, 999, {number: 1}, this.props.auth.token._id, 'add');
        }
    }

    componentDidUpdate(props, state) {
        if ((this.props.table.data.length !== this.state.items.length || this.props.table.updateCount > props.table.updateCount || this.props.table.data.length !== this.state.items.length) && this.props.table.view === "") {
            this.setState({items: this.props.table.data});
        }
    }

    render() {
        //console.log("Rendering?", this.props.events.competition.tabulation ? true : false);
        // On initial load, we want to display loading indicator
        // Once data is fetched, loaded will be true, so we set loading to false
        this.props.events.loading = !this.props.events.loaded;
        return this.props.events.competition.tabulation ? (
            <div>

                {this.props.match.params.round === "overview" || this.props.match.params.round === "final" ?

                    <TabulatorOverviewTable round={this.props.match.params.round} />
                    :
                    this.props.events.competition.competition.grade.scoring.judge_rotation_type === "SET" ?
                        <JudgeStandardTable judge={this.props.match.params.round} />
                        :
                        this.props.events.competition.competition.grade.scoring.judge_rotation_type === "SPLIT" &&
                        this.props.match.params.round.toString() === "1" ?
                        <TabulatorTwoRoundJudges round={this.props.match.params.round}/> :
                        <TabulatorJudgeTable round={this.props.match.params.round}/>
                }

            </div>
        ) : null
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    user: state.user,
    events: state.events
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(TabulatorTable));

class TabulatorTableContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default TabulatorTableContainer;
