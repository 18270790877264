/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import * as authActions from '../../../store/actions/auth';


class Logout extends React.Component {

    componentDidMount(){
        //console.log
        if (this.props.auth.token._id) {
            this.props.logout(this.props.auth.token._id);
        }
    }

    render() {
        return (
            <div className={'page-container'}>
                <div className={'page-header'}>
                    <Row>
                        <Col>
                            <h1>Logout</h1>
                        </Col>
                    </Row>
                </div>


            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table
});

const mapDispatchToProps = dispatch => ({
    logout: (token) => {
        dispatch(authActions.logout(token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Logout));

class LogoutContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default LogoutContainer;