import socketIOClient from "socket.io-client";
import urls from '../../utils/urls';
import {NotificationManager} from "react-notifications";
import note from '../../assets/audio/note.mp3';
//const ENDPOINT = //"https://api.feisfwd.com"; //"http://localhost:8081";
const ENDPOINT = urls.urls.development.host;
let socket = null;


function prep(obj) {
    return JSON.stringify(obj);
}

export function connect(token) {

    return dispatch => {
        try {
            socket = socketIOClient(ENDPOINT);


            socket.on("message", data => {
                //console.log("RECEOVOMG", data);
                switch (data.type) {
                    case 'connected':
                        return socket.send(prep({type: 'PERSIST_TOKEN', data: token}));
                    case 'NEW_NOTIFICATION':
                        try {
                            const audio = new Audio(note);
                            audio.play();
                        }catch(e){
                            console.log("Notification error", e);
                        }

                        return dispatch({
                            type: 'NEW_NOTIFICATION',
                            payload: data.data
                        })
                    case 'UPDATED_NESTED_SINGLE':
                        if (!data.data.data) {
                            NotificationManager.error("Please reload this view and try again", "An Error Occured")
                        }
                        return dispatch({
                            type: 'UPDATED_NESTED_SINGLE',
                            payload: data.data
                        })
                    case 'GENERATING_STATUS_CHANGED':
                        return dispatch({
                            type: 'GENERATING_STATUS_CHANGED',
                            payload: data.data
                        })
                    case 'LOGOUT':
                        console.log("Logging out");
                        return dispatch({
                            type: 'LOGOUT',
                            payload: null
                        })
                    case 'ADD_REPORT':
                        return dispatch({
                            type: 'ADD_REPORT',
                            payload: data.data
                        })
                    case 'LOGOUT_IF_TOKEN':
                        console.log("Logging out - token request");
                        if (token._id === data.data.token.token) {
                            return dispatch({
                                type: 'LOGOUT',
                                payload: null
                            })
                        } else {
                            return true;
                        }

                    case '':
                        return null;
                    default:
                        return;
                }

            })

        }catch(err) {
            //console.log
        }
    }
    }

export function send(data) {
    return dispatch => {
        if (socket) {
            socket.send(prep(data))
        }
    }
}
