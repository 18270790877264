/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Modal from "react-bootstrap/Modal";
import * as singleActions from '../../store/actions/single';
import Table from "react-bootstrap/Table";
import * as eventActions from '../../store/actions/events';

class SchoolModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            preview: ''
        }
    }
    hideModal = () => {
        this.props.handleModal(false)
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(props) {
        if (this.props.data._id !== props.data._id) {
            this.init();
        }
    }

    init = () => {
        //console.log("th", this.props.data.event, this.props.match.params.id);
        this.props.get({id: this.props.match.params.id, school: this.props.data._id}, this.props.auth.token._id);
    }


    render() {
        if (this.props.events.organizerSchool) {
            if (this.props.events.organizerSchool._id === this.props.data._id) {
                return (
                    <div className={this.props.user.user.theme}>
                        <Modal centered size={'lg'} className={`theme-${this.props.user.user.theme}`} show={this.props.show} onHide={e => this.props.hideModal(false)}>
                            <Modal.Body>
                                <div className={"spacer"} />
                                <h5>
                                    {this.props.events.organizerSchool.name ? this.props.events.organizerSchool.name : ''}
                                </h5>
                                <div className={"spacer"} />
                                <Table responsive>
                                    <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Competitions</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {this.props.events.schoolEntries.map((entry, i) => {
                                        return entry.sections.map((section, o) => {
                                            if (section.competitions.length > 0) {
                                                return (
                                                    <tr>
                                                        <td>{section.profile.name} {section.profile.lname}</td>
                                                        <td>{section.competitions.map((comp, compi) => {
                                                            return comp.name;
                                                        }).join(', ')}</td>
                                                    </tr>
                                                )
                                            } else {
                                                return null;
                                            }

                                        })

                                    })}


                                    </tbody>
                                </Table>
                            </Modal.Body>
                        </Modal>
                    </div>
                )
            } else{
                return null
            }
        } else {
            return null
        }

    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user,
    single: state.single,
    events: state.events
});

const mapDispatchToProps = dispatch => ({
    post: (uri, item, token) => {
        dispatch(singleActions.post(uri, item, token));
    },
    postFile: (uri, fd, token) => {
        dispatch(singleActions.postFile(uri, fd, token));
    },
    patch: (uri, id, item, token) => {
        dispatch(singleActions.patch(uri, id, item, token));
    },
    get: (query, token) => {
        dispatch(eventActions.getOrganizerSchool(query, token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(SchoolModal));

class SchoolModalContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default SchoolModalContainer;
