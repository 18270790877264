// import Image from 'next/image'
import React from 'react';

import { PDFViewer, Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';

import Logo from "../../../assets/images/wcro-2021-logo.png";
import urls from "../../../utils/urls";

const PDF = ({ pdfData }) => {

  const table = pdfData.table;
  const titles = pdfData.titles;
  const subtitles = pdfData.subtitles;
  const tables = pdfData.tables;
  const labels = pdfData.labels;
  const footer = pdfData.footer;
  const podiumResults = pdfData.podiumResults;
  //const labelSize = pdfData.labelSize ? pdfData.labelSize : 24;
  let logo = urls.getS3Url(pdfData.image);
  console.log("Tables", tables);

  // Determine column size for podium results based on how many cols there are
  // This will set the width of the 2 large columns (name and school)
  const podiumStyleLarge = podiumResults ? tables ? tables[0] ? tables[0].headers ?
    tables[0].headers.length === 5 ? styles.headersLarge :
    tables[0].headers.length === 6 ? styles.headersLarge2 :
    tables[0].headers.length === 7 ? styles.headersLarge3 :
    '' : '' : '' : '' : '';

  // This will set the width of the extra column(s) if there are more than 4
  const podiumStyleSmall = podiumResults ? tables ? tables[0] ? tables[0].headers ?
    tables[0].headers.length === 5 ? '' :
    tables[0].headers.length === 6 ? styles.extraHeaders1 :
    tables[0].headers.length === 7 ? styles.extraHeaders2 :
    '' : '' : '' : '' : '';

  return (
      <PDFViewer style={styles.pdfWrap}>
        <Document>
          {tables ?
              tables.map((item, index) => {
                //console.log("Building item", item);
                let pager = "Page " + (index + 1).toString() + " / " + tables.length.toString();

                return (
                    <Page size="A4" wrap>
                      <Image style={styles.logoImage} src={logo}/>
                      <View style={styles.titleWrap}>
                        {labels ? labels.map((labelItem) =>
                            <View>
                              <Text style={[styles.labels, {fontSize: 24}]}>{labelItem}</Text>
                              <Text>{"\n"}</Text>
                            </View>
                        ) : null}
                        <Text>{"\n"}</Text>
                        {titles.map((title) =>
                            <Text style={[styles.titles, {fontSize: 12}]}>{title}</Text>
                        )}

                      </View>

                      <View style={styles.subtitleWrap}>


                        {subtitles ? subtitles.map((title) =>
                            <Text style={[styles.subtitles, {fontSize: 11}]}>{title}</Text>
                        ) : <Text></Text>}
                        <Text style={[styles.subtitles, {fontSize: 11, marginTop: 20}]}>{pager}</Text>

                      </View>
                      <View style={styles.tableWrap}>
                        <View style={styles.tableContentWrap}>
                          <View key={"headers"} style={styles.contentRow}>
                            {/* TODO: This is a total hack, need a better way to pass in styles for columns */}
                            {podiumResults ? 
                              <>
                                <View key={"headers1"} style={styles.headersSmall}>
                                  <Text key={0} style={styles.tableTitles}>{item.headers[0]}</Text>
                                  <Text key={1} style={styles.tableTitles}>{item.headers[1]}</Text>
                                </View>
                                <View key={"headers2"} style={styles.dancerName}>
                                  <Text key={2} style={styles.tableTitles}>{item.headers[2]}</Text>
                                </View>
                                <View key={"headers3"} style={podiumStyleLarge}>
                                  <Text key={3} style={styles.tableTitles}>{item.headers[3]}</Text>
                                </View>
                                <View key={"headers4"} style={styles.region}>
                                  <Text key={4} style={styles.tableTitles}>{item.headers[4]}</Text>
                                </View>
                                {item.headers.length > 5 ? 
                                  <View key={"headers5"} style={podiumStyleSmall}>
                                    <Text key={5} style={styles.tableTitles}>{item.headers[5]}</Text>
                                  </View>
                                  : null
                                }
                                {item.headers.length > 6 ? 
                                  <View key={"headers6"} style={podiumStyleSmall}>
                                    <Text key={6} style={styles.tableTitles}>{item.headers[6]}</Text>
                                  </View>
                                  : null
                                }
                              </>
                              :
                              <>
                              {item.headers ? item.headers.map ? item.headers.map((td, i) => {
                                console.log("TD", td);
                                    return <Text key={i} style={styles.tableTitles}>{td}</Text>
                                  }
                              ) : null : null}
                              </>}
                          </View>
                          {item.content ? item.content.map((tr, i) =>
                            <View key={i} style={styles.contentRow}>
                              {podiumResults ? 
                                <>
                                  <View key={"content1"} style={styles.headersSmall}>
                                    <Text key={0} style={styles.tableTitlesBigText}>{tr[0]}</Text>
                                    <Text key={1} style={styles.tableTitlesBigText}>{tr[1]}</Text>
                                  </View>
                                  <View key={"content2"} style={styles.dancerName}>
                                    <Text key={2} style={styles.tableTitlesSmallText}>{tr[2]}</Text>
                                  </View>
                                  <View key={"content3"} style={podiumStyleLarge}>
                                    <Text key={3} style={styles.tableTitlesSmallText}>{tr[3]}</Text>
                                  </View>
                                  <View key={"content4"} style={styles.region}>
                                    <Text key={4} style={styles.regionText}>{tr[4]}</Text>
                                  </View>
                                  {item.headers.length > 5 ? 
                                    <View key={"content5"} style={podiumStyleSmall}>
                                      <Text key={5} style={styles.tableTitlesSmallText}>{tr[5]}</Text>
                                    </View>
                                  : null
                                  }
                                  {item.headers.length > 6 ? 
                                    <View key={"content6"} style={podiumStyleSmall}>
                                      <Text key={6} style={styles.tableTitlesSmallText}>{tr[6]}</Text>
                                    </View>
                                  : null
                                  }
                                </>
                              :
                              <>
                                {tr.map((td, j) => {
                                  var font = { fontSize: 11 };
        
                                  if (j === 0) {font = { fontSize: 22 } }
                                  return (
                                    <Text key={j} style={[styles.tableTitles, font]}>{td}</Text>
                                  );
                                })}
                              </>
                              }
                            </View>
                          ) : null}
                        </View>
                      </View>
                      {footer ?
                        <Text style={styles.footer} fixed>{footer}</Text>
                      : null}
                    </Page>
                )
              })

              :
              <Page size="A4" wrap>
                <Image style={styles.logoImage} src={logo}/>
                <View style={styles.titleWrap}>
                  {labels.map((labelItem) =>
                      <View>
                        <Text style={[styles.labels, {fontSize: 24}]}>{labelItem}</Text>
                        <Text>{"\n"}</Text>
                      </View>
                  )}
                  <Text>{"\n"}</Text>
                  {titles.map((title) =>
                      <Text style={[styles.titles, {fontSize: 12, marginBottom: '20px'}]}>{title}</Text>
                  )}
                </View>

                <View style={[styles.subtitleWrap, {marginTop: '20px'}]}>


                  {subtitles ? subtitles.map((title) =>
                      <Text style={[styles.subtitles, {fontSize: 11}]}>{title}</Text>
                  ) : <Text></Text>}
                </View>
                <View style={styles.tableWrap}>
                  <View style={styles.tableContentWrap}>
                    <View key={"headers"} style={styles.contentRow}>
                      {table.headers.map((td, i) =>
                          <Text key={i} style={styles.tableTitles}>{td}</Text>
                      )}
                    </View>
                    {table.content.map((tr, i) =>
                        <View key={i} style={styles.contentRow}>
                          {tr.map((td, j) => {
                          var font = { fontSize: 11 };

                          if (j === 0) {font = { fontSize: 22 } }
                          return (
                          <Text key={j} style={[styles.tableTitles, font]}>{td}</Text>
                          );
                          })}
                        </View>
                    )}
                  </View>
                </View>
                {footer ?
                  <Text style={styles.footer} fixed>{footer}</Text>
                : null}
              </Page>
          }
        </Document>
      </PDFViewer>
  )
}

// Create styles
const styles = StyleSheet.create({
  container: {
    height: "100vh !important",
  },
  pdfWrap: {
    backgroundColor: "green",
    fontSize: 9,
    height: "100%",
    width: "100%",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  // LOGO
  logoImage: {
    position: "absolute",
    right: "25px",
    top: "25px",
    height: "50px",
    width: "50px",
    zIndex: 3,
  },

  // LABELS
  labels: {
    display: "flex",
    margin: "5px 0 5px 0",
    lineHeight: ".5px",
    fontWeight: "bold",
  },

  // TITLES
  titles: {
    display: "flex",
    margin: "5px 0 5px 0",
    lineHeight: ".5px",
  },

  subtitles: {
    textAlign: 'left'
  },

  // TITLE WRAP
  titleWrap: {
    alignItems: "left",
    justifyContent: "center",
    color: "black",
    display: "flex",
    margin: "20px 0 0 50px",
    lineHeight: ".5px",
    width: "100%",
  },

  subtitleWrap: {
    alignItems: "left",
    justifyContent: "center",
    color: "black",
    display: "flex",
    margin: "0px 0 0 50px",
    width: "100%",
  },

  //TABLE
  tableWrap: {
    border: "1px solid black",
    margin: "20px 0 0 50px",
    width: "500px",
    fontSize: 9,
  },
  tableTitlesWrap: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  tableContentWrap: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  contentRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  dancerName: {
    display: "flex",
    flexDirection: "row",
    width: "25%",
  },
  region: {
    display: "flex",
    flexDirection: "row",
    width: "10%",
  },
  headersSmall: {
    display: "flex",
    flexDirection: "row",
    width: "22%",
  },
  headersLarge: {
    display: "flex",
    flexDirection: "row",
    width: "43%",
  },
  headersLarge2: {
    display: "flex",
    flexDirection: "row",
    width: "35%",
  },
  headersLarge3: {
    display: "flex",
    flexDirection: "row",
    width: "31%",
  },
  extraHeaders1: {
    display: "flex",
    flexDirection: "row",
    width: "8%",
  },
  extraHeaders2: {
    display: "flex",
    flexDirection: "row",
    width: "6%",
  },
  tableTitles: {
    border: "1px solid black",
    flex: 1,
    padding: "5px",
  },
  tableTitlesSmallText: {
    border: "1px solid black",
    flex: 1,
    padding: "5px",
    fontSize: 14,
  },
  regionText: {
    border: "1px solid black",
    flex: 1,
    padding: "5px",
    fontSize: 8,
  },
  tableTitlesBigText: {
    border: "1px solid black",
    flex: 1,
    padding: "5px",
    fontSize: 24,
  },
  tableContent: {
    border: "1px solid black",
    flex: 1,
    padding: "3px 5px",
  },

  // GRID
  gridContainer: {
    height: "841px",
    width: "594px",
    position: "absolute",
  },
  HG: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    position: "absolute",
    width: "100%",
  },
  VG: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    position: "absolute",
    width: "100%",
  },
  HGP: {
    border: "1px solid red",
    height: "5%",
  },
  VGP: {
    border: "1px solid red",
    height: "100%",
    width: "5%",
  },
  footer: {
    position: 'absolute',
    fontSize: 8,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center'
  },
});

export default PDF;
