/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 2/9/2021
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import * as tableActions from '../../../store/actions/table';
import EventResultTile from "../../Common/EventResultTile";
import placeholder2 from "../../../assets/images/placeholder1.png";
import {OpenOutline} from "react-ionicons";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

class EventGrid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    componentDidMount(props) {
        this.props.getTableDataWithQuery("events", 0, 999, {number: 1}, {hasResults: true}, this.props.auth.token._id, 'add');

    }

    componentDidUpdate(props, state) {
        if ((this.props.table.data.length !== this.state.items.length || this.props.table.updateCount > props.table.updateCount || this.props.table.data.length !== this.state.items.length) && this.props.table.view === "events") {
            this.setState({items: this.props.table.data});

        }
    }

    render() {
        return (
            <div>
                <div className={"tile-grid"}>
                    <Row>
                        <Col>
                            <Button variant={'primary'} href={"/public/results/event/62409672ae235852956bad68"} target={"_blank"}>ACIDC Live Results &nbsp; &nbsp;<OpenOutline /> </Button>
                            <div className={"spacer"} />
                        </Col>
                    </Row>
                    <Row>
                        {this.props.table.data.length > 0 ?
                            this.props.table.data.map((d, i) => {
                                return <EventResultTile {...d} data={d} key={i} placeholder={placeholder2} />
                            })
                            :
                            <h4 className={"no-data"}>No Events Found</h4>
                        }
                    </Row>
                </div>


            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(EventGrid));

class EventGridContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default EventGridContainer;