/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Modal from "react-bootstrap/Modal";
import * as singleActions from '../../store/actions/single';
import Button from "react-bootstrap/Button";
import {Add, Checkmark, CheckmarkDone, DoneAll, Trash} from 'react-ionicons';
import { Close } from 'react-ionicons';
import CategoryPicker from "./CategoryPicker";
import Form from "react-bootstrap/Form";
import Select from "./Select";
import Table from "react-bootstrap/Table";

class CompetitionDeleteConfirmationModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            preview: ''
        }
    }
    hideModal = () => {
        this.props.handleCompetitionDeleteConfirmationModal(false)

    }



    render() {
        return (
            <div className={this.props.user.user.theme}>
                <Modal centered size={'lg'} className={`theme-${this.props.user.user.theme} ${this.props.user.user ? this.props.user.user.darkTheme ? 'dark-theme' : '' : ''}`} show={this.props.show} onHide={e => this.props.hideModal(false)}>
                    <Modal.Body className={"text-center"}>


                        <h5>Are you sure you want to disable all competitions with no entries?</h5>

                        <p>By clicking 'Yes' you acknowledge that this action is not reversible.  No new entrants will be able to create entries for those competitions removed.</p>



                        {/*
                        <div className={"merge-table"}>
                            <div className={"merge-table-row merge-table-header"}>
                                <span className={"merge-row-item"}>From</span>
                                <span className={"merge-row-item mini-col"}>=></span>
                                <span className={"merge-row-item"}>To</span>
                                <span className={"merge-row-item"}>New Competition Name</span>
                            </div>
                            {this.props.values.comps.map((item, i) => {
                                return (
                                    <div className={"merge-table-row"}>
                                                <span className={"merge-row-item"}>
                                                    {item[0].code}
                                                </span>
                                        <span className={"merge-row-item mini-col"}>
                                                   =>
                                                </span>

                                        <span className={"merge-row-item"}>
                                                   {item[1].code}
                                                </span>

                                        <span className={"merge-row-item"}>
                                                    {item[2]}
                                                </span>


                                    </div>
                                )
                            })}
                            <div className={"separator"}>
                                <br />
                            </div>
                        </div>
                        */}
                        <div className={"separator"}>
                            <br />
                        </div>

                        <div className={"text-center"}>
                            <br /><br />
                            <Button variant={"flat"} className={'btn btn-flat raised'} onClick={e => {
                                this.props.hideModal(false)
                            }}><Close /> Cancel</Button> &nbsp; &nbsp;
                            <Button variant={"primary"} onClick={e => {
                                this.props.submitCompetitionDelete(this.props.values);
                            }}><CheckmarkDone /> Yes I'm Sure!</Button>
                        </div>




                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user,
    single: state.single
});

const mapDispatchToProps = dispatch => ({
    post: (uri, item, token) => {
        dispatch(singleActions.post(uri, item, token));
    },
    postFile: (uri, fd, token) => {
        dispatch(singleActions.postFile(uri, fd, token));
    },
    patch: (uri, id, item, token) => {
        dispatch(singleActions.patch(uri, id, item, token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(CompetitionDeleteConfirmationModal));

class CompetitionDeleteConfirmationModalContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default CompetitionDeleteConfirmationModalContainer;
