import React from 'react';


class PageLoader extends React.Component {

    render () {
        return (
            <div className={"page-load-container"}>

                {this.props.loaded ?
                    this.props.children
                :

                    <div id="bars6">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                }

            </div>
        )
    }
}

export default PageLoader;