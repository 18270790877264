
/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 1/29/2021
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import * as tableActions from '../../../store/actions/table';
import Button from "react-bootstrap/Button";
import {ArrowBack, Copy, CopyOutline, Print, PrintOutline} from "react-ionicons";
import { BarChart, BarChartOutline } from 'react-ionicons';
import { Trophy, TrophyOutline } from 'react-ionicons';
import { Apps, AppsOutline } from 'react-ionicons';
import { People, PeopleOutline } from 'react-ionicons';
import { AlertCircle, AlertCircleOutline } from 'react-ionicons';
import * as eventActions from '../../../store/actions/events';
import { Compass, CompassOutline } from 'react-ionicons';
import { Build, BuildOutline } from 'react-ionicons';
import { Transition  } from 'react-transition-group';
import { ExitOutline, Exit, EnterOutline, Enter } from 'react-ionicons';
import AccountCircle from "../../Common/AccountCircle";


const iconStyle = {
    fontSize: '64px',
    height: '64px',
    width: '64px',
    marginTop: '50px'
}

class SingleEvent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            item: ''
        }
    }

    componentDidMount(props) {
        this.props.getSingleEvent(this.props.match.params.id, this.props.auth.token._id);
    }

    componentDidUpdate(props, state) {
        if (props.events.event) {
            if (props.events.event._id !== this.props.events.event._id) {
                this.props.getSingleEvent(this.props.match.params.id, this.props.auth.token._id);
            }
        }
    }

    grid = [
        {
            title: 'Edit',
            icon: <Build key={1} {...iconStyle} />,
            outline: <BuildOutline key={1} {...iconStyle} />,
            link: 'edit/1',
            level: 1
        },
        {
            title: 'Reports',
            icon: <BarChart key={1} {...iconStyle} />,
            outline: <BarChartOutline key={1} {...iconStyle} />,
            link: 'stats',
            level: 1
        },
        {
            title: 'Competitions',
            icon: <Apps key={1} {...iconStyle} />,
            outline: <AppsOutline key={1} {...iconStyle} />,
            link: 'competitions'
        },
        {
            title: 'Judges',
            icon: <People key={1} {...iconStyle} />,
            outline: <PeopleOutline  key={1} {...iconStyle} />,
            link: 'judges',
            level: 1
        },
        {
            title: 'Results',
            icon: <Trophy key={1} {...iconStyle} />,
            outline: <TrophyOutline key={1} {...iconStyle} />,
            link: 'results',
            level: 1
        },
        {
            title: 'Alerts',
            icon: <AlertCircle key={1} {...iconStyle} />,
            outline: <AlertCircleOutline key={1} {...iconStyle} />,
            link: 'alerts',
            level: 1
        },
        {
            title: "Activity",
            icon: <Compass key={1} {...iconStyle} />,
            outline: <CompassOutline key={1} {...iconStyle} />,
            link: 'logs',
            level: 1
        },
        {
            title: "Merge Competitions",
            icon: <Enter {...iconStyle} />,
            outline: <EnterOutline {...iconStyle} />,
            link: 'merge'
        },
        {
            title: "Split  Competitions",
            icon: <Exit {...iconStyle} />,
            outline: <ExitOutline {...iconStyle} />,
            link: 'split'
        },
        {
            title: 'Print Materials',
            icon: <Print {...iconStyle} />,
            outline: <PrintOutline {...iconStyle} />,
            link: 'print'
        },
        {
            title: 'Entries',
            icon: <People {...iconStyle} />,
            outline: <PeopleOutline {...iconStyle} />,
            link: 'entries'
        }
    ]
    duration = 300;

    defaultStyle = {
        transition: `opacity ${this.duration}ms ease-in-out`,
        opacity: 0,
    }

    transitionStyles = {
        entering: { opacity: 1 },
        entered:  { opacity: 1 },
        exiting:  { opacity: 0 },
        exited:  { opacity: 0 },
    };


    render() {
        return (
            <div>
                <div className={'page-header'}>
                    <Row>
                        <Col>
                            <h1>
                                {this.props.location.pathname.split("/").length > 3 ?
                                    <Button variant={"flat btn-round"} onClick={e => {

                                        this.props.history.push("/app/event-dashboard");
                                    }}>
                                        <ArrowBack />
                                    </Button>
                                    : null}

                                {this.props.events.event ? this.props.events.event.name : ''}</h1>
                        </Col>
                    </Row>
                </div>
                <div>
                    {this.grid.map((g, i) => {
                        console.log("G", g, this.props.events.event.level)
                        if ((g.level === 1 && this.props.events.event.level === 1) || !g.level || g.level === 0){
                            return (
                                <div className={"grid-tile"} key={i} onClick={e => {
                                    if (g.customLink) {
                                        this.props.history.push(`${g.customLink}/${this.props.match.params.id}/edit/1`);
                                    } else {
                                        this.props.history.push(`/app/event-dashboard/${this.props.match.params.id}/${g.link}`);
                                    }
                                }} onMouseEnter={e => this.setState({item: g.title})} onMouseLeave={e => {
                                    if (this.state.item === g.title) {
                                        this.setState({item: ''});
                                    }
                                }}>
                                    <Transition
                                        transitionName="example"
                                        timeout={500}
                                        transitionLeaveTimeout={300}>

                                        {state => {
                                            return this.state.item === g.title ? g.icon : g.outline
                                        }}

                                    </Transition>
                                    <h3>{g.title}</h3>
                                </div>
                            )
                        } else {
                            return null
                        }

                    })}
                </div>

            </div>
        )
    }
}


const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    events: state.events
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
    getSingleEvent: (id, token) => {
        dispatch(eventActions.getSingleEvent(id, token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(SingleEvent));

class SingleEventContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default SingleEventContainer;
