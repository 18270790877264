/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import * as singleActions from '../../store/actions/single';
import Col from 'react-bootstrap/Col';
import {ListGroup} from "react-bootstrap";

class ScoreModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            preview: ''
        }
    }
    hideModal = () => {
        this.props.handleScoreModal(false)

    }



    render() {
        return (
            <div className={this.props.user.user.theme}>
                <Modal centered size={'lg'} className={`theme-${this.props.user.user.theme} ${this.props.user.user ? this.props.user.user.darkTheme ? 'dark-theme' : '' : ''}`} show={this.props.show} onHide={e => this.props.hideModal(false)}>
                    <Modal.Body>
                        {this.props.entry ?
                                this.props.entry.score ?
                        <h5>
                            #{this.props.entry.entry.sections[0].dancerNumber ? this.props.entry.entry.sections[0].dancerNumber : ''} |&nbsp;
                            {this.props.entry.event ? this.props.entry.event.name : ''} |&nbsp;
                            {this.props.entry.name ? this.props.entry.name : ''}
                            {this.props.entry.competition.grade.type === "championship" ? ` | R${this.props.match.params.round}` : ''}
                        </h5>
                        : null
                        : null}

                            {this.props.entry ?
                                this.props.entry.score ?
                            <ListGroup as="ul" className={"score-modal-list"}>
                                <ListGroup.Item as="li">
                                    <Row>
                                        <Col md={6}>
                                            <label>Score:</label>
                                        </Col>
                                        <Col md={6} classNam={"text-right"}>
                                            <div className={"text-right"}> {this.props.entry.score.round1Score}</div>
                                        </Col>
                                    </Row>

                                </ListGroup.Item>
                                <ListGroup.Item as="li">
                                    <Row>
                                        <Col md={6}>
                                            <label>Rank:</label>
                                        </Col>
                                        <Col md={6} classNam={"text-right"}>
                                            <div className={"text-right"}> {this.props.entry.score.round1Rank ?
                                                this.props.entry.score.round1Rank + "/" + this.props.items
                                            : null}</div>
                                        </Col>
                                    </Row>


                                </ListGroup.Item>
                                <ListGroup.Item as="li">
                                    <Row>
                                        <Col md={12}>
                                            <label>Comments:</label>
                                        </Col>
                                        <Col md={12}>
                                            <span>{this.props.entry.score.round1Comments}</span>
                                        </Col>
                                    </Row>


                                </ListGroup.Item>
                            </ListGroup>
                                    : null
                                    : null}

                        {this.props.events.competition.competition.grade.rounds >= 2 && this.props.entry.score ?
                            <div>
                                <strong>Round 2</strong>
                                <ListGroup as="ul" className={"score-modal-list"}>
                                    <ListGroup.Item as="li">
                                        <Row>
                                            <Col md={6}>
                                                <label>Score:</label>
                                            </Col>
                                            <Col md={6} classNam={"text-right"}>
                                                <div className={"text-right"}> {this.props.entry.score.round2Score}</div>
                                            </Col>
                                        </Row>

                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                        <Row>
                                            <Col md={6}>
                                                <label>Rank:</label>
                                            </Col>
                                            <Col md={6} classNam={"text-right"}>
                                                <div className={"text-right"}> {this.props.entry.score.round2Rank ?

                                                    this.props.entry.score.round2Rank + "/" + this.props.items
                                                    : null}</div>
                                            </Col>
                                        </Row>


                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                        <Row>
                                            <Col md={12}>
                                                <label>Comments:</label>
                                            </Col>
                                            <Col md={12}>
                                                <span>{this.props.entry.score.round2Comments}</span>
                                            </Col>
                                        </Row>


                                    </ListGroup.Item>
                                </ListGroup>
                            </div>

                            : null}

                        {this.props.events.competition.competition.grade.rounds >= 3 && this.props.entry.score ?
                            <div>
                                <strong>Round 3</strong>
                                <ListGroup as="ul" className={"score-modal-list"}>
                                    <ListGroup.Item as="li">
                                        <Row>
                                            <Col md={6}>
                                                <label>Score:</label>
                                            </Col>
                                            <Col md={6} classNam={"text-right"}>
                                                <div className={"text-right"}> {this.props.entry.score.round3Score}</div>
                                            </Col>
                                        </Row>

                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                        <Row>
                                            <Col md={6}>
                                                <label>Rank:</label>
                                            </Col>
                                            <Col md={6} classNam={"text-right"}>
                                                <div className={"text-right"}> {this.props.entry.score.round3Rank ?

                                                    this.props.entry.score.round3Rank + "/" + this.props.items
                                                    : null}</div>
                                            </Col>
                                        </Row>


                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                        <Row>
                                            <Col md={12}>
                                                <label>Comments:</label>
                                            </Col>
                                            <Col md={12}>
                                                <span>{this.props.entry.score.round3Comments}</span>
                                            </Col>
                                        </Row>


                                    </ListGroup.Item>
                                </ListGroup>
                            </div>

                            : null}


                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user,
    single: state.single,
    events: state.events
});

const mapDispatchToProps = dispatch => ({
    post: (uri, item, token) => {
        dispatch(singleActions.post(uri, item, token));
    },
    postFile: (uri, fd, token) => {
        dispatch(singleActions.postFile(uri, fd, token));
    },
    patch: (uri, id, item, token) => {
        dispatch(singleActions.patch(uri, id, item, token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(ScoreModal));

class ScoreModalContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default ScoreModalContainer;
