/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Formik } from 'formik';
import * as yup from 'yup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as singleActions from '../../../store/actions/single';
import FormButtons from '../../Common/FormButtons';
import CreditCardInput from 'react-credit-card-input';
import Bound from '../../Common/Bound';
import { ArrowForward } from 'react-ionicons';

const schema = yup.object({
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('This field is required'),
  password: yup.string().min(8, 'Password needs to be at least 8 characters long'),
  password2: yup.mixed().test('match', 'Password does not match', function (password2) {
    return password2 === this.parent.password;
  })
});

class CardForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fileSelectField: '',
      filePickerOpen: false,
      selectingFileType: 'image',
      preview: '',
      image: '',
      disableNext: false,
      createSchool: '',
      createTeacher: ''
    };
  }

  handleFormModal = (e) => {
    this.setState({ filePickerOpen: false });
  };

  selectFile = (file) => {
    //this.props.updateField('image', file);
    this.props.setValues({ image: file });
  };

  handleSubmit = (values) => {
    console.log('handleSubmit');
    if (this.props.location.pathname.indexOf('entries') > -1) {
      this.props.postCard(values, this.props.auth.token._id);
    } else {
      console.log('handleSubmit - cards');
      this.props.post('cards', values, this.props.auth.token._id);
      this.props.setAdding(false);
    }
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(props, state) {}

  init = () => {};

  grades = [
    { name: 'Novice', id: 'novice' },
    { name: 'Beginner', id: 'beginner' },
    { name: 'Advanced', id: 'advanced' },
    { name: 'Prizewinner', id: 'prizewinner' }
  ];

  render() {
    return (
      <div>
        <Formik
          validationSchema={schema}
          onSubmit={this.handleSubmit}
          enableReinitialize
          initialValues={{
            number: '',
            expiry: '',
            cvc: ''
          }}>
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            isSubmitting,
            setFieldValue
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              {!this.props.hideFormButtons ? (
                <FormButtons
                  confirm={(e) => {
                    this.handleSubmit(values);
                  }}
                  back={(e) => {
                    e.preventDefault();
                    this.props.setAdding(false);
                  }}
                  showPrevious={true}
                  showRevert={false}
                  showNext={false}
                  next={(e) => {}}
                  showDefault={true}
                  label={'Add Payment Method'}
                />
              ) : null}

              <Row>
                <Col md={12}>
                  <Bound>
                    <div className={'field-container'}>
                      <Form.Group controlId="name">
                        <Form.Label>Card Number</Form.Label>
                        <CreditCardInput
                          cardNumberInputProps={{
                            value: values.number,
                            onChange: (e) => {
                              setFieldValue('number', e.target.value);
                            }
                          }}
                          cardExpiryInputProps={{
                            value: values.expiry,
                            onChange: (e) => {
                              setFieldValue('expiry', e.target.value);
                            }
                          }}
                          cardCVCInputProps={{
                            value: values.cvc,
                            onChange: (e) => {
                              setFieldValue('cvc', e.target.value);
                            }
                          }}
                          fieldClassName="input"
                        />
                      </Form.Group>
                    </div>
                  </Bound>
                </Col>
              </Row>

              {this.props.hideFormButtons ? (
                <div className={'text-center'}>
                  <div className={'divider'} />
                  <div className={'spacer'} />
                  <Button variant={'secondary'} onClick={(e) => this.handleSubmit(values)}>
                    Add Card &nbsp; <ArrowForward color={'#fff'} />
                  </Button>
                </div>
              ) : null}
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  single: state.single,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  postOnboarding: (view, data, token, redirect) => {
    dispatch(singleActions.postNextStep(view, data, token, true, redirect));
  },
  post: (view, data, token, redirect) => {
    dispatch(singleActions.post(view, data, token, true, redirect));
  },
  patch: (view, id, data, token) => {
    dispatch(singleActions.patch(view, id, data, token));
  },
  postCard: (data, token) => {
    dispatch(singleActions.postCard(data, token));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(CardForm));

class CardFormContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    );
  }
}

export default CardFormContainer;
