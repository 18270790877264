/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as tableActions from '../../../store/actions/table';
import DancerForm from './DancerForm';
import * as singleActions from '../../../store/actions/single';
import DancerProfile from './DancerProfile';
import PageLoader from '../../Common/PageLoader';

class SingleDancer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: []
    };
  }

  componentDidMount(props) {
    this.init();
  }

  componentDidUpdate(props, state) {
    if (this.props.match.params.id !== props.match.params.id) {
      this.init();
    }
  }

  init = () => {
    //console.log("Setting new", this.props.match.params.id)
    if (this.props.match.params.id === 'new') {
      this.props.setSingle({});
    } else {
      this.props.get(this.props.match.params.id, this.props.auth.token._id);
    }
  };

  render() {
    //console.log("D", this.props.match.params.subview);
    return (
      <div className={'page-container elevated padded rounded school-profile profile-page'}>
        <PageLoader loaded={true}>
          {this.props.match.params.subview ? (
            this.props.match.params.subview === 'edit' ? (
              <DancerForm />
            ) : this.props.match.params.id === 'new' ? (
              <DancerForm />
            ) : (
              <DancerProfile />
            )
          ) : this.props.match.params.id === 'new' ? (
            <DancerForm />
          ) : (
            <DancerProfile />
          )}
        </PageLoader>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  table: state.table,
  single: state.single
});

const mapDispatchToProps = (dispatch) => ({
  getTableData: (view, skip, limit, sort, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
  },
  getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
  },
  get: (id, token) => {
    dispatch(singleActions.getSingle('dancers', id, token));
  },
  setSingle: (d) => {
    dispatch(singleActions.setSingleEntryHard(d));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(SingleDancer));

class SingleDancerContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    );
  }
}

export default SingleDancerContainer;
