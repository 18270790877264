/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 1/16/2021
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as tableActions from '../../../store/actions/table';
import {Formik} from "formik";
import Form from "react-bootstrap/Form";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import { ArrowForward } from 'react-ionicons';
import * as singleActions from "../../../store/actions/single";


class TeacherAccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            addingCard: false
        }
    }

    schema = yup.object({

    });

    handleSubmit = (values) => {
        let data = new FormData();
        data.append("files", null);
        let params = {teacherAccess: values.teacherAccess, entryId: this.props.match.params.id};

        //console.log("Posting", values);
        data.set("data", JSON.stringify(params));

        this.props.post("entries/updateTeacherAccess", data, this.props.auth.token._id, (id) => {
            //this.props.history.push('/admin/entries/' + id);
        });

    }

    componentDidUpdate(props) {
        if (props.single.cards && this.props.single.cards) {
            if (props.single.cards.length !== this.props.single.cards.length) {
                this.setState({addingCard: false});
                this.init();
            }
        }
    }

    componentDidMount() {
        this.init();
    }

    init = () => {
        if (this.props.single.single._id  && this.props.single.single.event && this.props.match.params.view) {
            this.props.history.push(`/app/entries/${this.props.single.single._id}`)
        }
    }


    render() {
        return (
            <div className={"ex-pad elevated rounded"}>
                <Formik
                    validationSchema={this.schema}
                    onSubmit={this.handleSubmit}
                    enableReinitialize
                    initialValues={{
                        card: this.props.single.cards ? this.props.single.cards[0] : {last4: '', _id: ''},
                        tos: false,
                        highlight: false,
                        publication: false,
                        teacherAccess: this.props.single.single.teacherAccess
                    }}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                          touched,
                          isValid,
                          errors,
                          isSubmitting,
                          setFieldValue
                      }) => (
                        <Form noValidate onSubmit={handleSubmit} className={""}>




                            <div className={"spacer"}>

                            </div>
                            <div>

                                <p><b>Teacher Access:</b> I give permission for my teachers to view the uploaded videos for this entry.</p>
                                <p><i>If you do not allow this, the teacher will only be able to view the name of the entered competitions and whether or not you have uploaded a video.  They will not be able to view the video.</i></p>

                                <Form.Check
                                    type="checkbox"
                                    className="mb-2"
                                    label="I give permission"
                                    name="teacherAccess"
                                    checked={values.teacherAccess}
                                    onChange={(v) => {

                                        setFieldValue('teacherAccess', !values.teacherAccess)

                                    }}
                                    onBlur={handleBlur}
                                />
                                <div className={"spacer"} />


                            </div>

                            <Button variant={"primary"} onClick={e =>
                            {
                                e.preventDefault();

                                handleSubmit(values)
                            }}>

                                Save  &nbsp; <ArrowForward color={"#fff"} />
                            </Button>

                        </Form>
                    )}
                </Formik>


            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    single: state.single
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
    post: (view, data, token, redirect) => {
        dispatch(singleActions.postFormDataNoUpdate(view, data, token, true, redirect, true, true));
    },
    entryFirstStep: (id, token) => {
        dispatch(singleActions.entryFirstStep(id, token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(TeacherAccess));

class TeacherAccessContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default TeacherAccessContainer;
