import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { useFormikContext, Formik } from 'formik';
import * as yup from 'yup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as singleActions from '../../../../store/actions/single';
import FormButtons from '../../../Common/FormButtons';
import Select from '../../../Common/Select';
import 'react-quill/dist/quill.snow.css';

const schema = yup.object({
  name: yup.string().required('This field is required'),
  drop_high_low: yup.boolean().test('drop_high_low', '',
    function (value) {
      // For some reason if you use an arrow function here you cannot access the yup context as 'this'
      // https://www.npmjs.com/package/yup/v/1.0.0-alpha.3#api
      const { path, createError, parent } = this;

      let notEnoughJudges = false;
      if (value === true) {
        let judgeFieldName = 'judgeCount';
        for (let x = 1; x <= parent.rounds; x++) {
          // If any round has 3 judges or fewer this value cannot be true
          if(parseInt(parent[judgeFieldName + x]) <= 3) {
            notEnoughJudges = true;
            break;
          }
        }
      }

      if (notEnoughJudges) {
        return createError({
          path, 
          message: 'Drop High/Low cannot be used with fewer than 4 judges'
        });
      } else {
        return true;
      }
    }
  ),
  rounds: yup.number().test('rounds', '',
    function(value) {
      const { path, createError, parent } = this;

      if (value < 1 || value > 3) {
        return createError({
          path, 
          message: 'Rounds must be between 1 and 3'
        });
      } else {
        return true;
      }
    })
});

// TODO: Make this global
const ROTATION_TYPE = {
  SET: "SET",
  SPLIT: "SPLIT",
  ROTATING: "ROTATING"
}

const errorStyle = {
  color: '#D8000C'
};

// const handleRoundsChange = (rounds) => {
//   // Update judge count array to have correct number of values
//   const { values } = useFormikContext();
//   this.props.single.single.judge_count = [];
//   for (let i = 1; i <= rounds; i++) {
//     this.props.single.single.judge_count.push(values["judgeCount" + i]);
//   }
// }

class EditScoring extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fileSelectField: '',
      filePickerOpen: false,
      selectingFileType: 'image',
      pdf: '',
      preview: '',
      image: '',
      createModerator: '',
      createAdmin: ''
    };
  }

  handleFormModal = (e) => {
    this.setState({ filePickerOpen: false });
  };

  componentDidMount() {
    // TODO: May want to do this in getSingle in actions/single.js
    if (this.props.match.params.id === 'new') {
      // No need to fetch from DB, this is a new record
      this.props.single.single = {
        name: '',
        judge_rotation_type: ROTATION_TYPE.SET,
        showIp: true,
        judge_count: [1],
        rounds: 1,
        drop_high_low: false
      }
    } else {
      this.props.get(this.props.match.params.id, this.props.auth.token._id);
    }
    this.init();
  }

  componentDidUpdate(props, state) {
    if (props.single.single !== this.props.single.single) {
      this.init();
    }
  }

  init = () => {
    this.setState({ loading: false });
  };

  handleSubmit = (values) => {
    let view = 'scoring';
    let params = values;

    if (this.props.match.params.id !== 'new') {
      params._id = this.props.match.params.id;
    }

    params.judge_count = this.props.single.single.judge_count;

    if (params._id) {
      this.props.patch(view, params._id, params, this.props.auth.token._id);
    } else {
      this.props.post(view, params, this.props.auth.token._id, (id) => {
        this.props.history.push('/app/admin/scoring/edit/' + id);
      });
    }
  };

  handleJudgeChange = (event) => {
    let count = parseInt(event.target.value)
    let iterations = 0;
    let judgeCountArray = this.props.single.single.judge_count;
    if (!judgeCountArray || judgeCountArray === 0) {
      judgeCountArray = [];
    }
    switch (event.target.name) {
      case 'judgeCount1':
        iterations = 1;
        break;
      case 'judgeCount2':
        iterations = 2;
        break;
      case 'judgeCount3':
        iterations = 3;
        break;
    }

    // set values in judge_count array if there are enough rounds
    for (let x = 1; x <= iterations; x++) {
      if(this.props.single.single.rounds >= x) {
        // This round exists, set the judge count
        // The value possibilities in order: 
        // 1 - The new value if this round is the correct round for the component
        // 2 - The existing value if there is one
        // 3 - Default to 1
        let value = x === iterations ? count : 
          judgeCountArray.length >= x ? judgeCountArray[x - 1] : 1;
        if (judgeCountArray.length >= x) {
          judgeCountArray[x - 1] = value;
        } else {
          judgeCountArray.push(value);
        }
      }
    }
  }

  types = [
    {
      id: 'app',
      name: 'App'
    },
    {
      id: 'api',
      name: 'API'
    }
  ];

  render() {
    return (
      <div className={'elevated rounded ex-pad'}>
        <Formik
          validationSchema={schema}
          onSubmit={this.handleSubmit}
          enableReinitialize={true}
          initialValues={{
            name: this.props.single.single.name || '',
            judge_rotation_type: this.props.single.single.judge_rotation_type || '',
            showIp: this.props.single.single.showIp,
            judgeCount1: this.props.single.single.judge_count && this.props.single.single.judge_count.length > 0 ? this.props.single.single.judge_count[0] : 0,
            judgeCount2: this.props.single.single.judge_count && this.props.single.single.judge_count.length > 1 ? this.props.single.single.judge_count[1] : 0,
            judgeCount3: this.props.single.single.judge_count && this.props.single.single.judge_count.length > 2 ? this.props.single.single.judge_count[2] : 0,
            rounds: this.props.single.single.rounds || 1,
            drop_high_low: this.props.single.single.drop_high_low || false

          }}>
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            isSubmitting,
            setFieldValue
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <FormButtons
                back={(e) => {
                  e.preventDefault();
                  this.step(values, 'previous');
                }}
                showPrevious={false}
                showBack={true}
                onBack={(e) => {
                  this.props.history.push(`/app/admin/scoring`);
                }}
                showRevert={false}
                showNext={false}
                confirm={(e) => {
                  e.preventDefault();
                  this.handleSubmit(values);
                }}
                showDefault={true}
              />
              <Row>
                <Col md={6}>
                  <div className={'field-container'}>
                    <Form.Group>
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Enter Name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.name && !errors.name}
                        className={`form-control ${
                          touched.name ? (errors.name ? 'is-invalid' : 'is-valid') : ''
                        }`}
                      />
                    </Form.Group>
                  </div>
                </Col>
              </Row>
              <div className={'spacer'} />
              <div className={'divider'} />
              <div className={'spacer'} />
              <Row>
                <Col md={6}>
                  <div className={'field-container'}>
                    <Form.Check
                      type="checkbox"
                      className="mb-2"
                      label="Show Irish Points"
                      name="showIp"
                      value={values.showIp}
                      checked={values.showIp}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.showIp && !errors.showIp}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className={'field-container'}>
                    <Form.Check
                      type="checkbox"
                      className="mb-2"
                      label="Drop High and Low Scores"
                      name="drop_high_low"
                      value={values.drop_high_low}
                      checked={values.drop_high_low}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.drop_high_low && !errors.drop_high_low}
                    />
                    {errors.drop_high_low ? (
                        <div style={errorStyle}>{errors.drop_high_low}</div>
                      ) : null}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div>
                    <div className={'field-container'}>
                      <Form.Group controlId="judge_rotation_type">
                        <Form.Label>Panel Type</Form.Label>
                        <Select
                          items={[
                            {
                              id: ROTATION_TYPE.SET,
                              name: 'Set'
                            },
                            {
                              id: ROTATION_TYPE.SPLIT,
                              name: 'Split'
                            },
                            {
                              id: ROTATION_TYPE.ROTATING,
                              name: 'Rotating'
                            }
                          ]}
                          value={values.judge_rotation_type ? { id: values.judge_rotation_type } : {}}
                          itemValue={'id'}
                          onChange={(val) => {
                            setFieldValue(
                              'judge_rotation_type',
                              val.id
                            );
                          }}
                          displayValue={'name'}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <div className={'field-container'}>
                    <Form.Group>
                      <Form.Label>Round 1 Judges</Form.Label>
                      <Form.Control
                        type="number"
                        name="judgeCount1"
                        placeholder=""
                        value={values.judgeCount1}
                        onChange={(e) => {
                          this.handleJudgeChange(e);
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        isValid={touched.judgeCount1 && !errors.judgeCount1}
                        className={`form-control ${
                          touched.judges ? (errors.judgeCount1 ? 'is-invalid' : 'is-valid') : ''
                        }`}
                      />
                    </Form.Group>
                  </div>
                </Col>
                <Col md={4}>
                  <div className={'field-container'}>
                    <Form.Group>
                      <Form.Label>Round 2 Judges</Form.Label>
                      <Form.Control
                        type="number"
                        name="judgeCount2"
                        placeholder=""
                        value={values.judgeCount2}
                        onChange={(e) => {
                          this.handleJudgeChange(e);
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        isValid={touched.judgeCount2 && !errors.judgeCount2}
                        className={`form-control ${
                          touched.judges ? (errors.judgeCount2 ? 'is-invalid' : 'is-valid') : ''
                        }`}
                      />
                    </Form.Group>
                  </div>
                </Col>
                <Col md={4}>
                  <div className={'field-container'}>
                    <Form.Group>
                      <Form.Label>Round 3 Judges</Form.Label>
                      <Form.Control
                        type="number"
                        name="judgeCount3"
                        placeholder=""
                        value={values.judgeCount3}
                        onChange={(e) => {
                          this.handleJudgeChange(e);
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        isValid={touched.judgeCount2 && !errors.judgeCount3}
                        className={`form-control ${
                          touched.judges ? (errors.judgeCount3 ? 'is-invalid' : 'is-valid') : ''
                        }`}
                      />
                    </Form.Group>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <div className={'field-container'}>
                    <Form.Group>
                      <Form.Label>Rounds</Form.Label>
                      <Form.Control
                        type="number"
                        name="rounds"
                        placeholder=""
                        value={values.rounds}
                        onChange={(e) => {
                          this.props.single.single.rounds = e.target.value;
                          //handleRoundsChange(e.target.value);
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        isValid={touched.rounds && !errors.rounds}
                        className={`form-control ${
                          touched.rounds ? (errors.rounds ? 'is-invalid' : 'is-valid') : ''
                        }`}
                      />
                      {errors.rounds ? (
                        <div style={errorStyle}>{errors.rounds}</div>
                      ) : null}
                    </Form.Group>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  single: state.single,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  post: (view, data, token, redirect) => {
    dispatch(singleActions.post(view, data, token, true, redirect, true));
  },
  postOnboarding: (view, data, token, redirect) => {
    dispatch(singleActions.postNextStep(view, data, token, true, redirect));
  },
  patch: (view, id, data, token) => {
    dispatch(singleActions.patch(view, id, data, token, false, null, true));
  },
  get: (id, token) => {
    dispatch(singleActions.getSingle('scoring', id, token));
  },
  setBlankSingle: () => {
    dispatch(singleActions.setSingleEntry({ step: 0 }));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(EditScoring));

class EditScoringContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    );
  }
}

export default EditScoringContainer;
