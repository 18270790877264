import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as yup from 'yup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import urls from '../../../utils/urls';
import * as singleActions from '../../../store/actions/single';
import Select from '../../Common/Select';
import CategoryPicker from '../../Common/CategoryPicker';
import FormButtons from '../../Common/FormButtons';

const schema = yup.object({
  name: yup.string().required('This field is required')
});

class EventBasic extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fileSelectField: '',
      filePickerOpen: false,
      selectingFileType: 'image',
      pdf: '',
      preview: '',
      image: '',
      createModerator: '',
      createAdmin: ''
    };
  }

  handleFormModal = (e) => {
    this.setState({ filePickerOpen: false });
  };

  selectFile = (file) => {
    this.props.setValues({ image: file });
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(props, state) {
    if (props.single.single !== this.props.single.single) {
      this.init();
    }
  }

  init = () => {
    if (this.props.single.single.image) {
      if (this.props.single.single.image.path) {
        this.setState({
          preview: urls.getS3Url(
            this.props.single.single.image.thumb || this.props.single.single.image.path
          )
        });
      }
    } else if (this.state.preview) {
      this.setState({ preview: '' });
    }
  };

  handleSubmit = (values) => {
    let view = 'events';

    let data = new FormData();
    data.append('files', this.state.image);
    data.append('files', this.state.pdf);

    let params = { event: values };
    if (this.props.single.single._id) {
      params.eventId = this.props.single.single._id;
      params.direction = 'add';
    }
    params.event.admins = values.admins.map((item) => item._id);
    params.event.moderators = values.moderators.map((item) => item._id);
    params.event.region = values.region;

    if (params.event.region === '') {
      delete params.event.region;
    }

    if (!params.event.region._id) {
      delete params.event.region;
    }

    if (this.state.createAdmin) {
      params.createAdmin = this.state.createAdmin;
    }

    if (this.state.createModerator) {
      params.createModerator = this.state.createModerator;
    }

    if (this.props.single.single._id) {
      params.event._id = this.props.single.single._id;
    }

    data.set('data', JSON.stringify(params));

    if (params._id) {
      this.props.patch(view, params._id, data, this.props.auth.token._id);
    } else {
      if (this.props.single.single._id) {
        this.props.patch(
          view,
          this.props.single.single._id,
          data,
          this.props.auth.token._id,
          (id) => {
            this.props.history.push('/admin/events/' + id);
          }
        );
      } else {
        this.props.post(view, data, this.props.auth.token._id, (id) => {
          this.props.history.push('/admin/events/' + id);
        });
      }
    }
  };

  currencies = [
    {
      id: 'usd',
      name: 'United States Dollar'
    },
    {
      id: 'cad',
      name: 'Canadian Dollar'
    },
    {
      id: 'aud',
      name: 'Australian Dollar'
    }
  ];

  render() {
    return (
      <div>
        <Formik
          validationSchema={schema}
          onSubmit={this.handleSubmit}
          enableReinitialize
          initialValues={{
            name: this.props.single.single.name || '',
            image: this.props.single.single.image || '',
            pdf: this.props.single.single.pdf || '',
            email: this.props.single.single.email || this.props.user.user.email,
            phone: this.props.single.single.phone || this.props.user.user.phone || '',
            videoStart:
              this.props.location.pathname.indexOf('/event-dashboard/') > -1
                ? this.props.match.params.id === 'new'
                  ? ''
                  : this.props.single.single.videoStart
                  ? new Date(this.props.single.single.videoStart).toISOString().split('T')[0]
                  : ''
                : this.props.single.dancer.videoStart
                ? new Date(this.props.single.dancer.videoStart).toISOString().split('T')[0]
                : '' || '',
            videoEnd:
              this.props.location.pathname.indexOf('/event-dashboard/') > -1
                ? this.props.match.params.id === 'new'
                  ? ''
                  : this.props.single.single.videoEnd
                  ? new Date(this.props.single.single.videoEnd).toISOString().split('T')[0]
                  : ''
                : this.props.single.dancer.videoEnd
                ? new Date(this.props.single.dancer.videoEnd).toISOString().split('T')[0]
                : '' || '',
            registrationStart:
              this.props.location.pathname.indexOf('/event-dashboard/') > -1
                ? this.props.match.params.id === 'new'
                  ? ''
                  : this.props.single.single.registrationStart
                  ? new Date(this.props.single.single.registrationStart).toISOString().split('T')[0]
                  : ''
                : this.props.single.dancer.registrationStart
                ? new Date(this.props.single.dancer.registrationStart).toISOString().split('T')[0]
                : '' || '',
            registrationEnd:
              this.props.location.pathname.indexOf('/event-dashboard/') > -1
                ? this.props.match.params.id === 'new'
                  ? ''
                  : this.props.single.single.registrationEnd
                  ? new Date(this.props.single.single.registrationEnd).toISOString().split('T')[0]
                  : ''
                : this.props.single.dancer.registrationEnd
                ? new Date(this.props.single.dancer.registrationEnd).toISOString().split('T')[0]
                : '' || '',
            judgingStart:
              this.props.location.pathname.indexOf('/event-dashboard/') > -1
                ? this.props.match.params.id === 'new'
                  ? ''
                  : this.props.single.single.judgingStart
                  ? new Date(this.props.single.single.judgingStart).toISOString().split('T')[0]
                  : ''
                : this.props.single.dancer.judgingStart
                ? new Date(this.props.single.dancer.judgingStart).toISOString().split('T')[0]
                : '' || '',
            judgingEnd:
              this.props.location.pathname.indexOf('/event-dashboard/') > -1
                ? this.props.match.params.id === 'new'
                  ? ''
                  : this.props.single.single.judgingEnd
                  ? new Date(this.props.single.single.judgingEnd).toISOString().split('T')[0]
                  : ''
                : this.props.single.dancer.judgingEnd
                ? new Date(this.props.single.dancer.judgingEnd).toISOString().split('T')[0]
                : '' || '',
            region: this.props.single.single.region || {},
            regions: this.props.single.single.regions || [],
            admins: this.props.single.single.admins
              ? this.props.single.single.admins
              : [this.props.user.user],
            moderators: this.props.single.single.moderators || [],
            inPerson: this.props.single.single.inPerson || false,
            hideEntryDancerNumber: this.props.single.single.hideEntryDancerNumber || false,
            hideCompetitorEntries: this.props.single.single.hideCompetitorEntries || false,
            currency: this.props.single.single.currency || 'cad',
            admissionFee: this.props.single.single.admissionFee || 0,
            programFee: this.props.single.single.programFee || 0,
            familyMax: this.props.single.single.familyMax || 0,
            serviceFee: this.props.single.single.serviceFee || false,
            tabulators: this.props.single.single.tabulators || []
          }}>
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            isSubmitting,
            setFieldValue
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <FormButtons
                back={(e) => {
                  e.preventDefault();
                  this.step(values, 'previous');
                }}
                showPrevious={false}
                showBack={true}
                onBack={(e) => {
                  if (this.props.single.single._id) {
                    this.props.history.push(`/app/event-dashboard/${this.props.single.single._id}`);
                  } else {
                    this.props.history.push(`/app/event-dashboard`);
                  }
                }}
                showRevert={false}
                showNext={false}
                confirm={(e) => {
                  e.preventDefault();
                  this.handleSubmit(values);
                }}
                showDefault={true}
              />

              <Row>
                <Col md={12}>
                  <div className={''}>
                    <Row>
                      <Col>
                        <Row>
                          <Col md={12}>
                            <div className={'field-container'}>
                              <Form.Group controlId="name">
                                <Form.Label>Logo</Form.Label>
                                {this.state.preview ? (
                                  <img
                                    src={this.state.preview}
                                    className={'form-preview-image'}
                                    alt={'Event Logo'}
                                  />
                                ) : null}
                                <Form.Control
                                  type="file"
                                  name="image"
                                  placeholder="Upload image"
                                  onChange={(e) => {
                                    this.setState({
                                      preview: URL.createObjectURL(e.target.files[0]),
                                      image: e.target.files[0]
                                    });
                                  }}
                                  onBlur={handleBlur}
                                  isValid={touched.image && !errors.image}
                                  className={`form-control ${
                                    touched.image ? (errors.image ? 'is-invalid' : 'is-valid') : ''
                                  }`}
                                />
                              </Form.Group>
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className={'field-container'}>
                              <Form.Group controlId="name">
                                <Form.Label>Syllabus PDF</Form.Label>
                                {values.pdf ? (values.pdf.name ? values.pdf.name : null) : null}

                                <Form.Control
                                  type="file"
                                  name="pdf"
                                  placeholder="Upload PDF"
                                  onChange={(e) => {
                                    this.setState({
                                      pdf: e.target.files[0]
                                    });
                                  }}
                                  accept="application/pdf"
                                  onBlur={handleBlur}
                                  isValid={touched.pdf && !errors.pdf}
                                  className={`form-control ${
                                    touched.pdf ? (errors.pdf ? 'is-invalid' : 'is-valid') : ''
                                  }`}
                                />
                              </Form.Group>
                            </div>
                          </Col>
                        </Row>
                      </Col>

                      <Col>
                        <Row>
                          <Col md={12}>
                            <div className={'field-container'}>
                              <Form.Group controlId="name">
                                <Form.Label>Event Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="name"
                                  placeholder="Enter event name"
                                  value={values.name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  isValid={touched.name && !errors.name}
                                  className={`form-control ${
                                    touched.name ? (errors.name ? 'is-invalid' : 'is-valid') : ''
                                  }`}
                                />
                              </Form.Group>
                            </div>
                          </Col>

                          <Col md={12}>
                            <div className={'field-container'}>
                              <Form.Check
                                type="checkbox"
                                className="mb-2"
                                label="Charge Service Fee (3%)"
                                name="serviceFee"
                                value={values.serviceFee}
                                checked={values.serviceFee}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.serviceFee && !errors.serviceFee}
                              />
                            </div>
                          </Col>

                          <Col md={12}>
                            <div className={'field-container'}>
                              <Form.Check
                                type="checkbox"
                                className="mb-2"
                                label="In Person Event"
                                name="inPerson"
                                value={values.inPerson}
                                checked={values.inPerson}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.inPerson && !errors.inPerson}
                              />
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className={'field-container'}>
                              <Form.Check
                                type="checkbox"
                                className="mb-2"
                                label="Hide Entry Dancer Number/Disable Download Card"
                                name="hideEntryDancerNumber"
                                value={values.hideEntryDancerNumber}
                                checked={values.hideEntryDancerNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.hideEntryDancerNumber && !errors.hideEntryDancerNumber}
                              />
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className={'field-container'}>
                              <Form.Check
                                type="checkbox"
                                className="mb-2"
                                label="Hide Competitor Entries Number"
                                name="hideCompetitorEntries"
                                value={values.hideCompetitorEntries}
                                checked={values.hideCompetitorEntries}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.hideCompetitorEntries && !errors.hideCompetitorEntries}
                              />
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className={'field-container'}>
                              <Form.Group>
                                <Form.Label>Currency</Form.Label>
                                <Select
                                  disableTyping={true}
                                  items={this.currencies}
                                  value={this.currencies.filter((c) => c.id === values.currency)[0]}
                                  itemValue={'id'}
                                  onChange={(val) => {
                                    setFieldValue('currency', val.id);
                                  }}
                                  displayValue={'name'}
                                />
                              </Form.Group>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <div className={'spacer'} />
                    <div className={'divider'} />
                    <div className={'spacer'} />
                    <Row>
                      <Col md={4}>
                        <div className={'field-container'}>
                          <Form.Group controlId="name">
                            <Form.Label>Admission Fee</Form.Label>
                            <Form.Control
                              type="number"
                              name="admissionFee"
                              placeholder="Admission Fee"
                              value={values.admissionFee}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isValid={touched.admissionFee && !errors.admissionFee}
                              className={`form-control ${
                                touched.admissionFee
                                  ? errors.admissionFee
                                    ? 'is-invalid'
                                    : 'is-valid'
                                  : ''
                              }`}
                            />
                          </Form.Group>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className={'field-container'}>
                          <Form.Group controlId="name">
                            <Form.Label>FF Fee</Form.Label>
                            <Form.Control
                              type="number"
                              name="programFee"
                              placeholder="FF Fee"
                              value={values.programFee}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isValid={touched.programFee && !errors.programFee}
                              className={`form-control ${
                                touched.programFee
                                  ? errors.programFee
                                    ? 'is-invalid'
                                    : 'is-valid'
                                  : ''
                              }`}
                            />
                          </Form.Group>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className={'field-container'}>
                          <Form.Group controlId="name">
                            <Form.Label>
                              Family Maximum Charge (for relevant competitions)
                            </Form.Label>
                            <Form.Control
                              type="number"
                              name="familyMax"
                              placeholder="Family Maximum Charge"
                              value={values.familyMax}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isValid={touched.familyMax && !errors.familyMax}
                              className={`form-control ${
                                touched.familyMax
                                  ? errors.familyMax
                                    ? 'is-invalid'
                                    : 'is-valid'
                                  : ''
                              }`}
                            />
                          </Form.Group>
                        </div>
                      </Col>
                    </Row>

                    <div className={'spacer'} />
                    <div className={'divider'} />
                    <div className={'spacer'} />

                    <Row>
                      <Col md={6}>
                        <div className={'field-container'}>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Registration Start</Form.Label>
                            <Form.Control
                              type="date"
                              name="registrationStart"
                              placeholder="Enter email"
                              value={values.registrationStart}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isValid={touched.registrationStart && !errors.registrationStart}
                              className={`form-control ${
                                touched.registrationStart
                                  ? errors.registrationStart
                                    ? 'is-invalid'
                                    : 'is-valid'
                                  : ''
                              }`}
                            />
                          </Form.Group>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className={'field-container'}>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Registration End</Form.Label>
                            <Form.Control
                              type="date"
                              name="registrationEnd"
                              placeholder="Enter email"
                              value={values.registrationEnd}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isValid={touched.registrationEnd && !errors.registrationEnd}
                              className={`form-control ${
                                touched.registrationEnd
                                  ? errors.registrationEnd
                                    ? 'is-invalid'
                                    : 'is-valid'
                                  : ''
                              }`}
                            />
                          </Form.Group>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <div className={'field-container'}>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Video Start</Form.Label>
                            <Form.Control
                              type="date"
                              name="videoStart"
                              placeholder="Enter Date"
                              value={values.videoStart}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isValid={touched.videoStart && !errors.videoStart}
                              className={`form-control ${
                                touched.videoStart
                                  ? errors.videoStart
                                    ? 'is-invalid'
                                    : 'is-valid'
                                  : ''
                              }`}
                            />
                          </Form.Group>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className={'field-container'}>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Video End</Form.Label>
                            <Form.Control
                              type="date"
                              name="videoEnd"
                              placeholder="Enter email"
                              value={values.videoEnd}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isValid={touched.videoEnd && !errors.videoEnd}
                              className={`form-control ${
                                touched.videoEnd
                                  ? errors.videoEnd
                                    ? 'is-invalid'
                                    : 'is-valid'
                                  : ''
                              }`}
                            />
                          </Form.Group>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <div className={'field-container'}>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Judging Start</Form.Label>
                            <Form.Control
                              type="date"
                              name="judgingStart"
                              placeholder=""
                              value={values.judgingStart}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isValid={touched.judgingStart && !errors.judgingStart}
                              className={`form-control ${
                                touched.judgingStart
                                  ? errors.judgingStart
                                    ? 'is-invalid'
                                    : 'is-valid'
                                  : ''
                              }`}
                            />
                          </Form.Group>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className={'field-container'}>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Judging End</Form.Label>
                            <Form.Control
                              type="date"
                              name="judgingEnd"
                              placeholder="Enter email"
                              value={values.judgingEnd}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isValid={touched.judgingEnd && !errors.judgingEnd}
                              className={`form-control ${
                                touched.judgingEnd
                                  ? errors.judgingEnd
                                    ? 'is-invalid'
                                    : 'is-valid'
                                  : ''
                              }`}
                            />
                          </Form.Group>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <div className={'divider'} />
              <div className={'spacer'} />

              <Row>
                <Col md={6}>
                  <Row>
                    <Col md={12}>
                      <div className={'field-container'}>
                        <label>Regions</label>
                        {values.regions.map((c, i) => {
                          return (
                            <CategoryPicker
                              key={i}
                              items={this.props.single.regions}
                              value={c}
                              itemValue={'_id'}
                              selectedItems={values.regions.map((item) => item._id)}
                              onChange={(val) =>
                                setFieldValue(
                                  'regions',
                                  values.regions.map((cats, cati) => {
                                    if (cati === i) {
                                      let relevant = this.props.single.regions.filter(
                                        (c) => c._id === val
                                      );
                                      console.log('V', this.props.single.regions, relevant, val);
                                      return relevant[0]._id || {};
                                    } else {
                                      return cats;
                                    }
                                  })
                                )
                              }
                              displayValue={'name'}
                              imageKey={'image'}
                              showDelete={true}
                              disableTyping={true}
                              onDelete={(val) => {
                                console.log('DELETE', val);
                                setFieldValue(
                                  'regions',
                                  values.regions.filter((c) => c !== val)
                                );
                              }}
                            />
                          );
                        })}
                      </div>
                    </Col>
                    <Col md={12}>
                      <CategoryPicker
                        items={this.props.single.regions}
                        value={''}
                        itemValue={'_id'}
                        selectedItems={values.regions.map((item) => item._id)}
                        onChange={(val) => {
                          setFieldValue('regions', values.regions.concat(val));
                        }}
                        displayValue={'name'}
                        imageKey={'image'}
                        disableTyping={true}
                        onType={(e) => {}}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <div className={'field-container'}>
                    <label>Organization</label>

                    <Select
                      items={this.props.single.organizations}
                      value={values.organizations || {}}
                      itemValue={'_id'}
                      onChange={(val) => {
                        setFieldValue('organizations', val);
                      }}
                      displayValue={'name'}
                    />
                  </div>
                </Col>
              </Row>

              <div className={'divider'} />
              <div className={'spacer'} />

              <Row>
                <Col md={6}>
                  <Row>
                    <Col md={12}>
                      <div className={'field-container'}>
                        <label>Tabulators</label>
                        {values.tabulators.map((c, i) => {
                          return (
                            <CategoryPicker
                              items={
                                this.props.single.users
                                  ? this.props.single.users.concat(c)
                                  : [].concat(c)
                              }
                              value={c}
                              itemValue={'_id'}
                              onChange={(val) =>
                                setFieldValue(
                                  'tabulators',
                                  values.tabulators.map((cats, cati) => {
                                    console.log('ID', val);
                                    if (cati === i) {
                                      return val;
                                    } else {
                                      return cats;
                                    }
                                  })
                                )
                              }
                              remote={true}
                              remoteKey={'users'}
                              urlRoute={'/search'}
                              displayValue={'name'}
                              displayValue2={'lname'}
                              displayValue3={'email'}
                              localState={true}
                              imageKey={'image'}
                              showDelete={true}
                              onDelete={(val) => {
                                console.log('DELETE', val);
                                setFieldValue(
                                  'tabulators',
                                  values.tabulators.filter((c) => c !== val)
                                );
                              }}
                            />
                          );
                        })}
                      </div>
                    </Col>
                    <Col md={12}>
                      <CategoryPicker
                        items={this.props.single.users}
                        value={''}
                        itemValue={'_id'}
                        onChange={(val) => {
                          console.log('T', val);
                          setFieldValue('tabulators', values.tabulators.concat(val));
                        }}
                        remote={true}
                        remoteKey={'users'}
                        urlRoute={'/search'}
                        displayValue={'name'}
                        displayValue3={'email'}
                        imageKey={'image'}
                        localState={true}
                        displayValue2={'lname'}
                        showDelete={false}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <div className={'divider'} />
              <div className={'spacer'} />

              <Row>
                <Col md={6}>
                  <div className={'field-container'}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Contact Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder=""
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.email && !errors.email}
                        className={`form-control ${
                          touched.email ? (errors.email ? 'is-invalid' : 'is-valid') : ''
                        }`}
                      />
                    </Form.Group>
                  </div>
                </Col>
                <Col md={6}>
                  <div className={'field-container'}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Contact Phone Number</Form.Label>
                      <Form.Control
                        type="phone"
                        name="phone"
                        placeholder=""
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.phone && !errors.phone}
                        className={`form-control ${
                          touched.phone ? (errors.phone ? 'is-invalid' : 'is-valid') : ''
                        }`}
                      />
                    </Form.Group>
                  </div>
                </Col>
              </Row>

              <div className={'divider'} />
              <div className={'spacer'} />
              <Row>
                <Col md={12}>
                  <div className={'field-container'}>
                    <label>Administrators</label>
                    {values.admins.map((c, i) => {
                      return (
                        <CategoryPicker
                          key={i}
                          items={this.props.single.users}
                          value={c}
                          itemValue={'_id'}
                          selectedItems={values.admins.map((item) => item._id)}
                          onChange={(val) =>
                            setFieldValue(
                              'admins',
                              values.admins.map((cats, cati) => {
                                if (cati === i) {
                                  let relevant = this.props.single.users.filter(
                                    (c) => c._id === val._id
                                  );
                                  return relevant[0] || {};
                                } else {
                                  return cats;
                                }
                              })
                            )
                          }
                          remote={true}
                          remoteKey={'users'}
                          displayValue={'email'}
                          imageKey={'image'}
                          showDelete={i > 0}
                          onDelete={(val) => {
                            setFieldValue(
                              'admins',
                              values.admins.filter((c) => c._id !== val._id)
                            );
                          }}
                        />
                      );
                    })}
                  </div>
                </Col>
                <Col md={12}>
                  <CategoryPicker
                    items={this.props.single.users}
                    value={''}
                    itemValue={'_id'}
                    selectedItems={values.admins.map((item) => item._id)}
                    onChange={(val) => {
                      this.setState({ createAdmin: '' });
                      setFieldValue('admins', values.admins.concat(val));
                    }}
                    remote={true}
                    remoteKey={'users'}
                    displayValue={'email'}
                    displayJoinValue={'path'}
                    displayJoin={true}
                    imageKey={'image'}
                    onType={(e) => {
                      this.setState({ createAdmin: e.target.value });
                    }}
                  />
                </Col>
              </Row>
              <div className={'spacer'} />
              <div className={'divider'} />
              <div className={'spacer'} />
              <Row>
                <Col md={12}>
                  <div className={'field-container'}>
                    <label>Moderators</label>
                    {values.moderators.map((c, i) => {
                      return (
                        <CategoryPicker
                          key={c._id}
                          items={this.props.single.users}
                          value={c}
                          itemValue={'_id'}
                          selectedItems={values.moderators.map((item) => item._id)}
                          onChange={(val) =>
                            setFieldValue(
                              'moderators',
                              values.moderators.map((cats, cati) => {
                                if (cati === i) {
                                  let relevant = this.props.single.users.filter(
                                    (c) => c._id === val._id
                                  );
                                  return relevant[0] || {};
                                } else {
                                  return cats;
                                }
                              })
                            )
                          }
                          remote={true}
                          remoteKey={'users'}
                          displayValue={'email'}
                          imageKey={'image'}
                          showDelete={true}
                          onDelete={(val) => {
                            setFieldValue(
                              'moderators',
                              values.moderators.filter((c) => c._id !== val._id)
                            );
                          }}
                        />
                      );
                    })}
                  </div>
                </Col>
                <Col md={12}>
                  <CategoryPicker
                    items={this.props.single.users}
                    value={''}
                    itemValue={'_id'}
                    selectedItems={values.moderators.map((item) => item._id)}
                    onChange={(val) => {
                      this.setState({ createModerator: '' });
                      setFieldValue('moderators', values.moderators.concat(val));
                    }}
                    remote={true}
                    remoteKey={'users'}
                    displayValue={'email'}
                    displayJoinValue={'path'}
                    displayJoin={true}
                    imageKey={'image'}
                    onType={(e) => {
                      this.setState({ createModerator: e.target.value });
                    }}
                  />
                </Col>
              </Row>
              <div className={'spacer'} />
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  single: state.single,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  post: (view, data, token, redirect) => {
    dispatch(singleActions.postFormData(view, data, token, true, redirect, true));
  },
  postOnboarding: (view, data, token, redirect) => {
    dispatch(singleActions.postNextStep(view, data, token, true, redirect));
  },
  patch: (view, id, data, token) => {
    dispatch(singleActions.patchFormData(view, id, data, token, false, null, true));
  },
  get: (id, token) => {
    dispatch(singleActions.getSingle('events', id, token));
  },
  setBlankSingle: () => {
    dispatch(singleActions.setSingleEntry({ step: 0 }));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(EventBasic));

class EventBasicContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    );
  }
}

export default EventBasicContainer;
