import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as tableActions from '../../../store/actions/table';
import Table from 'react-bootstrap/Table';
import checks from '../../../utils/checks';

class ResultTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: []
    };
  }

  componentDidMount(props) {}

  componentDidUpdate(props, state) {}

  render() {
    let teams = this.props.competition.grade.type === "teams" || this.props.competition.grade.type === "adultteams"
    return (
      <div>
        <div className={'spacer'} />
        <h5>
          Comp {this.props.competition.code} | {this.props.competition.rowTitle}{' '}
          {this.props.competition.columnTitle} - {this.props.title}
        </h5>
        {this.props.competition.results[0].judges ? (
          <h6>
            Adjudicator:{' '}
            {this.props.competition.results[0]
              ? this.props.competition.results[0].judges.map((j) => j.name).join(', ')
              : ''}
          </h6>
        ) : null}

        {this.props.competition.totalResults ? (
          <h6>Total Competitors: {this.props.competition.totalResults}</h6>
        ) : null}
        <Table responsive striped>
          <thead>
            {this.props.competition.results[0] ? (
              <tr>
                <th>Rank</th>
                <th>{teams ? "Team" : "Dancer"}</th>
                {this.props.competition.results[0] ? this.props.competition.results[0].judges ? this.props.competition.results[0].judges.map((j, ji) => {
                    return (
                        <th key={ji}>{j.name}</th>
                    )

                }) : null : null}
                {this.props.competition.results[0].finalScore ? <th>Score</th> : null}
                {/* {this.props.competition.results[0].finalScore && !this.props.isMulti ? (
                  <th>Top Scores</th>
                ) : null} */}
                <th>School</th>
                {this.props.competition.results[0].judges ? <th>Comments</th> : null}
              </tr>
            ) : null}
          </thead>
          <tbody>
            {this.props.competition.results.map((res, c) => {
              return (
                <>
                  <tr key={c}>
                    {(this.props.title === 'Boys First Feis Trophy' ||
                      this.props.title === 'Girls First Feis Trophy') &&
                    c >= Math.ceil(this.props.competition.results.length / 2) ? (
                      <td>HM</td>
                    ) : (
                      <td>{res.finalRank}</td>
                    )}

                    <td>
                      {res.dancerNumber} {res.dancerName ? ' - ' + res.dancerName : ''}
                    </td>
                    {res.judges ? res.judges.map((judge, j) => {
                        return (
                            <td style={{width: "12.5%"}} key={j}>
                                {judge.totalScore}
                            </td>
                        )
                    }) : null}

                    {res.finalScore ? (
                      this.props.rounds ? (
                        this.props.rounds >= 2 ? (
                          <td>
                            <b>R1:</b> {res.judges[0].round1Score}
                            <br />
                            <b>R2:</b> {res.judges[0].round2Score}
                            <br />
                            <b>Total:</b> {res.judges[0].totalScore}
                          </td>
                        ) : (
                          // This will only work with 1 Rd 1 Judge but the Results record does not appear ton have final raw score
                          <td>{res.judges[0].totalScore}</td>
                        )
                      ) : (
                        <td>{res.finalScore}</td>
                      )
                    ) : null}

                    {/* {res.finalScore
                      ? res.judges && !this.props.isMulti
                        ? res.judges.map((judge, j) => {
                          return judge.topRound1 ?
                            (
                              <td style={{ width: '25%' }} key={j}>
                                <div>{judge.topRound1.join(', ')}</div>
                              </td>
                            ) : <td style={{ width: '25%' }} key={j}></td>

                          })
                        : null
                      : null} */}

                    <td>{res.school ? res.school.name : null}</td>
                    <td>{res.judges ? res.judges[0].round1Comments : null}</td>
                    {/* <td>
                      {res.judges
                        ? res.judges.map((judge, j) => {
                            return (
                              <div key={j}>
                                <div>
                                  <b>Round 1</b>
                                  <br />
                                  {judge.round1Comments}
                                </div>
                                {this.props.rounds ? (
                                  this.props.rounds >= 2 ? (
                                    <div>
                                      <br />
                                      <div>
                                        <b>Round 2</b>
                                        <br />
                                        {judge.round2Comments}
                                      </div>
                                      <div>
                                        <b>Good Job: </b>
                                        {judge.round2GoodJob
                                          ? Object.keys(judge.round2GoodJob)
                                              .filter((k) => {
                                                return judge.round2GoodJob[k];
                                              })
                                              .map((k) => {

                                                let check = checks.filter((ch) => ch.key === k)[0];
                                                if (check) {
                                                  return check.label;
                                                } else {
                                                  return null;
                                                }
                                              })
                                              .join(', ')
                                          : null}
                                      </div>
                                      <div>
                                        <b>Needs Work: </b>
                                        {judge.round2NeedsWork
                                          ? Object.keys(judge.round2NeedsWork)
                                              .filter((k) => {
                                                return judge.round2NeedsWork[k];
                                              })
                                              .map((k) => {

                                                let check = checks.filter((ch) => ch.key === k)[0];
                                                if (check) {
                                                  return check.label;
                                                } else {
                                                  return null;
                                                }
                                              })
                                              .join(', ')
                                          : null}
                                      </div>
                                    </div>
                                  ) : null
                                ) : null}
                              </div>
                            );
                          })
                        : null}
                    </td> */}
                  </tr>
                  {res.judges && this.props.rounds >= 3 ?
                    <tr>
                        <td></td>
                        <td></td>
                        {res.judges.map((judge, j) => {
                            return (
                              <td key={j}>
                                  <b>R1: </b> {judge.round1Score}<br />
                                  {this.props.competition.grade.rounds >= 2 ?
                                      (<span><b>R2: </b> {judge.round2Score}<br /></span>)
                                      : null}
                                  {this.props.competition.grade.rounds >= 3 ?
                                      (<span><b>R3: </b> {judge.round3Score}<br /></span>)
                                      : null}
                                  {this.props.competition.grade.rounds >= 2 ?
                                      (<span><b>Total: </b> {Math.round(((judge.round1Score ?? 0) + (judge.round2Score ?? 0) + (judge.round3Score ?? 0)) * 100) / 100}</span>)
                                      : null}
                              </td>
                            )
                        })}
                        <td></td>
                        <td></td>
                    </tr>
                        : null}
                </>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  table: state.table
});

const mapDispatchToProps = (dispatch) => ({
  getTableData: (view, skip, limit, sort, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
  },
  getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(ResultTable));

class ResultTableContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    );
  }
}

export default ResultTableContainer;
