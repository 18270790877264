/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Modal from "react-bootstrap/Modal";
import * as singleActions from '../../store/actions/single';
import Button from "react-bootstrap/Button";
import {CheckmarkDone} from 'react-ionicons';
import { Close } from 'react-ionicons';

class FinalizeCompetitionModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            preview: ''
        }
    }
    hideModal = () => {
        this.props.handleFinalizeCompetitionModal(false)

    }



    render() {
        return (
            <div className={this.props.user.user.theme}>
                <Modal centered size={'lg'} className={`theme-${this.props.user.user.theme} ${this.props.user.user ? this.props.user.user.darkTheme ? 'dark-theme' : '' : ''}`} show={this.props.show} onHide={e => this.props.hideModal(false)}>
                    <Modal.Body className={"text-center"}>


                        <h5>Are you sure you want to submit this competition?</h5>

                        <p>By clicking the button below, your scores for all entries in this competition will be submitted.  Please make sure that you have scored this competition
                         accurately, and are finished before proceeding.</p>
                        <p>Finalizing this competition will prevent you from being able to access it, or any of its entries, again.</p>

                        <div className={"text-center"}>
                            <Button variant={"secondary"} onClick={e => {
                                this.props.hideModal(false)
                            }}><Close /> I'll Double Check!</Button> &nbsp;
                            <Button variant={"primary"} onClick={e => {
                                this.props.submitCompetition();
                            }}><CheckmarkDone /> Yes I'm Sure!</Button>
                        </div>




                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user,
    single: state.single
});

const mapDispatchToProps = dispatch => ({
    post: (uri, item, token) => {
        dispatch(singleActions.post(uri, item, token));
    },
    postFile: (uri, fd, token) => {
        dispatch(singleActions.postFile(uri, fd, token));
    },
    patch: (uri, id, item, token) => {
        dispatch(singleActions.patch(uri, id, item, token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(FinalizeCompetitionModal));

class FinalizeCompetitionModalContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default FinalizeCompetitionModalContainer;
