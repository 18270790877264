/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 1/30/2021
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import * as tableActions from '../../../store/actions/table';
import Button from "react-bootstrap/Button";
import { ArrowBack } from "react-ionicons";
import * as eventActions from '../../../store/actions/events';


class Results extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    componentDidMount(props) {

    }

    componentDidUpdate(props, state) {

    }

    render() {
        return (
            <div className={'page-container'}>
                <div className={'page-header'}>
                    <Row>
                        <Col>
                            <h1>
                                {this.props.location.pathname.split("/").length > 3 ?
                                    <Button variant={"flat btn-round"} onClick={e => {

                                        this.props.history.push(`/app/event-dashboard/${this.props.match.params.id}`);
                                    }}>
                                        <ArrowBack />
                                    </Button>
                                    : null}

                                Results</h1>
                        </Col>
                    </Row>
                </div>
                <div>
                    {this.props.events.event.hasResults || this.props.events.event.hasPrivateResults ?
                        <Button variant={"primary"} onClick={e => {
                            this.props.history.push(`/app/results/event/${this.props.events.event._id}`);
                        }}>View Results</Button>
                    : null}
                    <br /><br />

                    {this.props.events.event.hasPrivateResults && !this.props.events.event.hasResults ?
                        <Button variant={"primary"} onClick={e => {

                            this.props.publishResults(this.props.match.params.id, true, this.props.auth.token._id);
                        }}>Publish Results</Button>
                    : this.props.events.event.hasResults ?
                            <Button variant={"primary"} onClick={e => {

                                this.props.publishResults(this.props.match.params.id, false, this.props.auth.token._id);
                            }}>Unpublish Results</Button> : null}

                    <br /><br />
                    {this.props.events.event.generatingResults ?
                        <div className={`lds-ring active form-upload-loader`}>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>

                        </div>
                    : null}

                    {!this.props.events.event.hasResults && !this.props.events.event.generatingResults ?
                    <Button variant={"primary"} onClick={e => {
                        this.props.generateResults(this.props.match.params.id, this.props.auth.token._id);
                    }}>Generate Results</Button>
                        : null}


                    {this.props.events.event.hasResults && !this.props.events.event.generatingResults ?
                        <Button variant={"primary"} onClick={e => {
                            this.props.generateResults(this.props.match.params.id, this.props.auth.token._id);
                        }}>Regenerate Results</Button>
                        : null}
                </div>


            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    events: state.events
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
    generateResults: (id, token) => {
        dispatch(eventActions.generateResults(id, token));
    },
    publishResults: (id, publish, token) => {
        dispatch(eventActions.togglePublishedResults(id, publish, token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Results));

class ResultsContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default ResultsContainer;