import composePlayer from 'vimond-replay/playerComposer';
import graphics from 'vimond-replay/default-player/default-skin/graphics';
import strings from '../../utils/strings';

const Player = composePlayer({
    name: 'Player',
    graphics,
    strings
});

export default Player;