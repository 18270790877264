import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import * as tableActions from '../../../store/actions/table';

const ages = [
    {
      id: 'all',
      name: 'All Ages'
    },
    {
      id: 'only5',
      name: '5 years'
    },
    {
      id: 'only6',
      name: '6 years'
    },
    {
      id: 'only7',
      name: '7 years'
    },
    {
      id: 'only8',
      name: '8 years'
    },
    {
      id: 'only9',
      name: '9 years'
    },
    {
      id: 'only10',
      name: '10 years'
    },
    {
      id: 'only11',
      name: '11 years'
    },
    {
      id: 'only12',
      name: '12 years'
    },
    {
      id: 'only13',
      name: '13 years'
    },
    {
      id: 'only14',
      name: '14 years'
    },
    {
      id: 'only15',
      name: '15 years'
    },
    {
      id: 'only16',
      name: '16 years'
    },
    {
      id: 'only17',
      name: '17 years'
    },
    {
      id: 'only18',
      name: '18 years'
    },
    {
      id: 'only19',
      name: '19 years'
    },
    {
      id: 'only20',
      name: '20 years'
    },
    {
      id: 'only21',
      name: '21 years'
    },
    {
      id: 'U5',
      name: 'Under 5'
    },
    {
      id: 'U6',
      name: 'Under 6'
    },
    {
      id: 'U7',
      name: 'Under 7'
    },
    {
      id: 'U8',
      name: 'Under 8'
    },
    {
      id: 'U9',
      name: 'Under 9'
    },
    {
      id: 'U10',
      name: 'Under 10'
    },
    {
      id: 'U11',
      name: 'Under 11'
    },
    {
      id: 'U12',
      name: 'Under 12'
    },
    {
      id: 'U13',
      name: 'Under 13'
    },
    {
      id: 'U14',
      name: 'Under 14'
    },
    {
      id: 'U15',
      name: 'Under 15'
    },
    {
      id: 'U16',
      name: 'Under 16'
    },
    {
      id: 'U17',
      name: 'Under 17'
    },
    {
      id: 'U18',
      name: 'Under 18'
    },
    {
      id: 'U19',
      name: 'Under 19'
    },
    {
      id: 'U20',
      name: 'Under 20'
    },
    {
      id: 'U21',
      name: 'Under 21'
    },
    {
      id: '8&O',
      name: '8 & Over'
    },
    {
      id: '9&O',
      name: '9 & Over'
    },
    {
      id: '10&O',
      name: '10 & Over'
    },
    {
      id: '11&O',
      name: '11 & Over'
    },
    {
      id: '12&O',
      name: '12 & Over'
    },
    {
      id: '13&O',
      name: '13 & Over'
    },
    {
      id: '14&O',
      name: '14 & Over'
    },
    {
      id: '15&O',
      name: '15 & Over'
    },
    {
      id: '16&O',
      name: '16 & Over'
    },
    {
      id: '17&O',
      name: '17 & Over'
    },
    {
      id: '18&O',
      name: '18 & Over'
    },
    {
      id: '19&O',
      name: '19 & Over'
    },
    {
      id: '20&O',
      name: '20 & Over'
    },
    {
      id: '21&O',
      name: '21 & Over'
    },
    {
      id: '22&O',
      name: '22 & Over'
    },
    {
      id: '23&O',
      name: '23 & Over'
    },
    {
      id: '5-6',
      name: '5 and 6'
    },
    {
      id: '6-7',
      name: '6 and 7'
    },
    {
      id: '7-8',
      name: '7 and 8'
    },
    {
      id: '8-9',
      name: '8 and 9'
    },
    {
      id: '9-10',
      name: '9 and 10'
    },
    {
      id: '10-11',
      name: '10 and 11'
    },
    {
      id: '11-12',
      name: '11 and 12'
    },
    {
      id: '12-13',
      name: '12 and 13'
    },
    {
      id: '13-14',
      name: '13 and 14'
    },
    {
      id: '14-15',
      name: '14 and 15'
    },
    {
      id: '15-16',
      name: '15 and 16'
    },
    {
      id: '16-17',
      name: '16 and 17'
    },
    {
      id: '17-18',
      name: '17 and 18'
    },
    {
      id: '18-19',
      name: '18 and 19'
    },
    {
      id: '19-20',
      name: '19 and 20'
    },
    {
      id: '20-21',
      name: '20 and 21'
    },
    {
      id: '21-22',
      name: '21 and 22'
    },
    {
      id: '5-7',
      name: '5 to 7'
    },
    {
      id: '6-8',
      name: '6 to 8'
    },
    {
      id: '7-9',
      name: '7 to 9'
    },
    {
      id: '8-10',
      name: '8 to 10'
    },
    {
      id: '9-11',
      name: '9 to 11'
    },
    {
      id: '10-12',
      name: '10 to 12'
    },
    {
      id: '11-13',
      name: '11 to 13'
    },
    {
      id: '11-14',
      name: '11 - 14'
    },
    {
      id: '12-14',
      name: '12 to 14'
    },
    {
      id: '13-15',
      name: '13 to 15'
    },
    {
      id: '14-16',
      name: '14 to 16'
    },
    {
      id: '15-17',
      name: '15 to 17'
    },
    {
      id: '16-18',
      name: '16 to 18'
    },
    {
      id: '17-19',
      name: '17 to 19'
    },
    {
      id: '18-20',
      name: '18 to 20'
    },
    {
      id: '19-21',
      name: '19 to 21'
    },
    {
      id: '20-22',
      name: '20 to 22'
    },
    {
      id: '21-23',
      name: '21 to 23'
    }
  ];

class TabulatorCompetitions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    componentDidMount(props) {
        this.props.getTableData({id: this.props.match.params.id}, this.props.auth.token._id)
    }

    componentDidUpdate(props, state) {

    }

    render() {
        return (
            <div className={'page-container'}>
                <div className={'page-header'}>
                    <Row>
                        <Col>
                            <h1>Competitions</h1>
                        </Col>
                    </Row>
                </div>

                {this.props.table.view === "events/getTabulatorCompetitions" ?
                    this.props.table.data ?
                        this.props.table.data.length > 0 ?
                            this.props.table.data.map((d, i) => {
                                let completed = false;
                                let ageObj = ages.find((a) => a.id === d.columnTitle);
                                let ageDisplay = ageObj ? ageObj.name : d.columnTitle;
                                if(this.props.table.judgeEvent){
                                    if(this.props.table.judgeEvent.event === this.props.match.params.id ) {
                                        if(this.props.table.judgeEvent.completed.indexOf(d._id) > -1) {
                                            completed = true;
                                        }
                                    }
                                }

                                return (
                                    <div className={`competition-tile ${completed ? 'disabled' : ''}`} onClick={e => {
                                        this.props.history.push(`/app/tabulation/${this.props.match.params.id}/${d._id}/1`)
                                    }}>

                                        <h1>{d.code}</h1>
                                        {d.grade ? 
                                            <h4>{d.grade.title}</h4>
                                        : null}
                                        <h4>{d.rowTitle} {ageDisplay}</h4>

                                        {
                                            completed ?
                                                <h5 className={``}>Completed</h5>
                                                :
                                                <h5 className={`${d.count > 0 ? '' : 'text-danger'}`}>{d.count} entries</h5>

                                        }


                                    </div>
                                )
                            })
                            : null
                        : null
                    : null}


            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table
});

const mapDispatchToProps = dispatch => ({
    getTableData: (query, token, mode) => {
        dispatch(tableActions.getTableDataWithQueryWithData("events/getTabulatorCompetitions", 0, 999, {}, query, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(TabulatorCompetitions));

class TabulatorCompetitionsContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default TabulatorCompetitionsContainer;
