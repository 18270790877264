/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Modal from "react-bootstrap/Modal";
import * as singleActions from '../../store/actions/single';
import Button from "react-bootstrap/Button";
import {Checkmark, CheckmarkDone, DoneAll, Trash} from 'react-ionicons';
import { Close } from 'react-ionicons';
import CategoryPicker from "./CategoryPicker";
import Form from "react-bootstrap/Form";
import Select from "./Select";
import Table from "react-bootstrap/Table";

class MergeConfirmationModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            preview: ''
        }
    }
    hideModal = () => {
        this.props.handleMergeConfirmationModal(false)

    }



    render() {
        return (
            <div className={this.props.user.user.theme}>
                <Modal centered size={'lg'} className={`theme-${this.props.user.user.theme} ${this.props.user.user ? this.props.user.user.darkTheme ? 'dark-theme' : '' : ''}`} show={this.props.show} onHide={e => this.props.hideModal(false)}>
                    <Modal.Body className={"text-center"}>


                        <h5>Are you sure you want to submit these changes?</h5>

                        <p>By clicking 'Yes' you acknowledge that this action is not reversible.  If you're sure you want to merge the following competitions, click 'Yes' to proceed.</p>


                        <Table>
                            <thead>
                            <tr>
                                <th>
                                    From
                                </th>
                                <th>
                                    =>
                                </th>
                                <th>
                                    To
                                </th>
                                <th>
                                    Level
                                </th>
                                <th>
                                    Dance
                                </th>
                                <th>
                                    Age Group
                                </th>
                                <th>
                                    Age Group Name
                                </th>
                                <th>
                                    Actions
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.props.values.comps.map((item, i) => {
                                return (
                                    <tr>
                                        <td>
                                            {item[0].code}
                                            {/*
                                                    <Form.Control
                                                        type="text"
                                                        name="lname"
                                                        placeholder="Competition #"
                                                        value={item[0]}
                                                        onChange={(val) => {
                                                            setFieldValue('comps', values.comps.map((c, ci) => {
                                                                if (ci === i) {
                                                                    return {
                                                                        ...c,
                                                                        0: val.target.value
                                                                    }
                                                                } else {
                                                                    return c;
                                                                }
                                                            }))
                                                        }}
                                                        onBlur={handleBlur}
                                                        className={`form-control`}
                                                    />
                                                    */}
                                        </td>
                                        <td>
                                            =>
                                        </td>
                                        <td>
                                            {item[1].code}
                                            {/*
                                                    <Form.Control
                                                        type="text"
                                                        name="lname"
                                                        placeholder="Competition #"
                                                        value={item[1]}
                                                        onChange={(val) => {
                                                            setFieldValue('comps', values.comps.map((c, ci) => {
                                                                if (ci === i) {
                                                                    return {
                                                                        ...c,
                                                                        1: val.target.value
                                                                    }
                                                                } else {
                                                                    return c;
                                                                }
                                                            }))
                                                        }}
                                                        onBlur={handleBlur}
                                                        className={`form-control`}
                                                    />
                                                    */}
                                        </td>
                                        <td>
                                            {item[2].title}
                                        </td>
                                        <td>
                                            {item[2].rowTitle}
                                        </td>
                                        <td>
                                            {item[2].columnCode}

                                        </td>
                                        <td>
                                            {item[2].columnTitle}
                                        </td>

                                    </tr>
                                )
                            })}
                            </tbody>


                        </Table>
                        {/*
                        <div className={"merge-table"}>
                            <div className={"merge-table-row merge-table-header"}>
                                <span className={"merge-row-item"}>From</span>
                                <span className={"merge-row-item mini-col"}>=></span>
                                <span className={"merge-row-item"}>To</span>
                                <span className={"merge-row-item"}>New Competition Name</span>
                            </div>
                            {this.props.values.comps.map((item, i) => {
                                return (
                                    <div className={"merge-table-row"}>
                                                <span className={"merge-row-item"}>
                                                    {item[0].code}
                                                </span>
                                        <span className={"merge-row-item mini-col"}>
                                                   =>
                                                </span>

                                        <span className={"merge-row-item"}>
                                                   {item[1].code}
                                                </span>

                                        <span className={"merge-row-item"}>
                                                    {item[2]}
                                                </span>


                                    </div>
                                )
                            })}
                            <div className={"separator"}>
                                <br />
                            </div>
                        </div>
                        */}
                        <div className={"separator"}>
                            <br />
                        </div>

                        <div className={"text-center"}>
                            <br /><br />
                            <Button variant={"flat"} className={'btn btn-flat raised'} onClick={e => {
                                this.props.hideModal(false)
                            }}><Close /> I'll Double Check!</Button> &nbsp; &nbsp;
                            <Button variant={"primary"} onClick={e => {
                                this.props.submitMerge(this.props.values);
                            }}><CheckmarkDone /> Yes I'm Sure!</Button>
                        </div>




                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user,
    single: state.single
});

const mapDispatchToProps = dispatch => ({
    post: (uri, item, token) => {
        dispatch(singleActions.post(uri, item, token));
    },
    postFile: (uri, fd, token) => {
        dispatch(singleActions.postFile(uri, fd, token));
    },
    patch: (uri, id, item, token) => {
        dispatch(singleActions.patch(uri, id, item, token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(MergeConfirmationModal));

class MergeConfirmationModalContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default MergeConfirmationModalContainer;
