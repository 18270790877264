/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 11/26/2020
 */

const defaultState = {
    sidebarOpen: false
};

const app = (state = defaultState, action) => {
    switch (action.type) {
        case 'LOGOUT':
            return defaultState;
        default:
            return defaultState
    }
}

export default app;