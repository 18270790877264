/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 1/13/2021
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as yup from 'yup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import urls from '../../../utils/urls';
import * as singleActions from '../../../store/actions/single';
import CategoryPicker from '../../Common/CategoryPicker';
import FormButtons from '../../Common/FormButtons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const schema = yup.object({
  name: yup.string().required('This field is required')
});

class EditUpdate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fileSelectField: '',
      filePickerOpen: false,
      selectingFileType: 'image',
      pdf: '',
      preview: '',
      image: '',
      createModerator: '',
      createAdmin: ''
    };
  }

  handleFormModal = (e) => {
    this.setState({ filePickerOpen: false });
  };

  selectFile = (file) => {
    //this.props.updateField('image', file);
    this.props.setValues({ image: file });
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(props, state) {
    if (props.single.single !== this.props.single.single) {
      this.init();
    }
  }

  init = () => {
    if (this.props.single.single.image) {
      if (this.props.single.single.image.path) {
        this.setState({
          preview: urls.getS3Url(
            this.props.single.single.image.thumb || this.props.single.single.image.path
          )
        });
      }
    } else if (this.state.preview) {
      this.setState({ preview: '' });
    }
  };

  handleSubmit = (values) => {
    let view = 'updates';
    let params = values;

    if (this.props.match.params.id !== 'new') {
      params._id = this.props.match.params.id;
    }

    if (params._id) {
      this.props.patch(view, params._id, params, this.props.auth.token._id);
    } else {
      this.props.post(view, params, this.props.auth.token._id, (id) => {
        this.props.history.push('/app/updates/' + id);
      });
    }
  };

  types = [
    {
      id: 'app',
      name: 'App'
    },
    {
      id: 'api',
      name: 'API'
    }
  ];

  render() {
    return (
      <div className={'elevated rounded ex-pad'}>
        <Formik
          validationSchema={schema}
          onSubmit={this.handleSubmit}
          enableReinitialize
          initialValues={{
            title: this.props.single.single.title || '',
            content: this.props.single.single.content || '',
            type: this.props.single.single.type || 'app',
            description: this.props.single.single.description || '',
            version: this.props.single.single.version || '',
            user: this.props.single.single.user || this.props.user.user
          }}>
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            isSubmitting,
            setFieldValue
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <FormButtons
                back={(e) => {
                  e.preventDefault();
                  this.step(values, 'previous');
                }}
                showPrevious={false}
                showBack={true}
                onBack={(e) => {
                  if (this.props.single.single._id && this.props.match.params.id !== 'new') {
                    this.props.history.push(`/app/updates/${this.props.single.single._id}`);
                  } else {
                    this.props.history.push(`/app/updates`);
                  }
                }}
                showRevert={false}
                showNext={false}
                confirm={(e) => {
                  e.preventDefault();
                  this.handleSubmit(values);
                }}
                showDefault={true}
              />
              <Row>
                <Col md={6}>
                  <div className={'field-container'}>
                    <Form.Group>
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        type="text"
                        name="title"
                        placeholder="Enter Title"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.title && !errors.title}
                        className={`form-control ${
                          touched.title ? (errors.title ? 'is-invalid' : 'is-valid') : ''
                        }`}
                      />
                    </Form.Group>
                  </div>
                </Col>
                <Col md={6}>
                  <div className={'field-container'}>
                    <label>Type</label>
                    <CategoryPicker
                      items={this.types}
                      value={values.type}
                      itemValue={'id'}
                      selectedItems={[]}
                      onChange={(val) => {
                        setFieldValue('type', val);
                      }}
                      displayValue={'name'}
                      imageKey={'image'}
                      disableTyping={true}
                      onType={(e) => {}}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}></Col>
                <Col md={6}>
                  <div className={'field-container'}>
                    <Form.Group>
                      <Form.Label>Version</Form.Label>
                      <Form.Control
                        type="text"
                        name="version"
                        placeholder="Version"
                        value={values.version}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.version && !errors.version}
                        className={`form-control ${
                          touched.version ? (errors.version ? 'is-invalid' : 'is-valid') : ''
                        }`}
                      />
                    </Form.Group>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <Form.Group>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      as="textarea"
                      name="description"
                      placeholder="Summary"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.description && !errors.description}
                      className={`form-control ${
                        touched.description ? (errors.description ? 'is-invalid' : 'is-valid') : ''
                      }`}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <ReactQuill
                theme="snow"
                value={values.content}
                onChange={(val) => {
                  setFieldValue('content', val);
                }}
              />

              <div className={'spacer'} />
              <div className={'divider'} />
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  single: state.single,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  post: (view, data, token, redirect) => {
    dispatch(singleActions.post(view, data, token, true, redirect, true));
  },
  postOnboarding: (view, data, token, redirect) => {
    dispatch(singleActions.postNextStep(view, data, token, true, redirect));
  },
  patch: (view, id, data, token) => {
    dispatch(singleActions.patch(view, id, data, token, false, null, true));
  },
  get: (id, token) => {
    dispatch(singleActions.getSingle('events', id, token));
  },
  setBlankSingle: () => {
    dispatch(singleActions.setSingleEntry({ step: 0 }));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(EditUpdate));

class EditUpdateContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    );
  }
}

export default EditUpdateContainer;
