/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as tableActions from '../../../../store/actions/table';
import Comments from './Comments';
import urls from "../../../../utils/urls";
import Rounds from './Rounds';
import LeaderboardModal from "../../../Common/LeaderboardModal";
import VideoIssueModal from "../../../Common/VideoIssueModal";
import Button from "react-bootstrap/Button";
import { Alert } from 'react-ionicons';
import Player from "../../../Common/Player";
import HlsjsVideoStreamer from "vimond-replay/video-streamer/hlsjs";


class Video extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            preview: null,
            showModal: false,
            showIssueModal: false,
            round: ""
        }
    }

    componentDidMount(props) {
        this.init();
    }

    componentDidUpdate(props, state) {
        if (this.props.location.pathname !== props.location.pathname) {
            this.setState({round: "", preview: ''});
        } else if(this.props.match.params.round !== this.state.round) {
            this.init();
        }


    }

    init = () => {
        //console.log

        if (this.props.events.competition.single["round" + this.props.match.params.round.toString()]) {
            if (this.props.events.competition.single["round" + this.props.match.params.round.toString()].path) {
                this.setState({round: this.props.match.params.round, preview: urls.getS3Url(this.props.events.competition.single["round" + this.props.match.params.round.toString()].path)})
            }
        } else if (this.state.preview) {
            this.setState({preview: ''})

        }
    }

    showModal = (event) => {
        this.setState({showModal: true});
    }

    hideModal = () => {
        this.setState({showModal: false});
    }


    showIssueModal = (event) => {
        this.setState({showIssueModal: true});
    }

    hideIssueModal = () => {
        this.setState({showIssueModal: false});
    }

    render() {

        return (
            <div className={"video-container-wrapper"}>
                <LeaderboardModal show={this.state.showModal} hideModal={this.hideModal} />
                <VideoIssueModal show={this.state.showIssueModal} hideModal={this.hideIssueModal} />

                <div className={"video-container"}>
                    <h3 className={"video-overlay-left"}>Dancer #{
                        this.props.events.competition.single?
                            this.props.events.competition.single.dancerNumber ?
                                this.props.events.competition.single.dancerNumber
                                :
                                this.props.events.competition.single.entry ?
                        this.props.events.competition.single.entry.sections ?
                            this.props.events.competition.single.entry.sections[0] ?
                                this.props.events.competition.single.entry.sections[0].dancerNumber
                                : null
                            : null
                                    : null

                        : null}</h3>
                    {this.state.round ?
                        <div>
                            <Player
                                options={{
                                    controls: {
                                        includeControls: [
                                            'playPauseButton',
                                            'timeline',
                                            'timeDisplay',
                                            'volume',
                                            'qualitySelector',
                                            'fullscreenButton',
                                        ],
                                        qualitySelectionStrategy: 'fix-bitrate'
                                    }
                                }}
                                source={this.state.preview} initialPlaybackProps={{ isPaused: false }}>
                                <HlsjsVideoStreamer />
                            </Player>
                            <br />
                        </div>

                        : null}

                </div>
                <div className={"text-right pt-1"}>
                    {/* <Button variant={"danger"} onClick={e => this.showIssueModal()}><Alert color={'#ffffff'} /> Report Issue</Button> */}
                    <Button variant={"danger"} onClick={() => (window.location.href='mailto:dance@feisfwd.com')}><Alert color={'#ffffff'} /> Report Issue</Button> 
                </div>


                {this.props.events.competition.single.rounds > 1 ?
                    <Rounds {...this.props} showModal={this.showModal} />
                : <div className={"spacer"} />}
                <Comments {...this.props} />

                <div className={"footer"}>
                    <div className={"text-center"}>
                        <a href={"https://www.feisfwd.com/privacy"} target={"_blank"} rel="noopener noreferrer">Privacy</a> ·&nbsp;
                        <a href={"https://www.feisfwd.com/terms"} target={"_blank"} rel="noopener noreferrer">Terms</a> ·&nbsp;
                        <a href={"https://www.feisfwd.com/"} target={"_blank"} rel="noopener noreferrer">About</a> ·&nbsp;
                        <a href={"http://www.feisfwd.com/faq"} target={"_blank"} rel="noopener noreferrer">FAQ</a>

                    </div>

                    <div className={"text-center"}>
                        FeisFWD &copy; {new Date().getFullYear()}
                    </div>

                </div>
            </div>
        )

    }
}


const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    single: state.single,
    events: state.events
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Video));

class VideoContainer extends React.Component {
    render() {
        return (

                <Connected {...this.props} />

        )
    }
}

export default VideoContainer;