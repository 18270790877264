/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 2/2/2021
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import * as tableActions from '../../../store/actions/table';
import urls from "../../../utils/urls";
import placeholder from "../../../assets/images/placeholder1.png";
import Button from "react-bootstrap/Button";


class JudgeEntry extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            preview: ''
        }
    }

    componentDidMount(props) {
        if (this.props.data["round" + this.props.r.toString()]) {
            if (this.props.data["round" + this.props.r.toString()].thumb && !this.state.preview) {
                this.setState({preview: urls.getS3Url(this.props.data["round" + this.props.r.toString()].thumb)})
            } /*else if (!this.props.data["round" + this.props.r.toString()].path && this.state.preview) {
                this.setState({preview: ''})
            }*/
        } else if (this.state.preview && !this.props.data["round" + this.props.r.toString()].thumb) {
            this.setState({preview: ''})
        }
    }

    componentDidUpdate(props, state) {
        if (this.props.data["round" + this.props.r.toString()]) {
            if (this.props.data["round" + this.props.r.toString()].thumb && !this.state.preview) {
                //this.setState({preview: urls.getS3Url(this.props.data["round" + this.props.r.toString()].thumb)})
            } /*else if(this.props.data["round" + this.props.r.toString()].path !== props.data["round" + this.props.r.toString()].path) {
                    this.setState({preview: urls.getS3Url(this.props.data["round" + this.props.r.toString()].path)})

                }*/
        } else if (this.state.preview) {
            this.setState({preview: ''})
        }
        if (props.data["round" + this.props.r.toString()] && this.props.data["round" + this.props.r.toString()]) {
            if (props.data["round" + this.props.r.toString()].thumb !== this.props.data["round" + this.props.r.toString()].thumb) {
                //console.log
                this.setState({preview: '', image: null})
                //this.setState({preview: urls.getS3Url(this.props.data["round" + this.props.r.toString()].path), image: null})
            }
        }
    }

    render() {
        return (
            <div className={'plan'} onClick={e => {
                this.props.history.push(`/app/j/1/${this.props.data._id}`)
            }}>
                <div>
                    <Row>
                        <Col md={3} className={""}>
                            <span className={'row-name article-row'}>
                                <div className={"news-item-img circle-img"} style={{background: this.state.preview ? `url(${this.state.preview}), linear-gradient(0deg, rgba(0,0,0,.6), rgba(0,0,0,.4), rgba(0,0,0,.2), transparent)` : `url(${placeholder}), linear-gradient(0deg, rgba(0,0,0,.6), rgba(0,0,0,.4), rgba(0,0,0,.2), transparent)`}}></div>
                                <div className={"row-title"}>
                                    Dancer #{
                                        this.props.data.dancerNumber ?
                                            this.props.data.dancerNumber
                                            :
                                            this.props.data.entry.sections[0] ?
                                                this.props.data.entry.sections[0].dancerNumber
                                                :null
                                    }
                                </div>
                                <div className={"row-content overflow-hidden"}>

                                </div>

                            </span>
                        </Col>
                        <Col md={6}>

                            {this.props.events.competition.competition.grade.rounds > 1 ?
                                <Row className={"row-details"}>
                                    <Col md={1}>
                                        <label className={"text-center"}>R1</label>
                                        <div
                                            className={"detail-value text-center"}>{this.props.data.score.round1Score ? this.props.data.score.round1Score : '-'}</div>
                                    </Col>
                                    <Col md={1}>
                                        <label className={"text-center"}>R2</label>
                                        <div
                                            className={"detail-value text-center"}>{this.props.data.score.round2Score ? this.props.data.score.round2Score : '-'}</div>
                                    </Col>

                                    {this.props.events.competition.competition.grade.rounds >= 3 ? 
                                        <Col md={2}>
                                        <label className={"text-center"}>Subtotal</label>
                                        <div className={"detail-value text-center"}>
                                            {
                                                this.props.data.score.round1Score && this.props.data.score.round2Score 
                                                    ? this.props.data.score.round1Score + this.props.data.score.round2Score 
                                                    : '-'
                                            }
                                        </div>
                                        </Col>
                                    : null}

                                    {this.props.events.competition.competition.grade.rounds >= 3 ?
                                        <Col md={2}>
                                            <label className={"text-center"}>R3</label>
                                            <div
                                                className={"detail-value text-center"}>{this.props.data.score.round3Score ? this.props.data.score.round3Score : '-'}</div>
                                        </Col>
                                    : null}

                                    <Col md={2}>
                                        <label className={"text-center"}>Total</label>
                                        <div className={"detail-value text-center"}>
                                            {
                                                this.props.events.competition.competition.grade.rounds >= 3 
                                                    ? (this.props.data.score.round1Score && this.props.data.score.round2Score && this.props.data.score.round3Score ? this.props.data.score.round1Score + this.props.data.score.round2Score + this.props.data.score.round3Score : '-')
                                                    : (this.props.data.score.round1Score && this.props.data.score.round2Score ? this.props.data.score.round1Score + this.props.data.score.round2Score : '-')
                                            }    
                                        </div>
                                    </Col>

                                    {this.props.events.competition.competition.grade.scoring.ip ?
                                        <Col md={2}>
                                            <label className={"text-center"}>IP</label>
                                            <div className={"detail-value text-center"}>
                                                {this.props.data.score.finalScore ? this.props.data.score.finalScore  : '-'}
                                            </div>
                                        </Col>
                                    : null}

                                    <Col md={2}>
                                        <label className={"text-center"}>Rank</label>
                                        <div className={"detail-value text-center"}>
                                            {this.props.data.score.round3Rank ?
                                                    this.props.data.score.round3Rank
                                                    : this.props.data.score.round2Rank ?
                                                    this.props.data.score.round2Rank
                                                        : this.props.data.score.round1Rank ?
                                                        this.props.data.score.round1Rank
                                                            : '-'
                                            }
                                        </div>
                                    </Col>

                                </Row>
                                :
                                <Row className={"row-details"}>
                                    <Col md={3}>
                                        <label className={"text-center"}>Score</label>
                                        <div
                                            className={"detail-value text-center"}>{this.props.data.score.round1Score ? this.props.data.score.round1Score : '-'}</div>
                                    </Col>
                                    <Col md={3}>
                                        <label className={"text-center"}>Rank</label>
                                        <div
                                            className={"detail-value text-center"}>{this.props.data.score.round1Rank ? this.props.data.score.round1Rank : '-'}</div>
                                    </Col>

                                </Row>
                            }
                        </Col>
                        <Col md={3} className={"text-right"}>
                            <div className={"pt-1"} />
                            <Button variant={"primary"} onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.props.loadScoreModal(this.props.data)
                            }}>Comments</Button>
                        </Col>

                    </Row>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    events: state.events
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(JudgeEntry));

class JudgeEntryContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default JudgeEntryContainer;
