/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 1/23/2021
 */

const defaultState = {
    notifications: [],
    count: 0,
    totalCount: 0
};

const notifications = (state = defaultState, action) => {
    switch (action.type) {
        case 'MARK_SEEN':
            return {
                ...state,
                notifications: state.notifications.map((note) => {
                    if (note._id === action.payload._id) {
                        return {
                            ...note,
                            seen: true,
                        }
                    } else {
                        return note
                    }
                })
            }
        case 'MARK_ALL_SEEN':
            return {
                ...state,
                notifications: state.notifications.map(note => {
                    return {
                        ...note,
                        seen: true
                    }
                }),
                count: 0
            }
        case 'INIT_NOTIFICATION':
            return {
                ...state,
                notifications: action.payload.data,
                count: action.payload.count
            }
        case 'NEW_NOTIFICATION':
            let existing = state.notifications.filter(n => n._id === action.payload._id);
            if (existing.length === 0) {

                return {
                    ...state,
                    notifications: [action.payload].concat(state.notifications),
                    count: state.count + 1
                }
            } else {
                return state
            }
        default:
            return {
                ...state
            };
    }
}

export default notifications;