/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import * as singleActions from '../../store/actions/single';
import Col from 'react-bootstrap/Col';
import Form from "react-bootstrap/Form";
import {Formik} from "formik";
import Button from "react-bootstrap/Button";
import * as errorActions from "../../store/actions/errors";

class VideoIssueModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            preview: ''
        }
    }
    hideModal = () => {
        this.props.handleVideoIssueModal(false)

    }


    handleSubmit = (values) => {
        let params = {
            url: window.location.href,
            error: {
                type: values.type,
                comments: values.comments
            }
        }
        //console.log

        this.props.postError(params.url, params.error, params.error, this.props.auth.token._id);
        this.props.hideModal(false)
    }

    render() {
        return (
            <div className={this.props.user.user.theme}>
                <Modal centered size={'lg'} className={`theme-${this.props.user.user.theme} ${this.props.user.user ? this.props.user.user.darkTheme ? 'dark-theme' : '' : ''}`} show={this.props.show} onHide={e => this.props.hideModal(false)}>
                    <Modal.Body>

                            <Formik
                                validationSchema={this.schema}
                                onSubmit={this.handleSubmit}
                                enableReinitialize
                                ref={this.form}
                                initialValues={{

                                }}
                            >
                                {({
                                      handleSubmit,
                                      handleChange,
                                      handleBlur,
                                      values,
                                      touched,
                                      isValid,
                                      errors,
                                      isSubmitting,
                                      setFieldValue
                                  }) => (
                                    <Form noValidate onSubmit={handleSubmit}>

                                        <Row>
                                            <Col md={12}>
                                                <div className={"field-container"}>
                                                    <Form.Group controlId="formGridState">
                                                        <Form.Label>Type of issue</Form.Label>
                                                        <Form.Control as="select" name="type" defaultValue="Choose..."
                                                                      onChange={handleChange}
                                                                      onBlur={handleBlur}
                                                                      value={values.type}>
                                                            <option>Choose...</option>
                                                            <option value={"wrongVideo"}>Wrong Video</option>
                                                            <option value={"other"}>Other</option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <div className={"field-container"}>
                                                    <Form.Group controlId="formGridState">
                                                        <Form.Label>Additional comments</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            placeholder={"Write a bit about what the issue is"}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.comments}
                                                            name={"comments"}
                                                        >
                                                        </Form.Control>
                                                    </Form.Group>
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <div className={"text-right"}>
                                                    <Button type={"submit"} variant="secondary">Send Report</Button>
                                                </div>
                                            </Col>
                                        </Row>

                                    </Form>
                                    )}
                            </Formik>


                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user,
    single: state.single
});

const mapDispatchToProps = dispatch => ({
    post: (uri, item, token) => {
        dispatch(singleActions.post(uri, item, token));
    },
    postFile: (uri, fd, token) => {
        dispatch(singleActions.postFile(uri, fd, token));
    },
    patch: (uri, id, item, token) => {
        dispatch(singleActions.patch(uri, id, item, token));
    },
    postError: (url, error, errorInfo, token) => {
        dispatch(errorActions.logVideoError(url, error, errorInfo, token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(VideoIssueModal));

class VideoIssueModalContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default VideoIssueModalContainer;
