/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 6/11/2020
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Trash } from 'react-ionicons';
import { Create } from 'react-ionicons';
import * as tableActions from '../../../../store/actions/table';
import * as singleActions from '../../../../store/actions/single';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import arrayMove from 'array-move';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { CheckmarkDone } from 'react-ionicons';
import { Globe } from 'react-ionicons';

const ItemRenderer = SortableElement((props) => {
  const {
    data: { _id, name },
    remove,
    edit
  } = props;

  return (
    <div>
      <div className={'plan'}>
        <Row>
          <Col md={8}>
            <span className={'row-name'}>{name}</span>
          </Col>
          <Col md={4} className={'text-right action-buttons-right'}>
            <Button
              variant={'primary'}
              onClick={(e) => edit(_id)}
              className={'btn-flat btn-transparent btn-slim'}>
              <Create fontSize={'22px'} />
            </Button>{' '}
            &nbsp;
            <Button
              variant={'primary'}
              onClick={(e) => remove(_id)}
              className={'btn-flat btn-transparent btn-slim'}>
              <Trash fontSize={'22px'} />
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
});

const SortableItems = SortableContainer(({ data, save, remove, edit }) => {
  return (
    <div className={'sortable-categories-droppad'}>
      {data.map((item, i) => {
        return (
          <ItemRenderer
            key={i}
            index={i}
            remove={(id) => remove(id)}
            edit={(id) => edit(id)}
            data={item}
          />
        );
      })}
    </div>
  );
  /*

    return (
        <div className={'page-container'}>
            <div className={'page-header'}>
                <Row>
                    <Col>
                        <h1>Regions</h1>
                    </Col>
                    <Col>
                        <div className={'text-right'}>
                            <Button variant={'primary'} className={'btn-transparent btn-flat'}><MdUndo
                                fontSize={'18px'}/> Reset</Button>
                            <Button variant={'primary'} onClick={e => save(e, data)}><MdDoneAll
                                fontSize={'18px'}/> Save</Button>
                        </div>
                    </Col>
                </Row>
            </div>

            <Sortly items={items} onChange={handleChange}>
                {(props) => <ItemRenderer key={props.index} {...props} remove={id => remove(id)}
                                          edit={id => edit(id)}/>}
            </Sortly>

        </div>
    );

 */
});

class Regions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: []
    };
  }

  componentDidMount(props) {
    if (this.props.table.view !== 'Regions') {
      this.props.getTableData('Regions', 0, 999, { number: 1 }, this.props.auth.token._id, 'add');
    }
  }

  componentDidUpdate(props, state) {
    if (
      (this.props.table.data.length !== this.state.items.length ||
        this.props.table.updateCount > props.table.updateCount ||
        this.props.table.data.length !== this.state.items.length) &&
      this.props.table.view === 'Regions'
    ) {
      //this.setState({items: this.props.table.data});
    }
  }

  dragEnd = () => {};

  remove = (id) => {
    this.props.deleteItem(id, this.props.auth.token._id);
  };

  edit = (id) => {
    if (this.props.table.data.filter((d) => d._id === id).length > 0) {
      this.props.handleFormModalWithData(this.props.table.data.filter((d) => d._id === id)[0]);
    }
  };

  save = (e, items) => {
    this.props.reorder(items, this.props.auth.token._id);
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    //console.log("OnSortEnd");
    this.props.setSingleEntry(arrayMove(this.props.table.data, oldIndex, newIndex));
  };

  render() {
    return (
      <div className={'circle-plan-button-page'}>
        <div className={'page-header'}>
          <Row>
            <Col>
              <h1>
                <Globe /> Regions
              </h1>
            </Col>

            <Col>
              {this.props.user.user ? (
                this.props.user.user.role ? (
                  this.props.user.user.role === 'admin' ? (
                    <Col>
                      <div className={'text-right'}>
                        <Button
                          variant={'primary'}
                          onClick={(e) => {
                            this.save(e, this.props.table.data);
                          }}>
                          <CheckmarkDone fontSize={'18px'} color={'#fff'} /> &nbsp; Save
                        </Button>
                      </div>
                    </Col>
                  ) : null
                ) : null
              ) : null}
            </Col>
          </Row>
        </div>

        <div id={'cat-click-1'}>
          <SortableItems
            save={(e, items) => this.save(e, items)}
            items={this.props.table.data}
            remove={(id) => this.remove(id)}
            edit={(id) => this.edit(id)}
            data={this.props.table.data}
            onSortEnd={this.onSortEnd}
            hideSortableGhost={true}
            helperContainer={document.getElementById('cat-click-1')}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  table: state.table,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  getTableData: (view, skip, limit, sort, token, mode) => {
    dispatch(tableActions.getTableData(view, skip, limit, sort, token, mode));
  },
  reorder: (data, token) => {
    dispatch(tableActions.reorder('Regions'.toLowerCase(), data, token));
  },
  setSingleEntry: (data) => {
    dispatch(tableActions.setSingleEntry(data));
  },
  deleteItem: (id, token) => {
    dispatch(tableActions.deleteItem('Regions'.toLowerCase(), id, token));
  },
  setSingleData: (item) => {
    dispatch(singleActions.setSingleEntry(item));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Regions));

class RegionsContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    );
  }
}

export default RegionsContainer;
