// import Image from 'next/image'
import React from 'react';

import { PDFViewer, Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';

import Logo from "../../../assets/images/wcro-2021-logo.png";
import urls from "../../../utils/urls";

const PublicPDF = ({ pdfData }) => {

    const table = pdfData.table;
    const titles = pdfData.titles;
    const subtitles = pdfData.subtitles;
    const judge = pdfData.judges || [];
    let tables = pdfData.tables;
    let logo = urls.getS3Url(pdfData.image);
    console.log("Tables", tables);
    if (tables.length === 0) {
        tables = [table];
    }

    return (
        <PDFViewer style={styles.pdfWrap}>
            <Document>
                {tables ?
                    tables.map((item, index) => {
                        //console.log("Building item", item);
                        let pager = "Page " + (index + 1).toString() + " / " + tables.length.toString();

                        return (
                            <Page size="A4" key={index} wrap>
                                <Image style={styles.logoImage} src={logo}/>
                                <View style={styles.titleWrap}>
                                    {titles.map((title, i) => {
                                        if (i === 0) {
                                            return <Text key={i} style={[styles.titles, {fontSize: 19, textAlign: 'left'}]}>{title}</Text>
                                        } else if (i === 1) {
                                            return <Text key={i} style={[styles.titles2, {fontSize: 100, textAlign: 'left'}]}>{title}</Text>
                                        } else {
                                            return <Text key={i} style={[styles.titles3, {fontSize: 22, textAlign: 'left'}]}>{title}</Text>
                                        }
                                    }

                                    )}

                                </View>

                                {judge ?
                                <View style={styles.judgeWrap}>



                                    <Text style={[styles.subtitles, {fontSize: 20}]}>Judge: {judge.join(', ')}</Text>

                                </View> : null}

                                <View style={styles.subtitleWrap}>


                                    {subtitles ? subtitles.map((title) =>
                                        <Text style={[styles.subtitles, {fontSize: 11}]}>{title}</Text>
                                    ) : <Text></Text>}
                                    {/*
                                    <Text style={[styles.subtitles, styles.pagerWrap, {fontSize: 11}]}>{pager}</Text>
                                    */}

                                </View>
                                <View style={styles.tableWrap}>
                                    <View style={styles.tableContentWrap}>
                                        <View key={"headers"} style={styles.contentRow}>
                                            {item.headers ? item.headers.map ? item.headers.map((td, i) => {

                                                    console.log("TD", td);
                                                    if (td === "Rank") {
                                                        return <Text key={i} style={[styles.rankColumn, {fontSize: '16px', textAlign: 'left'}]}>{td}</Text>
                                                    } else if (td === "Dancer #") {

                                                        return <Text key={i} style={[styles.dancerNumberColumn, {fontSize: '16px', textAlign: 'left'}]}>{td}</Text>
                                                    } else {
                                                        return <Text key={i} style={[styles.tableTitles, styles.titleColumn, {fontSize: '16px', textAlign: 'left'}]}>{td}</Text>
                                                    }
                                                }

                                            ) : null : null}
                                        </View>


                                        <View style={styles.tableContentBorder}>
                                            {item.content ? item.content.map((tr, i) =>
                                                <View key={i} style={styles.contentRow}>
                                                    {tr ? tr.map((td, j) => {
                                                        if (item.headers[j] === "Rank") {
                                                            return <Text key={j} style={[styles.rankColumn, styles.tableCells, {fontSize: '20px', fontWeight: 'bold'}]}>{td}</Text>
                                                        } else if (item.headers[j] === "Dancer #") {
                                                            return <Text key={j} style={[styles.dancerNumberColumn, styles.tableCells, {fontSize: '20px', fontWeight: 'bold'}]}>{td}</Text>
                                                        } else {
                                                            return <Text key={j} style={[styles.tableCells, {fontSize: '14px', fontWeight: 'normal'}]}>{td}</Text>
                                                        }

                                                    }) : null}
                                                </View>
                                            ) : null}
                                        </View>



                                    </View>
                                </View>
                            </Page>
                        )
                    })

                    :
                    <Page size="A4" wrap>
                        <Image style={styles.logoImage} src={Logo}/>
                        <View style={styles.titleWrap}>
                            {titles.map((title) =>
                                <Text style={[styles.titles, {fontSize: 12}]}>{title}</Text>
                            )}

                        </View>

                        <View style={styles.subtitleWrap}>


                            {subtitles ? subtitles.map((title) =>
                                <Text style={[styles.subtitles, {fontSize: 11}]}>{title}</Text>
                            ) : <Text></Text>}
                        </View>
                        <View style={styles.tableWrap}>
                            <View style={styles.tableContentWrap}>
                                <View key={"headers"} style={styles.contentRow}>
                                    {table.headers.map((td, i) =>
                                        <Text key={i} style={styles.tableTitles}>{td}</Text>
                                    )}
                                </View>
                                {table.content.map((tr, i) =>
                                    <View key={i} style={styles.contentRow}>
                                        {tr.map((td, j) => <Text key={j} style={styles.tableTitles}>{td}</Text>)}
                                    </View>
                                )}
                            </View>
                        </View>
                    </Page>
                }
            </Document>
        </PDFViewer>
    )
}

// Create styles
const styles = StyleSheet.create({
    container: {
        height: "100vh !important",
    },
    pdfWrap: {
        backgroundColor: "green",
        fontSize: 9,
        height: "100%",
        width: "100%",
    },
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    // LOGO
    logoImage: {
        position: "absolute",
        right: "25px",
        top: "25px",
        height: "50px",
        width: "50px",
        zIndex: 3,
    },

    // TITLES
    titles: {
        display: "flex",
        margin: "5px 0 5px 0",
        lineHeight: ".5px",
    },

    titles2: {
        display: "inline",
        margin: "15px 0 5px 0",
        lineHeight: "1px",
        fontWeight: 'bold'
    },
    titles3: {
        display: "inline",
        margin: "00px 0px 0px 0px",
        lineHeight: "1px",
        fontWeight: 'bold',
        position: 'relative',
        left: '0px',
        paddingTop: '5px'

    },
    subtitles: {
        textAlign: 'left'
    },
    pagerWrap: {
      position: 'absolute',
      bottom: '20px',
        right: '30px',
        display: 'block',
        height: '20px'
    },

    // TITLE WRAP
    titleWrap: {
        alignItems: "left",
        justifyContent: "center",
        color: "black",
        display: "flex",
        margin: "20px 0 0 50px",
        lineHeight: ".5px",
        width: "100%",
    },

    subtitleWrap: {
        alignItems: "left",
        justifyContent: "center",
        color: "black",
        display: "flex",
        margin: "0px 0 0 50px",
        width: "100%",
    },

    judgeWrap: {
        alignItems: "left",
        justifyContent: "center",
        color: "black",
        display: "flex",
        marginTop: "20px",
        marginLeft: "50px",
        width: "100%",
    },

    //TABLE
    tableWrap: {
        border: "0px solid transparent",
        margin: "20px 0 0 50px",
        width: "500px",
        fontSize: 9,
    },
    tableTitlesWrap: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
    },
    tableContentWrap: {
        display: "flex",
        flexDirection: "column",

        width: "100%",
    },
    rankColumn: {
      flex: 1,
        lineHeight: "initial",
      maxWidth: "70px",
        textAlign: 'center'
    },
    dancerNumberColumn: {
        flex: 1,
        maxWidth: "100px",
        textAlign: 'center'
    },
    titleColumn: {
        border: "0px solid grey",
        flex: 1,
        padding: "0px",
        paddingBottom: "10px",

        textAlign: 'center'
    },
    contentRow: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
    },
    tableTitles: {
        border: "0px solid grey",
        flex: 1,
        padding: "5px",
    },
    tableCells: {
        border: "1px solid grey",
        flex: 1,
        padding: "5px",
        margin: "0px !important"
    },
    tableContent: {
        border: "0px solid black",
        flex: 1,
        padding: "3px 5px",
    },

    // GRID
    gridContainer: {
        height: "841px",
        width: "594px",
        position: "absolute",
    },
    HG: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        position: "absolute",
        width: "100%",
    },
    VG: {
        display: "flex",
        flexDirection: "row",
        height: "100%",
        position: "absolute",
        width: "100%",
    },
    HGP: {
        border: "1px solid red",
        height: "5%",
    },
    VGP: {
        border: "1px solid red",
        height: "100%",
        width: "5%",
    }
});

export default PublicPDF;
