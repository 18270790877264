/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Modal from "react-bootstrap/Modal";
import * as singleActions from '../../store/actions/single';
import { Trash } from 'react-ionicons';
import Button from 'react-bootstrap/Button';
import { Close } from 'react-ionicons';


class DeleteModal extends React.Component {

    postSingle = (item) => {

    }

    renderContent = () => {

    }
    render() {
        return (
            <div className={this.props.user.user.theme}>
                <Modal centered size={'md'} className={`theme-${this.props.user.user.theme} ${this.props.user.user ? this.props.user.user.darkTheme ? 'dark-theme' : '' : ''}`} show={this.props.show} onHide={e => this.props.hideModal(false)}>
                    <Modal.Body className={"text-center"}>
                        <h4>Are you sure you want to delete {this.props[this.props.deleteType].title || 'Untitled'}?</h4>
                        <p>This will permanently remove this from the event, once saved.  In addition, the following data will also be deleted:</p>

                        <ul>
                            {this.props.deleteCompetitions.map((comp, i) => {

                                return (
                                    <li key={i}>{comp.code}</li>
                                )
                            })}
                        </ul>

                        <Button variant={"flat"} onClick={e => this.props.hideModal(false)}><Close /> Cancel</Button> &nbsp;

                        <Button variant={"danger"} onClick={e => {
                            //this.props.history.push('/app/logout')
                            if (this.props.onDelete) {
                                this.props.onDelete();
                            }
                        }}><Trash /> Delete</Button>

                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user,
    single: state.single
});

const mapDispatchToProps = dispatch => ({
    post: (uri, item, token) => {
        dispatch(singleActions.post(uri, item, token));
    },
    postFile: (uri, fd, token) => {
        dispatch(singleActions.postFile(uri, fd, token));
    },
    patch: (uri, id, item, token) => {
        dispatch(singleActions.patch(uri, id, item, token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteModal));

class DeleteModalContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default DeleteModalContainer;
