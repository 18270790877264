/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 3/26/2021
 */

const defaultState = {
  income: 0,
  dancers: 0,
  schools: 0,
  videos: 0,
  expectedVideos: 0,
  entryCompetitions: 0,
  generatedReports: {
    competitions: [],
    competitors: [],
    transactions: [],
    awards: [],
    videos: [],
    dancerNumbers: [],
    judgingSheets: [],
    checkins: [],
    lateEntries: [],
    stageHeaders: []
  },
  revenue: {
    title: {
      text: 'Revenue'
    },
    series: [
      {
        data: [1, 2, 3]
      }
    ]
  },
  transactions: [],
  entries: [],
  competitions: [],
  grades: []
};

const reports = (state = defaultState, action) => {
  switch (action.type) {
    case 'GOT_COMPETITIONS':
      return {
        ...state,
        competitions: action.payload.competitions,
        grades: action.payload.grades
      };
    case 'GOT_GENERATED_REPORTS':
      return {
        ...state,
        generatedReports: action.payload
      };
    case 'CLEAR_GENERATED_REPORTS':
      state.generatedReports[action.payload] = [];

      return {
        ...state
      };
    case 'ADD_REPORT':
      return {
        ...state,
        generatedReports: {
          ...state.generatedReports,
          [action.payload.type]: [action.payload].concat(
            state.generatedReports[action.payload.type]
          )
        }
      };
    case 'GOT_KPI':
      return {
        ...state,
        income: action.payload.income || 0,
        dancers: action.payload.dancers || 0,
        schools: action.payload.schools || 0,
        champDancers: action.payload.champDancers || 0,
        gradesDancers: action.payload.gradesDancers || 0,
        videos: action.payload.videos || 0,
        expectedVideos: action.payload.expectedVideos || 0,
        entryCompetitions: action.payload.entryCompetitions || 0
      };
    case 'GOT_REVENUE':
      return {
        ...state,
        revenue: {
          ...state.revenue,
          ...action.payload
        }
      };
    default:
      return state;
  }
};

export default reports;
