/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */

import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as tableActions from '../../../store/actions/table';
import Nav from 'react-bootstrap/Nav';
import UserForm from '../Users/UserForm';
import * as singleActions from "../../../store/actions/single";
import JoinOrganization from "../Organizations/JoinOrganization";
import * as SingleActions from "../../../store/actions/single";
import Cards from './Cards';
import Bound from '../../Common/Bound';

class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    menu = [
        {
            title: 'Account',
            path: '/app/settings/account',
            component: <UserForm />,
            dataSet: () => {
                this.props.getSingle("users", this.props.user.user._id, this.props.auth.token._id);
            }
        },
        {
            title: 'Payments',
            path: '/app/settings/payments',
            component: <Cards />
        },
        {
            title: 'Region',
            path: '/app/settings/region',
            component: <JoinOrganization />
        }
    ]

    loadData = () => {
        this.props.loadOnboarding(this.props.auth.token._id);
        let menuItem = this.menu.filter(item => item.path === this.props.location.pathname);
        if (menuItem.length > 0) {

            if (menuItem[0].dataSet) {
                menuItem[0].dataSet();
            }
        } else {
            return null;
        }
    }

    componentDidMount(props) {
        this.loadData();
    }

    componentDidUpdate(props, state) {
        if (props.location.pathname !== this.props.location.pathname) {
            this.loadData();
        }
    }

    renderItem = (item, i) => {
        return (
            <Nav.Link key={i} to={item.path} as={Link} className={`tab tab-${i} ${this.props.location.pathname === item.path ? 'active' : ''}`}>{item.title}</Nav.Link>
        )
    }

    renderContent = () => {
        let menuItem = this.menu.filter(item => item.path === this.props.location.pathname);
        if (menuItem.length > 0) {
            return (
                <Bound>
                    {menuItem[0].component}
                </Bound>

            );
        } else {
            return null;
        }
    }


    render() {
        return (
            <div className={'page-container elevated rounded page-container-slim'} style={{overflow: 'visible'}}>
                <div className={"tab-container"}>
                    <div className={"tab-row"}>
                        {this.menu.map((item, i) => {
                            return this.renderItem(item, i)
                        })}
                        <div className={"tab-highlight"} />
                        <div className={"tab-highlight-track"} />
                    </div>

                </div>
                <div className={"tab-content ex-pad"}>

                    {this.renderContent()}

                </div>


            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    user: state.user,
    single: state.single
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
    getSingle: (view, id, token) => {
        dispatch(singleActions.getSingle(view, id, token));
    },
    loadOnboarding: (token) => {
        dispatch(SingleActions.loadOnboarding(token));
    }
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(withRouter(Settings));

class SettingsContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default SettingsContainer;
