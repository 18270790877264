/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */
import Api from '../../utils/Api';
import {NotificationManager} from 'react-notifications';

export function setSidebar(st) {
    return dispatch => {
        dispatch({
            type: 'SET_SIDEBAR',
            payload: st
        })
    }
}

export function updateUserField(id, k, v, token) {
    return dispatch => {
        const api = new Api(token);
        api.patch('users', id, {[k]: v}, res => {
            try {
                dispatch({
                    type: "UPDATE_USER_FIELD",
                    payload: {key: k, val: v}
                })
            }catch (err) {
                NotificationManager.error("Please reload this view and try again", "An Error Occured")
            }

        })
    }
}

export function logout(token) {
    return dispatch => {
        const api = new Api(token);
        api.post('users/logout', {token}, (res) => {
            try {
                dispatch({
                    type: 'LOGOUT',
                    payload: null
                })

            } catch (err) {
                NotificationManager.error("Please reload this view and try again", "An Error Occured")
            }
        });

    }
}

export function register(credentials) {
    return dispatch => {
        const api = new Api();
        api.post('users/register', credentials, (res) => {
            if (res.data.status === 200) {
                try {
                    dispatch({
                        type: 'LOGIN',
                        payload: res
                    })
                }catch (err) {
                    NotificationManager.error("Please reload this view and try again", "An Error Occured")
                }

            } else {
                NotificationManager.error(res.data.data.message, 'Register Failed');
                try {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: res.data.data
                    })
                }catch (err) {
                    NotificationManager.error("Please reload this view and try again", "An Error Occured")
                }

            }
        })
    }
}

export function setBlankMessage(){
    return dispatch => {
        dispatch({
            type: 'SET_FORGOT_PASSWORD_MESSAGE',
            payload: ""
        })
    }
}

export function sendForgotPassword(email) {
    return dispatch => {
        const api = new Api();
        api.post('users/forgotPassword', {email}, (res) => {
            dispatch({
                type: 'SET_FORGOT_PASSWORD_MESSAGE',
                payload: "We'll send an email to this address if it exists"
            })
        });
    }
}

export function validateToken(email, token) {
    return dispatch => {
        const api = new Api();
        api.post('users/validateToken', {email, token}, (res) => {
            if (res.status === 200) {
                if (res.data.status === 200) {
                    if (res.data.data.valid) {
                        dispatch({
                            type: 'VALID_CODE',
                            payload: true
                        })
                    } else {
                        dispatch({
                            type: 'VALID_CODE',
                            payload: false
                        })
                    }
                } else {
                    dispatch({
                        type: 'VALID_CODE',
                        payload: false
                    })
                }
            } else {
                dispatch({
                    type: 'VALID_CODE',
                    payload: false
                })
            }

        });
    }
}

export function resetPassword(token, password) {
    return dispatch => {
        const api = new Api();
        api.post('users/resetPassword', {token, password}, (res) => {
            if (res.data.status === 200) {
                try {
                    dispatch({
                        type: 'LOGIN',
                        payload: res
                    })
                }catch (err) {
                    dispatch({
                        type: 'VALID_CODE',
                        payload: false
                    })
                    NotificationManager.error("Please reload this view and try again", "An Error Occured")
                }

            } else {
                NotificationManager.error('Check your username and password and try again.', 'Login Failed');
                try {
                    dispatch({
                        type: 'VALID_CODE',
                        payload: false
                    })
                }catch (err) {
                    NotificationManager.error("Please reload this view and try again", "An Error Occured")
                }

            }
        });
    }
}


export function login(credentials) {
    return dispatch => {
        const api = new Api();
        api.post('users/admin-login', credentials, (res) => {
            if (res.data.status === 200) {
                try {
                    dispatch({
                        type: 'LOGIN',
                        payload: res
                    })
                }catch (err) {
                    NotificationManager.error("Please reload this view and try again", "An Error Occured")
                }

            } else {
                NotificationManager.error('Check your username and password and try again.', 'Login Failed');
                try {
                    dispatch({
                        type: 'LOGIN_FAILED',
                        payload: res
                    })
                }catch (err) {
                    NotificationManager.error("Please reload this view and try again", "An Error Occured")
                }

            }
        })
    }
}

export function saveFavorites(data, token) {
    return dispatch => {
        const api = new Api(token);
        api.post('users/saveFavorites', {favorites: data}, res => {
        })
    }
}

export function deleteFavorite(id, token) {
    return dispatch => {
        const api = new Api(token);
        api.post('users/deleteFavorite', {id: id}, res => {
            if (res.status === 200) {
                dispatch({
                    type: 'REMOVE_FAVORITE',
                    payload: id
                })
            }
        })
    }
}

export function setTheme(name, token) {
    return dispatch => {
        dispatch({
            type: 'CHANGE_THEME',
            payload: name
        })
    }
}