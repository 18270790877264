/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */

import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {Formik} from 'formik';
import * as yup from 'yup';
import * as authActions from '../../../store/actions/auth';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {NotificationManager} from "react-notifications";
import { ArrowForward } from "react-ionicons";


const schema = yup.object({
    email: yup.string().email("Please enter a valid email address").required("This field is required"),
    email2: yup.mixed().required('Please re-enter your email address').test('match', 'Email addresses do not match', function (email2) {
        return email2 === this.parent.email
    }),
    name: yup.string().required("This field is required"),
    lname: yup.string().required("This field is required"),
    password: yup.string().required('Password can not be empty').min(8, 'Password needs to be at least 8 characters long'),
    password2: yup.mixed().required('Password can not be empty').test('match', 'Password does not match', function (password2) {
        return password2 === this.parent.password
    }),
    tos: yup.boolean().required('You must accept the terms of service'),
    privacy: yup.boolean().required('You must accept the privacy policy'),
    age: yup.boolean().required('You must be 18 years or older to register')
});

class Register extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            submitting: false,
            submitCount: 0,
        }
    }

    handleSubmit = (values) => {
        this.setState({submitting: true, submitCount: this.state.submitCount + 1});
        this.props.register(values);
    }

    componentDidUpdate(props) {
        if (props.auth.attempts < this.props.auth.attempts) {
            this.setState({submitting: false});
        }
    }

    render() {
        return (
            <div className={`login-form ex-pad`}>

                <h4 className="auth-title">Register</h4>
                <Formik
                    validationSchema={schema}
                    onSubmit={this.handleSubmit}
                    initialValues={{
                        email: '',
                        email2: '',
                        name: '',
                        lname: '',
                        password: '',
                        password2: '',
                        dancer: false,
                        teacher: false,
                        organizer: false,
                        tabulator: false,
                        judge: false,
                        parent: false,
                        tos: false,
                        privacy: false,
                        age: false
                    }}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                          touched,
                          isValid,
                          errors,
                          isSubmitting,
                        setFieldError,
                        setTouched,
                        setFieldTouched,
                        setErrors
                      }) => (

                        <Form noValidate onSubmit={handleSubmit}>
                            <Row>
                                <Col>
                                    <Form.Group controlId="name">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            placeholder="Enter name"
                                            value={values.name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isValid={touched.name && !errors.name}

                                        />
                                        {(this.state.submitCount > 0 || touched.name) && errors.name ?
                                            <div className={"text-danger"}>{errors.name}</div>
                                            : null}
                                    </Form.Group>
                                </Col>

                                <Col>
                                    <Form.Group controlId="lname">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="lname"
                                            placeholder="Enter name"
                                            value={values.lname}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isValid={touched.lname && !errors.lname}

                                        />
                                        {(this.state.submitCount > 0 || touched.lname) && errors.lname ?
                                            <div className={"text-danger"}>{errors.lname}</div>
                                            : null}
                                    </Form.Group>
                                </Col>
                            </Row>




                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    placeholder="Enter email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.email && !errors.email}

                                />
                                {(this.state.submitCount > 0 || touched.email) && errors.email ?
                                    <div className={"text-danger"}>{errors.email}</div>
                                    : null}
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail2">
                                <Form.Label>Confirm email address</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email2"
                                    placeholder="Enter email"
                                    value={values.email2}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.email2 && !errors.email2}

                                />
                                {(this.state.submitCount > 0 || touched.email2) && errors.email2 ?
                                    <div className={"text-danger"}>{errors.email2}</div>
                                    : null}
                            </Form.Group>

                            <div className={"spacer"} />
                            <div className={"divider"} />
                            <div className={"spacer"} />

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.password && !errors.password}
                                />
                                {(this.state.submitCount > 0 || touched.password) && errors.password ?
                                    <div className={"text-danger"}>{errors.password}</div>
                                    : null}
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword2">
                                <Form.Label>Password (again)</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="password2"
                                    placeholder="Password (again)"
                                    value={values.password2}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.password2 && !errors.password2}
                                />
                                {(this.state.submitCount > 0 || touched.password2) && errors.password2 ?
                                    <div className={"text-danger"}>{errors.password2}</div>
                                    : null}
                            </Form.Group>
                            <div className={"spacer"} />
                            <div className={"divider"} />
                            <div className={"spacer"} />

                            <h5>I am a (select all that apply)</h5>
                            <div className={"spacer"} />
                            <Form.Check
                                type="checkbox"
                                className="mb-2"
                                label="Dancer"
                                name="dancer"
                                value={values.dancer}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.dancer && !errors.dancer}
                            />
                            <Form.Check
                                type="checkbox"
                                className="mb-2"
                                label="Parent"
                                name="parent"
                                value={values.parent}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.parent && !errors.parent}
                            />

                            <Form.Check
                                type="checkbox"
                                className="mb-2"
                                label="TCRG"
                                name="teacher"
                                value={values.teacher}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.teacher && !errors.teacher}
                            />

                            <Form.Check
                                type="checkbox"
                                className="mb-2"
                                label="Tabulator"
                                name="tabulator"
                                value={values.tabulator}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.tabulator && !errors.tabulator}
                            />

                            {/*<Form.Check
                                type="checkbox"
                                className="mb-2"
                                label="Organizer"
                                name="organizer"
                                value={values.organizer}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.organizer && !errors.organizer}
                            />*/}

                            <Form.Check
                                type="checkbox"
                                className="mb-2"
                                label="ADCRG/BG/SD"
                                name="judge"
                                value={values.judge}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.judge && !errors.judge}
                            />

                            <div className={"spacer"} />
                            <div className={"divider"} />
                            <div className={"spacer"} />
                            <Form.Group controlId="tos">
                            <Form.Check
                                type="checkbox"
                                className="mb-2"
                                label={<div>I agree to the <a href={"https://www.feisfwd.com/terms"} target={"_blank"} rel="noopener noreferrer">Terms and Conditions</a></div>}
                                name="tos"
                                value={values.tos}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.tos && !errors.tos}
                            />
                                {(this.state.submitCount > 0 || touched.tos) && errors.tos ?
                                    <div className={"text-danger"}>{errors.tos}</div>
                                    : null}
                            </Form.Group>
                            <Form.Group controlId="privacy">
                            <Form.Check
                                type="checkbox"
                                className="mb-2"
                                label={<div>I agree to the <a href={"https://www.feisfwd.com/privacy"} target={"_blank"} rel="noopener noreferrer">Privacy Policy</a></div>}
                                name="privacy"
                                value={values.privacy}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.privacy && !errors.privacy}
                            />
                            {(this.state.submitCount > 0 || touched.privacy) && errors.privacy ?
                                <div className={"text-danger"}>{errors.privacy}</div>
                                : null}
                        </Form.Group>

                            <Form.Group controlId="tos">
                                <Form.Check
                                    type="checkbox"
                                    className="mb-2"
                                    label={<div>I am 18 years or older</div>}
                                    name="age"
                                    value={values.age}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.age && !errors.age}
                                />
                                {(this.state.submitCount > 0 || touched.age) && errors.age ?
                                    <div className={"text-danger"}>{errors.age}</div>
                                    : null}
                            </Form.Group>

                            <div className={"spacer"} />
                            <div className={"divider"} />
                            <div className={"hint-text text-center auth-hint"}>
                                Already have an account?  <Link to={"/a/login"}>Login here</Link>.
                            </div>
                            <div className={`text-center mb-20`}>
                                <Button variant="primary" disabled={this.state.submitting} onClick={e => {
                                    e.preventDefault();

                                    /*
                                    const schema = yup.object({
    email: yup.string().email("Please enter a valid email address").required("This field is required"),
    email2: yup.mixed().required('Please re-enter your email address').test('match', 'Email addresses do not match', function (email2) {
        return email2 === this.parent.email
    }),
    name: yup.string().required("This field is required"),
    lname: yup.string().required("This field is required"),
    password: yup.string().required('Password can not be empty').min(8, 'Password needs to be at least 8 characters long'),
    password2: yup.mixed().required('Password can not be empty').test('match', 'Password does not match', function (password2) {
        return password2 === this.parent.password
    }),
    tos: yup.boolean().required('You must accept the terms of service'),
    privacy: yup.boolean().required('You must accept the privacy policy'),
    age: yup.boolean().required('You must be 18 years or older to register')
});
                                     */
                                    if (!values.tos) {
                                        setFieldError('tos', 'You must accept the terms of service');
                                        setFieldTouched('tos', true);
                                    }

                                    if (!values.privacy) {
                                        setFieldError('privacy', 'You must accept the privacy policy');
                                        setFieldTouched('privacy', true);
                                    }

                                    if (!values.age) {
                                        setFieldError('age', 'You must be 18 years or older to register');
                                        setFieldTouched('privacy', true);
                                    }

                                    if (values.password !== values.password2) {
                                        setFieldError('password2', 'Password does not match');
                                        setFieldTouched('privacy', true);
                                    }

                                    if (values.email !== values.email2) {
                                        setFieldError('email2', 'Email addresses do not match');
                                        setFieldTouched('privacy', true);
                                    }

                                    setTouched({
                                        email: true,
                                        email2: true,
                                        name: true,
                                        lname: true,
                                        password: true,
                                        password2: true,
                                        dancer: true,
                                        teacher: true,
                                        organizer: true,
                                        judge: true,
                                        parent: true,
                                        tos: true,
                                        privacy: true,
                                        age: true
                                    })


                                    if (!values.tos || !values.privacy) {
                                        NotificationManager.error("You must agree to the terms of service and privacy policy to continue")

                                    } else if (!values.age){
                                        NotificationManager.error("You must be 18 to register an account.  Please have a parent create an account.")
                                    }else if (values.dancer === false && values.teacher === false && values.organizer === false && values.judge === false && values.parent === false && values.tabulator === false){
                                        NotificationManager.error("You must select at least one role.")
                                    }else{
                                        handleSubmit(values)
                                    }
                                }}>
                                    Register &nbsp; <ArrowForward />
                                </Button>
                            </div>
                        </Form>

                    )}

                </Formik>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    login: (credentials) => {
        dispatch(authActions.login(credentials));
    },
    register: (values) => {
        dispatch(authActions.register(values));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Register));

class RegisterContainer extends React.Component {
    render() {
        return (
            <div>

                    <Connected/>

            </div>
        )
    }
}

export default RegisterContainer;
