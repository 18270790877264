/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 3/28/2021
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import * as tableActions from '../../../../store/actions/table';
import urls from "../../../../utils/urls";
import placeholder from "../../../../assets/images/placeholder-person.png";
import dayjs from "dayjs";

class Payment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            preview: ''
        }
    }

    componentDidMount(props) {
        if (this.props.data.user.image) {
            this.setState({preview: urls.getS3Url(this.props.data.user.image.thumb || this.props.data.user.image.path)})
        }
    }

    componentDidUpdate(props, state) {

    }

    getDancerNumber = () => {
        let numbers = [];
        if (this.props.data.entry) {
            if (this.props.data.entry.sections) {
                numbers = this.props.data.entry.sections.filter(s => s.dancerNumber).map(s => `Dancer #${s.dancerNumber}`);
            }
        }

        return numbers.join(", ");
    }

    getDances = () => {

    }

    render() {
        let {data} = this.props;
        return (
            <div className={'plan'}>
                <div>
                    <Row>
                        <Col md={6} className={""}>
                        <span className={'row-name article-row'}>
                            <div className={"news-item-img"} style={{background: this.state.preview ? `url(${this.state.preview}), linear-gradient(0deg, rgba(0,0,0,.6), rgba(0,0,0,.4), rgba(0,0,0,.2), transparent)` : `url(${placeholder}), linear-gradient(0deg, rgba(0,0,0,.6), rgba(0,0,0,.4), rgba(0,0,0,.2), transparent)`}}></div>
                            <div className={"row-title"}>
                                {data.user.name ? data.user.name : ''}&nbsp;
                                {data.user.lname ? data.user.lname : ''}&nbsp;
                                {!data.user.name ? data.user.email : `(${data.user.email})`}
                            </div>
                            <div className={"row-content overflow-hidden"}>
                                {this.getDancerNumber()}
                            </div>

                        </span>
                        </Col>
                        <Col md={6}>
                            <div  className={"text-right text-hint price-container price-container-large"}>
                                <div className={"price"}>${data.price}</div>
                                {dayjs(data.createdAt).format("MMM DD YYYY @ hh:mm A")} &nbsp; | &nbsp; {data.status ? <span className={"text-success"}>Completed</span> : <span className={"text-danger"}>Incomplete</span>}

                            </div>
                            </Col>


                    </Row>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Payment));

class PaymentContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default PaymentContainer;
