/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 5/24/2020
 */

import React from 'react';
import {connect} from 'react-redux';
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer} from 'react-notifications';
import Auth from './Pages/Auth/Auth';
import PageContainer from './Pages/PageContainer';
import Onboarding from './Pages/Onboarding';
import PublicResults from "./Pages/Public/PublicResults";
import colorLogo from '../assets/images/color-logo.png';

class App extends React.Component {


    componentDidUpdate(prevProps, prevState, ss){
        if(prevProps.auth.token._id !== this.props.auth.token._id || this.props.location.pathname !== prevProps.location.pathname) {
            this.init();
        }

            if (this.props.location !== prevProps.location) {
                window.scrollTo(0, 0)
            }

    }

    componentDidMount(){
        this.init();

    }

    init = (props={}) => {
        if (this.props.auth) {
            if (!this.props.auth.token._id && (this.props.location.pathname.indexOf('/a/') === -1 && this.props.location.pathname.indexOf('/public/') === -1)) {

                this.props.history.push('/a/login');
            } else if (!this.props.auth.token._id && (this.props.location.pathname.indexOf('/onboarding') > -1)) {
                this.props.history.push('/a/login');
            } else if (!this.props.auth.token._id && this.props.location.pathname === '/' && this.props.user.user.step >= 100) {

                this.props.history.push('/app');
            } else if (this.props.location.pathname !== '/onboarding' && this.props.user.user.step < 100) {
                this.props.history.push('/onboarding');
            } else if (this.props.auth.token._id && this.props.location.pathname.indexOf('/a/') > -1) {
                this.props.history.push('/app');
            }
        }


    }

    render() {
        return (
            <div className={`feis-app theme-${this.props.user.user ? this.props.user.user.theme ? this.props.user.user.theme : 'pretty' : 'pretty'} 
            ${this.props.user.user ? this.props.user.user.darkTheme ? 'dark-theme' : '' : ''}`} id={'feis-app'}>
                <div className={"feis-app-container"}>
                    <img src={colorLogo} className={"print-only print-logo"} alt={"print only logo"} />


                            <Switch>
                                <Route path={"/"} exact={true} render={(props) => {
                                    if (this.props.auth.token._id) {
                                        return <Redirect to={"/app"}/>
                                    } else {
                                        return <Redirect to={"/a/login"} />
                                    }
                                }} />
                                <Route path={`/a`} component={Auth} />
                                <Route path={`/app`} component={PageContainer} />
                                <Route path={`/onboarding`} component={Onboarding} />
                                <Route path={`/public/results`} component={PublicResults} />
                            </Switch>

                    <NotificationContainer/>

                </div>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user,
});

 const mapDispatchToProps = dispatch => ({});
//const mapDispatchToPropsPromise = import("../store/actions/auth").then(mod => ({}));

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

class AppContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default AppContainer;
