/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 3/30/2021
 */

import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import * as tableActions from '../../../../store/actions/table';
import { LogoFacebook } from 'react-ionicons';
import { LogoInstagram } from 'react-ionicons';
import { Globe } from 'react-ionicons';
import dayjs from "dayjs";


class Details extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    componentDidMount(props) {

    }

    componentDidUpdate(props, state) {

    }

    links = [{
        key: 'url',
        logo: <Globe />
    },
        {
            key: 'fb',
            logo: <LogoFacebook />
        },
        {
            key: 'ig',
            logo: <LogoInstagram />
        }];


    getAge = (dateString) => {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    render() {
        return (
            <div className={'page-container'}>
                <div className={'page-header'}>
                    <Row>
                        <Col>

                            <div className={"profile-right"}>
                                {/*this.links.map((item, i) => {
                                    return this.props.single.single[item.key] !== "" ? (
                                        <Button variant={"flat btn-circle"} as={Link} to={{pathname: this.props.single.single[item.key]}} target={"_blank"}>{item.logo}</Button>
                                    ) : null
                                })*/}
                            </div>
                            <div className={"profile-left"}>
                                <h1>Details</h1>
                                <div className={"spacer"} />

                                <h5><b>Level:</b> <span>{this.props.single.single.grade}</span></h5>
                                <h5><b>Age:</b> <span>{this.getAge(this.props.single.single.birthdate)}</span></h5>
                                <h5><b>Date of Birth:</b> <span>{dayjs(this.props.single.single.birthdate).format('MM-DD-YYYY')}</span></h5>
                                <h5><b>School:</b> <span><Link to={`/app/schools/${this.props.single.single.school._id}/info`}>{this.props.single.single.school.name}</Link> </span></h5>
                                <h5><b>Region:</b> <span>{this.props.single.single.region.name}</span></h5>





                                <p></p>
                            </div>

                        </Col>
                    </Row>
                </div>


            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    single: state.single
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Details));

class DetailsContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default DetailsContainer;
